import { Injector } from '@angular/core';
import { MenuService } from '../svc/menuService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';

export class SavePackageCommand {

    constructor(
        private injector: Injector
    ) {
        this.menuService = this.injector.get(MenuService);
        this.menuService.register(Constants.MENU_SAVE_PACKAGE, this.cmd, Constants.ROUTE_PROJECTREPORTCHECKDETAIL_MAIN )
    }

    private menuService: MenuService;

    public cmd: AppCommand = AppCommand.createWithShow(
        ()=> {
            return false;
        },
        ()=> {
        } ,
        ()=> { return false; }
    );

}
<div fxLayout="row" fxFill class="full-height-bs">

    <div [fxFlex]="gridAssist.isDetailState ? '0 0 380px' : ''" class="sw-segment">
        <spinner *ngIf="!gridAssist.isDataReady"></spinner>
        <angular-slickgrid gridId="grid1" gridHeight="99%" gridWidth="98%" *ngIf="gridAssist.isDataReady"
            [columnDefinitions]="gridAssist.columnDefs" 
            [gridOptions]="gridAssist.gridOptions"
            [dataset]="gridAssist.items"
            (onAngularGridCreated)="gridAssist.gridReady($event)"
            (onGridStateChanged)="gridAssist.gridStateChanged($event)">
        </angular-slickgrid>    
    </div>

    <div fxFlex *ngIf='gridAssist.isDetailState'>
        <router-outlet></router-outlet>
    </div>

</div>

import * as CRS from '../api/CRS';

export class StdRule {

    id:string;
    name:string;
    ruleId:string;
    expressionChain: Array<StdRulePart>
    dirty:boolean;
    isValid:boolean;

    public serialise() : string {
        var id = this.id??this.name;
        let s: any = {id:id, ruleId:this.ruleId, name:this.name, fixScript:null, chain:[]};
        if (this.expressionChain) {
            this.expressionChain.forEach(v=> {
                if (v.element) {
                    let i:any = {id:v.element.id, val:v.contextValue};
                    s.chain.push(i);
                }
            });
            var ecLast = this.expressionChain[this.expressionChain.length -1];
            s.fixScript = ecLast.element.fixId;
            s.refreshOnAppUpdate = ecLast.element.refreshOnAppUpdate;
        }
        return JSON.stringify(s);
    }

    public static deserialise(value:string, standardsRuleGroup: CRS.RSCustomRuleGroup) : StdRule {
        var s: any = JSON.parse(value);
        let se: Array<CRS.RSCustomRuleElement> =standardsRuleGroup.elements;
        let prv: StdRulePart = null;
        let ret: StdRule = new StdRule();
        ret.expressionChain =[];
        ret.id = s.id;
        ret.name = s.name;
        ret.ruleId = s.ruleId;
        s.chain.forEach(c => {
            let lst = se.filter(v=>v.id == c.id);
            if (lst.length==0)
                console.log("cannot find chain element " + c.id);
            prv = StdRulePart.create(ret, lst[0], prv, c.val);
            ret.expressionChain.push(prv);
            se = lst[0].children;
        });
        return ret;
    }
}

export class StdRulePart {

    public static create(rule: StdRule, element:CRS.RSCustomRuleElement, prev: StdRulePart, contextValue: string): StdRulePart {
        let r = new StdRulePart();
        r.rule = rule;
        r.element = element;
        r.previous = prev;
        r.contextValue = contextValue;
        r.hasContextValue = ((element)?element.hasContextValue:false);
        return r;
    }

    contextValue:string;
    rule: StdRule;
    element:CRS.RSCustomRuleElement;
    previous: StdRulePart;
    hasContextValue:boolean;
}


import { Router } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { Component, Injector, OnInit, OnDestroy } from '@angular/core';
import { UtilService } from '../../svc/utilService';
import { CurrentConversionJobService } from '../../svc/currentConversionJobService';
import { CurrentApplicationService } from '../../svc/currentApplicationService';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { PlatformService } from '../../svc/platformService';
import { StateHelperService } from '../../svc/stateHelperService';
import { SelectWrapperList } from '../../shared-helper/select-wrapper';
import { ActionIdParts } from 'src/app/model/ActionIdParts';
import { CommandCollection } from '../../commands/command-collection';
import { Constants } from '../../api/Constants';

import * as CRS from '../../api/CRS';

@Component({
selector: 'conversion-task-convert',
templateUrl: './conversion-task-convert.html',
styleUrls: ['../../shared/css/view.scss', '../../shared/css/core.scss', '../../shared/css/convert.scss']
})
export class ConversionTaskConvert implements OnInit , OnDestroy {

    constructor(
        public currentConversionJob: CurrentConversionJobService,
        private currentApplication: CurrentApplicationService,

        private injector: Injector,
        private currentProject: CurrentProjectService,
        private platformService: PlatformService,
        private stateHelperService: StateHelperService,
        private utilService: UtilService
    ) { }

    public arguments:string;
    public pvadDirectory:string=null;
    public virtualiseFromRepack:boolean=false;
    public lastSpecifiedForMSI:string=null;
    public lastSpecifiedForOther:string=null;
    public useArpProductDetails:boolean=false;
    public useExtractTransform:boolean=false;
    public msiExtractOnly:boolean=false;
    public useResponseTransform: boolean=false;
    public useFixTransform: boolean=false;
    public includeAllCompanionFiles:boolean=true;
    public includeEvalResultsInReadme: boolean;
    public selectedCompanionFiles:Array<string> = [];
    public manualModificationRequired : boolean = false;
    public useExistingSnapshot: boolean= false;
    public siblingInstallers :CRS.RSGetApplicationSiblingInstallers=null;
    public dependencyList: SelectWrapperList<CRS.RSDependencyItem>;

    private subscriptions : Array<Subscription> = new Array<Subscription>();

    ngOnInit(): void {
        this.initialiseSwitches();
        this.currentConversionJob.updateTaskLaunchInfo();
        this.subscriptions.push(this.currentConversionJob.dataContext.onLoaded().subscribe(()=>{
            this._switchesInitialised=false;
            this.initialiseSwitches();
        }));
        this.subscriptions.push(this.stateHelperService.onIdChange().subscribe((v)=> {
            this.currentConversionJob.updateTaskLaunchInfo();
        }));
        this.includeEvalResultsInReadme = this.utilService.localStorageHelper.includeEvalResultsInReadme;
    }

    ngOnDestroy() : void {
        this.subscriptions.forEach(x=>x.unsubscribe());
    }

    public get application() : CRS.RSGetApplication {
        return this.currentApplication.dataItem;
    }

    public get applicationIsReady(): boolean {
        return this.currentApplication.dataContext.isReady;
    }

    public get applicationHasFixTransform(): boolean {
        return this.currentApplication.hasFixTransform();
    }

    public get applicationHasBeenRepackaged(): boolean {
        return this.currentApplication.hasBeenSuccessfullyRepackaged();
    }

    public get job(): CRS.RSGetActionDetails {
        return this.currentConversionJob.dataItem;
    }

    public get jobIdParts(): ActionIdParts {
        return this.currentConversionJob.idParts;
    }

    public get jobIsReady(): boolean {
        return this.currentConversionJob.dataContext.isReady;
    }

    public get jobAlwaysTargetsOriginalPackage(): boolean {
        return this.currentConversionJob.alwaysTargetsOriginalPackage();
    }

    public get jobIsVirtualisationConversionType() : boolean
    {
        return this.currentConversionJob.isVirtualisationConversionType();
    }

    public get isPublishTest():boolean {
        return this.currentConversionJob.idParts.conversionType == Constants.JOBTYPE_PUBLISHTEST;
    }

    public launchTask() {
        this.utilService.localStorageHelper.includeEvalResultsInReadme = this.includeEvalResultsInReadme;

        let appId = this.stateHelperService.details.applicationId;
        let projectId = this.stateHelperService.details.projectId;
        let pn :number = this.platformService.getCurrent(projectId);
        let fixTransformPlatform = (this.useFixTransform) ? pn : 0;
        let canUseFixTransform = this.utilService.conversionJobHelper.canUseFixTransform(this.currentApplication.dataItem,pn, this.currentConversionJob.idParts,this.virtualiseFromRepack);
        let convTypeStr = this.utilService.conversionJobHelper.getConvTypeStr(this.currentConversionJob.idParts, this.useFixTransform, canUseFixTransform);

        if (this.currentConversionJob.idParts.type=="P"){
        //     var parent : ApplicationActionsController = this.$scope.$parent.vm;
        //     let parentAction = parent.findAction(this.conversionType, this.conversionSubType);
        //     this.conversionService.publishApplication(parentAction.id, this.includeEvalResultsInReadme).then((data)=> {
        //         this.reload(data.jobId);
        //     });
        }
        else {
            let cmd = CommandCollection.CreateConversionTask;
            cmd.companionFiles = this.selectedCompanionFiles;
            cmd.args = this.arguments;
            cmd.virtualiseFromRepack = this.virtualiseFromRepack;
            cmd.fixTransformPlatform = fixTransformPlatform;
            cmd.useResponseTransform = this.useResponseTransform;
            cmd.useExtractTransform =this.useExtractTransform??false;
            cmd.msiExtractOnly =this.msiExtractOnly;
            cmd.useArpProductDetails = this.useArpProductDetails;
            cmd.usePVAD = this.currentConversionJob.usePvad;
            cmd.PVADDirectory = this.pvadDirectory;
            cmd.manualModificationRequired = this.manualModificationRequired;
            cmd.useExistingSnapshot=this.useExistingSnapshot;
             cmd.execute(convTypeStr);

            this.utilService.localStorageHelper.useArpProductProperties =this.useArpProductDetails;
        }
    }

    public argumentsChanged() : void {
        if (this.virtualiseFromRepack)
            this.lastSpecifiedForMSI = this.arguments;
        else
            this.lastSpecifiedForOther = this.arguments;
    }

    public canSpecifyPvad() :number {
        if (!this.currentConversionJob.isVirtualisationConversionType())
            return 0;
        if (this.siblingInstallers && this.siblingInstallers.installers.filter((x)=>x.installAsPrerequisite && x.selected).length>0)
            return 1;
        if (this.currentApplication.dataContext.isReady && this.currentApplication.dataItem.additionalVirtualisationConnectionGroupsRequired)
            return 2;
        return 0;
    }

    public siblingInstallAsPrereqChanged() : void {
        if (this.currentConversionJob.usePvad && this.canSpecifyPvad() != 0) 
        this.currentConversionJob.usePvad=false;
    }

    public hasAnySiblingInstallers(): boolean {
        return this.siblingInstallers && this.siblingInstallers.installers.length>0;
    }

    public siblingMoveUp(item: CRS.RSInstaller) : void {
        let prevIdx:number = item.order - 1;
        let prev = this.siblingInstallers.installers.filter(x=>x.order == prevIdx);
        if (prev.length== 1) {
            item.order = prevIdx;
            prev[0].order = prevIdx + 1;
        }
    }

    public hasFixTransformForCurrentPlatform(item:CRS.RSInstaller) : boolean {
        let pn :number = this.platformService.getCurrent(this.currentProject.dataContext.id);
        return item.platformsWithFixes.indexOf(pn) != -1;
    }

    public canLaunchTask() : boolean {
        let ret:boolean=true;
        if (!this.currentApplication.dataContext.isReady || !this.currentProject.dataContext.isReady) {
            ret=false;
        }
        else {
            if (this.currentApplication.dataItem.sourceAvailabilityState != Constants.SOURCE_AVAILABILITY_OK)
                return false;
            if (this.currentProject.dataItem.isSASTokenExpired) 
                return false;
            if (!this.currentConversionJob.taskLaunchInfoReady)
                return false;
            if (this.currentConversionJob.taskLaunchInfo.anyUnsatisfied)
                return false;
            if (this.currentConversionJob.idParts.conversionType == Constants.JOBTYPE_PUBLISHTEST)
                ret=false;
        }
        return ret;
    }

    public virtualiseFromRepackChanged(): void {
        if (this.virtualiseFromRepack) 
            this.arguments = this.lastSpecifiedForMSI || this.currentApplication.dataItem.defaultMsiArgsSilent;
        else 
            this.arguments = this.lastSpecifiedForOther || this.lastSpecifiedForOther;
    }

    public includeAllCompanionsChanged() {
        if (this.includeAllCompanionFiles) {
                this.currentApplication.dataItem.companionFiles.forEach(x=>{
                    if (this.selectedCompanionFiles.indexOf(x.companionFileId) == -1)
                        this.selectedCompanionFiles.push(x.companionFileId);
                });
        }
        else {
            this.selectedCompanionFiles=[];
        }
    }

    public sortSiblingInstallers(prop: string) {
        return this.siblingInstallers.installers.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
    }

    public companionFilesChanged(items: Array<string>) {
        this.selectedCompanionFiles = items;
        console.log(items);
    }

    public openSilentUrlLink() {
        window.open(this.currentApplication.dataItem.exeFlavourUrl);
    }

    public addApplicationPreReq() : void {
        var appId = this.currentApplication.dataContext.id;
        var omits = this.siblingInstallers.installers.map(x=>x.id);
        omits.push(appId);
    }

    private _switchesInitialised:boolean=false;
    private initialiseSwitches() : void
    {
        if (!this.currentApplication.dataContext.isReady || !this.currentConversionJob.dataContext.isReady || this._switchesInitialised) 
            return;

        this._switchesInitialised=true;

        if (!this.pvadDirectory)
            this.pvadDirectory=this.currentApplication.dataItem.installPath;

        if (this.currentConversionJob.idParts.conversionType != 1 && this.currentConversionJob.idParts.conversionType != 15 && this.currentApplication.dataItem.lastRepackSwitches)
            this.arguments = this.currentApplication.dataItem.lastRepackSwitches;

        if (!this.arguments)
        {
            this.arguments = (this.currentConversionJob.idParts.conversionType ==15)
                ? this.currentApplication.dataItem.recommendedSwitches
                : this.currentApplication.dataItem.recommendedSilentSwitches;
        }

        if (this.currentApplication.dataItem.isMsi)
        {
            this.lastSpecifiedForMSI =(this.currentConversionJob.idParts.conversionType == 15)
                ? this.currentApplication.dataItem.recommendedSwitches
                : this.currentApplication.dataItem.recommendedSilentSwitches;
        }
        else if (this.currentApplication.dataItem.lastRepackSwitches)
        {
            this.lastSpecifiedForOther = this.currentApplication.dataItem.lastRepackSwitches;
        }
        else
        {
            this.lastSpecifiedForOther = this.currentApplication.dataItem.recommendedSilentSwitches;
        }

        if (this.currentApplication.dataItem.repackageStatus == 4 && this.currentConversionJob.idParts.conversionType == 2) {
            this.virtualiseFromRepack=true;
            this.arguments = this.currentApplication.dataItem.defaultMsiArgsSilent;
        }
        if (this.currentApplication.dataItem.exeFlavourRepackageWarning && this.currentConversionJob.idParts.conversionType == 1){
            this.msiExtractOnly=true;
        }

        this.useArpProductDetails = this.utilService.localStorageHelper.useArpProductProperties;
        this.selectedCompanionFiles = this.currentApplication.dataItem.companionFiles.map(a=>a.companionFileId);
        this.useFixTransform= this.currentApplication.hasFixTransform(this.currentProject.platformNumber);
        this.useResponseTransform= this.currentApplication.dataItem.hasResponseTransform;
    }

}

import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'input-dialog',
    templateUrl: './input-dialog.html',
    styleUrls: ['./dialogs.scss']
})
export class InputDialog implements OnInit {

    public title:string;
    public texts:Array<string>;
    public form: FormGroup;
    public username: string;

    constructor(
        private dialogRef: MatDialogRef<InputDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
        this.title = this.data.title;
        this.texts = this.data.texts;
    }

    public closeDialog() {
        this.dialogRef.close(this.username);
    }

    public cancelDialog() {
        this.dialogRef.close("");
    }
}
import { Router } from '@angular/router';
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, Injector } from '@angular/core';
import { Subscription } from 'rxjs';
import { UtilService } from '../../svc/utilService';
import { CurrentConversionJobService } from '../../svc/currentConversionJobService'
import { CurrentApplicationService } from '../../svc/currentApplicationService';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { PlatformService } from '../../svc/platformService';
import { StateHelperService } from '../../svc/stateHelperService';
import { CommandCollection } from '../../commands/command-collection';

import * as CRS from '../../api/CRS';
import { Constants } from 'src/app/api/Constants';

@Component({
  selector: 'conversion-task-test',
  templateUrl: './conversion-task-test.html',
  styleUrls: ['../../shared/css/core.scss', '../css/convert.scss']
})
export class ConversionTaskTest implements OnInit, OnDestroy {

    constructor(
        private injector: Injector,
        private currentApplication: CurrentApplicationService,
        public currentConversionJob: CurrentConversionJobService,
        private stateHelperService: StateHelperService,
        public currentProject: CurrentProjectService,
        private platformService: PlatformService,
        private utilService: UtilService
    ) { }

    public siblingInstallers: CRS.RSGetApplicationSiblingInstallers=null;
    public useExtractTransform: boolean=false;
    public useResponseTransform: boolean;
    public virtualiseFromRepack:boolean;
    public useFixTransform: boolean;
    public manualModificationRequired: boolean=false;
    public useRepack: boolean=true;
    public companionFiles: Array<string> =[];

    private subscriptions : Array<Subscription> = new Array<Subscription>();

    ngOnInit(): void {
        this.ensureSiblingInstallers();
        this.currentConversionJob.updateTaskLaunchInfo();
        this.subscriptions.push(this.stateHelperService.onIdChange().subscribe((v)=> {
            this.ensureSiblingInstallers();
            this.currentConversionJob.updateTaskLaunchInfo();
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach(x=>x.unsubscribe());
    }

    public get application(): CRS.RSGetApplication {
        return this.currentApplication.dataItem;
    }
    public get applicationIsReady(): boolean {
        return this.currentApplication.dataContext.isReady;
    }

    public canUseFixTransform(): boolean {
        if (!this.currentProject.dataContext.isReady)
            return false;
        var platformNo =this.platformService.getCurrent(this.currentProject.dataContext.id);
        switch(this.currentConversionJob.idParts.conversionType) // this is the branch of the tree, not the type of the current node
        {
            case 0:
                return this.currentApplication.dataContext.isReady && this.currentApplication.dataItem.isMsi && this.currentApplication.hasFixTransform(platformNo);
            case 1:
                return this.canUseRepackaged() && this.currentApplication.hasFixTransform(platformNo);
            case 2:
                return this.virtualiseFromRepack && this.currentApplication.hasFixTransform(platformNo) && this.currentConversionJob.dataItem.type != 'T';
            default:
                return false;
        }
    }

    public canUseRepackaged() : boolean {
        return this.currentApplication.hasBeenSuccessfullyRepackaged();
    }

    public canLaunchTask(): boolean {
        if (!this.applicationIsReady)
            return false;
        if (!this.currentConversionJob.dataContext.isReady)
            return false;
        if (!this.currentProject.dataContext.isReady)
            return false;
        if (!this.currentApplication.dataContext.isReady)
            return false;
        if (this.currentApplication.dataItem.sourceAvailabilityState != Constants.SOURCE_AVAILABILITY_OK)
            return false;
        if (this.currentProject.dataItem.isSASTokenExpired) 
            return false;
        if (this.currentConversionJob.idParts.conversionType == Constants.JOBTYPE_MSIX && !this.currentProject.hasMsixPublisherDetails()) 
            return false; 
        if (this.currentConversionJob.idParts.type == "RTC" && this.currentApplication.dataItem.hasUserInterface != 1)
            return false;
        return true;
    }

    public isMsiMod()    {
        return this.currentConversionJob.dataContext.isReady && this.currentConversionJob.idParts.conversionType == Constants.JOBTYPE_MSIMODIFY;
    }

    public getPromoPara0(): string {
        if (this.currentConversionJob.idParts.conversionType == Constants.JOBTYPE_DISCOVERY)
            return "Getting application discovery right, the first time is the most important part of application packaging.";
        else
            return "Quality Assurance (QA) is time-consuming, technically challenging and usually requires the most experienced team members to deliver.";
    }

    public getPromoPara1(): string {
        if (this.currentConversionJob.idParts.conversionType == Constants.JOBTYPE_DISCOVERY)
            return null;
        else
            return "Automate the most difficult part—reduce errors while automating your audit trail";
    }

    public getPromoClass(): string {
        if (this.currentConversionJob.idParts.conversionSubType == "Runtime")
            return "promo-qa";
        else if (this.currentConversionJob.idParts.conversionType == Constants.JOBTYPE_DISCOVERY)
            return "promo-discovery";
        else
            return "promo-test";
    }

    public getPromoBullets(): Array<string> {
        if (this.currentConversionJob.idParts.conversionType == Constants.JOBTYPE_DISCOVERY){
            return [
                "Integration with ServiceNow",
                "Automated dependency and pre-requisite analysis",
                "Automated recording (PDF or video)",
                "Output can be used for application packaging",
                "Shareable online platforms allow for distributed Discovery"
            ];
        }
        else {
            return [
                "Tunable process and customizable forms",
                "Automatic QA form completion",
                "Install/Roll-back/Repair/Uninstall testing",
                "Multiple and cross-build testing"
            ];
        }
    }

    public getCannotLaunchText(): string {
        if (this.currentProject.dataItem.isSASTokenExpired)
            return "SAS key has expired";
        if (this.currentConversionJob.idParts.conversionType == Constants.JOBTYPE_MSIX && !this.currentProject.hasMsixPublisherDetails()) 
            return "MSIX publisher details must be provided. Enter these details in Settings/Conversion";
            if (this.currentConversionJob.idParts.type == "RTC" && this.currentApplication.dataItem.hasUserInterface != 1)
            return "No user interface is available to respond to";
            if (this.currentConversionJob.idParts.type == "RTC" && this.currentApplication.dataItem.hasUserInterface != 1)
            return "No user interface is available to respond to";
        if (this.currentApplication.dataItem.sourceAvailabilityState == Constants.SOURCE_AVAILABILITY_MISSING)
            return "Source is missing";
        if (this.currentApplication.dataItem.sourceAvailabilityState == Constants.SOURCE_AVAILABILITY_NOTDEFINED)
            return "Source undefined";
        return "Unknown error";
    }

    public companionFilesChanged(items: Array<string>) {
        this.companionFiles = items;
        console.log(items);
    }

    public launchTask() {

        this.useRepack = 
            this.canUseRepackaged() &&
            this.currentConversionJob.idParts.conversionSubType == Constants.TESTINSTALL_SUBTYPE_RUNTIME;

        let appId = this.currentApplication.dataContext.id;
        let projectId = this.currentApplication.dataItem.projectId;
        let pn :number = this.platformService.getCurrent(projectId);
        let hasFixForPn = this.currentApplication.dataItem.platformsWithFixTransform.indexOf(pn) != -1;
        let fixTransformPlatform =
         ((this.currentConversionJob.idParts.conversionSubType == Constants.TESTINSTALL_SUBTYPE_FIXED && hasFixForPn) ||
         (this.currentConversionJob.idParts.conversionSubType == Constants.TESTINSTALL_SUBTYPE_RUNTIME && hasFixForPn) ||
         (this.currentConversionJob.idParts.conversionSubType == Constants.TESTINSTALL_SUBTYPE_REPACK && hasFixForPn)) ? pn : 0;
        let canUseFixTransform = this.utilService.conversionJobHelper.canUseFixTransform(this.currentApplication.dataItem,pn, this.currentConversionJob.idParts,this.virtualiseFromRepack);
        let convTypeStr = this.utilService.conversionJobHelper.getConvTypeStr(this.currentConversionJob.idParts, this.useFixTransform, canUseFixTransform);

        let cmd = CommandCollection.CreateConversionTask;
        cmd.fixTransformPlatform = fixTransformPlatform;
        cmd.useResponseTransform = this.currentConversionJob.idParts.conversionSubType == Constants.TESTINSTALL_SUBTYPE_FIXED && this.currentApplication.dataItem.hasResponseTransform;
        cmd.useExtractTransform = this.useExtractTransform??false;
        cmd.manualModificationRequired = this.manualModificationRequired??false;
        cmd.companionFiles = this.companionFiles;
        cmd.virtualiseFromRepack=this.useRepack;
        cmd.execute(convTypeStr);
    }

    private _siblingInstallersApplicationId:string=null;
    private ensureSiblingInstallers() {
        if (!this._siblingInstallersApplicationId || this._siblingInstallersApplicationId != this.currentApplication.dataContext.id) {
            this._siblingInstallersApplicationId=this.currentApplication.dataContext.id;
            this.currentApplication.getSiblingInstallers().then((data)=> {
                this.siblingInstallers = data;
            })
        }
    }

    public get isRuntimeTest()  : boolean {
        return this.currentConversionJob.idParts.conversionSubType == Constants.TESTINSTALL_SUBTYPE_RUNTIME;
    }
}

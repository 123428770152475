import * as STHS from '../../svc/stateHelperService';

import * as CRS from '../../api/CRS';
import * as SVC from '../../api/SVC';

import { PollingService } from '../../svc/pollingService';
import { StylingService } from 'src/app/svc/stylingService';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Constants } from 'src/app/api/Constants';

@Component({
    selector: Constants.ROUTE_ADMINTASKMGR,
    templateUrl: './admin-task-mgr.html',
    styleUrls: ['../../shared/css/view.scss', '../../shared/css/tables.scss', './admin-task-mgr.scss']
  })
  
export class AdminTaskMgr implements OnInit, OnDestroy {

    constructor(
        public route: ActivatedRoute,
        private xadminService: SVC.AdminService,
        private stateHelperService: STHS.StateHelperService,
        private pollingService: PollingService,
        private stylingService: StylingService
    ) {
        
    }

    public tasks: CRS.RSAdminGetTaskMgr=null;
    public currentTask : CRS.RSAdminTaskMgrItem=null;
    public pstr: string;
    public qstr: string;
    public tstr: string;

    public stateHelperInstance: STHS.StateHelperInstance;

    ngOnInit(): void {
        this.stateHelperInstance= this.stateHelperService.register(Constants.ROUTE_ADMINTASKMGR);
        this.stylingService.waitForApiEndpoint(()=>this.start());
    }

    ngOnDestroy(): void {
        this.pollingService.stopSequence(Constants.POLLING_ID_TASKMGR);
    }

    public isDataReady() : boolean {
        return this.tasks != null;
    }

    private start() {
        this.pollingService.startPollingSequence(
            Constants.POLLING_ID_TASKMGR,
            Constants.POLLING_TASKS_INTVL,
            -1,
            (resolve, reject) => { this.update(resolve, reject); }
        );
    }

    private update(resolve, reject): void {
          this.xadminService.adminGetTaskMgr().then((response) => {
            this.tasks = response.data;
            this.qstr = `(${this.tasks.queues.length})`;
            this.pstr = `(${this.tasks.items.length})`;
            this.tstr = `(${this.tasks.timerActivities.length})`;
            resolve();
        }, () => {
            console.error('Server data couldn\'t be loaded');
            reject();
        });
    }

}


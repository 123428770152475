<div class="full-height-bs sw-outer" fxLayout="column">

    <div fxFlex="none">
        <h4 class="sw-upper sw-subtitle">Task Summary</h4>
    </div>

    <div fxFlex fxLayout="column" fxLayoutGap="20px" fxFill>
        <div *ngFor="let we of items" fxFlex="none" fxLayout="row" fxLayoutGap="15px">

            <div fxFlex="none">
                <mat-icon role="listitem" class="cs-state cs-state-unknown" *ngIf="we.state == -1">not_interested</mat-icon>
                <mat-icon role="listitem" class="cs-state cs-state-green" *ngIf="we.state == 0">check_circle</mat-icon>
                <mat-icon role="listitem" class="cs-state cs-state-amber" *ngIf="we.state == 1">highlight_off</mat-icon>
                <mat-icon role="listitem" class="cs-state cs-state-red" *ngIf="we.state == 2">highlight_off</mat-icon>
            </div>

            <div fxFlex fxLayout="column" matTooltip="{{we.state != 0 && we.state != -1 ? we.tooltip : ''}}">
                <h4 fxFlex>{{we.text}}</h4>    
            </div>  
        </div>  
        <div fxFlex></div>
    </div>

</div>  
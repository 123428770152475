<div fxLayout="row" class="sw-dialog-header">
    <div fxFlex="none">
        <span class='sw-dialog-title-icon'>
            <span ng-if='dialogClass === "success"'><i class="material-icons">check_circle</i></span>
            <span ng-if='dialogClass === "error"'><i class="material-icons">check_circle</i></span>
            <span ng-if='dialogClass === "info"'><i class="material-icons">error</i></span>
            <span ng-if='dialogClass === "warning"'><i class="material-icons">warning</i></span>
        </span>
    </div>
    <div fxFlex>
        <h2 mat-dialog-title>{{title}}</h2>
    </div>  
    <div fxFlex="none">
        <button mat-button (click)='closeDialog(false)'>
            <mat-icon>cancel</mat-icon>
        </button>
    </div>
</div>  

<mat-dialog-content [formGroup]="form">
    <p *ngFor='let text of texts' [innerHTML]="text" style="width:800px;height:100px"></p>
</mat-dialog-content>

<mat-dialog-actions>
    <div fxLayout="row" class="full-width">
        <div fxFlex></div>
        <div fxFlex="none">
            <button mat-raised-button (click)="closeDialog(true)">
                <mat-icon>done</mat-icon>
                Accept
            </button>
            <button mat-raised-button (click)="cancelDialog()">
                <mat-icon>cancel</mat-icon>
                Close
            </button>
        </div>
    </div>
</mat-dialog-actions>
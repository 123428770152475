import { Injectable, Injector, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from './svc/errorService';

@Injectable()
export class AppErrorHandler implements ErrorHandler {

  constructor(private injector: Injector) { }
  
  handleError(error: any) {
    
    
    if (error.message.indexOf("login.microsoftonline.com") != -1)
      alert(`Login err ${error.message}`);
    if (error.message == "Uncaught (in promise): ObjectUnsubscribedError: object unsubscribed")
      return;
    // Can't find a way to handle this error
    if (error.message == "Cannot read properties of null (reading 'width')" && error.stack.indexOf("getCanvasWidth")>0)
      return;

    const errorService = this.injector.get(ErrorService);
    errorService.show(error.name, error.message, error.stack, null);

  }

}
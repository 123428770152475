import { Injector } from '@angular/core';
import { MenuService } from '../svc/menuService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';
import { UtilService } from '../svc/utilService';

export class ToggleViewInternalTablesCommand {

    constructor(
        private injector: Injector
    ) {
        this.menuService = this.injector.get(MenuService);
        this.utilService=this.injector.get(UtilService);

        this.menuService.register(Constants.MENU_TOGGLE_SHOW_FIXED_ISSUES, this.cmd, Constants.ROUTE_PROJECTREPORTCHECKDETAIL_MAIN )
    }

    private menuService: MenuService;
    private utilService: UtilService;

    public cmd: AppCommand = AppCommand.create(
        ()=> {
            return true;
        },
        ()=> {
            this.utilService.viewInternalTablesInAppBrowse = !this.utilService.viewInternalTablesInAppBrowse;
        } 
    );

}
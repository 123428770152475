import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { CHKINFO } from '../model/CHKINFO';
import { AssessmentGroupService } from '../api/SVC';

import * as CRS from '../api/CRS';

@Injectable({
    providedIn: 'root'
})
export class CheckInfoService {

    private checkInfo : { [key:string] : CHKINFO} = {};

    constructor( 
        private assessmentGroupService: AssessmentGroupService 
    ) { 
    }

    private _subLoaded: Subject<CHKINFO> =  new Subject<CHKINFO>();
    public onLoaded(): Subject<CHKINFO> {
        return this._subLoaded;
    }

    public get(checkId: string, ruleId: string) : CHKINFO {
        let k : string = checkId + "+" + ruleId;
        let ci : CHKINFO = this.checkInfo[k];
        if (ci) {
            if (ci.state == 1)
                return ci;
        }
        else {
            this.loadCheckInfo(checkId, ruleId);
        }
        return null;
    }

    private loadCheckInfo(checkId: string, ruleId: string): Subject<CHKINFO> {

        let k : string = checkId + "+" + ruleId;
        let v :CHKINFO = this.checkInfo[k];
        if (v) {
            this._subLoaded.next();
            return;
        }

        let chkInfo : CHKINFO = new CHKINFO();
        chkInfo.state = 0;
        this.checkInfo[k] = chkInfo;

        this.assessmentGroupService.getCheckInfo(checkId, ruleId).then((response) => {
            chkInfo.checkId = response.data.checkId;
            chkInfo.checkName = response.data.checkName;
            chkInfo.description = response.data.description;
            chkInfo.nextSteps = response.data.moreInfo;
            chkInfo.state = 1;

            this._subLoaded.next(chkInfo);
        });
    }

    
}
 
import { Injector } from '@angular/core';
import { MenuService } from '../svc/menuService';
import { UtilService } from '../svc/utilService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';
import { CurrentApplicationService } from '../svc/currentApplicationService';

export class ViewPackageMultiCommand {

    constructor(
        private injector: Injector
    ) {
        this.menuService = this.injector.get(MenuService);
        this.utilService = this.injector.get(UtilService);
        this.currentApplication = this.injector.get(CurrentApplicationService);

        this.menuService.register(Constants.MENU_VIEW_PACKAGE_MULTI, this.command, "*"+Constants.ROUTE_PROJECTAPPLICATIONLANDING_MAIN )
    }

    private menuService: MenuService;
    private currentApplication: CurrentApplicationService;
    private utilService: UtilService;

    private command: AppCommand = AppCommand.createWithShow(
        ()=> {
            let ret : boolean =false;
            if (this.currentApplication.dataContext.isReady) {
                if (this.currentApplication.dataItem.isMsi)  
                     ret=true;
                else if (this.currentApplication.dataItem.repackageStatus == Constants.STATUS_SUCCESS) 
                    ret = true;
            } 
            return ret;
        },
        () => {
            //this.$state.go("editPackage", { "applicationId": this.stateHelperService.getApplicationId(), "platform" : 0});
        },
        ()=> { 
            let ret: boolean =  false;
            if (!this.currentApplication.dataContext.isReady || !this.currentApplication.isLoadedSuccessfully || !this.utilService.privileges?.msiEd)
                ret = false;
            else if (this.currentApplication.hasFixTransform()) /* Looking for multiple options orig + fixed */
                ret= true;
            return ret;
        }
    );
}
import { ActionIdParts } from '../model/ActionIdParts';

import * as CRS from '../api/CRS';

export class ConversionJobHelper {

    public canUseFixTransform(application: CRS.RSGetApplication, platformNo: number, idParts: ActionIdParts, convertFromRepack:boolean): boolean {

        var hasFixTransform = application.platformsWithFixTransform.indexOf(platformNo) != -1;
        var canUseRepack = application.noAssess && application.hasBeenAssessed;

        switch(idParts.conversionType) // this is the branch of the tree, not the type of the current node
        {
            case 0:
                return application && hasFixTransform;
            case 1:
                return canUseRepack && hasFixTransform;
            case 2:
                return convertFromRepack && hasFixTransform && idParts.type != 'T';
            default:
                return false;
        }
    }

    public getConvTypeStr(idParts: ActionIdParts, useFixTransform: boolean, canUseFixTransform: boolean) :string {
        switch(idParts.type)
        {
            case "RTC":
            case "MM":
            case "DISC":
            case "P":
            case "T":
                var subType = idParts.conversionSubType;
                let cmd = (idParts.type == "P") ? "Publish": "Test";
                return cmd + "(" + idParts.conversionType + "," + subType + ")";
                 break;
            case "L": 
                return "LIQUIDWARE";
            case "M":
                return "MSIX";
            case "I":
                return "INTUNE";
            default:
                return (idParts.conversionType==1) ? "Repackage" : "AppV5.1";
        }
    }

}
 <div fxLayout="column" class="full-width full-height" fxLayoutAlign="center center" >

    <spinner fxFlex *ngIf='!isDataReady()'></spinner>

    <div fxFlex *ngIf="isDataReady()"class="full-width">
        
        <angular-slickgrid  gridId="actGrid" gridHeight="100%" gridWidth="100%"
            [columnDefinitions]="gridColumnDefs" 
            [gridOptions]="gridOptions"
            [dataset]="historyItems" >
        </angular-slickgrid>

    </div>
    
</div>

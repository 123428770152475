
<div mat-dialog-title>
    <h4>IMPORT WINDOWS UPDATE VIRTUAL MACHINE</h4>
</div>

<div mat-dialog-content class="sw-pad">
    <mat-form-field appearance="outline" class="full-width-bs">
        <mat-label>Update Name</mat-label>
        <input matInput type="text" [(ngModel)]="updateName" style="width:100%" class="sw-dialog-content">
    </mat-form-field>   
    <mat-form-field appearance="outline" class="full-width-bs">
        <mat-label>Update Date</mat-label>
        <mat-select [(ngModel)]="selectedDate" aria-label="Update date" aria-placeholder="Update date" style="margin: 2px">
            <mat-option [value]="x" *ngFor="let x of getPossibleDates()">{{x}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>

<div mat-dialog-actions fxLayout='row'>
    <div fxFlex></div>
    <button mat-button (click)='closeDialog()' [disabled]="!isValid()">
        <mat-icon>done</mat-icon>
        <span>Accept</span>
    </button>
    <button mat-button (click)='cancelDialog()'>
        <mat-icon>cancel</mat-icon>
        <span>Cancel</span>
    </button>
</div>


import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { CurrentApplicationService } from '../../svc/currentApplicationService';
import { UtilService } from '../../svc/utilService';

@Component({
  selector: 'project-application-detail-header',
  templateUrl: './project-application-detail-header.html',
  styleUrls:['./project-applications.scss', '../../shared/css/forms.scss', '../../shared/css/buttons.scss']
})
export class ProjectApplicationDetailHeader implements OnInit, OnDestroy {

  constructor(
      private currentApplication: CurrentApplicationService,
      public utilService: UtilService
  ) { }

  private subscriptions : Array<Subscription> = new Array<Subscription>();

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x=>x.unsubscribe());
  }

  public get application() {
    return this.currentApplication.dataItem;
  }

  public isDataReady() : boolean {
    return this.currentApplication.dataContext.isReady;
  }

}

import { Directive, ElementRef, Input, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[image-size]'
})
export class ImageSizeDirective implements AfterViewInit {  
    constructor(private el: ElementRef) {
    }

    ngAfterViewInit(): void {  
        if (this.sizeValue)
            this.el.nativeElement.style.fontSize = this.sizeValue;  
        if (this.sizeWeight)
            this.el.nativeElement.style.fontWeight = this.sizeWeight;
    }
    @Input('size') sizeValue: string;  
    @Input('weight') sizeWeight: string  
}
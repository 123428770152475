import * as Enum from '../api/Enum';

export class CLColumn {

	constructor(name:string, heading:string, columnType:Enum.GridColumnType, field:string, canSort:boolean, filterType:Enum.GridFilterType, width:number, isFixed:boolean, properties:string, isDynamic: boolean) {
        this.id=name;
		this.name = heading;
		this.columnType = columnType;
        this.field = field;
        this.width = width;
		this.isFixed= isFixed;
		this.isDynamic = isDynamic;
		this.canSort = canSort;
		this.filterType = filterType;
        this.properties=properties;
        this.propValues = {};
        const regex = new RegExp('^\\[([a-z]+)\\]\.(.+)', 'g');
        var m = regex.exec(field);
        if (m && m.length==3) {
            this.field = m[1];
            this.fieldExtended=m[2];
        }
		if (this.properties!=null) {
			this.properties.split(',').forEach(p=>{
				var kvp = p.split(':');
				this.propValues[kvp[0]]=kvp[1];
			});
		}
    };
    
    public id:string;
	public name: string;
    public heading: string;
    public width:number;
	public columnType: Enum.GridColumnType;
    public field: string;
    public fieldExtended:string;
	public filterType:Enum.GridFilterType;
	public canSort:boolean;
	public isFixed: boolean;
	public properties:string;
    public isDynamic: boolean;
	private propValues: { [id: string]: string; }
	public getValue(key:string) : string {
		return this.propValues[key];
	}
}

<div fxLayout="column" fxFill class="sw-segment">

    <div fxFlex class="full-height-bs">
        <table class="sw-hor-pad sw-simple-table full-width-bs sw-pad">
            <thead>
                <tr>
                    <th></th>
                    <th>Image</th>
                    <th style="width:160px">Operating System</th>
                    <th style="width:90px">Date</th>
                    <th style="width:110px">Last Updated</th>
                    <th style="width:110px">Image Status</th>
                    <th style="width:70px">Active</th>
                    <th style="width:60px">Queue#</th>
                    <th style="width:50px">VM#</th>
                    <th style="width:220px" class="sw-xcheck-tbl">Progress</th>
                    <th style="width:60px">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let wu of windowsUpdates | callback:filterUpdateType">
                    <td>
                        <mat-icon *ngIf="!wu.imageExists" class="sw-error" title="VHD associated with this update is missing">warning</mat-icon>
                    </td>
                    <td>{{wu.id}}<span class="sw-building-xcheck-vm" *ngIf="wu.buildingCount>0">&nbsp;&nbsp;({{getBuildingText(wu)}})</span></td>
                    <td>{{wu.operatingSystemId}}</td>
                    <td>
                        <span style="padding-left:20px;">{{wu.month}}</span>
                    </td>
                    <td style="padding-left:20px">{{wu.imageStatusLastChangedExpression}}</td>
                    <td style="padding-left:20px">
                        <div fxLayout="column">
                            <div fxFlex>{{wu.imageStatusDescription}}</div>
                            <div fxFlex="none" *ngIf="wu.imageStatus == 1">
                                <mat-progress-bar mode="determinate" (value)="wu.percentageComplete"></mat-progress-bar>
                            </div>
                        </div>  
                    </td>
                    <td>
                        <mat-slide-toggle [(ngModel)]="wu.isRunning"  aria-label="Accepting x-check tasks" (change)="setRunningState(wu)"></mat-slide-toggle> 
                    </td>
                    <td style="text-align:right;padding-right:20px">
                        <span *ngIf="wu.runCandidateCount > 0">{{wu.runCandidateCount}}</span>
                    </td>
                    <td style="text-align:right;padding-right:20px">
                        <span *ngIf="wu.vmCount > 0">{{wu.vmCount}}</span>
                    </td>
                    <td class="sw-right-tbl" class="sw-xcheck-tbl">
                        <distribution-block [values]="getDistribution(wu)" [styling]="getDistributionStyling()" *ngIf="wu.succeededCount + wu.inProgressCount + wu.remainingCount + wu.failedCount > 0"></distribution-block>
                    </td>
                    <td>
                        <div fxLayout="row">
                            <div fxFlex="50">
                                <button mat-button (click)="retireUpdate(wu)" *ngIf="wu.canRetire" class="sw-button-thin md-primary" title="Retire">
                                    <i class="material-icons" image-size="16pt">delete</i>
                                </button>
                            </div>
                            <div fxFlex="50">
                                <button mat-button (click)="buildUpdate(wu)" *ngIf="wu.canBuild" class="sw-button-thin" title="Build image">
                                    <i class="material-icons" image-size="16pt">build</i>
                                </button>
                            </div>
                        </div>  
                    </td> 
                </tr> 
            </tbody>
        </table>
    </div>

</div>
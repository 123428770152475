import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { Constants } from './../../api/Constants';
import { StateHelperService } from '../../svc/stateHelperService';
import { AppCommand } from '../../model/AppCommand';
import { MenuService } from 'src/app/svc/menuService';
import { QueryRuleHelper } from './queryRuleHelper';

import * as CRS from '../../api/CRS';

@Component({
  selector: Constants.ROUTE_PROJECTQUERIESSTANDARDS,
  templateUrl: './project-queries-standards.html',
  styleUrls: ['./project-queries-standards.scss']
})
export class ProjectQueriesStandards implements OnInit {

  constructor(
    private menuService:MenuService,
    private stateHelperService: StateHelperService,
    public currentProject:CurrentProjectService
  ) { 
    this.qrh = new QueryRuleHelper(this.currentProject, Constants.RULE_GROUP_STANDARDS);
  }
  
    public standardsCustomRuleGroup: CRS.RSCustomRuleGroup = null;
    public queryResponse: CRS.RSExecuteQuery;
    public qrh: QueryRuleHelper;

    ngOnInit(): void {
        this.menuService.register(Constants.MENU_IMPORT_STANDARDS_CHECKS, this.importStandardsChecks, Constants.ROUTE_PROJECTQUERIESSTANDARDS);
        this.currentProject.ensureCustomRuleSets(Constants.RULE_GROUP_STANDARDS);
    }

    public isReady() : boolean {  
        return this.currentProject.dataContext.isReady && this.qrh.isReady;
    }

    private importStandardsChecks : AppCommand = AppCommand.createWithShow(
        ()=> {
            return true;
        },
        () => {
            // this.$mdDialog.show( {
            //     controller:ImportStandardsChecksDialogController, 
            //     controllerAs:'vm',
            //     parent: angular.element(document.body),
            //     templateUrl: 'app/views/queryView/ruleGroup/importStandardsChecksDialog.html',
            //     clickOutsideToClose:true,
            //     bindToController:true
            // }).then((ans)=> {
            //     if (ans) {
            //         this.updated=false;
            //         this.refreshRules(true);
            //     }
            // });

        },
        ()=> {
            return this.stateHelperService.details.ruleGroupId == Constants.RULE_GROUP_STANDARDS;
        }
    );

}

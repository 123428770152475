<div fxLayout="column" class="full-height">

    <div fxFlex="none" fxLayout="row" fxLayoutGap="20px" *ngIf="metaData">
        <mat-form-field fxFlex="25" appearance="outline">
            <mat-label>Entity</mat-label>
            <mat-select [(ngModel)]='queryInput.entity' placeholder="Select Entity">
                <mat-option *ngFor='let item of metaData.entities' [value]='item'>{{item.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex="25" appearance="outline">
            <mat-label>Attribute</mat-label>
            <mat-select [(ngModel)]='queryInput.attribute' placeholder="Select Attribute">
                <mat-option *ngFor='let item of queryInput.entity?.attributes' [value]='item'>{{item.name}}</mat-option>
                </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex="25" appearance="outline">
            <mat-label>Output Attributes</mat-label>
            <mat-select [(ngModel)]="queryInput.outputAttributes" multiple>
                <mat-optgroup label="Attributes">
                    <mat-option [value]="att" *ngFor="let att of queryInput.entity?.attributes">{{att.name}}</mat-option> 
                </mat-optgroup>
            </mat-select>
        </mat-form-field> 
        <mat-form-field fxFlex="25" appearance="outline">
            <mat-label>Values</mat-label>
            <textarea matInput [(ngModel)]="queryInput.values" row="10" md-select-on-focus></textarea>
        </mat-form-field>  
    </div>

     <div ngFlex *ngIf="jobData != null && jobData.status < 2">
        <div flex fxLayout="column" fxLayoutAlign='center center'>
            <div fxFlex></div>
            <div fxFlex="none" class='sw-loading-text' *ngIf="jobData?.status == 0">Waiting to start...</div>
            <div fxFlex="none" class='sw-loading-text' *ngIf="jobData?.status == 1">Executing...</div>
            <div fxFlex="none">
                <mat-progress-bar class='accent' mode="determinate" [value]="jobData?.percentageComplete"></mat-progress-bar>
            </div>
            <div fxFlex></div>
        </div>
    </div>
    
    <div ngFlex *ngIf="jobData && jobData.status == 4">
        <table class="sw-query-output">
            <thead>
                <th *ngFor="let c of jobData.queryColumns">
                    <span>{{c.name}}</span>
                </th>
            </thead>
            <tbody>
                <tr *ngFor="let r of jobData.rows">
                    <td *ngFor="let c of r.columns">
                        {{c.value}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div> 

</div>

import { Component, Input, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { UtilService } from '../../svc/utilService';
import { Constants } from '../../api/Constants';
import { StateHelperService } from '../../svc/stateHelperService';

import * as CRS from '../../api/CRS';

@Component({
  selector: 'xcheck-output-env',
  templateUrl: './xcheck-output-env.html',
})
export class XCheckOutputEnv implements OnInit {

  constructor(
      private router:Router, 
      private route:ActivatedRoute, 
      private stateHelperService: StateHelperService,
      private utilService:UtilService) { }

  @Input('item') public item:CRS.RSXCheckResult;

  ngOnInit(): void {
  }

  public get userEnvVars() : Array<CRS.RSXCheckEnvVarResult> {
      return(this.item) ? this.item.envVarItems.filter(x=>x.type == "user"): null;
  }

  public get machineEnvVars() : Array<CRS.RSXCheckEnvVarResult> {
    return(this.item) ? this.item.envVarItems.filter(x=>x.type == "machine"): null;
  }

  public get pathItems() : Array<CRS.RSXCheckPathResult> {
    return(this.item) ? this.item.pathItems : null;
  }

  public hasPathItems() : boolean {
    return this.pathItems && this.pathItems.length> 0;
  }

  public hasUserEnvVars(): boolean {
    return this.userEnvVars && this.userEnvVars.length > 0;
  }

  public hasMachineEnvVars(): boolean {
    return this.machineEnvVars && this.machineEnvVars.length > 0;
  }

  public hasItems() : boolean {
    return this.item && (this.item.envVarItems.length + this.item.pathItems.length> 0);
  }

}

import { Injectable } from '@angular/core';
import { CmdReg } from '../model/CmdReg';
import { AppCommand } from '../model/AppCommand';
import { StateHelperService } from '../svc/stateHelperService';
import { CurrentDataService } from './currentDataService';

@Injectable({
    providedIn: 'root'
})
export class MenuService {

    constructor(
        private stateHelperService : StateHelperService,
        private currentDataService: CurrentDataService) { 
    
            this.currentDataService.onChanged().subscribe(()=>{
                this.refreshAll();
            })
    }

    public commands : { [id: string] : Array<CmdReg>; } = {};

    public refreshAll() {
        for(var cmdKey in this.commands) {
            var cmd =this.commands[cmdKey];
            cmd[0].cmd.refresh();
        }
    }
    
    public refresh(cmdKey:string) {
        var cmd =this.commands[cmdKey];
        if (cmd && cmd.length>0)
            cmd[0].cmd.refresh();
    }

    public invoke(id:string) {
        var cmd = this.commands[id];
        if (cmd) {
            var runnable = this.getRunnableCommand(id);
            if (runnable && runnable.cmd.execute != null && runnable.cmd.execute != undefined) {
                runnable.cmd.execute();
            }
        }
    }
   
    public canInvoke(id:string) : boolean {
        try{
            var cmd = this.commands[id];
            if (cmd) {
                var runnable = this.getRunnableCommand(id);
                if (runnable) {
                    return runnable.cmd.can;
                }
            }
        }
        catch(ex){
        }
        return false;
    }

    public canShow(id:string) : boolean {
        var cmd = this.commands[id];
        if (cmd) {
            var runnable = this.getRunnableCommand(id);
            if (runnable) {
                return runnable.cmd.show;
            }
        }
        return false;
    }

    public getSubMenu(id: string) : Array<any> {
        var cmd = this.commands[id];
        if (cmd) {
            var runnable = this.getRunnableCommand(id);
            if (runnable) {
                if (runnable.cmd.subItems)
                    return runnable.cmd.subItems();
            }
        }
        return [];
    }

    public invokeSubMenuItem(id: string, item: any) {
        var cmd = this.commands[id];
        if (cmd) {
            var runnable = this.getRunnableCommand(id);
            if (runnable && runnable.cmd.execute != null && runnable.cmd.execute != undefined) {
                runnable.cmd.executeSubItem(item);
            }
        }
    }

    public isRunnable(id:string) :boolean {
        var cmd = this.commands[id];
        if (cmd) 
            var runnable = this.getRunnableCommand(id);
        return runnable != null;
    }

    public register(id: string, cmd: AppCommand, stateExpression: string) {

        let c: Array<CmdReg>  = this.commands[id];
        if (!c) {
            c = new Array<CmdReg>();
            this.commands[id] = c;
        }

        let lst = c.filter((x)=>x.stateExpression == stateExpression);
        if (lst.length == 0) {
            c.push(new CmdReg(id, cmd, stateExpression, null));
        }
        else {
            lst[0].cmd = cmd;
        }
    }

    private getRunnableCommand(id:string) : CmdReg {
        let ret: CmdReg = null;
        let cmds: Array<CmdReg> = this.commands[id];
        if (cmds) {
            cmds.forEach((v) => {
                let resolvedState: boolean = this.resolveState(v.stateExpression);
                if (resolvedState) {
                    ret = v;
                } 
            });
        }
        return ret; 
    } 

    private resolveState(expression: string) : boolean {
        if (!expression)
            return false;
        let sn:string = this.stateHelperService.details.statePathCurrent.id;
        let pn:string = this.stateHelperService.details.statePathParent.id;
        let idx =pn.indexOf("/");
        let pnx = (idx>0) ? pn.substring(0, idx) : pn; // parent id contains param for admin seemingly??
        let rp = this.stateHelperService.details.statePath;
        let ret: boolean=false;
        expression.split(";").forEach((v) => {
            if (v.startsWith("*")) {
                var rs = rp.filter(x=>x.id == v.substr(1));
                if (rs.length>0)
                    ret = ret || rp.indexOf(rs[0]) >= 0;
            }
            else if (v.indexOf("P-") == 0)
                ret = ret || (v.substring(2) == pn || v.substring(2) == pnx);
            else
                ret = ret ||  (v == sn);
        });
        return ret;
    }

}

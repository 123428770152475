<div class="full-height-bs promo-repack">
    <h3 *ngIf="jobIdParts.getJobCreateHeading()">{{jobIdParts.getJobCreateHeading()}}</h3>

    <div class="sw-vert-pad" *ngIf="currentConversionJob.supportsManualModificationStep()">
        <mat-checkbox [(ngModel)]="manualModificationRequired">Manual Modification Step Required</mat-checkbox> 
    </div>

    <div class="sw-vert-pad" *ngIf="application.discoveryJobGeneratedSnapshot && jobIdParts.conversionType == 1" fxLayout="row">
        <div fxFlex="30">
            <mat-checkbox [(ngModel)]="useExistingSnapshot" [disabled]="application.discoveryJobSnapshotContainedDependencies">Use existing snapshot</mat-checkbox>
        </div>
        <div fxFlex fxLayout="row" fxLayoutGap="25px" *ngIf="application.discoveryJobSnapshotContainedDependencies">
            <div fxFlex="none" class="sw-right-pad">
                <mat-icon image-size size="28pt" class="sw-warning">warning</mat-icon>
            </div>
            <div fxFlex>
                The snapshot captured during discovery contains one or more common dependencies. This package needs to be repackaged using a virtual machine in order to handle this/these dependencies effectively
            </div>
        </div>
    </div>

    <div *ngIf="applicationIsReady && application.companionFiles.length>0" fxLayout="column">
        <application-companion-selector (on-change)="companionFilesChanged($event)"></application-companion-selector>
    </div>

    <div class="sw-vert-pad" *ngIf="applicationIsReady && applicationHasBeenRepackaged && jobIsReady && !jobAlwaysTargetsOriginalPackage">
        <mat-checkbox [(ngModel)]="virtualiseFromRepack" (change)="virtualiseFromRepackChanged()">{{(jobIdParts.conversionType==0)?"Virtualise repackaged installer":"Package using repackaged installer"}}</mat-checkbox>
    </div> 
    <div class="sw-vert-pad" *ngIf="applicationIsReady && applicationHasFixTransform && !jobAlwaysTargetsOriginalPackage">
        <mat-checkbox [(ngModel)]="useFixTransform" [disabled]="!virtualiseFromRepack && application.noAssess">Apply Fix Transform</mat-checkbox>
    </div>
    <div class="sw-vert-pad" *ngIf="applicationIsReady && application.hasResponseTransform && !jobAlwaysTargetsOriginalPackage && !isPublishTest">
        <mat-checkbox [(ngModel)]="useResponseTransform" [disabled]="!virtualiseFromRepack && application.noAssess">Apply Response Transform</mat-checkbox>
    </div>
    <div class="sw-vert-pad" *ngIf="applicationIsReady && application.exeFlavourRepackageWarning && jobIsReady && jobIdParts.conversionType == 1">
        <mat-checkbox [(ngModel)]="msiExtractOnly">Gather embedded MSIs only</mat-checkbox>
    </div>

    <div class="sw-vert-pad" *ngIf="jobIdParts.conversionType == 1 && applicationIsReady && application.productDetailsDefined  && !msiExtractOnly">
        <mat-checkbox [(ngModel)]="useArpProductDetails">Use ARP product details (from registry uninstall keys) in preference to specified product details (from application details page)</mat-checkbox>
    </div>

    <div class="sw-vert-pad sw-big-vert-pad-top" *ngIf="jobIsReady && currentConversionJob.isVirtualisationConversionType()" >
        <div fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="none">
                <mat-checkbox [(ngModel)]="currentConversionJob.usePvad" [disabled]="canSpecifyPvad() != 0" class="sw-right-pad">Use PVAD instead of VFS</mat-checkbox>
            </div>
            <div fxFlex fxLayout="row" fxLayoutAlign="start center" *ngIf="canSpecifyPvad()!=0">
                <div fxFlex="none" class="sw-right-pad">
                    <mat-icon class="sw-warning md-24">info</mat-icon>
                </div>
                <div fxFlex *ngIf="canSpecifyPvad() == 1">PVAD can not be used here because connection groups will be created from the prerequisite applications specified above.</div>
                <div fxFlex *ngIf="canSpecifyPvad() == 2">PVAD can not be used here because connection groups will be created from rules defined for this project that are satisfied by this application.</div>
            </div>
        </div>
    </div>

    <div class="sw-vert-pad" *ngIf="currentConversionJob.isVirtualisationConversionType() && currentConversionJob.usePvad">
        <mat-form-field class="full-width" appearance="outline">
            <label>PVAD Directory</label>
            <input matInput type="text" [(ngModel)]="currentConversionJob.pvadDirectory" md-maxlength="250" style="width:500px"/>
        </mat-form-field>
    </div>

    <div class="sw-vert-pad sw-big-vert-pad-top" *ngIf="applicationIsReady && application.exeFlavourDescription">
        <span>For more information about silent installation of {{application.exeFlavourDescription}} installers</span>&nbsp;
        <button mat-button (click)="openSilentUrlLink()" class="sw-button-link">click here</button>
    </div>

    <div class="sw-error" *ngIf="jobIsReady && jobAlwaysTargetsOriginalPackage && applicationIsReady && application.exeFlavourRepackageWarning" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
        <div fxFlex="none">
            <img src="/img/misc/warning.png" width="48px" height="48px">
        </div>
        <div fxFlex>
            <div class="sw-warning">WARNING</div>
            <div>THIS EXE CONTAINS MSI FILES</div>
        </div>
    </div>

    <br>

    <button mat-button 
        mat-raised-button color="primary" 
        class="sw-button-default" 
        *ngIf="jobIsReady && jobIdParts.conversionType && !isPublishTest"
        [disabled]="!canLaunchTask()" 
        (click)="launchTask()">Launch</button>

    <br>
    <br>

    <div fxLayout="row" fxLayoutAlign="start top" fxLayoutGap="15px" *ngIf="!canLaunchTask() && currentConversionJob.taskLaunchInfo?.anyUnsatisfied">
        <div fxFlex="none">
            <mat-icon class="sw-warning" image-size size="32pt" style="width:50px;height:50px">warning</mat-icon>
        </div>
        <div fxFlex="60">
            <div class="sw-warning">One or more conditions have to be satisfied before this task can be launched:</div>
            <br>
            <bullet text="{{pc.description}}" *ngFor="let pc of currentConversionJob.taskLaunchInfo?.unsatisfiedPreconditions"></bullet>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" *ngIf="jobIdParts.conversionType == 2 && applicationIsReady && application.hasInvalidPackageId">
        <div fxFlex="none">
            <mat-icon class="sw-warning">warning</mat-icon>
        </div>
        <div fxFlex>
            This package has a name that contains characters which may cause an App-V conversion to fail. The package will be renamed to <span class="sw-bold">{{application.cleanedPackageId}}</span> in order to overcome this problem.
        </div>
    </div>

</div>

import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router,ActivatedRoute,NavigationEnd } from '@angular/router';
import { UtilService } from '../../svc/utilService';
import { StateHelperService,StateHelperInstance } from '../../svc/stateHelperService';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { CurrentAssessmentGroupService } from '../../svc/currentAssessmentGroupService';
import { PlatformService } from '../../svc/platformService';
import { Constants } from '../../api/Constants';

import * as CRS from '../../api/CRS';

@Component({
  selector: Constants.ROUTE_PROJECTREPORTAG,
  templateUrl: './project-reports-checks.html',
  styleUrls: ['./project-reports.scss']
})
export class ProjectReportsChecks implements OnInit, OnDestroy {

    constructor(
      private route:ActivatedRoute, 
      private router: Router,
      private currentAssessmentGroup: CurrentAssessmentGroupService, 
      private utilService:UtilService,
      public currentProject: CurrentProjectService,
      public platformService: PlatformService,  
      public stateHelperService: StateHelperService) { }

    private subscriptions: Array<Subscription> =new Array<Subscription>();

    ngOnInit(): void {
        this.subscriptions.push(this.currentAssessmentGroup.dataContext.onLoaded().subscribe((response)=>{
            if (!response.data.noChange) {
                this.setCurrentPatchGroup();
            } 
        }));

        this.stateHelperInstance =  this.stateHelperService.register(Constants.ROUTE_PROJECTREPORTAG);
    }

    ngOnDestroy() : void {
        this.subscriptions.forEach(x=>x.unsubscribe());
    }

    public get assessmentGroup() : CRS.RSGetAssessmentGroup {
        return this.currentAssessmentGroup.dataItem;
    }

    public currentPatchGroup: CRS.RSPatchGroup=null;
    public stateHelperInstance: StateHelperInstance;

    public openCheck(item: CRS.RSCheck | CRS.RSPatch) {
        if (this.currentAssessmentGroup.dataContext.isReady && this.assessmentGroup.isEnabled) {
            this.router.navigate([Constants.ROUTE_PROJECTREPORTCHECKDETAIL_MAIN, item.checkId], 
                {relativeTo: this.route} );        
        }
    }

    public isReady() : boolean {
        var state = this.utilService.isEmptyAny(this.assessmentGroup)
        return !state;
    }

    public hasAssGroupSpecified() : boolean {
        return this.stateHelperService.details.assessmentGroupId != null;
    }

    public hasCheckIdSpecified() : boolean {
        return this.stateHelperService.details.checkId != null;
    }

    private setCurrentPatchGroup() : void {
        let platformNum = this.platformService.getCurrent(this.stateHelperService.details.projectId);
        let pl = this.assessmentGroup.platforms.filter((p)=>p.id == platformNum)[0];
        if (pl.updateDate) {
            var pg = this.assessmentGroup.monthlyPatches.filter((p=> { return p.mnYr == pl.updateDate; }));
            this.currentPatchGroup= (pg.length==1) ? pg[0]: null;
        }
        else {
            this.currentPatchGroup=null;
        }
    }

    private selectCVE(patch:CRS.RSPatch) : void {
        var yrm = this.currentPatchGroup ? (this.currentPatchGroup.year * 12) + this.currentPatchGroup.month :0;
        var min = (2019 * 12) + 7;
        if (yrm>=min) {
            // this.$state.go('reportCheckIssues', {
            //     checkId: patch.checkId
            // });
        }
    }
}

import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { CurrentApplicationService } from '../../svc/currentApplicationService';
import { ApplicationService } from '../../api/SVC';
import { INPUTEDITARGS } from '../../model/INPUTEDITARGS';
import { QAVALUEARGS } from 'src/app/model/QAVALUEARGS';

import * as CRS from '../../api/CRS';

@Component({
  selector: 'qa-worksheet',
  templateUrl: './qa-worksheet.html',
  styleUrls: [ './qa-worksheet.scss', './qa-worksheet.scss']
})
export class QaWorksheet implements OnInit, OnDestroy {

  constructor(
      private currentProject: CurrentProjectService,
      private currentApplication: CurrentApplicationService, 
      private applicationService: ApplicationService,
  ) { }

  public checksheet: CRS.RSGetApplicationQaChecksheet = null;

  private subscriptions : Array<Subscription> = new Array<Subscription>();

  ngOnInit(): void {
    this.loadChecksheet();
    this.subscriptions.push( this.currentProject.dataContext.onLoaded().subscribe(()=> {
      this.loadChecksheet();
    }));
    this.subscriptions.push(this.currentApplication.dataContext.onLoaded().subscribe(()=> {
      this.loadChecksheet();
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x=>x.unsubscribe());
  }

  public isDataReady() : boolean {
    return this.currentProject.dataContext.isReady && this.currentApplication.dataContext.isReady && this.checksheet !=null;
  }

  public itemChanged = (args:INPUTEDITARGS) : void => {
    //this.applicationService.setApplicationNote(this.currentApplication.dataContext.id, args.context, args.value);
  };

  public getColumnWidth(section: CRS.RSQaChecksheetSection, index:number) : string {
    if (!section.columnWidths)
      return null;
    var colWidths = section.columnWidths.map(x=>"");
    section.columnWidths.forEach((cw,idx)=> {
      if (Number.isInteger(cw))
          colWidths[idx] = `${cw}px`;
      else
          colWidths[idx] = null;
    });
    return colWidths[index];
    
  }

  public handleColumnValueChange(args: QAVALUEARGS) {
    this.currentApplication.updateQaValue(args.column.sectionId, args.column.rowIndex, args.column.id, args.value);
  }

  public getColumnCount(section: CRS.RSQaChecksheetSection) : number {
      return section.columnHeadings.length;
  }

  public getColumns(section: CRS.RSQaChecksheetSection, row: CRS.RSQaChecksheetRow) : Array<CRS.RSQaChecksheetColumn> {
    var len = this.getColumnCount(section);
    if (row.columns.length> len)
      len = row.columns.length;
    var ret :Array<CRS.RSQaChecksheetColumn>=[];
    for(let i=0; i<len; i++) {
        ret.push((i< row.columns.length)?row.columns[i]:null);
    }
    return ret;
  }

  public getNextColumn(section: CRS.RSQaChecksheetSection, column: CRS.RSQaChecksheetColumn) : CRS.RSQaChecksheetColumn {
    if (column) {
      var row = section.rows[column.rowIndex];
      var cidx = column.id;
      if (cidx < row.columns.length -1)
        return row.columns[cidx+1];
    }
    return null;
  }

  public isNextColumnMergeAbove(section: CRS.RSQaChecksheetSection, column: CRS.RSQaChecksheetColumn) : boolean {
    return this.isColumnMergeAbove(this.getNextColumn(section, column));
  }

  public isColumnMergeAbove(column: CRS.RSQaChecksheetColumn) : boolean {
    return column && column.flags && column.flags.split('|').indexOf("MergeAbove") != -1;

  }

  public getFlattenedColumns(section: CRS.RSQaChecksheetSection) : Array<CRS.RSQaChecksheetColumn> {
      var len = this.getColumnCount(section);
      var ret :Array<CRS.RSQaChecksheetColumn>=[];
      section.rows.forEach((r)=>{
          for(let i=0; i<len; i++) {
              ret.push((i< r.columns.length)?r.columns[i]:null);
          }
      });
      return ret;
  }

  private _checksheetLoaded:boolean=false;
  private loadChecksheet() {
    if (this._checksheetLoaded)
      return;
    this._checksheetLoaded=true;
    this.currentApplication.getQAChecksheet().then((data)=>{
        this.checksheet= data;
    })
  }
}

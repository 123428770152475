<div class="full-width-bs full-height-bs" fxLayout="row">

    <spinner fxFlex *ngIf="!isDataReady()"></spinner>
    
    <div fxFlex *ngIf="isDataReady()">

        <angular-slickgrid gridId="grid1" gridHeight="100%" gridWidth="100%" *ngIf="isDataAny() && stateHelperInstance.isCurrent()"
            [columnDefinitions]="gridColumnDefs" 
            [gridOptions]="gridOptions"
            [dataset]="actions" 
            (onAngularGridCreated)="appAngularGridReady($event)"
            (onGridStateChanged)="appGridStateChanged($event)">
        </angular-slickgrid>

        
        <angular-slickgrid gridId="grid2" gridHeight="100%" gridWidth="100%" *ngIf="isDataAny() && !stateHelperInstance.isCurrent()"
            [columnDefinitions]="gridColumnDefsMinimal" 
            [gridOptions]="gridOptions"
            [dataset]="actions" 
            (onAngularGridCreated)="appAngularGridReady($event)"
            (onGridStateChanged)="appGridStateChanged($event)">
        </angular-slickgrid>

        <div *ngIf="!isDataAny()" class="full-height full-width" fxLayout="column" fxLayoutAlign="center center">
            <div flex></div>
            <div flex="none">There aren't any actions to display currently</div>
            <div flex></div> 
        </div>

    </div>
    
    <div fxFlex="70" *ngIf="isDataReady() && stateHelperInstance.isNotCurrent()">
        <div class="sw-pad-horiz full-width-bs full-height-bs">
            <router-outlet></router-outlet>  
        </div>
    </div>

</div>
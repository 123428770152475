import { Injector } from '@angular/core';
import { Component, OnInit, OnDestroy } from '@angular/core'
import { Constants } from '../../api/Constants'
import { GridAssistant } from 'src/app/model/gridAssistant';

import * as CRS from '../../api/CRS';
import * as SVC from '../../api/SVC';

@Component({
    selector: Constants.ROUTE_ADMINWINDOWSUPDATES,
    templateUrl: './admin-windows-updates.html',
    styleUrls: ['../../shared/css/admin.scss', '../../shared/css/tables.scss', '../../shared/css/view.scss']
})

export class AdminWindowsUpdates implements OnInit, OnDestroy {

    constructor(
        private injector: Injector,
        private xadminService: SVC.AdminService,
    ) {
    }

    public gridAssist: GridAssistant<CRS.RSAdminGetWindowsUpdates, CRS.RSAdminWindowsUpdate>;

    ngOnInit() {
        this.gridAssist = new GridAssistant<CRS.RSAdminGetWindowsUpdates, CRS.RSAdminWindowsUpdate>(Constants.ROUTE_ADMINWINDOWSUPDATES, this.injector);
        this.gridAssist.setColumns(   [         
                { id: 'id',   name: 'Id',   field: 'id',   sortable: true, width: 200 },
                { id: 'operatingSystem', name: 'OS', field: 'operatingSystem', sortable: true, width:200 },
                { id: 'enabled', name: 'Enabled', field: 'enabled', sortable: true, width: 100 },
                { id: 'month', name: 'month', field: 'Month', sortable: true, width: 100 },
                { id: 'imageStatusLastChangedExpression', name: 'Last Changed', field: 'imageStatusLastChangedExpression', sortable: true, width: 100 },
                { id: 'imageStatusDescription', name: 'Status', field: 'imageStatusDescription', sortable: true, width: 100 },
            ]
        );
        this.gridAssist.setDetailColumns([ "Id" ]);
        //this.gridAssist.allowRowSelection((r)=>this.openCheck(r));
        this.gridAssist.setUpdate(()=>this.update());
    }

    ngOnDestroy(){
        this.gridAssist.dispose();
    }

    private update(): void {
         this.xadminService.adminGetWindowsUpdates().then((response) => {
            this.gridAssist.mergeDataResponse(response, "items", "id", CRS.RSAdminWindowsUpdate );
         }, () => {
             console.error('Windows updates couldn\'t be loaded');
         });
    }

    public updateChanged(update: CRS.RSAdminWindowsUpdate) : void
    {
        this.xadminService.adminUpdateWindowsServiceInfo(update.id, update.description, update.operatingSystemId, update.enabled);
    }

    public retireUpdate(update: CRS.RSAdminWindowsUpdate) : void
    {
        this.xadminService.adminRetireWindowsUpdate(update.id).then((ret)=> {
            
        });
    }

    // private createVm : AppCommand = AppCommand.create(()=> {
    //     return true;
    // },
    // ()=> {
    //     const ans = this.dialogService.showYesNoMessage("Create Machine", ["Are you sure that you want to create a virtual machine?"])
    //         if (ans) {
    //             this.xadminService.adminCreateVirtualMachine("").then(()=> {
    //                 this.dialogService.showInfoMessage("Create Machine", [ "Create machine request issued. An email will be sent to you when the machine is ready" ]);
    //             });
    //         }
    // });

    // private importWindowsUpdate : AppCommand = AppCommand.create(()=> {
    //     return true;
    // },
    // ()=> {

    // });

}

import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Column, GridOption, Formatter, Formatters, FieldType, Editors, OperatorType, SlickGrid, GridStateChange } from 'angular-slickgrid';
import { CurrentApplicationService } from '../../svc/currentApplicationService';
import { Constants } from '../../api/Constants';
import { StateHelperService } from '../../svc/stateHelperService';
import { PollingService } from '../../svc/pollingService';
import { MergeHelper } from '../../api/DataMerger';
import { UtilService } from '../../svc/utilService';

import * as CRS from '../../api/CRS';

@Component({
  //selector: Constants.ROUTE_PROJECTAPPLICATIONHISTORY,
  selector: 'project-application-history',
  templateUrl: './project-application-history.html',
})
export class ProjectApplicationHistory implements OnInit, OnDestroy {

  constructor(
      private currentApplication: CurrentApplicationService,
      private pollingService: PollingService,
      private utilService: UtilService
  ) { }

  public historyItems: Array<CRS.RSActivityItem> = null;

  ngOnInit(): void {
    this.updateHistory();
    this.pollingService.startPollingSequence(Constants.POLLING_ID_APPLICATIONHISTORY, Constants.POLLING_APPLICATIONHISTORY_INTVL, -1, ()=> {
      this.updateHistory();
    });
    this.currentApplication.dataContext.onLoaded().subscribe((data)=> {
      this.historyItems=null;
      this.pollingService.refreshPollingSequence(Constants.POLLING_ID_APPLICATIONHISTORY);
    });
    this.prepareGrid();
  }

  ngOnDestroy(): void {
    this.pollingService.stopSequence(Constants.POLLING_ID_APPLICATIONHISTORY);
  }

  public isDataReady() : boolean {
    return this.historyItems != null;
  }

  private _isGridPrepared=false;
  private prepareGrid() : void
  {
      if (this._isGridPrepared)
          return;
      this._isGridPrepared=true;
      this.initialiseGrid();
  }

  public gridColumnDefs: Column[];
  public gridOptions: GridOption;
  private initialiseGrid() : void {

    this.gridColumnDefs = [
      { id: 'activityDate', name: 'Date', field: 'activityDate', sortable: true, width: 120,
          formatter:  (r,c,v,cd,dc)=> {
            return this.utilService.getDateAsStringWithTime(v);
        }
      },
      { id: 'text', name: 'Description', field: 'text', sortable: true, width: 400 },
    ]

    this.gridOptions = {
      enableAutoResize: true,
      enableSorting: true,
      enableRowSelection: true,
      enableCellNavigation:true,
      enableColumnPicker: true,
      rowHeight:Constants.GRID_ROW_HEIGHT,
      multiSelect: false,
      gridMenu: {
        hideForceFitButton:true,
        hideSyncResizeButton: true,
      }
    };
  }

  private updateHistory() : void {

    this.currentApplication.getHistory().then((data) => {
        if (!this.historyItems)
          this.historyItems=[];
        MergeHelper.ListMerge(data, this.historyItems, (i) => { return i.id}, CRS.RSActivityItem);
    }, () => {
        console.error('History couldn\'t be loaded');
    });
}

}

import { Injector } from '@angular/core';
import { first } from 'rxjs/operators';
import { CurrentApplicationService } from "../svc/currentApplicationService";
import { MenuService } from '../svc/menuService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';
import { DialogService } from '../svc/dialogService';
import { UtilService } from '../svc/utilService';
import { StateHelperService } from '../svc/stateHelperService';

export class IgnoreIssuesCommand {

    constructor(
        private injector: Injector
    ) {
        this.menuService = this.injector.get(MenuService);
        this.utilService = this.injector.get(UtilService);
        this.dialogService = this.injector.get(DialogService);
        this.currentApplication = this.injector.get(CurrentApplicationService);
        this.stateHelperService = this.injector.get(StateHelperService);
        
        this.menuService.register(Constants.MENU_IGNORE_ISSUES, this.command, "*"+Constants.ROUTE_PROJECTAPPLICATIONLANDING_MAIN )
    }

    private stateHelperService: StateHelperService;
    private utilService: UtilService;
    private menuService: MenuService;
    private dialogService: DialogService;
    private currentApplication: CurrentApplicationService;

    private command: AppCommand = AppCommand.createWithShow(
        ()=> {
            return this.canExecuteCommand();
        },
        ()=> {
            var ilh = this.currentApplication.getIssuesDirect();
            let selectedIssues: Array<string> = ilh.issuesForCurrentPlatform.filter(x=>x.isSelected).map(x=>x.id);
            this.dialogService.showYesNoMessage("Ignore Issues", [ "Are you sure that you want to ignore these issues?" ]).pipe(first()).subscribe((ans)=>{
                ilh.clearSelected(); // Necessary to avoid angular error after ignore is executed
                this.menuService.refresh(Constants.MENU_FIX); 
                this.menuService.refresh(Constants.MENU_IGNORE_ISSUES);
                this.currentApplication.ignoreIssues(selectedIssues);
            });
        },
        ()=> {
            return this.showCommand();
        }
    );

    private canExecuteCommand(): boolean {
        if (!this.currentApplication.dataContext.isReady || this.currentApplication.isFixInProgress()) {
            return false;
        }
        var ilh = this.currentApplication.getIssuesDirect();
        if (!ilh)
            return false;   
        return ilh.issuesForCurrentPlatform.some(x=>x.isSelected);
    }


    private showCommand() : boolean {
        return this.utilService.privileges && this.utilService.privileges.canFix;
    }

}


<div fxLayout="column">

    <div fxFlex="none">
        <div fxLayout="row" fxLayoutAlign='center center'>
            <div fxFlex="80">
                <div class="sw-pad-top">
                    <h2 class="sw-heading">Create project</h2>
                </div>
            </div>
        </div>
    </div>

    <div fxFlex fxLayout='row' fxLayoutAlign='center center'>

        <div fxFlex="80" fxLayout="column">

            <div fxFlex fxLayout="row" fxLayoutGap="15px">
                <div fxFlex>
                    <div fxLayout='column' fxLayoutGap="5px">
                        <div fxFlex="none">
                            <mat-form-field appearance="outline" class="full-width">
                                <mat-label>Project name</mat-label>
                                <input #id1 matInput class="full-width" name='name' [(ngModel)]='name' required autocomplete='off' maxlength="50"/>
                                <mat-error *ngIf="!name">You must specify a name</mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex="none">
                            <mat-form-field appearance="outline" class="full-width">
                                <mat-label>Description</mat-label>
                                <textarea matInput rows='3' max-rows='3' class="full-width" md-no-resize [(ngModel)]='description' maxlength="500"></textarea>
                            </mat-form-field>
                        </div>
                        <div fxFlex>
                            <mat-form-field appearance="outline" class="full-width">
                                <mat-label>Storage Access Key</mat-label>
                                <input matInput class="full-width" name='accesskey' [(ngModel)]='storageAccessKey' autocomplete='off' maxlength="500"/>
                            </mat-form-field>
                        </div>
                        <div class="sw-error">{{validationError}}</div>
                    </div>  
                </div>
                <div fxFlex="none">
                    <div style="width:200px">
                        <image-upload [image]='image'></image-upload>
                    </div>
                </div>
            </div>

            <div fxFlex>
                <div fxLayout="row wrap">
                    <div fxFlex="25">
                        <div fxLayout="column">
                            <mat-checkbox [(ngModel)]="rptChkCompat.mainChecked" (change)="rptChkCompat.change(null)">Generate Compatibility Reports</mat-checkbox>
                            <div fxLayout="column" class="sw-sub-list">
                                <mat-checkbox fxFlex [(ngModel)]="rptChkCompat.subChecked['DESKTOP']" (change)="rptChkCompat.change('DESKTOP')" [disabled]="true" MatTooltip="The project requires assessment of desktop based operating systems">Include Desktop Reports</mat-checkbox>
                                <mat-checkbox fxFlex [(ngModel)]="rptChkCompat.subChecked['SERVER']" (change)="rptChkCompat.change('SERVER')" matTooltip="The project requires assessment of server based operating systems">Include Server Reports</mat-checkbox>
                                <mat-checkbox fxFlex [(ngModel)]="rptChkCompat.subChecked['OFFICE']" (change)="rptChkCompat.change('OFFICE')" matTooltip="The project requires assessment relating to Microsoft Office products">Include Office Reports</mat-checkbox>
                                <mat-checkbox fxFlex [(ngModel)]="rptChkCompat.subChecked['PATCH']" (change)="rptChkCompat.change('PATCH')" matTooltip="The project requires assessment of the impact of Microsoft's monthly updates">Include Patch Reports</mat-checkbox> 
                                <mat-checkbox fxFlex [(ngModel)]="rptChkCompat.subChecked['BROWSER']" (change)="rptChkCompat.change('BROWSER')" matTooltip="The project requires assessment relating to web browsers">Include Browser Reports</mat-checkbox>
                            </div>
                        </div>
                    </div>
                    <div fxFlex="25">
                        <div fxLayout="column">
                            <mat-checkbox fxFlex [(ngModel)]="rptChkQuality.mainChecked" (change)="rptChkQuality.change(null)">Generate Quality Reports</mat-checkbox>
                            <div fxFlex fxLayout="column" class="sw-sub-list">
                                <mat-checkbox fxFlex [(ngModel)]="rptChkQuality.subChecked['DEPANAL']" (change)="rptChkQuality.change('DEPANAL')" MatTooltip="The project requires assessment of dependent applications">Include Dependency Analysis</mat-checkbox>    
                                <mat-checkbox fxFlex [(ngModel)]="rptChkQuality.subChecked['MSIICE']" (change)="rptChkQuality.change('MSIICE')" MatTooltip="The project requires assessment of MSI integrity rules">Include MSI Installer Analysis</mat-checkbox>  
                                <mat-checkbox fxFlex [(ngModel)]="rptChkQuality.subChecked['PKGSTD']" (change)="rptChkQuality.change('PKGSTD')" MatTooltip="The project requires assessment relating to the implementation of packaging standards">Include Packaging Standards</mat-checkbox>
                                <div fxFlex *ngIf="projects && rptChkQuality.subChecked['PKGSTD']" class="sw-pad">
                                    <mat-select [(ngModel)]="referenceProject" (change)="referenceProjectChanged()" placeholder="Reference project (Optional)" style="width:150px">
                                        <mat-option value="{{null}}">Not Required</mat-option>
                                        <mat-option value="p" *ngFor="let p of projects">{{p.name}}</mat-option>
                                    </mat-select>
                                </div>
                            </div>  
                        </div>
                    </div>
                    <div fxFlex="25">
                        <div fxLayout="column">
                            <mat-checkbox fxFlex [(ngModel)]="rptChkSuitability.mainChecked" (change)="rptChkSuitability.change(null)">Generate Suitability Reports</mat-checkbox>
                            <div fxFlex fxLayout="column" class="sw-sub-list">
                                <mat-checkbox fxFlex [(ngModel)]="rptChkSuitability.subChecked['APPV']" (change)="rptChkSuitability.change('APPV')" MatTooltip="The project requires assessment of the suitability of converting applications to the AppV virtualisation technology">Include AppV Reports</mat-checkbox>   
                                <mat-checkbox fxFlex [(ngModel)]="rptChkSuitability.subChecked['APPX']" (change)="rptChkSuitability.change('APPX')" MatTooltip="The project requires assessment of the suitability of converting applications to AppX">Include AppX Reports</mat-checkbox> 
                                <mat-checkbox fxFlex [(ngModel)]="rptChkSuitability.subChecked['THINAPP']" (change)="rptChkSuitability.change('THINAPP')" MatTooltip="The project requires assessment of the suitability of converting applications to the ThinApp virtualisation technology">Include ThinApp Reports</mat-checkbox>   
                                <mat-checkbox fxFlex [(ngModel)]="rptChkSuitability.subChecked['XENAPPH']" (change)="rptChkSuitability.change('XENAPPH')" ng-disabled="!vm.rptChkCompat.subChecked['SERVER']" MatTooltip="The project requires assessment of the suitability of converting applications to the XenApp virtualisation technology. This technology is only applicable to server operating systems. Select &quot;Server Reports&quot; to enable this option." [disabled]="!rptChkCompat.subChecked['SERVER']">Include XenApp Hosted &amp; MS RDS</mat-checkbox>   
                                <mat-checkbox fxFlex [(ngModel)]="rptChkSuitability.subChecked['VMWARE']" (change)="rptChkSuitability.change('VMWARE')" MatTooltip="The project requires assessment of the suitability of converting applications to the VMWare Workspace virtualisation technology.">Include VMWare Workspace ONE- Horizon</mat-checkbox> 
                                <mat-checkbox fxFlex [(ngModel)]="rptChkSuitability.subChecked['LIQD']" (change)="rptChkSuitability.change('MSIX')" MatTooltip="The project requires assessment of the suitability of converting applications to Liquidware FlexApp">Include Liquidware FlexApp Reports</mat-checkbox>
                                <mat-checkbox fxFlex [(ngModel)]="rptChkSuitability.subChecked['MSIX']" (change)="rptChkSuitability.change('MSIX')" MatTooltip="The project requires assessment of the suitability of converting applications to MSIX">Include MSIX Reports</mat-checkbox>
                            </div>  
                        </div>
                    </div>
                    <div fxFlex="25">
                        <div fxLayout="column">
                            <mat-checkbox fxFlex [(ngModel)]="rptChkSecurity.mainChecked"  (change)="rptChkSecurity.change(null)">Generate Security Reports</mat-checkbox>
                            <div fxFlex fxLayout="column" class="sw-sub-list">
                                <mat-checkbox fxFlex [(ngModel)]="rptChkSecurity.subChecked['ANTIVIRUS']" (change)="rptChkSecurity.change('ANTIVIRUS')" MatTooltip="The project requires applications to be virus checked. Including this option will significant increase the elapsed time for assessment">Include Anti-Virus Reports</mat-checkbox>  
                                <mat-checkbox fxFlex [(ngModel)]="rptChkSecurity.subChecked['COMPLIANCE']" (change)="rptChkSecurity.change('COMPLIANCE')" MatTooltip="The project requires assessment of application compliance">Include Compliance Reports</mat-checkbox> 
                                <mat-checkbox fxFlex [(ngModel)]="rptChkSecurity.subChecked['WINSECURITY']" (change)="rptChkSecurity.change('WINSECURITY')" MatTooltip="The project requires assessment of windows security issues">Include Windows Security Reports</mat-checkbox>    
                            </div>  
                        </div>
                    </div>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="5px">
                <div fxFlex></div>
                <div fxFlex="none" fxLayout="column">
                    <button mat-raised-button color="primary" class='md-raised md-accent md-hue-3' (click)="create()" [disabled]="!isValid() || isSaving">
                        <sw-button-text text="Save"></sw-button-text>
                    </button>
                    <mat-progress-bar mode="indeterminate" *ngIf="isSaving" class="sign-in-progress"></mat-progress-bar>
                </div>  
            </div> 

        </div>

    </div>


</div>
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UtilService } from '../../svc/utilService';
import { StateHelperService } from '../../svc/stateHelperService';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { PlatformService } from '../../svc/platformService';
import { ProjectService } from '../../api/SVC';

import * as ENUM from '../../api/Enum';
import * as CRS from '../../api/CRS';

@Component({
  selector: "patch-checks-table",
  templateUrl: './patch-checks-table.html',
  styleUrls: ['./project-reports.scss']
})
export class PatchChecksTable implements OnInit {

    constructor(
        private utilService:UtilService,
        private platformService: PlatformService,
        public currentProject: CurrentProjectService,
        public stateHelperService: StateHelperService) { }

    @Input("patch-group") patchGroup: CRS.RSPatchGroup;
    @Input("is-ready") public isReady: boolean;
    @Input("platforms") platforms: Array<CRS.RSPlatform>;
    @Input("collapsed") collapsed: boolean;
    @Input("narrow") public narrow: boolean;
    @Output("on-click") onClick = new EventEmitter<CRS.RSPatch>();

    public platProds: Array<String>;
    public platform: CRS.RSPlatform;

    ngOnInit(): void {
    }

    public hasData() : boolean {
      var stat = this.utilService.isEmptyAny(this.patchGroup);
      return !stat;
    }

    public applicableOsFilter = (item: CRS.RSPatch) : boolean => {
      return this.isApplicableToOs(item) 
    }

    public get sortedPatches() : Array<CRS.RSPatch> {
      return this.patchGroup.patches.sort((a,b)=>b.impactRag - a.impactRag);
    }

    public getBaseScore(patch:CRS.RSPatch) : number {
      let threats= this.getThreatsForCurrentPlatform(patch);
      return (threats.length==0) ? null : threats[0].baseScore;
    }

    public isApplicableToOs(patch:CRS.RSPatch) : boolean {
      let lst = this.getThreatsForCurrentPlatform(patch);
      return lst.length > 0;
    }

    public getSeverity(patch:CRS.RSPatch) : string {
      let threats = this.getThreatsForCurrentPlatform(patch);
      return (threats.length==0) ? null : ENUM.MonthlyPatchSeverityType[threats[0].severity];
    }

    public isCurrentCheckId(id:string) : boolean {
      return this.stateHelperService.details.checkId == id;
    }

    public openCheck(check: CRS.RSPatch) {
      if (this.onClick)
        this.onClick.emit(check);
    }

    private getThreatsForCurrentPlatform(patch:CRS.RSPatch) : Array<CRS.RSPatchThreat> {
      if (!this.initialised())
          return [];
      return patch.threats.filter(x=>{
          return x.productIds.filter(x=>this.platProds.indexOf(x) != -1).length == x.productIds.length;
      });
    }

    private initialised() : boolean {
      if (!this.currentProject.dataContext.isReady || !this.patchGroup)
          return false;
      if (this.currentProject.dataContext.isReady && this.patchGroup)            {
          if (!this.platProds)
              this.setCurrentPlatform();
      }
      return true;
    }

    private setCurrentPlatform() : void {
      if (this.currentProject.dataContext.isReady && this.patchGroup) {
          let platformNum = this.platformService.getCurrent(this.currentProject.dataItem.id);
          this.platform = this.platforms.filter((p)=>p.id == platformNum)[0];
          this.platProds = this.platformService.getPlatformProductIds(this.platform);
      }
    }

}

export class MenuArgs {

    static create(id:string, text:string, icon:string) : MenuArgs {
        return new MenuArgs(id,text,icon);
    }

    static createToggle(id:string, text:string, offText:string, icon:string, storageId:string) : MenuArgs {
        let ret= new MenuArgs(id,text,icon);
        ret.offText=offText;
        ret.storageId = storageId;
        return ret;
    }

    static createMulti(id:string, text:string, icon:string) : MenuArgs {
        let ret= new MenuArgs(id,text,icon);
        ret.hasSubMenu=true;
        return ret;
    }

    constructor(id: string, text:string, icon:string) {
        this.id = id;
        this.canShow=false;
        this.canInvoke=false;
        this.subMenuItems = null;
        this.subItem=null;
        this.text=text;
        this.icon= icon;
        this.hasSubMenu=false;
    }

    public id:string;
    public canInvoke: boolean;
    public canShow:boolean;
    public subMenuItems: Array<any>
    public subItem:any;
    public text:string;
    public offText:string;
    public icon:string;
    public hasSubMenu: boolean;
    public storageId:string;
}
<div class="full-height full-width" fxLayout="column" *ngIf="summarisation">

    <div fxFlex="initial" *ngIf="isDataReady() && application.fixingJobInfo">
        <mat-progress-bar mode="indeterminate" *ngIf="application.fixingJobInfo.status <= 1"></mat-progress-bar>
    </div>

    <div fxFlex="initial" *ngIf="hasErrors()" id="err-block">
        <div fxLayout="row" fxLayoutGap="10px" class="sw-error">
            <div fxFlex="none"><mat-icon>error</mat-icon></div>
            <p class="sw-error">One or more errors occurred applying auto-fixes. This may be due to integrity issues within the package.</p>
        </div>
        <table class="full-width-bs">
            <ng-container *ngFor="let e of application.fixingJobInfo.errors">
                <tr >
                    <td>{{e.checkId}}</td>
                    <td>{{e.message}}</td>
                </tr>
                <tr class="sw-subtext">
                    <td colspan="2">
                        <div *ngFor="let c of e.commands">{{c}}</div>
                    </td>
                </tr>
            </ng-container>
        </table>
    </div>

    <div fxFlex="initial" fxLayout="row">
        <fieldset fxFlex>
            <legend>Autofix available</legend>
            <div>{{summarisation.autoFixCount}}</div>
        </fieldset>
        <fieldset fxFlex>
            <legend>Manual fix required</legend>
            <div class='sw-bold'>{{summarisation.manualFixCount}}</div>
        </fieldset>
        <fieldset fxFlex>
            <legend>Excluded check issues</legend>
            <div class='sw-bold'>{{summarisation.excludedCount}}</div>
        </fieldset>
        <fieldset fxFlex>
            <legend>Total remaining</legend>
            <div class='sw-bold'>{{summarisation.remainingCount}}</div>
        </fieldset>
        <fieldset fxFlex>
            <legend>Auto fixed</legend>
            <div class='sw-bold'>{{summarisation.autoFixedCount}}</div>
        </fieldset>
        <fieldset fxFlex *ngIf="isDataReady() && application.fixingJobInfo">
            <legend>Fixed</legend>
            <div class="sw-bold"  [ngClass]="{'sw-error' : application.fixingJobInfo.status == 2, 'sw-ok' : application.fixingJobInfo.status == 4}">{{application.fixingJobInfo.fixDateExpression}}</div>
        </fieldset>
        <fieldset fxFlex>
            <legend>Fix On Publish</legend>
            <div class='sw-bold'>{{summarisation.fixOnPublishCount}}</div>
        </fieldset>
    </div>

    <app-issues-table fxFlex grid-id="appIssueTbl" class="sw-pad-small" [options]="['CHK']" [issues]="issuesForCurrentPlatform" [show-selection-box]="userCanFix()"></app-issues-table>

</div>

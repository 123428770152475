import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { Column, GridOption, Formatter, Formatters, FieldType, Editors, OperatorType, SlickGrid, AngularGridInstance } from 'angular-slickgrid';
import { UtilService } from '../../svc/utilService';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { PatchService } from '../../api/SVC';
import { PollingService } from '../../svc/pollingService';
import { Constants } from '../../api/Constants';
import { PlatformService } from '../../svc/platformService';
import { RagValueResolverService } from '../../svc/ragValueResolverService';
import { MonthlyPatchSeverityType, MonthlyPatchImpactType } from '../../model/PatchExt';

import * as CRS from '../../api/CRS';

@Component({
  selector: Constants.ROUTE_PROJECTSETTINGS,
  templateUrl: './project-patches.html'
})
export class ProjectPatches implements OnInit, OnDestroy {

  constructor(
    private route:ActivatedRoute, 
    private currentProjectService: CurrentProjectService, 
    private patchService: PatchService,
    private pollingService: PollingService,
    private platformService: PlatformService,
    private utilService:UtilService
  ) { }

  public patchGroup: CRS.RSGetPatchGroup=null;
  public platform: CRS.RSPlatform=null;
  public platProds: Array<String>;
  public muDateRequired: boolean= false;

  private patchGrid: SlickGrid=null;

  ngOnInit(): void {

    this.pollingService.startPollingSequence(Constants.POLLING_ID_PROJECTPATCHES, Constants.POLLING_PROJECTPATCHES_INTVL, 0, (r1,r2)=> {
      this.updateData(r1,r2);
    });

    this.prepareGrid();
  }

  ngOnDestroy(): void {
    this.pollingService.stopSequence(Constants.POLLING_ID_PROJECTPATCHES);
    if (this.patchGrid) {
      this.patchGrid.destroy();
    }
  }

  public isDataReady() : boolean {
    return !this.utilService.isEmptyAny( this.patchGroup)
  }

  public handleGridReady(angularGrid:AngularGridInstance) {
    this.patchGrid = angularGrid.slickGrid;
  }

  private updateData(resolve: Function, reject: Function) : void {
    if (this.currentProjectService.dataContext.isReady) {
      var platNo = this.platformService.getCurrent(this.currentProjectService.dataContext.id);
      var upd = this.currentProjectService.getUpdateDate(platNo);
      if (upd) {
        this.patchService.getPatchGroup(this.currentProjectService.dataContext.id, this.utilService.getEmptyGuid(), upd.year, upd.month).then((response) => {
            this.patchGroup = response.data;
            this.setCurrentPlatform(platNo);
        }, () => {
            console.error('Patch data couldn\'t be loaded');
        });
      }
      else {
        this.muDateRequired=true;
      }
    }
  }

  private setCurrentPlatform(platNo:number) : void {
    this.platform = this.patchGroup.platforms.filter((p)=>p.id == platNo)[0];
    this.platProds = this.platformService.getPlatformProductIds(this.platform);
  }

  public getSeverity(patch:CRS.RSPatch) : string {
    let threats = this.getThreatsForCurrentPlatform(patch);
    return (threats.length==0) ? null : MonthlyPatchSeverityType[threats[0].severity];
  }

  public getImpact(patch:CRS.RSPatch) : string {
    let threats= this.getThreatsForCurrentPlatform(patch);
    return (threats.length==0) ? null : MonthlyPatchImpactType[threats[0].impact];
  }

  public getBaseScore(patch:CRS.RSPatch) : number {
    let threats= this.getThreatsForCurrentPlatform(patch);
    return (threats.length==0) ? null : threats[0].baseScore;
  }

  private getThreatsForCurrentPlatform(patch:CRS.RSPatch) : Array<CRS.RSPatchThreat> {
    return patch.threats.filter(x=>{
        return x.productIds.filter(x=>this.platProds.indexOf(x) != -1).length == x.productIds.length;
    });
  }

  private isApplicableToOs(patch:CRS.RSPatch) : boolean {
    let lst = this.getThreatsForCurrentPlatform(patch);
    return lst.length > 0;
  }

  public patchFilter = (item: CRS.RSPatch) : boolean => {
    return this.isApplicableToOs(item);
  }

  private _isGridPrepared=false;
  private prepareGrid() : void
  {
      if (this._isGridPrepared)
          return;
      this._isGridPrepared=true;
      this.initialiseGrid();
  }

  public gridColumnDefs: Column[];
  public gridOptions: GridOption;
  private initialiseGrid() : void {

    this.gridColumnDefs = [
      { id: 'rag', name: 'RAG', field: 'id', sortable: true, width:60,
          formatter: (r,c,v,cd,dc)=> {
              var p = <CRS.RSPatch>dc;
              var val = RagValueResolverService.resolveExplicit(String(p.impactRag));
              return "<div class='full-width full-height sw-rag-small sw-rag-" + val.colorClass + "'></div>";
          }
      },
      { id: 'cve', name: 'CVE', field: 'id', sortable: true, width: 100 },
      { id: 'productFamily', name: 'Product Family', field: 'productFamily', sortable: true, width: 100  },
      { id: 'name', name: 'Name', field: 'name', sortable: true, width: 300 },
      { id: 'severity', name: 'Severity', field: 'severity', sortable: true, width: 80, 
        formatter: (r,c,v,cd,dc)=> { return this.getSeverity(<CRS.RSPatch>dc); }
      },
      { id: 'impact', name: 'Impact', field: 'impact', sortable: true, width: 100,
        formatter: (r,c,v,cd,dc)=> { return this.getImpact(<CRS.RSPatch>dc); }
      },   
      { id: 'nistRating', name: 'NIST Rating', field:'nistRating', sortable: true, width: 100,
        formatter: (r,c,v,cd,dc)=> { return String(this.getBaseScore(<CRS.RSPatch>dc)); }
      }
    ]

    this.gridOptions = {
      enableAutoResize: true,
      enableSorting: true,
      enableRowSelection: true,
      enableCellNavigation:true,
      enableColumnPicker: true,
      rowHeight:28,
      multiSelect: false,
      rowSelectionOptions: {
        selectActiveRow: true,
      },
      gridMenu: {
        hideForceFitButton:true,
        hideSyncResizeButton: true,
      }
    };
  }


}

import { Component, OnInit } from '@angular/core';
import * as STHS from '../../svc/stateHelperService';

import * as CRS from '../../api/CRS';
import * as SVC from '../../api/SVC';
import { Constants } from 'src/app/api/Constants';
import { UtilService } from 'src/app/svc/utilService';

@Component({
    selector: "admin-patch-cve-cats",
    templateUrl: './admin-patch-cve-cats.html',
    styleUrls: ['../../shared/css/admin.scss', '../../shared/css/tables.scss', '../../shared/css/view.scss', '../../shared/css/nav-bar.scss']
})

export class AdminPatchCveCats implements OnInit {

    constructor(
        private xadminService: SVC.AdminService,
        private stateHelperService: STHS.StateHelperService,
        private utilService: UtilService
    ) {
    }

    private patchGroup: string = '';
    public patchGroups: CRS.RSAdminGetPatchGroups=null;
    public patchCategories: Array<CatInfo> = null;
    public patchGroupDetails: Array<CVEState>=null;
    public patchGroupDetailsLoading: boolean=false;

    public stateHelperInstance: STHS.StateHelperInstance;

    ngOnInit() {
        this.stateHelperInstance= this.stateHelperService.register(Constants.ROUTE_ADMINPATCHCVECATS);
        this.update();
    }

    private update(): void {

        this.xadminService.adminGetPatchGroups().then((response) =>{
            this.patchGroups = response.data;
            if(this.patchGroups){
                this.patchGroup = this.patchGroups.items[0].id
            }
            this.updateCVEList();
        });
    }

    private isPatchGrpReady() : boolean {
        return this.patchGroups  !== undefined && this.patchGroups !=null;
    }

    public isActivePatchGroup(pg: CRS.RSPatchGroup) : boolean {
        if (!this.isPatchGrpReady())
            return false; 
        return pg.id == this.getActivePatchGroup();
    }
    
    private getActivePatchGroup() : string {
        return (this.patchGroup==null) ? this.patchGroups.items[0].id : this.patchGroup;
    }

    public selectPatchGroup(pg: CRS.RSPatchGroup) : void {
        if (this.getActivePatchGroup()!=pg.id) {
            this.patchGroup = pg.id
            this.patchGroupDetails=null;
            this.updateCVEList();
        }
    }

    public openUrl(pg:CVEState) : void {
        window.open(pg.url);
    }

    public getPatchCatStateClass(pgi: CVEState) : string {
        let state = (pgi.category) ? 1 : 2;
        return "sw-patch-cat-state-" + state
    }

    public categoryChange(pgi: CVEState) : void {
        this.xadminService.adminSetCveCategory(pgi.category, pgi.id);
    }

    public isDataReady() : boolean {
        return this.utilService.isEmptyAny( this.patchGroupDetails);
    }
    
    private updateCVEList(): void {
        if (this.isPatchGrpReady()) {
            this.patchGroupDetailsLoading=true;
            let apg = this.getActivePatchGroup();
            this.xadminService.adminGetPatchGroupItemSummarised(apg).then((response)=> {
                this.patchGroupDetails = response.data.items.map(x=>new CVEState(x.cve, x.title, x.url, x.category));
                this.patchCategories = response.data.categories.map(x=>new CatInfo(x,x));
                this.patchCategories.push(new CatInfo("","Not Specified"))
                this.patchGroupDetailsLoading=false;
            }, (ex)=> {
                this.patchGroupDetailsLoading=false;
            });
        }
    }

}

class CatInfo {
    constructor(id:string,text:string) {
        this.id =id;
        this.text =text;
    }
    public id:String;
    public text:string;
}

class CVEState {
    constructor(id:string,title:string,url:string,category:string) {
        this.id = id;
        this.title = title;
        this.url = url;
        this.category=(category==null) ? "" :category;
    }
    public id:string;
    public title:string;
    public url:string;
    public category:string;
}


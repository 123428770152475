import { AppCommand } from './AppCommand';

export class CmdReg {
    constructor(id:string, cmd: AppCommand, stateExpression:string, createdState: string) {
        this.id = id;
        this.stateExpression = stateExpression;
        this.cmd = cmd;''
        this.createdState = createdState;
    }
    
    id:string;
    stateExpression:string;
    cmd: AppCommand;
    createdState: string; 
}
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Observable, Subscription } from "rxjs";
import { Router,ActivatedRoute } from '@angular/router';
import { Column, GridOption, ExtensionName,  GridMenuItem, CurrentRowSelection, Aggregators, SlickGrid, GridStateChange} from 'angular-slickgrid';
import { UtilService } from '../../svc/utilService';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { StateHelperInstance, StateHelperService } from '../../svc/stateHelperService';
import { GridColumnsHelperService } from '../../svc/gridColumnsHelperService';
import { AppCommand } from '../../model/AppCommand';
import { DISOApplication } from '../../model/DISOApplication';  
import { CLColumn } from '../../model/CLColumn';
import { Grids } from '../../model/Grids';
import { CLGridApplicationTypes } from '../../model//CLGridApplicationTypes';
import { ProjectApplicationsGrid } from './project-applications-grid';
import { UserCacheService } from '../../svc/userCacheService';
import { MenuService } from '../../svc/menuService';
import { Constants } from '../../api/Constants';
import { MatDialog } from '@angular/material/dialog';
import { AppUploadService } from '../../svc/appUploadService';
import { CreateGridProfileDialog } from '../../dialogs/create-grid-profile/create-grid-profile-dialog';
import { CurrentApplicationService } from 'src/app/svc/currentApplicationService';

import * as CRS from '../../api/CRS';
import { IdType } from 'src/app/api/Enum';
import { SetWorkflowStateDialog } from 'src/app/dialogs/set-workflow-state/set-workflow-state-dialog';

@Component({
  selector: Constants.ROUTE_PROJECTAPPLICATIONS,
  templateUrl: './project-applications.html',
  styleUrls: [ './project-applications.scss', '../../shared/rag-column/rag-column.scss']
})
export class ProjectApplications implements OnInit, OnDestroy {

  constructor(
    public appUploadService: AppUploadService,
    private router: Router,
    private route:ActivatedRoute, 
    private dialog: MatDialog, 
    private utilService:UtilService,
    private menuService: MenuService,
    private gridColumnsHelperService: GridColumnsHelperService,
    private grids:Grids,
    private userCacheService:UserCacheService,
    private stateHelperService: StateHelperService,
    private currentApplication: CurrentApplicationService,
    private currentProject: CurrentProjectService) { 

    this.menuService.register(Constants.MENU_UPLOAD_APPLICATION, this.uploadApplicationsCommand, Constants.ROUTE_PROJECTAPPLICATIONS);
    CLGridApplicationTypes.customValueMapper = (c:CLColumn,i:DISOApplication)=>this.appGridCustomValueMapper(c,i);
  }

  private subscriptions: Array<Subscription> =new Array<Subscription>();
  @ViewChild("con1") con1;

  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription

  ngOnInit(): void {

    this.stateHelperInstance =  this.stateHelperService.register(Constants.ROUTE_PROJECTAPPLICATIONS);
    this.essentialsState |= (this.currentProject.dataContext.isReady) ? 1 :0;
    this.essentialsState |= (this.userCacheService.isReady) ? 2 : 0;
    this.loadData();
    this.gridColumnsHelperService.register();
    this.subscriptions.push(this.currentProject.dataContext.onLoaded().subscribe(x=> {
      this.essentialsState |= 1;
      this.loadData();
    }));
    this.subscriptions.push(this.currentProject.dataContext.onRefreshed().subscribe(x=> {
      this.essentialsState |= 1;
      this.loadData();
    }));
    this.subscriptions.push(this.userCacheService.onUserLoaded().subscribe(x=> {
      this.essentialsState |= 2;
      this.loadData();
    }));
    this.subscriptions.push(this.stateHelperService.onIdChange().subscribe(x=> {
      if (x.type == IdType.Project)
        this.resizeGrid();
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x=>x.unsubscribe());
    if (this.appGridFull)
      this.appGridFull.destroy();
  }

  private isDataLoaded:boolean=false;
  private essentialsState:number=0;
  private appGridFull: SlickGrid=null;
  private appGridFullDataView:any;
  private allGridColumns: Array<Column>;

  public applications: Array<DISOApplication> =[];
  public stateHelperInstance: StateHelperInstance;
  public isFullWidth:boolean;
  public gridColumnDefs: Column[];
  public gridColumnDefsSide: Column[];
  public gridOptionsFull: GridOption;
  public gridOptionsSide: GridOption;
  public isOverDropZone:boolean = false;
n
  private columnFilters: {};

  public isDataReady() : boolean {
    return this.currentProject.dataContext.isReady && this.userCacheService.isReady && this.isDataLoaded;
  }

  public fileDropOver(state:boolean) {
    this.isOverDropZone=state;
  }

  private loadData() {
    if (this.isAppBrowsing()) //Grid won't be rendered - collapsed in this state
      return;
    if (this.essentialsState==3) {
      DISOApplication.merge(this.currentProject.dataItem, this.currentProject.platformNumber, this.currentProject.dataItem.applications, this.applications);
      if (!this.gridOptionsFull) {
          this.PrepareGrid();
      }
      else {
        var g = this.appGridFull;
        var d = g.getData();
        if (d) {
          var sc = g.getSortColumns();
          if (sc.length>0)
            this.sortApplications(sc);
          d.setItems(this.applications);
          g.render();
        }
      }
    }
  }

  private uploadApplicationsCommand: AppCommand = AppCommand.createWithShow(
    ()=> { 
        return true;
    },
    ()=> {
        $("#projectUploadElement").click();
    },
    ()=> {
        return true;
    }
  )

  public get hasApplicationContext() : boolean
  {
    return !this.stateHelperInstance.isCurrent();
  }

  private _isGridPrepared=false;
  private PrepareGrid() : void
  {
      if (this._isGridPrepared)
          return;
      this._isGridPrepared=true;
      this.initialiseGrid();
  }

  private initialiseGrid() : void {

    this.gridColumnDefs = ProjectApplicationsGrid.getColumns(this.currentProject, this.utilService);
    this.gridColumnDefsSide = this.gridColumnDefs.filter(x=>CLGridApplicationTypes.fixedItems.indexOf(<string>x.id)>=0);
    this.gridOptionsFull = this.getGridOptions(true);
    this.gridOptionsSide = this.getGridOptions(false);
    this.gridOptionsSide.enableGridMenu=false;
  } 

  public appAngularGridReady(angularGrid:any) {

    if (this.appGridFull && this.hasApplicationContext) {
      this.appGridFull.destroy();
    }
    const gridMenuInstance = angularGrid.extensionService.getSlickgridAddonInstance(ExtensionName.gridMenu);
    if (gridMenuInstance) {
      this.allGridColumns = gridMenuInstance.getAllColumns();
      gridMenuInstance.onCommand.subscribe((e,a)=>this.executeGridCommand(e,a));
      gridMenuInstance.onBeforeMenuShow.subscribe((e,a)=>this.beforeGridMenuShow(e,a));
    }
    const hdrMenuInstance = angularGrid.extensionService.getSlickgridAddonInstance( ExtensionName.headerMenu);
    if(hdrMenuInstance)
      hdrMenuInstance.onCommand.subscribe((e,col)=> {
      if (col.command == Constants.GRID_HDR_CMD_GROUP) {
        this.applyDataGroup(angularGrid,col.column);
      }
    });

    var gridId = this.getGridId(angularGrid.slickGrid);
    this.appGridFull = angularGrid.slickGrid;
    this.appGridFullDataView = angularGrid.dataView;
    this.appGridFullDataView.setFilter((r,cf)=>this.grids.quickFilter(r,cf,(r,c)=>this.getGridValue(r,c)));
    this.resizeGrid();
    this.initGridSorting(this.appGridFull);

    if (!this.utilService.privileges.canSelectApplication) 
      this.appGridFull.onDblClick.subscribe(()=>this.openWorkflowStateUpdate());

    if (this.hasApplicationContext) {
      this.appGridFull.onRendered.subscribe((e,a)=>{
        if (this.appGridFull.getSelectedRows().length == 0)
          this.setSelectedApp();
      });
    }
  }

  private getGridValue(r:any, c: any ):any {
    return CLGridApplicationTypes.getValueFromColumnId(r, <string>c.id);
  }

  private openWorkflowStateUpdate() {

    var app = this.getSelectedApplication(this.appGridFull);
    if (app) {
      this.userCacheService.getUserGroup(this.currentProject.userGroup).then((userData)=> {
        var data= {
          'application':app,
          'stage':app.workflowStage,
          'state':app.workflowStageState,
          'workflowStages':this.currentProject.getWorkflowStages(),
          'workflowStatuses':this.currentProject.getWorkflowStatuses(),
          'assignee':app.assignee,
          'assignees':userData
        };
        this.openDialog(app,data);
      });
    }
  }

  private openDialog(app:DISOApplication, data:any) {
      const dialogRef = this.dialog.open(SetWorkflowStateDialog, 
        { height:'700px', width:'700px', data: data });

      dialogRef.afterClosed().subscribe( answer => {
          if(answer) {
              var stage= answer.stage;
              var state = answer.state;
              var assignee = answer.assignee;
              var assigneeName=answer.assigneeName;
              this.currentProject.setApplicationWorkflowState(app.id, stage, state, assignee, assigneeName).then((ret)=>{
                app.workflowStage = stage;
                app.workflowStageState = state;
                app.updateWorkflowNames(this.currentProject.dataItem);
                app.assignee = assignee;
                this.appGridFull.invalidateAllRows();
                this.appGridFull.render();
              });
          }
      });
  }

  private applyDataGroup(angularGrid:any,col:Column) {
    var adgCol = CLGridApplicationTypes.get(<string>col.id) 
    var dv = angularGrid.dataView
    dv.setGrouping({
      getter: function (g) {
        var v= CLGridApplicationTypes.getValue(g, adgCol);
        return v;
      },
      formatter: function (g) {
        return col.name + ": " + (g.value||"Blank") + "  <span style='color:green'>(" + g.count + " items)</span>";
      },
      aggregators: [
        new Aggregators.Sum("count")
      ],
      aggregateCollapsed: false,
      lazyTotalsCalculation: true
    });
    this.refreshClearGroupingStatus(true);
  }

  private executeGridCommand(e:any,a:any) {
    var context = a.item.commandContext;
    switch(a.item.command) {
      case Constants.APP_GRID_MENU_CMD_LOAD_PROFILE:
        this.selectProfile(context);
        break;
      case Constants.APP_GRID_MENU_CMD_ADD_PROFILE:
        this.launchCreateGridProfileDialog()
        break;
      case Constants.APP_GRID_MENU_CMD_CLEAR_GROUPING:
        this.clearGrouping();
        break;
      case Constants.APP_GRID_MENU_CMD_TOGGLE_FILTER:
        this.utilService.localStorageHelper.appGridMenuHeaderRowVisible = this.appGridFull.getOptions().showHeaderRow;
        break;
    }
  }

  private beforeGridMenuShow(e:any,a:any) {
  }

  private clearGrouping() {
      this.appGridFullDataView.setGrouping([]);
      this.refreshClearGroupingStatus(false);
  }

  private refreshClearGroupingStatus(status:boolean) {
    var cgm : Array<GridMenuItem> = this.appGridFull.getOptions().gridMenu.customItems
      .filter(x=>(<GridMenuItem>x).command == "clear-grouping")
      .map(x=><GridMenuItem>x);
    if (cgm.length==1)
      cgm[0].disabled =this.appGridFullDataView.getGrouping().length == 0;
  }

  private selectProfile(item: CRS.RSUserColumnProfile) {
    this.userCacheService.registerSelectedUserProfile(item.profileId).then((ret)=> {
      var profileCols = this.getCurrentProfileColumns();
      var visibleCols = this.allGridColumns.filter(x=>x.field == "sel" || profileCols.indexOf(x.id.toString()) != -1);
      this.appGridFull.setColumns(visibleCols);
      this.appGridFull.invalidateAllRows();
      this.appGridFull.render();
      this.highlightGridMenuProfile(this.appGridFull.getOptions().gridMenu);
    });
  }

  private initGridSorting(grid: SlickGrid) {
    grid.onSort.subscribe((e, args) => {
      this.sortApplications(args.sortCols);
       grid.getData().setItems(this.applications);
       grid.invalidateAllRows();
       grid.render();
     });
  };

  private sortApplications(sortCols) :void { 
    var cols = sortCols.map((c)=> {
      var cx = CLGridApplicationTypes.get(c.columnId)
      return {column:cx, asc:c.sortAsc};
    });
    this.applications.sort((d1, d2) => {
      for (var i=0, l=cols.length; i < l; i++) {
        var res = CLGridApplicationTypes.compare(d1,d2, cols[i].column, cols[i].asc);
        if (res!=0)
          return res
      }
      return 0;
    });
  }

  public appGridStateChanged(gridStateChanges: GridStateChange) {
    if (gridStateChanges.change.type == "rowSelection") {
        var crs = <CurrentRowSelection>gridStateChanges.change.newValues;
        if (crs.dataContextIds[0] != undefined) { // Grouped
          this.handleStateChanged(this.appGridFull);
        }
    }
  }

  private getGridOptions(fullFunctionality:boolean) : any {
    var gridMenu = {
      customTitle:null,
      customItems:null,
      hideForceFitButton:true,
      hideSyncResizeButton: true,
      onColumnsChanged : (e,arg)=> {
        var cols = arg.columns.map(x=>x.name);
        var colIds = arg.columns.map(x=>x.id);
        this.gridColumnsHelperService.registerColumnVisibilityChange(cols);
        var uinfo =this.userCacheService.GetUserInfo(); 
        if (uinfo.lastSelectedProfileId)
          this.userCacheService.updateUserProfileColumns(uinfo.lastSelectedProfileId, colIds);
      },
    };

    gridMenu.customItems = [];
    if (this.userCacheService.GetUserInfo().columnProfiles.length>0) {
      gridMenu.customTitle = "Saved Profiles";
      this.userCacheService.GetUserInfo().columnProfiles.forEach((x)=>{
        this.addGridMenuProfile(gridMenu, x);
      });
    }
    gridMenu.customItems.push({ divider: true });
    var newci = {
      title:"Create Profile",
      iconCssClass:"fa fa-plus-square",
      command:Constants.APP_GRID_MENU_CMD_ADD_PROFILE
    };
    gridMenu.customItems.push(newci);

    var clrGrp = {
      title:"Clear Grouping",
      iconCssClass:"fa fa-object-ungroup",
      command:Constants.APP_GRID_MENU_CMD_CLEAR_GROUPING,
      disabled:true
    }
    gridMenu.customItems.push(clrGrp)

    return {
      showHeaderRow: this.utilService.localStorageHelper.appGridMenuHeaderRowVisible,
      headerRowHeight: fullFunctionality ? 30 : 0,
      explicitInitialization: true,
      autoResize: {
        container: fullFunctionality ? "#sg1" : "#sg2",
      },
      autoSizeMode: "FCV",//GridAutosizeColsMode //????
//      autoHeight:true,
      enableAutoResize: true,
      enableSorting: false,
      enableFiltering:fullFunctionality,
      enableRowSelection: true,
      enableCheckboxSelector:true,
      enableCellNavigation:true,
      enableColumnPicker: true,
      rowHeight:Constants.GRID_ROW_HEIGHT,
      checkboxSelector: {
        hideSelectAllCheckbox: false,
      },
      multiSelect: false,
      rowSelectionOptions: {
        selectActiveRow: true,
      },
      gridMenu: gridMenu,
    };
  }

  public isAppBrowsing() {
    return this.stateHelperService.isAppBrowsing();
  }

  private getSelectedApplication(grid:SlickGrid): DISOApplication {
    var selRows = grid.getSelectedRows();
    if (selRows && selRows.length >0) {
      var rowIdx = selRows[0];
      return grid.getDataItem(rowIdx);
    }
    else {
      return null;
    }
  }

  private handleStateChanged(grid: SlickGrid) {
    var row  = this.getSelectedApplication(grid);
    if (row != null) {
      if ( this.utilService.privileges.canSelectApplication) {
        this.router.navigate([
          Constants.ROUTE_PROJECTAPPLICATIONLANDING_MAIN, 
          this.utilService.compressGuid(row.id)
        ], 
        {relativeTo: this.route});    
      } 
    }
  }

  private getGridId(grid:SlickGrid):string {
    var n = grid.getContainerNode();
    while (n!=null) {
      if (n.getAttribute("gridId"))
        return n.getAttribute("gridId");
      n=<HTMLElement>n.parentNode;
    }
    return null;
  }

  private resizeGrid() {
    if (this.hasApplicationContext) // Only do this on full grid
      return;
    if (this.allGridColumns) {
      var visibleColIds = this.getCurrentProfileColumns();
      if (!visibleColIds)
        visibleColIds = CLGridApplicationTypes.defaultItems;  
      var cols = this.allGridColumns.filter(x=>x.field == "sel" || visibleColIds.indexOf(x.id.toString()) != -1);
      this.appGridFull.setColumns(cols);
    }
  }

  private launchCreateGridProfileDialog() :void {
    const dialogRef = this.dialog.open(CreateGridProfileDialog);
    dialogRef.afterClosed().subscribe( answer => {
        if(answer) {
            let name: string = answer["name"];
            var cols = this.gridColumnsHelperService.list(this.appGridFull.getColumns());
            this.userCacheService.createColumnSetProfile(name, cols).then((ret)=>{
              var gridMenu = this.appGridFull.getOptions().gridMenu;
              var ciLst:Array<any>= gridMenu.customItems || [];
              this.userCacheService.GetUserInfo().columnProfiles.forEach((cii)=>{
                  if (ciLst.filter(x=>x.commandContext && cii.profileId == x.commandContext.profileId).length == 0) {
                    this.addGridMenuProfile(gridMenu, cii);
                  }
              });
            });
        }
    });
  }

  private addGridMenuProfile(gridMenu:any, x:CRS.RSUserColumnProfile){
    var uinfo =this.userCacheService.GetUserInfo(); 
    var isCurrent = uinfo && uinfo.lastSelectedProfileId == x.profileId;
    var ci = {
      title: x.profileName,
      disabled: false,
      command: Constants.APP_GRID_MENU_CMD_LOAD_PROFILE,
      commandContext:x,
      iconCssClass: (isCurrent) ?'fa fa-check':null,
      cssClass:(isCurrent) ? "sw-bold": null
    }
    var noProfiles = gridMenu.customItems.filter(x=>x.commandContext).length;
    gridMenu.customItems.splice(noProfiles,0,ci);
  }

  private highlightGridMenuProfile(gridMenu: any) {
    var uinfo =this.userCacheService.GetUserInfo(); 
    gridMenu.customItems.forEach((x)=> {
      if (x.commandContext) {
         x.iconCssClass = (x.commandContext.profileId == uinfo.lastSelectedProfileId) ? "fa fa-check" : null;
      }
    });
  }

  private getCurrentProfileColumns() : Array<string> {
    var uinfo =this.userCacheService.GetUserInfo(); 
    if (!uinfo)
      return;

    if (!this.utilService.isEmptyGuid(uinfo.lastSelectedProfileId))
    {
        if(!this.utilService.isEmptyGuid(uinfo.lastSelectedProfileId)) {
            var lst = uinfo.columnProfiles.filter(x=>x.profileId == uinfo.lastSelectedProfileId);
            if(lst.length==1) {
                return lst[0].columnNames;
            }
        }
    }
    return null;
  }

  private appGridCustomValueMapper(col : CLColumn, app: DISOApplication) : any {
    return (col.id == "AP_RAG") ? app.ragValue: null;
  }

  private setSelectedApp() {
    if (!this.applications)
      return;
    let id = this.currentApplication.dataContext.id;
    var currApp =this.applications.filter(x=>x.id == id);
    if (currApp && currApp.length > 0 && this.appGridFull) {
      var cnt = this.appGridFull.getData().getItemCount();
      if (cnt > 0) {
        var idx = this.appGridFull.getData().getIdxById(currApp[0].id);
        this.appGridFull.setSelectedRows([idx]);
      }
    }
  }

}

import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserCacheService } from '../../svc/userCacheService';

@Component({
    selector: 'create-grid-profile-dialog',
    templateUrl: 'create-grid-profile-dialog.html'
})
export class CreateGridProfileDialog implements OnInit {

    constructor(
        private dialogRef: MatDialogRef<boolean>,
        private userCacheService:UserCacheService,
    ) {
    }

    public values: any= { name:""};
 
    ngOnInit() {
    }

    isDataReady() : boolean {
        return true;
    }

    isValid(): boolean {
        if (!this.values.name)
            return false;
        var val:string= this.values.name.trim();
        if (val.length<3)
            return false;
        var lval=val.toLowerCase();
        if (this.userCacheService.GetUserInfo().columnProfiles.filter(x=>x.profileName.toLowerCase() == lval).length > 0)
            return false;

        return true;
    }

    closeDialog() {
        this.dialogRef.close(this.values);
    }

    cancelDialog() {
        this.dialogRef.close();
    }
}


import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectOutputsData} from './project-outputs-data';
import { UtilService } from '../../svc/utilService';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { OutputItemInfoApp } from '../../model/OutputItemInfoApp';
import { OutputItemInfoProj } from '../../model/OutputItemInfoProj';

import * as Enums from '../../api/Enum';
import * as CRS from '../../api/CRS';
import { CurrentApplicationService } from 'src/app/svc/currentApplicationService';

@Component({
    selector: 'project-outputs-dialog',
    templateUrl: 'project-outputs-dialog.html',
    styleUrls:['./project-outputs-dialog.scss']
  })
  export class ProjectOutputsDialog implements OnInit {
  
    constructor(
      private dialogRef: MatDialogRef<ProjectOutputsDialog>,
      public currentProject: CurrentProjectService, 
      private currentApplication: CurrentApplicationService,
      private utilService: UtilService,
      @Inject(MAT_DIALOG_DATA) public data: ProjectOutputsData) {}

    ngOnInit() {
      const matDialogConfig: MatDialogConfig = new MatDialogConfig();
      const rect = this.data.element.nativeElement.getBoundingClientRect();
      var wid:number=600;
      matDialogConfig.position = { left: `${rect.left - (wid - 60)}px`, top: `${rect.bottom}px` };
      matDialogConfig.width = `${wid}px`;
      matDialogConfig.minHeight = '170px';
      this.dialogRef.updateSize(matDialogConfig.width, matDialogConfig.height);
      this.dialogRef.updatePosition(matDialogConfig.position);
      if (!this.resize()){
        this.currentApplication.dataContext.onLoaded().subscribe(()=> {
          this.resize();
        });
      }
    }

    public isDataReady() : boolean{ 
      return this.currentProject.dataContext.isReady;
    }

    public get applicationIsReady(): boolean {
      return this.currentApplication.dataContext.isReady;
    }

    public get applicationOutputTypes(): Array<OutputItemInfoApp> {
      return this.currentApplication.outputTypes;
    }

    public outputExistsFilter = (item:OutputItemInfoApp | OutputItemInfoProj) : boolean => {
      return  item.getStatus() >= 0 ;
    }

    public itemExists(item: OutputItemInfoApp) : boolean {
      return item.getStatus() >= 0;
    }

    public onNoClick(): void {
        this.dialogRef.close();
    }

    public test() : void {
      alert('a');
    }

    public onClick(item: OutputItemInfoApp | OutputItemInfoProj): void {
      var fileLink = document.createElement('a');
      fileLink.href = item.getUrl();
      fileLink.click();     
    }

    public contentHeight:string="250px";

    private resize() :boolean {
      if (!this.currentApplication.dataContext.isReady)
        return false;
      var hgt=0;
      var cnt =this.currentApplication.outputTypes.filter(x=>this.outputExistsFilter(x)).length;
      hgt = (cnt*40)+60;
      cnt = this.currentProject.outputTypes.filter(x=>this.outputExistsFilter(x)).length;
      hgt += (cnt*40)+60;
      this.contentHeight = String(hgt) + "px";
      return true;
    }
  }
import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription } from "rxjs";
import { first ,take } from 'rxjs/operators';
import { MergeHelper } from '../../api/DataMerger';
import { UtilService } from '../../svc/utilService';
import { DialogService } from '../../svc/dialogService';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { StateHelperService } from '../../svc/stateHelperService';
import { XCheckVirtualMachineChangeItem } from 'src/app/model/XCheckVirtualMachineChangeItem';
import { WindowsUpdateChangeItem } from 'src/app/model/WindowsUpdateChangeItem';
import { Constants } from '../../api/Constants';
import { ImportVmDialog } from './import-vm-dialog';

import * as STHS from '../../svc/stateHelperService';
import * as CRS from '../../api/CRS';
import { TrackedOperatingSystem } from 'src/app/model/TrackedOperatingSystem';

@Component({
  selector: Constants.ROUTE_PROJECTSETTINGS,
  templateUrl: './project-xcheck.html'
})
export class ProjectXCheck implements OnInit, OnDestroy {

  constructor(
    private currentProjectService: CurrentProjectService, 
    private dialogService: DialogService,
    private utilService:UtilService,
    public stateHelperService: StateHelperService,
    private injector:Injector
  ) { }

  public windowsUpdates: Array<CRS.RSWindowsUpdate>;
  public updateListFilterType: number =0;
  public virtualMachines: Array<CRS.RSXCheckVirtualMachine>;
  
  private lastLoadedProject: string=null;
  private stateHelperInstance: STHS.StateHelperInstance;
  private deReg:any=null;
  private dialog: MatDialog;
  private subscriptions: Array<Subscription> =new Array<Subscription>();
  
  ngOnInit(): void {
    this.stateHelperInstance= this.stateHelperService.register(Constants.ROUTE_PROJECTXCHECK);
    this.updateListFilterType = this.utilService.getXCheckUpdateFilterState();

    this.update();
    this.subscriptions.push(this.currentProjectService.dataContext.onRefreshed().subscribe(x=>this.update()));

    this.dialog = this.injector.get(MatDialog);

    this.currentProjectService.onXCheckVirtualMachineCreated().pipe(first()).subscribe(x=>{
        this.update();
    });
  }

  ngOnDestroy() {
    if(this.deReg)
        this.deReg();
    this.subscriptions.forEach(x=>x.unsubscribe());
  }

  public get showVmsPanel() : boolean {
      return this.utilService.showVmsPanel;
  }

  public update(): void {

    if (!this.currentProjectService.dataContext.isReady)
        return;

    this.currentProjectService.getWindowsUpdates().then((data) => {
        if (!this.windowsUpdates)
           this.windowsUpdates=[];
        MergeHelper.ListMerge(data, this.windowsUpdates, (i)=>{ return i.id }, CRS.RSWindowsUpdate);
    }, () => {
        console.error('Windows updates couldn\'t be loaded');
    });

    this.currentProjectService.getXCheckVirtualMachines().then((data) => {
        if (!this.virtualMachines || this.currentProjectService.dataContext.id != this.lastLoadedProject)
           this.virtualMachines=[];
        MergeHelper.ListMerge(data, this.virtualMachines, (i)=>{ return i.id }, CRS.RSXCheckVirtualMachine);
        this.lastLoadedProject = this.currentProjectService.dataContext.id;
    }, () => {
        console.error('XCheck virtual machines couldn\'t be loaded');
    });

}

public buildUpdate(update: CRS.RSWindowsUpdate) : void {

}

public setRunningState(chg: WindowsUpdateChangeItem) : void {
    var tos = new TrackedOperatingSystem(chg.item.operatingSystemId, chg.item.checkpoint, chg.item.origin.toString());
    tos.isChecked = chg.item.isRunning;
    tos.originalTestEnabled = chg.item.isRunning;
     this.currentProjectService.updateXCheckTrackedOperatingSystems(tos ).then((data)=>{
         this.update();
     });
}

public deleteVirtualMachine(chg:XCheckVirtualMachineChangeItem) {
    this.dialogService.showYesNoMessage("Delete Virtual Machine", ["Are you sure that you want to delete this virtual machine?"])
    .pipe(take(1))
    .subscribe((ans)=> {
        if (ans) {
            this.currentProjectService.deleteVirtualMachineImage(chg.item.id).then((s)=>{
                this.update();
            })
        }
    });
}

public retireUpdate(chg: WindowsUpdateChangeItem) : void
{
    this.dialogService.showYesNoMessage("Delete Image", ["Are you sure that you want to delete this image?. This is not reversible"])
     .pipe(take(1))
     .subscribe((ans) => {
        if (ans) {
            this.currentProjectService.retireWindowsUpdate(chg.item).then((ret)=> {
                this.update();
            });
        }
    });
}

public importVirtualMachine(chg:XCheckVirtualMachineChangeItem) {
    var dialogRef = this.dialog.open(ImportVmDialog, { width : '600px', height:'330px'});
    dialogRef.afterClosed().subscribe((result)=> {
        if (result) {
            this.currentProjectService.importXCheckImage(chg.item.id, result.updateName, result.year, result.month).then((ret)=> {
                this.dialogService.showInfoMessage("Import Machine Into Windows Update", [ "The machine is being imported into the available updates. This will take a few minutes" ]);
                this.update();
            });
        }
    });
}

public hasVirtualMachines() : boolean {
   return !this.utilService.isEmptyAny(this.virtualMachines) && this.virtualMachines.length > 0;
}

public changeUpdateListFilterType() {
   this.utilService.setXCheckUpdateFilterState(this.updateListFilterType);
}

public isReady(): boolean {
    return !this.utilService.isEmptyAny( this.windowsUpdates );
}

public isReadyVMs(): boolean {
    return !this.utilService.isEmptyAny( this.virtualMachines );
}

}

import { Injectable } from '@angular/core';
import { HttpEvent,  HttpInterceptor,  HttpHandler,  HttpRequest} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './authService';

@Injectable({
    providedIn: 'root'
})
export class AuthTokenInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.authToken;
    if (token) {
      req = req.clone({
        setHeaders: {
          'Authorization': `Bearer ${token}`
        },
      });
    }

    return next.handle(req);
  }
}


// intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//   return this.authService.getAuthToken().pipe(mergeMap((t,i) => {
//       req = req.clone({ setHeaders: { 'Authorization': `Bearer ${t}` } });
//       return next.handle(req);
//   }));
// }

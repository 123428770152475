<div *ngIf="!hasItems()">
    No Scheduled Tasks were created by this installation
</div>

<div *ngFor="let si of item.scheduledTaskItems" style="width:1200px">
    <h3><span class="sw-bold">{{si.name}}</span></h3>
    <div fxLayout="row" class="full-width-bs">
        <div fxFlex>
            <table class="full-width-bs sw-pad-bottom">
                <tr>
                    <td style="width:150px">URI</td>
                    <td>{{si.uri}}</td>
                </tr>
                <tr>
                    <td>Author</td>
                    <td>{{si.author}}</td>
                </tr>
                <tr>
                    <td>Comment</td>
                    <td>{{si.comment}}</td>
                </tr>
                <tr>
                    <td>Days</td>
                    <td>{{si.days}}</td>
                </tr>
                <tr>
                    <td>Delete Task if Not Rescheduled</td>
                    <td>{{si.deleteTaskIfNotRescheduled}}</td>
                </tr>
                <tr>
                    <td>End Date</td>
                    <td>{{si.endDate}}</td>
                </tr>
                <tr>
                    <td>Host Name</td>
                    <td>{{si.hostName}}</td>
                </tr>
                <tr>
                    <td>Idle Time</td>
                    <td>{{si.idleTime}}</td>
                </tr>
                <tr>
                    <td>Last Result</td>
                    <td>{{si.lastResult}}</td>
                </tr>
                <tr>
                    <td>Last Run Time</td>
                    <td>{{si.lastRunTime}}</td>
                </tr>
                <tr>
                    <td>Logon Mode</td>
                    <td>{{si.logonMode}}</td>
                </tr>
                <tr>
                    <td>Months</td>
                    <td>{{si.months}}</td>
                </tr>
                <tr>
                    <td>Next Run Time</td>
                    <td>{{si.nextRunTime}}</td>
                </tr>
                <tr>
                    <td>Power Management</td>
                    <td>{{si.powerManagement}}</td>
                </tr>
                <tr>
                    <td>Repeat Every</td>
                    <td>{{si.repeatEvery}}</td>
                </tr>
                <tr>
                    <td>Repeat Stop If Still Running</td>
                    <td>{{si.repeatStopIfStillRunning}}</td>
                </tr>
                <tr>
                    <td>Repeat Until Duration</td>
                    <td>{{si.repeatUntilDuration}}</td>
                </tr>
                <tr>
                    <td>Repeat Until Time</td>
                    <td>{{si.repeatUntilTime}}</td>
                </tr>
                <tr>
                    <td>Run As User</td>
                    <td>{{si.runAsUser}}</td>
                </tr>
                <tr>
                    <td>Schedule</td>
                    <td>{{si.schedule}}</td>
                </tr>
                <tr>
                    <td>Schedule Type</td>
                    <td>{{si.scheduleType}}</td>
                </tr>
                <tr>
                    <td>Scheduled Task State</td>
                    <td>{{si.scheduledTaskState}}</td>
                </tr>
                <tr>
                    <td>Start Date</td>
                    <td>{{si.startDate}}</td>
                </tr>
                <tr>
                    <td>Start Time</td>
                    <td>{{si.startTime}}</td>
                </tr>
                <tr>
                    <td>Status</td>
                    <td>{{si.status}}</td>
                </tr>
                <tr>
                    <td>Stop Task If Runs XHours and XMins</td>
                    <td>{{si.StopTaskIfRunsXHoursandXMins}}</td>
                </tr>
            </table>
        </div>  
        <div fxFlex="30">
            <div *ngIf="!si.screenshot">NO IMAGE</div>
            <img *ngIf="si.screenshot" [src]="getScreenshot(si)" class="img-contain">
        </div>
    </div>
</div>


<h2 mat-dialog-title>TEST COMPLETE</h2>
  
<div mat-dialog-content class='sw-dialog-padding' style="width:450px">

    <div fxLayout="column" fxLayoutGap="5px">
        <div fxFlex>
            <p>What was the outcome of this test?</p>
            <mat-radio-group [(ngModel)]="values.outcome" class="sw-radio-group">
                <mat-radio-button value="+" class="sw-radio-button md-primary">Success</mat-radio-button>
                <mat-radio-button value="-" class="sw-radio-button">Failure</mat-radio-button>
                <mat-radio-button value="?" class="sw-radio-button">Inconclusive</mat-radio-button>
            </mat-radio-group>
        </div>
        <div fxFlex>
            <p>Further Information:</p>
            <textarea [(ngModel)]="values.text" rows="5" class="full-width-bs"></textarea>
        </div>
        <div fxFlex>
            <p>Click "Accept" button to release this machine, or "Cancel" button to retain the it for further testing.</p>
        </div>
    </div>

</div>

<div mat-dialog-actions fxLayout='row' fxLayoutGap="10px">
    <div fxFlex></div>
    <div fxFlex="none">
        <button mat-raised-button color="primary" (click)='closeDialog()' [disabled]="!isValid()">
            <mat-icon>done</mat-icon>
            <span>Accept</span>
        </button>
    </div>
    <div fxFlex="none">
        <button mat-raised-button (click)='cancelDialog()'>
            <mat-icon>cancel</mat-icon>
            <span>Cancel</span>
        </button>
    </div>
</div>


<div mat-dialog-title>
    <h4>CREATE PORTFOLIO TEST CYCLE</h4>
</div>

<div mat-dialog-content style="height:80%">

    <div class="full-width-bs">
        <mat-form-field appearance="outline" class="full-width-bs">
            <mat-label>Name</mat-label>
            <input type="text" matInput [(ngModel)]="testCycleName" maxlength="50" aria-placeholder="Test cycle name">
        </mat-form-field>
    </div>

    <div class="full-width-bs">
        <mat-form-field appearance="outline" class="full-width-bs">
            <mat-label>Base Image</mat-label>
            <mat-select [(ngModel)]="selectedBaseImage" aria-label="Base Image" aria-placeholder="Select Base Image" style="margin: 2px">
                <mat-option [value]="x" *ngFor="let x of getBaseImages()">{{x.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="full-width-bs">
        <mat-form-field appearance="outline" class="full-width-bs">
            <mat-label>Update</mat-label>
            <mat-select [(ngModel)]="selectedUpdate" aria-label="Patch Update" aria-placeholder="Select Update" style="margin: 2px">
                <mat-option [value]="x" *ngFor="let x of getPatchUpdates()">{{x.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="full-width-bs">
        <mat-form-field appearance="outline" class="full-width-bs">
            <mat-label>Test Cycle</mat-label>
            <mat-select [(ngModel)]="selectedTestCycle" aria-label="Test Cycle" aria-placeholder="Select Test Cycle" style="margin: 2px">
                <mat-option [value]="x" *ngFor="let x of ['Ring 0','Ring 1','Ring 2']">{{x}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="full-width-bs">
        <mat-checkbox [(ngModel)]="runTelemetryScripts" matFormFieldControl aria-label="Run telemetry scripts">Run Telemetry Scripts</mat-checkbox>
    </div>

</div>

<div mat-dialog-actions fxLayout='row' fxLayoutGap="10px">
    <div fxFlex></div>
    <button mat-button (click)='closeDialog()' [disabled]="!isValid()">
        <mat-icon>done</mat-icon>
        <span>Apply</span>
    </button> 
    <button mat-button (click)='cancelDialog()'>
        <mat-icon>cancel</mat-icon>
        <span>Cancel</span>
    </button>
</div>


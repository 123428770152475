import { Component, OnInit, OnDestroy } from '@angular/core';
import { Constants } from '../../api/Constants';
import { CurrentApplicationService } from 'src/app/svc/currentApplicationService';

@Component({
    selector: 'project-application-companions',
    templateUrl: './project-application-companions.html',
    styleUrls: []
})
export class ProjectApplicationCompanions implements OnInit, OnDestroy {

  constructor(
    private currentApplication: CurrentApplicationService,
  ) { }

    ngOnInit():void {
        
    }

    ngOnDestroy():void {

    }

    public isReady(): boolean {
        return this.currentApplication.dataContext.isReady;
    }

}



import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentConversionJobService } from "../svc/currentConversionJobService";
import { MenuService } from '../svc/menuService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';
import { CurrentApplicationService } from '../svc/currentApplicationService';
import { CurrentProjectService } from '../svc/currentProjectService';
import { UtilService } from '../svc/utilService';
import { StateHelperService } from '../svc/stateHelperService';

import * as CRS from '../api/CRS';

export class PublishedTestCommand {

    constructor(
        private injector: Injector
    ) {
        this.router = this.injector.get(Router);
        this.menuService = this.injector.get(MenuService);
        this.currentConversionJob = this.injector.get(CurrentConversionJobService);
        this.currentApplication = this.injector.get(CurrentApplicationService);
        this.currentProject = this.injector.get(CurrentProjectService);
        this.utilService = this.injector.get(UtilService);
        this.stateHelperService = this.injector.get(StateHelperService);

        this.menuService.register(Constants.MENU_PUBLISH_PACKAGE, this.cmd, Constants.ROUTE_PROJECTAPPLICATIONACTIONSDETAIL_MAIN )
    }

    private router: Router;
    private currentConversionJob: CurrentConversionJobService;
    private currentApplication: CurrentApplicationService;
    private currentProject: CurrentProjectService;
    private menuService: MenuService;
    private utilService: UtilService;
    private stateHelperService: StateHelperService;

    private get parentAction() : CRS.RSAction {
        let action = this.currentApplication.findActionById(this.stateHelperService.details.actionId);
        if (action) {
            var parentId = `PLC${Constants.CONV_ACTION_DELIM}${action.parentageId}`;
            return this.currentApplication.findActionByBaseId(parentId);
        }
        else {
            return null;
        }
    }

    public cmd: AppCommand = AppCommand.createWithShow(
        ()=> {
             return this.currentApplication.dataContext.isReady && !this.utilService.isEmptyAny(this.parentAction);
        },
        ()=> {
            let parentAction =this.parentAction;
            this.currentConversionJob.publishedTest(parentAction.id).then((jobId)=>{
                this.currentApplication.dataContext.refreshCurrent();
                this.router.navigate([
                    Constants.ROUTE_PROJECTDETAILS_MAIN,
                    this.utilService.compressGuidWithSvr(this.currentProject.dataContext.id),
                    Constants.ROUTE_PROJECTAPPLICATIONS,
                    Constants.ROUTE_PROJECTAPPLICATIONLANDING_MAIN, 
                    this.utilService.compressGuid(this.currentApplication.dataContext.id),
                    Constants.ROUTE_PROJECTAPPLICATIONACTIONSDETAIL_MAIN,
                    this.utilService.compressGuid(jobId) ]);        
            });
        },
        ()=>{
            return false;
        }
    );

}

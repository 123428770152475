<div class="sw-actions-tree">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="sw-tree">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
            <li class="mat-tree-node">
                <!-- use a disabled button to provide padding for tree leaf -->
                <button mat-icon-button class="sw-pad-button"></button>
                <button mat-button [class.sw-current]="isCurrent(node)" (click)="openAction(node)" class="sw-tree-button">
                  <div fxLayout="row" fxLayoutGap="5px">
                    <div fxFlex="none" *ngIf="node.icon">
                      <mat-icon [inline]="true" image-size size="16pt">{{node.icon}}</mat-icon>
                    </div>
                    <div fxFlex>
                      <span class="sw-tree-node" [ngClass]="{'sw-error-simple': node.status == 2 && !isCurrent(node), 'sw-ok-simple': node.status == 4  && !isCurrent(node)}">{{node.label}}</span>
                      <span class="sw-bold" [ngClass]="{'sw-count':!isCurrent(node), 'sw-count-current':isCurrent(node)}" *ngIf="node.hasCount">&nbsp;({{node.count}})</span>
                    </div>
                  </div>
                </button>
              </li>
        </mat-tree-node>
        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
            <li>
              <div class="mat-tree-node">
                <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.label" (click)="nodeExpandChanged()">
                  <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                  </mat-icon>
                </button>
                <button mat-button [class.sw-current]="isCurrent(node)" (click)="openAction(node)" class="sw-tree-button">
                  <div fxLayout="row" fxLayoutGap="5px">
                    <div fxFlex="none" *ngIf="node.icon"><mat-icon [inline]="true">{{node.icon}}</mat-icon></div>
                    <div fxFlex>
                      <span class="sw-tree-node" [ngClass]="{'sw-error-simple': node.status == 2 && !isCurrent(node), 'sw-ok-simple': node.status == 4 && !isCurrent(node)}">{{node.label}} </span>
                      <span class="sw-bold" [ngClass]="{'sw-count':!isCurrent(node), 'sw-count-current':isCurrent(node)}" *ngIf="node.hasCount">&nbsp;({{node.count}})</span>
                    </div>
                  </div>
                </button>
              </div>
              <ul [class.sw-tree-invisible]="!treeControl.isExpanded(node)">
                <ng-container matTreeNodeOutlet></ng-container>
              </ul>
            </li>
          </mat-nested-tree-node>
    </mat-tree>
  </div>
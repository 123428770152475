import { Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { first} from 'rxjs/operators';
import { UtilService} from '../svc/utilService';
import { MenuService } from '../svc/menuService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';
import { MergeHelper } from '../api/DataMerger';
import { CurrentProjectService } from '../svc/currentProjectService';
import { AddApplicationDialog } from '../dialogs/add-application/add-application-dialog';

import * as CRS from '../api/CRS';
import { AddApplicationData } from '../model/AddApplicationData';

export class AddApplicationCommand {

    constructor(
        private injector: Injector
    ) {
        this.menuService = this.injector.get(MenuService);
        this.currentProject = this.injector.get(CurrentProjectService);
        this.dialog = this.injector.get(MatDialog);
        this.utilService = this.injector.get(UtilService);

        this.menuService.register(Constants.MENU_ADD_APPLICATION, this.command, Constants.ROUTE_PROJECTAPPLICATIONS )
    }

    private menuService: MenuService;
    private currentProject: CurrentProjectService;
    private dialog: MatDialog;
    private utilService: UtilService;

    private _data: AddApplicationData=null;
    public get data(): AddApplicationData {
        if (!this._data)
            this._data = new AddApplicationData();
        return this._data;
    }

    public resetData(): void {
        this._data = new AddApplicationData();
    }

    public isDataValid(): boolean {
        return !this.utilService.isEmptyAny(this.data.productName)  && this.data.productName.length>3;
    }

    public apply(): Promise<CRS.ResponseWrapper<CRS.RSAddApplication>> {        return this.currentProject.addApplication(this.data.productName, this.data.productVersion, this.data.priority, this.data.sourceMediaPath);
    }

    public afterApply(data: CRS.RSAddApplication): void {
        var app = this.currentProject.dataItem.applications.filter(x=>x.id == data.application.id);
        if (app.length == 0) {
            MergeHelper.ListMerge([data.application], this.currentProject.dataItem.applications, (i)=>{ return i.id }, CRS.RSApplication );
        }
    }

    private command: AppCommand = AppCommand.createWithShow(
        ()=> {
            return this.currentProject.dataContext.isReady;
        },
        ()=> {
            this.resetData();
            let dialogRef = this.dialog.open(AddApplicationDialog,
                 { width:'700px', height:'600px' });
            dialogRef.afterClosed().pipe(first()).subscribe((data)=>{
            });
        }, 
        ()=> { 
            return this.utilService.privileges && this.utilService.privileges.canAddApplication;
        }

    );

}
export class PL {
    osType:string;
}

export class PLSLIDERSTEPVALUE {
    constructor(value:any, legend: string) {
        this.value =value;
        this.legend = legend;
    }
    value:any;
    legend: string;
}

export class PLSLIDEROPTIONS {
    showTicks:boolean;
    showTicksValues: boolean;
    floor: number; 
    ceil: number;
    onEnd: (id, v, hv) => void;
    translate: (v: any)=>void;
}

export class PLSLIDER {
    constructor(value:number, floor:number, ceil:number, items: Array<any>, changeHandler: ()=> void) {
        this.value =value;
        this.items = items;
        if (items) {
            this.options = new PLSLIDEROPTIONS();
            this.options.showTicks = true;
            this.options.showTicksValues=false;
            this.options.floor = floor;
            this.options.ceil = ceil;
//            this.options.onEnd = changeHandler;
            this.options.translate =   (v) =>  { 
                if (v>=0 && v<this.items.length)  {
                    return  this.items[v].display ? this.items[v].display : this.items[v].id
                } 
                return "--unknown--"; 
            };
        }
        else {
            this.options = new PLSLIDEROPTIONS();
            this.options.floor = 0;
            this.options.ceil=0;
        }
        this.changeHandler = changeHandler;
    }
    changeHandler: ()=> void;
    value:number;
    options: PLSLIDEROPTIONS
    osType:string;
    items:Array<any>;

    public updateEnd($event) {
        this.changeHandler();
    }
}

export class PLSLIDERRANGE {
    constructor(min:number, max:number, floor:number, ceil:number, items: Array<any>, changeHandler: ()=> void) {
        this.min =min;
        this.max = max;
        this.items = items;
        this.options = new PLSLIDEROPTIONS();
        this.options.showTicks = true;
        this.options.showTicksValues=false;
        this.options.floor = floor;
        this.options.ceil = ceil;
        this.options.onEnd = changeHandler;
        this.changeHandler = changeHandler;
        this.options.translate =   (v) =>  { 
            if (v>=0 && v<this.items.length)  {
                return  this.items[v].display ? this.items[v].display : this.items[v].id
            } 
            return "--unknown--"; 
        };
    }
    changeHandler: ()=> void;
    min:number;
    max:number;
    options: PLSLIDEROPTIONS
    osType:string;
    items:Array<any>;

    public updateEnd($event) {
        this.changeHandler();
    }

}
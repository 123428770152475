import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take} from 'rxjs/operators';
import { UtilService } from '../../svc/utilService';
import { XCheckOutputStateInfo } from '../../model/XCheckOutputStateInfo';
import { DialogService } from '../../svc/dialogService';
import { Constants } from '../../api/Constants';
import { CurrentProjectService } from 'src/app/svc/currentProjectService';
import { CurrentApplicationService } from 'src/app/svc/currentApplicationService';

import * as CRS from '../../api/CRS';

@Component({

  selector: 'xcheck-result-item',
  templateUrl: './xcheck-result-item.html',
  styleUrls: ['../xcheck.scss']
})
export class XCheckResultItem implements OnInit {

  constructor(
      private router:Router, 
      private currentProject: CurrentProjectService,
      private currentApplication: CurrentApplicationService,
      private dialogService: DialogService,
      private utilService:UtilService) { }

    @Input('item') public item:CRS.RSXCheckResult;
    @Input('currentState') public currentState: XCheckOutputStateInfo;

    ngOnInit(): void {
     }

    public setTab(tabId:string) {
       //localStorage.setItem(Constants.LOCALSET_XCHECK_ACTION_TABID , tabId);
    }

    public isCurrentTab(tabId:string) {
        return this.currentState.id == tabId;
    }

    public getJobFailScreenshotImageUrl(result: CRS.RSXCheckResult) : string {
        let auth :string = this.utilService.getToken();
        //return this.utilService.getAPIUrl() + "/conversion/getActionFailureScreenshot?jobId="+ this.item.jobId + "&Auth=" + auth;
        return null;
    }

    public clearJob() : void {
      this.dialogService.showYesNoMessage("Delete Action", ["Are you sure that you want to delete this action?"])
      .pipe(take(1))
       .subscribe((ans)=> {
          if (ans) {
              let jobId = this.item.jobId;
               this.currentApplication.resetXCheckJob(jobId).then(()=>{
                  this.currentApplication.dataContext.refreshCurrent();
                  this.router.navigate([
                      Constants.ROUTE_PROJECTDETAILS_MAIN,
                      this.utilService.compressGuidWithSvr(this.currentProject.dataContext.id),
                      Constants.ROUTE_PROJECTAPPLICATIONS,
                      this.utilService.compressGuid(Constants.ROUTE_PROJECTAPPLICATIONLANDING_MAIN), 
                      this.currentApplication.dataContext.id
                  ]);        
               });
          }
      });
    }

    public openRetainedLog($event, jobId: string, rf: CRS.RSRetainedFile) {
      var url = this.utilService.getAPIUrl( `Conversion/GetRetained?JobId=${jobId}&FileId=${rf.name}&serverId=${this.utilService.serverId}`);
      this.utilService.downloadViaApi(url, null, rf.contentType);
      $event.stopPropagation();
      $event.preventDefault();
   }

}

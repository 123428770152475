import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { UtilService } from '../../svc/utilService';
import { ProjectService } from '../../api/SVC';
import { CurrentProjectService} from '../../svc/currentProjectService';
import { UserCacheService } from '../../svc/userCacheService';
import { Constants } from '../../api/Constants';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as CRS from '../../api/CRS';

@Component({
    selector: 'import-vm-dialog',
    templateUrl: './import-vm-dialog.html',
    styleUrls: []
  })
export class ImportVmDialog {

    public selectedDate: string=null;
    public updateName:string=null;

    constructor(
        private dialogRef: MatDialogRef<ImportVmDialog>,
        public utilService: UtilService,
        @Inject(MAT_DIALOG_DATA) public data: ImportVmDialog) { }

    public getPossibleDates() : Array<string> {
        var d =new Date();
        var m =d.getMonth();
        var y =d.getFullYear();
        var ret = [];
        for(var i=0; i<5; i++)
        {
            var m2 = m<9 ? "0"+(m+1) : m+1;
            var v = y + "-" + m2;
            ret.push(v);
            m++;
            if (m>11) {
                m=1;
                y++;
            }
        }
        return ret;
    }

    public isValid() : boolean {
        return this.updateName != null && this.selectedDate !=null
    }

    public closeDialog() {
        var ym = this.selectedDate.split('-');
        var y = parseInt(ym[0]);
        var m =parseInt(ym[1]);
        this.dialogRef.close({ updateName: this.updateName, year: y, month: m });
    }

    public cancelDialog() {
        this.dialogRef.close(null);
    }
}

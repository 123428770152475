
<div mat-dialog-content [style.height]="contentHeight">

    <spinner *ngIf="!isDataReady()"></spinner>

    <div *ngIf="isDataReady()">

      <div *ngIf="applicationIsReady">
        <h4 class="sw-output">Application Outputs</h4>

        <table class="full-width-bs">
          <thead>
            <tr>
              <th></th>
              <th>Type</th>
              <th>Time</th>
              <th>Download</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let oi of applicationOutputTypes | callback : outputExistsFilter" class="sw-output-row">
              <td>
                <mat-icon *ngIf="oi.getStatus()==4" class="sw-ok" image-size size="20pt">check_circle</mat-icon>
                <mat-icon *ngIf="oi.getStatus()==2 || oi.getStatus()==5" class="sw-err" image-size size="20pt">cancel_icon</mat-icon>
                <div *ngIf="oi.getStatus()==1">
                  <mat-spinner [diameter]="20" ></mat-spinner>
                </div>
              </td>
              <td>{{oi.getTitle()}}</td>
              <td>{{oi.getDateExpression()}}</td>
              <td>
                <button mat-raised-button (click)="onClick(oi)" *ngIf="oi.getStatus()==4" title="{{oi.getOutputTypeDescription()}}">
                  <mat-icon>file_download</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <br>

      <div *ngIf="currentProject.outputTypes.length > 0">
        
        <h4 class="sw-output">Project Outputs</h4>

        <table class="full-width-bs">
            <tbody>
              <tr *ngFor="let oi of currentProject.outputTypes" class="sw-output-row">
                <td>
                  <mat-icon *ngIf="oi.getStatus()==4" class="sw-ok" image-size size="20pt">check_circle</mat-icon>
                  <mat-icon *ngIf="oi.getStatus()==2" class="sw-err" image-size size="20pt">cancel_icon</mat-icon>
                  <div *ngIf="oi.getStatus()==1">
                    <mat-spinner [diameter]="20" ></mat-spinner>
                  </div>
                </td>
                <td>{{oi.getTitle()}}</td>
                <td>{{oi.getDateExpression()}}</td>
                <td>
                  <button mat-raised-button (click)="onClick(oi)" *ngIf="oi.getStatus()==4" title="{{oi.getOutputTypeDescription()}}">
                    <mat-icon>file_download</mat-icon>
                  </button>
                </td>
              </tr>
            </tbody>
        </table>

    </div>

</div>



<div fxLayout="column" fxLayoutGap="10px" style="height:100%;overflow:scroll">

    <div fxFlex fxLayout="column" fxLayoutAlign='start center' *ngIf='!filePayload'>
        <spinner></spinner>
    </div>

    <div fxFlex *ngIf="filePayload">
        <div layout="column" style="border-bottom:solid 2px gray"> 
            <div fxFlex *ngFor="let url of filePayload.bulletinUrls">
                {{url}}
            </div>
        </div>
        
        <div fxLayout="column" style="border-bottom:solid 2px gray"> 
            <div fxFlex *ngFor="let url of filePayload.downloadUrls">
                {{url}}
            </div>
        </div>

        <div fxLayout="row" layout-wrap>
            <div fxFlex="50" *ngFor="let fileName of filePayload.filenames">
                {{fileName}}
            </div>
        </div>
    </div>  

</div>
import { Component, Inject } from '@angular/core';
import { UtilService } from '../../svc/utilService';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as SVC from '../../api/SVC';
import * as CRS from '../../api/CRS';

@Component({
    selector: 'create-xcheck-patch-test-dialog',
    templateUrl: './create-xcheck-patch-test-dialog.html',
    styleUrls: []
  })
export class CreateXCheckPatchTestDialog {

    public selectedMachine : CRS.RSVirtualMachine=null;
    public selectedDate: string=null;
    public selectedBaseImage: CRS.RSVhd2=null;
    public selectedTestCycle: string=null;
    public testCycleName:string=null;
    public selectedUpdate: CRS.RSPatchUpdate=null;
    public runTelemetryScripts: boolean=false;

    private baseImages: CRS.RSGetBaseImages=null;
    private patchUpdates: CRS.RSGetPatchUpdates=null;

    constructor(
        private dialogRef: MatDialogRef<CreateXCheckPatchTestDialog>,
        public utilService: UtilService,
        public conversionService: SVC.ConversionService,
        @Inject(MAT_DIALOG_DATA) public data: CreateXCheckPatchTestDialog) { 

        this.conversionService.getBaseImages().then((response)=> {
            this.baseImages = response.data;
        });
        this.conversionService.getPatchUpdates().then((response)=> {
            this.patchUpdates = response.data;
        })
    
    }

    public getBaseImages(): Array<CRS.RSVhd2> {
        return (this.baseImages) ? this.baseImages.images: [];
    }

    public getPatchUpdates(): Array<CRS.RSPatchUpdate> {
        return (this.patchUpdates) ? this.patchUpdates.items : [];
    }

    private _testCycles : [ 'Ring 0', 'Ring 1', 'Ring 2' ];

    public getTestCycles() : Array<string> {
        return this._testCycles;
    }

    public isValid() : boolean {
        return this.selectedBaseImage != null && this.selectedUpdate != null 
                && this.testCycleName && this.testCycleName.length > 3 && this.selectedTestCycle != null;
    }

    public closeDialog() {
        this.dialogRef.close({ 
            imageId: this.selectedBaseImage.id, 
            updateId: this.selectedUpdate.id, 
            runTelemetryScripts: this.runTelemetryScripts   
        });
    }

    public cancelDialog() {
        this.dialogRef.close(null);
    }
}

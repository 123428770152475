import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';
import { CurrentApplicationService } from '../svc/currentApplicationService';
import { CurrentProjectService } from '../svc/currentProjectService';
import { UtilService } from '../svc/utilService';
import { StateHelperService } from '../svc/stateHelperService';
import { ConversionService } from '../api/SVC';

import * as CRS from '../api/CRS';

export class EditPackageCommand {

    constructor(
        private injector: Injector
    ) {
        this.router = this.injector.get(Router);
        this.currentApplication = this.injector.get(CurrentApplicationService);
        this.currentProject = this.injector.get(CurrentProjectService);
        this.utilService = this.injector.get(UtilService);
        this.stateHelperService = this.injector.get(StateHelperService);
        this.conversionService = this.injector.get(ConversionService);
    }

    private router:Router;
    private conversionService: ConversionService;
    private currentApplication: CurrentApplicationService;
    private currentProject: CurrentProjectService;
    private utilService: UtilService;
    private stateHelperService: StateHelperService;

    public cmd: AppCommand = AppCommand.createWithShow(
        ()=> {
             return this.currentApplication.dataContext.isReady 
                    && this.currentApplication.dataItem.isMsi
        },
        ()=> {
            this.conversionService.convertApplication(
                this.currentApplication.dataItem.projectId, 
                this.currentApplication.dataItem.id,
                "EditPackage",
                '',
                [],
                [],
                [],
                [],
                false,
                0,
                false,
                false,
                false, 
                false,
                false,
                '',
                false,
                false
                ).then((response)=> {
                    this.redirect(response.data.jobId);
                });
    
        },
        ()=>{
            return false;
        }
    );

    private redirect(jobId:string) { 

        this.currentApplication.dataContext.refreshCurrent().then((data)=> {
            this.router.navigate([
                Constants.ROUTE_PROJECTDETAILS_MAIN, 
                this.utilService.compressGuidWithSvr(this.currentApplication.dataItem.projectId),
                Constants.ROUTE_PROJECTAPPLICATIONS,
                Constants.ROUTE_PROJECTAPPLICATIONLANDING_MAIN, 
                this.utilService.compressGuid(this.currentApplication.dataItem.id),
                Constants.ROUTE_PROJECTAPPLICATIONACTIONSDETAIL_MAIN, 
                this.utilService.compressGuid(jobId) ]);   
        });
    }

}

<!--
<h1>1-{{authService.isLoggedIn}}-</h1>
<h1>2-{{authService.isLoggedInResolved()}}-</h1>
-->
<div fxLayout="column" fxFlexFill fxLayoutAlign="start center" *ngIf="!authService.isLoggedIn">
  <div fxFlex></div>
    <no-auth fxFlex="none"></no-auth>
  <div fxFlex></div>
</div>


<div fxLayout="column" fxLayoutAlign="start stretch" fxFlexFill *ngIf="authService.isLoggedInResolved() && authService.isLoggedIn">
 
  <mat-toolbar fxFlex="none" fxLayout="row" fxLayoutAlign="center center" [style.background]="getHeaderBgColor()" *ngIf="isStylingReady()">

      <div fxFlex="none"><div class="sw-pad-small"></div></div>

      <div fxFlex="none">
        <img [src]="getHeaderImage()" [ngClass]="getHeaderImageClass()" (click)="goHome()">
      </div>

      <div fxFlex="auto" class="header-logo">
          <div fxLayout="row" fxLayoutAlign="center center">
              <div fxFlex class="head-logo" (click)="goHome()">
                  <span class="header-title">readiness</span>
                  <span class="header-stop">.</span>
              </div>
          </div>
      </div> 

      <div fxFlex></div>

      <div fxFlex="none" fxLayout="column">
        <div fxFlex fxLayout="row" fxLayoutAlign="end center" *ngIf="authService.isLoggedIn" fxLayoutGap="5px" class="sw-pad-top-small">
            <div fxFlex="none" *ngIf="isAdminUser()">
                <sw-button-text [text]="'Administrator options'" [icon]="'vpn_key'" (click)="goAdmin()" [collapsed]="true"></sw-button-text>
            </div>
            <div fxFlex="none" class="sw-pad-left-small">
              <sw-button-text [text]="'Help'" [icon]="'help'" (click)="showHelp()" [collapsed]="true"></sw-button-text>
            </div>
            <div fxFlex="none">
              <sw-button-text [text]="'About'" [icon]="'info'" (click)="showAbout()" [collapsed]="true"></sw-button-text>
            </div>
            <div fxFlex="none" *ngIf="authService.isLoggedIn">
              <sw-button-text [text]="'Logout'" [icon]="'cancel'" (click)="authService.logout()" [collapsed]="true"></sw-button-text>
            </div>
        </div> 
        <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
            <div fxFlex>
              <div class='sw-header-userinfo-username'  [style.color]="getHeaderFgColor()">{{user?.userName}}</div> 
              <div class='sw-header-userinfo-email' *ngIf='user?.userName != user?.userEmail'  [style.color]="getHeaderFgColor()">{{user?.userEmail}}</div>
              <div class='sw-error' *ngIf="userCacheService.inError">User authentication error</div>
            </div>
        </div>
      </div>

  </mat-toolbar>

  <div fxFlex>
    <router-outlet *ngIf="!isIframe"></router-outlet>
  </div>

  <footer *ngIf="authService.isLoggedIn">
    <mat-toolbar fxLayoutGap="20px">
      <div fxFlex="none" *ngIf="projectImageUrl" class="project-image-medium" [backgroundImage]="projectImageUrl" backgroundSize="contain" backgroundRepeat="false">
      </div>
      <div fxFlex="none" *ngIf="appUploadService.containsItems()"> 
        <file-upload-queue></file-upload-queue>
      </div>
      <div fxFlex>
        <div fxLayout="row" fxLayoutAlign="center">
            <div fxFlex="none">
              <div class="slide">
                <span [ngClass]="getNotClass()">{{currentNotification?.text}}</span>
              </div>
            </div>
        </div>
      </div>
      <div fxFlex="none" *ngIf="uiDetected">
        <mat-icon>keyboard</mat-icon>
      </div>
      <div fxFlex="none" *ngIf="currentProjectOwner">
        <div fxLayout="row" fxLayoutGap="5px">
          <div fxFlex="none"><mat-icon class="grey">person</mat-icon></div>
          <div fxFlex="none" class="footer-text">{{currentProjectOwner}}</div>
        </div>  
      </div>
      <div fxFlex="none" class="footer-text" *ngIf="currentProjectLastScanned">{{currentProjectLastScanned}}</div>
      <div fxFlex="none" *ngIf="canShowRemotingUrlLink">
          <button mat-button (click)="copyRemotingUrlToClipboard()" title="Copy remoting url to clipboard">
              <mat-icon>content_paste</mat-icon>
          </button>
      </div>
      <div fxFlex="none" *ngIf="currentAppSourceMissing">
        <div fxLayout="row" fxLayoutGap="10px" class="sw-warning">
            <div fxFlex="none">
                <mat-icon>warning</mat-icon>
            </div>
            <div fxflex class="sw-upper">
                The source wasn't found for this application
            </div>
        </div>
    </div>
    <div fxFlex="none" *ngIf="currentAppSourceUndefined">
      <div fxLayout="row" fxLayoutGap="10px" class="sw-warning">
          <div fxFlex="none">
              <mat-icon>warning</mat-icon>
          </div>
          <div fxflex class="sw-upper">
              The source isn't defined for this application
          </div>
      </div>
  </div>
    <div fxFlex="none" *ngIf="currentJobVerified">
        <div class="sw-job-verified">
          <div class="pulse-blob"></div>
        </div>
      </div>
      <div fxFlex="none">
        <div style="width:20px"></div>  
      </div>
    </mat-toolbar>
  </footer>

</div>
import { Subscription, Subject, Observable, timer } from 'rxjs';
import { AppState } from '../model/AppState';
import { StateHelperService } from './stateHelperService';
import { UtilService } from './utilService';

import * as Enums from '../api/Enum';
import { IResponseUpdater, ResponseWrapper, RSStatus } from '../api/CRS';
import { IResponseError } from '../api/IRS';

export class DataContextProvider<T> {

    constructor( 
        private utilService: UtilService,
        private stateHelperService: StateHelperService,
        idFetch:()=>string,
        preLoad: (id)=>void,
        mainLoad: (id:string, refreshOnly:boolean)=>Promise<T>,
        mainLoadOk?: ()=>void,
        partialRefresh?: (data:T)=>void
    ) { 
        this._idFetch=idFetch;
        this._preLoad=preLoad;
        this._mainLoad=mainLoad;
        this._mainLoadOk=mainLoadOk;
        this._partialRefresh=partialRefresh;

        this._onLoad=new Subject<T>();
        this._onRefresh=new Subject<T>();
        this._onStateChanged =new Subject<AppState>();

        this.checkServerIdReady();
        this.utilService.onServerIdReady().subscribe((o)=>{
            this.checkServerIdReady();
        });
        this.checkServerIdReady();
    }

    private initialised:boolean=false;
    private checkServerIdReady() {

        if (this.initialised)
            return;
        if (this.utilService.isEmptyAny(this.utilService.serverId))
            return;

        this.initialised=true;
        this.idChange(); // Handle first

        this.stateHelperService.onStateChange().subscribe((args)=>{
            this.idChange();
        });
    }

    private idChange() {
        let idc = this._idFetch();
        if (idc) {
            this.setCurrent(idc);
        }
        else {
            this._isReady=false;
            this._id=null;
        }
    }

    private _contextName:string=null;
    private _id:string;
    private _isLoading: boolean = false;
    private _isReady: boolean = false;
    private _inError: boolean = false;
    private _errorCode: number = 0;
    private _errorReason :string = "";
    private _onLoad : Subject<T>;
    private _onRefresh : Subject<T>;
    private _onStateChanged: Subject<AppState>;
    private _lastActivity:string = null;
    private _idFetch: ()=>string;
    private _preLoad: (id:string)=>void;
    private _mainLoad: (id:string, refreshOnly: boolean)=>Promise<T>;
    private _mainLoadOk: ()=>void;
    private _partialRefresh: (data:T)=>void;

    public timerSource : Observable<number>=null;
    public timerSourceSubscription : Subscription;

    public setTimerInterval(interval:number) {
        this.timerSource= timer(interval,interval);
    }

    public setName(name:string) {
        this._contextName = name;
    }

    public onLoaded() {
        return this._onLoad.asObservable();
    }
    public onRefreshed() {
        return this._onRefresh.asObservable();
    }
    public onStateChanged(){
        return this._onStateChanged.asObservable();
    }

    public get id() :string {
        return this._id;
    }

    public get isReady() : boolean {
        return this._id && this._isReady;
    }

    public get isInError() : boolean {
        return this._inError;
    }

    public get ErrorCode(): number {
        return this._errorCode;
    }

    public get ErrorReason(): string {
        return this._errorReason;
    }

    private _item : T;
    public item() : T {
        get: 
        {
            return this._item;
        }
    }

    public isLoading() : boolean {
        return this._isLoading;
    }

    public setCurrent(id: string) {
        if (this._id != id) {
            this._id = id;
            this._lastActivity=null;
            if (id)
                this.load(id);
        }
    }

    public refreshCurrent() : Promise<boolean> {
        return new Promise<boolean>((resolve,reject) => {
            if (this.isReady) {
                var refreshOnly = this._lastActivity != null;
                this._mainLoad(this._id, refreshOnly).then((data)=> {
                    var rs = (data) ? <RSStatus>(<any>data).status : null;
                    if (rs && rs.errorCode != 0)
                    {
                        this._inError=true;
                        this._errorReason=rs.message;
                        this._onStateChanged.next(AppState.create(Enums.LoadState.InError));
                        reject(rs);
                    }
                    else
                    {
                        var iru = <IResponseUpdater><unknown>data;
                        this._lastActivity = (iru) ? iru.updatedActivity : null;
                        if (iru && !iru.noChange)
                            this._item = data;
                        else if (this._partialRefresh)
                            this._partialRefresh(data);
                        this._inError=false;
                        this._errorReason=null;
                        resolve(true);
                        this._onRefresh.next();
                    }
                }, (err)=>{
                    reject(false);
                });
            }
            resolve(true);
        });
    }

    public reload() {
        this.load(this._id);
    }

    private load(id:string): void {

        this.clearTimer();

        this._onStateChanged.next(AppState.create(Enums.LoadState.InTransition));
        this._isLoading=true;
        this._inError=false;
        this._isReady=false;
 
        if (this._preLoad)
            this._preLoad(id);

        var refreshOnly = this._lastActivity!=null;

        this._mainLoad(id,refreshOnly).then((data:T)=> {
            var rs = (data) ? <RSStatus>(<any>data).status : null;
            if (rs && rs.errorCode)
            {
                this._inError=true;
                this._errorCode=rs.errorCode;
                this._errorReason=rs.message
                this._onStateChanged.next(AppState.create(Enums.LoadState.InError));
            }
            else
            {
                this._item = data;
                this._isLoading=false;        
                this._isReady=true;
                this._inError=false;
                this._errorReason="";
                if (this._mainLoadOk)
                    this._mainLoadOk();
                this._onLoad.next(data)
                this._onStateChanged.next(AppState.create(Enums.LoadState.Ready));
                this.enableTimer();
            }
        }, (err)=> {
            this._inError=true;
            this._errorReason=err.error;
            this._onStateChanged.next(AppState.create(Enums.LoadState.InError));
        });
    }

    private clearTimer() {
        if (this.timerSource) {
            if (this.timerSourceSubscription)
                this.timerSourceSubscription.unsubscribe();
        }
    }

    private enableTimer() {
        if (this.timerSource) {
            this.timerSourceSubscription = this.timerSource.subscribe(()=>this.timerRefresh());
        }
    }

    private timerRefresh() {
        if (this._isReady) {
            this.refreshCurrent();
        }
    }

}

export class AppCommand {

    public can: boolean;
    public show:boolean;

    public canRefresh: () => boolean;
    public showRefresh: () => boolean;
    public execute: () => void;

    public subItems: () => Array<any>
    public executeSubItem: (item: any) => void;

    public executeViaEvent: ($event: Event)=>void;

    constructor(
        private canFunc: () => boolean,
        private showFunc: ()=> boolean,
        private executeFunc: () => void
    ) {
        this.canRefresh = canFunc;
        this.showRefresh = showFunc;
        this.execute = executeFunc;
        this.can=false;
        this.show=false;
    }

    public refresh(): void {
        this.can = (this.canRefresh == null || this.canRefresh == undefined)  ? true : this.canRefresh();
        this.show = (this.showRefresh == null || this.showRefresh==undefined) ? this.can : this.showRefresh();
    }

    static create(can: () => boolean, execute: ()=> void) : AppCommand {
        return new AppCommand(can, null, execute);
    }

    static createWithShow(can: ()=> boolean, execute: ()=> void, show: ()=> boolean)  : AppCommand {
        let r = new AppCommand(can, show, execute);
        return r;
    }

}
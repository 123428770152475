import { GridColumnType } from '../api/Enum';
import { GridFilterType } from '../api/Enum';
import { CLColumn } from './CLColumn';
import { DISOApplication } from '../model/DISOApplication';
import { Column } from 'angular-slickgrid';

export class CLGridApplicationTypes {
	public static items : Array<CLColumn> = [
		new CLColumn('AP_RAG',          	'RAG',          	GridColumnType.RAG,        	'[item].CUSTOM',               	true, 	GridFilterType.Disabled,  	50,		true,null,false),
		new CLColumn('AP_NAME',         	'Name',         	GridColumnType.AppName,    	'[item].name',               	true, 	GridFilterType.Default,  	800,	true,null,false),
		new CLColumn('AP_IMPORTPERC',   	'Import %',     	GridColumnType.Percentage, 	'[item].importPercentComplete', true, 	GridFilterType.Disabled, 	120,	false,null,false),
		new CLColumn('AP_DATEIMP',      	'Date Loaded',  	GridColumnType.Date,       	'XImportDate',            		true, 	GridFilterType.Disabled, 	120,	false,null,false),
		new CLColumn('AP_PRODNAME',     	'Product Name', 	GridColumnType.Default,    	'[item].productName',        	true, 	GridFilterType.Default,  	500,	false,null,false),
		new CLColumn('AP_VERSION',      	'Version',      	GridColumnType.Default,    	'[item].compoundVersion',    	true, 	GridFilterType.Default,  	130,	false,null,false),
		new CLColumn('AP_MANUF',        	'Manufacturer', 	GridColumnType.Default,    	'[item].manufacturer',       	true, 	GridFilterType.Default,  	440,	false,null,false),
		new CLColumn('AP_PATH',         	'Path',         	GridColumnType.Default,    	'[item].path',              	true, 	GridFilterType.Default,  	600,	false,null,false),
		new CLColumn('AP_ARCHIT',       	'Architecture', 	GridColumnType.Default,    	'[item].architecture',       	true, 	GridFilterType.Default,  	120,	false,null,false),
		new CLColumn('AP_APPID',        	'AppId',        	GridColumnType.Default,    	'[item].appId',             	true, 	GridFilterType.Default,  	160,	false,null,false),
		new CLColumn('AP_INSTTYPE',     	'Installer Type',	GridColumnType.Default,   	'[item].installerType',     	true, 	GridFilterType.Values,   	160,	false,null,false),
		new CLColumn('AP_ISMIDDLE',     	'M/ware',       	GridColumnType.Tick,       	'[item].isMiddleware',       	true, 	GridFilterType.YesNo,    	100,	false,"TOOLTIP:Middleware",false),
		new CLColumn('AP_ISVENDOR',     	'Vendor',       	GridColumnType.Tick,       	'[item].isVendor',           	true, 	GridFilterType.YesNo,    	100,	false,"TOOLTIP:Is Vendor",false),
		new CLColumn('AP_DOTNETVERSION',	'.NET',         	GridColumnType.Default,    	'[item].dotNetVersion',      	true, 	GridFilterType.Values,   	100,	false,null,false),
		new CLColumn('AP_JAVAVERSION',  	'Java',         	GridColumnType.Default,    	'[item].javaVersion',        	true, 	GridFilterType.Values,   	100,	false,null,false),
		new CLColumn('AP_ORIGFROM',     	'Origin',       	GridColumnType.Default,    	'[item].originatedFrom',     	true, 	GridFilterType.Values,   	120,	false,null,false),
		new CLColumn('AP_TESTSTATUS',   	'Test Status',  	GridColumnType.Status,	   	'[item].testStatus',		 	false,	GridFilterType.Status,		110,	false,"TOOLTIP:Test Status",false),
		new CLColumn('AP_TESTOUTCOME',  	'Test Outcome', 	GridColumnType.EvalState,  	'[item].testOutcome',			false,	GridFilterType.Values, 		110,	false,"TOOLTIP:Test Outcome",false),
		new CLColumn('AP_TESTED',       	'Tested',       	GridColumnType.Tick,       	'[item].hasTest',            	true, 	GridFilterType.YesNo,    	110,	false,"TOOLTIP:Has Test",false),
		new CLColumn('AP_TESTSUCCESS',  	'Test Success', 	GridColumnType.Tick,       	'[item].hasTestSuccess',     	true, 	GridFilterType.YesNo,    	110,	false,"TOOLTIP:Has Successful Test",false),
		new CLColumn('AP_TESTFAIL',     	'Test Fail',    	GridColumnType.Tick,       	'[item].hasTestFail',        	true, 	GridFilterType.YesNo,    	110,	false,"TOOLTIP:Has Failed Test",false),
		new CLColumn('AP_REPACKSTAT',   	'Repackaged?',  	GridColumnType.Status,     	'[item].repackagedStatus',   	true, 	GridFilterType.Values,   	130,	false,null,false),
		new CLColumn('AP_VIRTSTAT',     	'Virtualised?', 	GridColumnType.Status,     	'[item].virtualisedStatus',  	true, 	GridFilterType.Values,   	130,	false,null,false),
		new CLColumn('AP_PUBSTAT',      	'Published?',   	GridColumnType.PubStatus,  	'[item].publishedState',     	true, 	GridFilterType.Values,   	130,	false,null,false),
		new CLColumn('AP_STAGEDONLY',   	'Staged Only',  	GridColumnType.Tick,       	'[item].stagedOnly',         	true, 	GridFilterType.YesNo,    	130,	false,null,false),
		new CLColumn('AP_DISCOVERYSTATUS',	'Discovery Status',	GridColumnType.Status,		'[item].discoveryStatus',		false,	GridFilterType.Values, 		110,	false,"TOOLTIP:Discovery status",false),
		new CLColumn('AP_DISCOVERYOUTCOME',	'Discovery Outcome',GridColumnType.EvalState,	'[item].discoveryOutcome',	false,	GridFilterType.Values, 		110,	false,"TOOLTIP:Discovery outcome",false),
		new CLColumn('AP_RUNTIMETESTSTATUS','Runtime Test Status', GridColumnType.Status,   '[item].runtimeTestStatus',  false, GridFilterType.Status,      130,    false,"TOOLTIP:Runtime Test Status", false),
		new CLColumn('AP_PRIORITY',			'Priority',			GridColumnType.Priority,	'[item].priority',			false,	GridFilterType.Values, 		100, 	false,	null,false),
		new CLColumn('AP_WORKFLOWSTAGE',	'Workflow Stage',	GridColumnType.Default,		'workflowStageName',		true,	GridFilterType.Values, 		180, 	false,	null,false),
		new CLColumn('AP_WORKFLOWSTAGESTATE','W/f State',		GridColumnType.Default,		'workflowStageStateName',	false,	GridFilterType.Values, 		120, 	false,	null,false),
		new CLColumn('AP_ASSIGNEDTO',		'Assigned To',		GridColumnType.Default,		'[item].assignedToName',			false,	GridFilterType.Values, 		250,	false,	null,false),
		new CLColumn('XCHECK_NAME',     	'XCHECK_HDG',   	GridColumnType.XCheck,     	'',                       	false,	GridFilterType.Disabled, 	180,	false,	null,true),
    ];

    public static defaultItems: Array<string>= [
		'AP_RAG',
		'AP_NAME',
		'AP_IMPORTPERC', 
		'AP_DATEIMP',  
		'AP_PRODNAME',  
		'AP_VERSION', 
		'AP_MANUF',
		'AP_PATH'
    ];

    public static fixedItems : Array<string> = [
        'AP_RAG',
        'AP_NAME'
    ]

    public static get(id:string) : CLColumn {
        let r = this.items.filter(x=>x.id == id);
        return (r.length == 1)  ? r[0] : null;
    }

	public static customValueMapper : (CLColumn: Column, item: DISOApplication) =>  any;

	public static compare(a: DISOApplication, b: DISOApplication, column: CLColumn, asc:boolean) : number {
		var sign = asc ? 1 : -1;
		var value1=this.getValue(a, column);
		var value2=this.getValue(b, column);

		if (typeof value1  == "string")
			value1=value1.toLowerCase();
		if (typeof value2 == "string")
			value2 = value2.toLowerCase();

		var ret=0;
		if (value1!=value2) {
			if(value1==null || value1 < value2)
				ret= -sign;
			else if (value2==null || value1 > value2)
				ret= sign;
		}
		return ret;
	}

	public static getValueFromColumnId(a: DISOApplication, id:string ) {
		var c = CLGridApplicationTypes.get(id);
		if (c)
			return this.getValue(a, c);
		return null;
	}

	public static getValue(a: DISOApplication, column: CLColumn) : any {
		var value1=null;
		if (column.field=="item") {
			if (column.fieldExtended == "CUSTOM") 
				value1 = this.customValueMapper(column, a);
			else
				value1 = a.item[column.fieldExtended];
		}
		else {
			value1 = a[column.field];
		}
		return value1;
	}
    
}


import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { ProjectService } from '../../api/SVC';
import { UtilService } from '../../svc/utilService';
import { Constants } from '../../api/Constants';
import { StateHelperService } from '../../svc/stateHelperService';
import { UserCacheService } from '../../svc/userCacheService';
import { RPTCHK } from '../../model/RPTCHK';

import * as CRS from '../../api/CRS';

@Component({
    selector: 'project-create',
    templateUrl: './project-create.html',
    styleUrls:['./project-create.scss']
  })
  export class ProjectCreate implements OnInit {

    constructor(
        private router: Router,
        private projectService: ProjectService,
        private utilService: UtilService,
        private userCacheService: UserCacheService
    ) { }

    @ViewChild('id1') id1:ElementRef;
    
    private generateServerPlatform: boolean=false;

    public name: string;
    public image: string = null;
    public description: string;
    public storageAccessKey: string;
    public validationError: string=null;
    public referenceProject:CRS.RSProject=null;
    public isSaving:boolean=false;
    public rptChkCompat: RPTCHK;
    public rptChkQuality: RPTCHK;
    public rptChkSuitability: RPTCHK;
    public rptChkSecurity: RPTCHK;
    public projects : Array<CRS.RSProject>=null;

    private errorCreating: boolean;

    ngOnInit() {
        this.initialiseRptChk();
        if (this.utilService.serverId)
            this.loadProjects();
        else
            this.userCacheService.onUserLoaded().pipe(first()).subscribe((data)=>this.loadProjects())
    }

    ngAfterViewInit() {
        //this.id1.nativeElement.focus();
        //this.id1.nativeElement.select();
    }

    private loadProjects(): void {
        this.projectService.getProjects().then(resp => {
            this.projects = resp.data.projects;
        });
    }

    public referenceProjectChanged() : void {
        if (this.referenceProject != null && this.referenceProject.platformRequirements) {
            this.rptChkCompat.checkSet(this.referenceProject.platformRequirements);
            this.rptChkQuality.checkSet(this.referenceProject.platformRequirements);
            this.rptChkSecurity.checkSet(this.referenceProject.platformRequirements);
            this.rptChkSuitability.checkSet(this.referenceProject.platformRequirements);
        }
    }

    public isValid() : boolean {
        let isValid =  this.name !== undefined && this.name && this.name.trim().length > 0;
        let nameValid:boolean = 
            this.name === undefined || 
                (this.name && this.name.length<=50 && this.name.match(/[^A-Za-z0-9\s!£$%&*()_+-={}\[\]@~#>?|]/) == null);
        let descValid:boolean = this.utilService.isEmpty(this.description) || (this.description && this.description.match(/[^A-Za-z0-9\s!£$%&*()_+-={}\[\]@~#>?|]+$/) == null);
        let accessKeyValid: boolean = this.utilService.isEmpty(this.storageAccessKey) || this.utilService.isValidUrl(this.storageAccessKey);
        if (!nameValid || !descValid || !accessKeyValid)
            isValid=false;
        
        if (!nameValid)
            this.validationError ="Name contains invalid characters";
        else if (!accessKeyValid)
            this.validationError="Storage access key must be a valid url";
        else if (!descValid)
            this.validationError="Description contains invalid characters";
        else 
            this.validationError=null;
        
            return isValid;
    }

    public create() {
        this.isSaving=true;
        let platReq= 
            this.combineRptChk(
                 this.rptChkCompat.asString() ,
                this.rptChkQuality.asString() ,
                this.rptChkSuitability.asString() ,
                this.rptChkSecurity.asString());

        let refPrj = this.referenceProject ? this.referenceProject.id : this.utilService.getEmptyGuid();
        this.projectService.createProject(this.name, this.description, this.storageAccessKey, this.image, platReq, refPrj).then((response) => {
            if (response.status && response.status.errorCode != 0){
                this.errorCreating = true;
                this.isSaving=false;
            }
            else{
                this.router.navigate([
                    Constants.ROUTE_PROJECTDETAILS_MAIN, 
                    this.utilService.compressGuidWithSvr(response.data.projectId)
                ]);
            }
        }, () => {
            this.errorCreating = true;
            this.isSaving=false;
        });
    }

    private combineRptChk(p1:string,p2:string,p3:string,p4:string) : string {
        let ret = "";
        ret += p1;
        ret += ((ret==""||p2=="")?"":",") + p2;
        ret += ((ret==""||p3=="")?"":",") + p3;
        ret += ((ret==""||p4=="")?"":",") + p4;
        return ret;
    }

    private initialiseRptChk()
    {
        this.rptChkCompat = new RPTCHK( ["DESKTOP", "SERVER", "OFFICE", "PATCH", "BROWSER"]);
        this.rptChkQuality = new RPTCHK( ["PKGSTD", "DEPANAL", "MSIICE"]);
        this.rptChkSuitability = new RPTCHK(["APPV", "APPX", "THINAPP", "LIQD", "MSIX"]);
        this.rptChkSecurity = new RPTCHK(["ANTIVIRUS", "COMPLIANCE", "WINSECURITY"]);

        this.rptChkCompat.mainChecked=true;
        this.rptChkCompat.disabled = ["DESKTOP"];

        this.rptChkCompat.subChecked["DESKTOP"]=true;
        this.rptChkCompat.subChecked["BROWSER"]=true;
        this.rptChkCompat.subChecked["OFFICE"]=true;
        this.rptChkCompat.subChecked["BROWSER"]=true;
        this.rptChkCompat.subChecked["PATCH"]=true;
        this.rptChkCompat.subChecked["SERVER"]=false;

        this.rptChkQuality.mainChecked=true;
        this.rptChkQuality.subChecked["PKGSTD"]=true;
        this.rptChkQuality.subChecked["DEPANAL"]=true;
        
        this.rptChkSuitability.mainChecked=true;
        this.rptChkSuitability.subChecked["APPV"]=true;

        this.rptChkSecurity.mainChecked=true;
        this.rptChkSecurity.subChecked["WINSECURITY"]=true;

        this.rptChkCompat.whenChanged = (i:string) =>{
            if (i=="SERVER")
                this.rptChkSuitability.subChecked["XENAPPH"]=false;
        };
    }

}



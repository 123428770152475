<div *ngIf="!hasItems()">
    No defender exlusions were created by this installation
</div>

<table style="padding-left:10px" *ngIf="hasItems()">
    <tr *ngFor="let def of item.defenderExclusionItems">
        <td style="min-width: 150px;" class="sw-column-top">{{def.exclusion}}</td>
        <td style="min-width: 150px;" class="sw-column-top">{{def.type}}</td>
    </tr>
</table>

     
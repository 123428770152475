import { Injectable } from '@angular/core';
import { GridOption, SlickGrid, CurrentRowSelection, GridStateChange, Column, Filter} from 'angular-slickgrid';
import { Constants } from 'src/app/api/Constants';
import { UtilService} from '../svc/utilService';

@Injectable({
    providedIn: 'root'
})
export class Grids {

    constructor(
        private utilService: UtilService
    ) {
    }

    private _options:  { [id: string] : GridOption; } = {};
    public options(id:string): GridOption {
        var ret=this._options[id];
        if (ret)
            return ret;

        return this._options[id] = {
            showHeaderRow: true,
            explicitInitialization: true,
            enableAutoResize: true,
            enableSorting: true,
            enableFiltering:true,
            enableRowSelection: true,
            enableCheckboxSelector:false,
            enableCellNavigation:true,
            enableHeaderMenu:false,
            enableCellMenu:false,
            enableContextMenu:false,
            enableGridMenu:false,
            enableColumnPicker: false,
            rowHeight:Constants.GRID_ROW_HEIGHT,
            multiSelect: false
          };
    }

    public enableEditing(id:string) {
        var opts = this.options(id);
        opts.editable=true,
        opts.autoEdit= true
    }

    public enableCheckboxSelector(id:string){
        var opts = this.options(id);
        opts.enableCheckboxSelector=true;
        opts.checkboxSelector={
            hideSelectAllCheckbox: false,
            selectableOverride: (row: number, dataContext: any, grid: any) => (dataContext.item.status === 0)
        };
    }

    public enableFiltering(id:string){
        var opts = this.options(id);
        opts.enableFiltering=true;
    }

    public enableMenu(id:string){
        var opts = this.options(id);
        opts.enableGridMenu=true;    
    }

    public disableActiveRowSelection(id:string){
        var opts = this.options(id);
        if (!opts.rowSelectionOptions){
            opts.rowSelectionOptions={
                selectActiveRow:false
            };
        }
        else {
            opts.rowSelectionOptions.selectActiveRow=false;
        }
    }

    public setHeaderRowVisibility(id:string, state:boolean){
        var opts = this.options(id);
        opts.showHeaderRow=state;
    }

    public getSelectedRow(grid:SlickGrid): any {
        var selRows = grid.getSelectedRows();
        if (selRows && selRows.length >0) {
          var rowIdx = selRows[0];
          return grid.getDataItem(rowIdx);
        }
        else {
          return null;
        }
    }
    
    public getSelectedRows(grid:SlickGrid): Array<any> {
        return grid.getSelectedRows().map(i=>grid.getDataItem(i));
    }

    public gridStateChanged(grid:SlickGrid, gridStateChanges: GridStateChange, handler: (row:any)=>void) {
        if (gridStateChanges.change.type == "rowSelection") {
            var crs = <CurrentRowSelection>gridStateChanges.change.newValues;
            var hasChkbox = grid.getOptions().enableCheckboxSelector;
            if (hasChkbox) {
                handler(this.getSelectedRows(grid));
            }
            else {
                if (crs.dataContextIds[0] != undefined) { // Grouped
                    var row  = this.getSelectedRow(grid);
                    if (row)
                        handler(row);
                }
            }
        }
    }

    public quickFilter(r, cf, getValue:(r,c)=>any) {
        if (!cf)
            return true;

        var fcm = this.getFilterToColumnMap(cf);

        return fcm.every(i=>{
            var v =getValue(r,i.column);
            return this.evaluateFilter(i.filter, v);
        });
    }

    private evaluateFilter(filter:any, value:any): boolean {
        var ret=true;
        if (!value){
            ret=false;
        }
        else{
            var visnum = (typeof value.toLocaleLowerCase !== 'function');
            switch (filter.operator.toLowerCase()) {
                case "contains":
                    if (ret)
                        ret= filter.parsedSearchTerms.some(st=>value && value.toLowerCase().indexOf(st.toLowerCase()) != -1);
                    break;
                case "in":
                    return filter.searchTerms.some(st=> {
                        return (visnum) ? value == st : value.toLocaleLowerCase() == st.toLocaleLowerCase();
                    });
            }      
        }
        return ret;
    }
    
    private getFilterToColumnMap(cf:any): Array<FCM> {
        var filtCols: Array<FCM> =[];
        var filtNames = Object.getOwnPropertyNames(cf.columnFilters);
        if (filtNames.length > 0) {
            filtNames.forEach(n=>{
                var f = cf.columnFilters[n];
                var ci = cf.grid.getColumnIndex(n);
                var c = cf.grid.getColumns()[ci];
                filtCols.push( { filter:f, column:c });
            });
        }
        return filtCols;
    }
}

class FCM {
    filter:Filter;
    column:Column;
}
<h2 mat-dialog-title>CREATE INSTALL DEPENDENCY</h2>
  
<div mat-dialog-content class='sw-dialog-padding' style="width:550px">

    <spinner *ngIf="!isDataReady()"></spinner>

    <div *ngIf="isDataReady()">

        <div class="sw-pad-top">
            <mat-form-field appearance="outline"  style="min-width: 400px">
                <mat-label>Dependency Type</mat-label>
                <mat-select [(ngModel)]="relationshipType">
                    <mat-option [value]="4">Standard Prerequisite</mat-option>
                    <mat-option [value]="5">Windows Feature</mat-option>
                    <mat-option [value]="3" *ngIf="hasSiblingApplications">Application within this project (same path)</mat-option>
                    <mat-option [value]="6">Application within this project (different path)</mat-option>
                </mat-select>
            </mat-form-field>   
        </div>
        <div class="sw-pad-bottom full-width-bs" *ngIf="relationshipType == 3 && hasSiblingApplications">
            <mat-form-field appearance="outline" class="full-width-bs">
                <mat-label>Application (same path)</mat-label>
                <mat-select [(ngModel)]="selectedSiblingApplication" style="min-width:300px">
                    <mat-option [value]="app" *ngFor="let app of candidateApplications">
                        {{app.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="sw-pad-bottom full-width-bs" *ngIf="relationshipType == 6">
            <mat-form-field appearance="outline" class="full-width-bs">
                <mat-label>Application (other path)</mat-label>
                <input type="text" [(ngModel)]="searchText" placeholder="Application Name" matInput [formControl]="myControl" [matAutocomplete]="auto">
                <mat-autocomplete autoActiveFirstOption [displayWith]="displayFn" (optionSelected)='setSelected($event.option.value)' #auto>
                    <mat-option *ngFor="let app of filteredOptions | async" [value]="app">
                        {{app.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>   
        </div>
        <div class="sw-pad-bottom full-width-bs" *ngIf="relationshipType == 4">
            <mat-form-field appearance="outline" class="full-width-bs">
                <mat-label>Select Prerequisite</mat-label>
                <mat-select [(ngModel)]="selectedStandardPrerequisite" placeholder="Choose Item"  style="min-width: 250px">
                    <mat-option [value]="wf.id" *ngFor="let wf of dependencyList">
                        {{wf.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
       <div class="sw-pad-bottom" *ngIf="relationshipType == 5">
            <mat-form-field appearance="outline" class="full-width-bs">
                <mat-label>Select Feature</mat-label>
                <mat-select [(ngModel)]="selectedWindowsFeature" placeholder="Choose Feature">
                    <mat-option [value]="wf.id" *ngFor="let wf of windowsFeatures">
                        {{wf.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>  

</div>

<div mat-dialog-actions fxLayout='row' fxLayoutGap="10px">
    <div fxFlex></div>
    <div fxFlex="none">
        <button mat-raised-button color="primary" (click)='closeDialog()' [disabled]="!isValid()">
            <mat-icon>done</mat-icon>
            <span>Accept</span>
        </button>
    </div>
    <div fxFlex="none">
        <button mat-raised-button (click)='cancelDialog()'>
            <mat-icon>cancel</mat-icon>
            <span>Cancel</span>
        </button>
    </div>
</div>

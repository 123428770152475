import * as CRS from '../api/CRS';

export class QueryInput {
    public entity:CRS.RSMetaEntity;
    public attribute:CRS.RSMetaAttribute;
    public values:string = "";
    public outputAttributes:Array<CRS.RSMetaAttribute> = [];
    public queryName: string;
    public selectedQuery:any;
}

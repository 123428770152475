import { Injector } from '@angular/core';
import { MenuService } from '../svc/menuService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';
import { EventService } from '../svc/eventService';
import { CurrentApplicationService } from '../svc/currentApplicationService';
import { CommandCollection } from './command-collection';

export class PackageFindCommand {

    constructor(
        private injector: Injector
    ) {
        this.menuService = this.injector.get(MenuService);
        this.currentApplication = this.injector.get(CurrentApplicationService);
        this.eventService = this.injector.get(EventService);

        this.menuService.register(Constants.MENU_PACKAGE_FIND, this.cmd, null )
    }

    private currentApplication: CurrentApplicationService;
    private menuService: MenuService;
    private eventService: EventService;
    
    public findText:string;

    public cmd: AppCommand = AppCommand.create(
        ()=> {
            return this.findText && this.findText.length>0;
        },
        ()=> {
            this.currentApplication.browser.find(this.findText).then((findResCnt)=>{
                if (findResCnt>0)
                    this.currentApplication.browser.goToFindItem(this.currentApplication.browser.findResults[0]);
                    this.eventService.__packageFindExecutedRefreshTrigger();
             });
        } 
    );

}
import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MenuService } from '../svc/menuService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';
import { CurrentApplicationService } from '../svc/currentApplicationService';
import { UtilService } from '../svc/utilService';

export class ViewPackageCommand {

    constructor(
        private injector: Injector
    ) {
        this.router = this.injector.get(Router);
        this.menuService = this.injector.get(MenuService);
        this.utilService = this.injector.get(UtilService);
        this.currentApplication = this.injector.get(CurrentApplicationService);

        this.menuService.register(Constants.MENU_VIEW_PACKAGE, this.cmd, "*"+Constants.ROUTE_PROJECTAPPLICATIONLANDING_MAIN )
    }

    private router: Router;
    private menuService: MenuService;
    private currentApplication: CurrentApplicationService;
    private utilService: UtilService;

    private cmd: AppCommand = AppCommand.createWithShow(
        ()=> {
            return this.currentApplication.browser.canBrowsePackage;
        },
        () => {
            this.router.navigate([
                Constants.ROUTE_PROJECTDETAILS_MAIN, 
                this.utilService.compressGuidWithSvr(this.currentApplication.dataItem.projectId),
                Constants.ROUTE_PROJECTAPPLICATIONS,
                Constants.ROUTE_PROJECTAPPLICATIONLANDING_MAIN,
                this.utilService.compressGuid(this.currentApplication.dataItem.id),
                Constants.ROUTE_PROJECTAPPLICATIONBROWSE ]);        
        },
        ()=> { 
            return true;
            // let ret: boolean =  false;
            // if (this.application === undefined || !this.utilService.isSpecialFeatureAvailable('MSIED'))
            //     ret = false;
            // else if (this.showViewOptions(false))
            //     ret= true;
            // return ret;
        }
    );

 
}
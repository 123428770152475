import { Component, OnInit, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { UtilService } from '../../svc/utilService';
import { StateHelperService,StateHelperInstance } from '../../svc/stateHelperService';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { Constants } from '../../api/Constants';

import * as CRS from '../../api/CRS';

@Component({
  selector: Constants.ROUTE_PROJECTREPORTS,
  templateUrl: './project-reports.html'
})
export class ProjectReports implements OnInit {

    constructor(
      private router:Router,
      private utilService:UtilService,
      private currentProject: CurrentProjectService,
      public stateHelperService: StateHelperService) { }

    ngOnInit(): void {
        this.stateHelperInstance =  this.stateHelperService.register(Constants.ROUTE_PROJECTREPORTS);
    }

    private startupPhase=true;
//    private charts : { [pid: string] : Chart; } = {};
    public stateHelperInstance : StateHelperInstance;

    public get project(): CRS.RSGetProject {
      return this.currentProject.dataItem;
    }

    public get projectIsReady(): boolean {
      return this.currentProject.dataContext.isReady;
    }

    public getReportUrl(jobId:string, subType:string): string {
      let url: string = this.utilService.getAPIUrl( "Project/GetProjectOutput?JobId=" + jobId);
      let auth :string = this.utilService.getToken();
      if (auth)
          url += "&Auth=" + auth;
      return url;
    }

    public isHidden() : boolean {
      return this.stateHelperService.details.isStatePathCurrent(Constants.ROUTE_PROJECTREPORTCHECKDETAIL_MAIN);
    }

    public openAssessmentGroup(item: CRS.RSAssessmentGroup) {
      this.router.navigate([
        Constants.ROUTE_PROJECTDETAILS_MAIN, 
        this.utilService.compressGuidWithSvr(this.currentProject.dataContext.id), 
        Constants.ROUTE_PROJECTREPORTS,
        Constants.ROUTE_PROJECTREPORTAG_MAIN, 
        item.id
      ]);        
    }

}

import { Component, Inject, OnInit } from '@angular/core';
import { SaveQueryData } from '../project-queries/save-query-data';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'save-custom-query-dialog',
    templateUrl: 'save-custom-query-dialog.html',
  })
  export class SaveCustomQueryDialog {
  
    constructor(
      public dialogRef: MatDialogRef<SaveCustomQueryDialog>,
      @Inject(MAT_DIALOG_DATA) public data: SaveQueryData) {}
  
    public onNoClick(): void {
        this.dialogRef.close();
    }
  
    public isValid() : boolean {
      const regex = new RegExp('^[a-z]+', 'g');
      return this.data.queryName && this.data.queryName.length > 5 && regex.test(this.data.queryName);
    }
  }

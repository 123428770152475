<div class="sw-breadcrumb-bar">

    <div fxLayout="row" fxLayoutAlign="start center" class="full-height" > 

        <div fxFlex="none" fxLayout="row" fxLayoutAlign="start center" *ngFor="let b of breadcrumbService.crumbs; let last=last" class="sw-breadcrumb-item">
            <div fxFlex *ngIf="(last || b.isLabel) && !b.command" [ngClass]="b.isLabel ? '' : 'sw-color-bg-700'">{{b.text}}</div>
            <div fxFlex *ngIf="(!last && !b.isLabel) || b.command" >
                <a (click)="gotoCrumb($event,b)" class="button-no-margin sw-crumb-link"><span>{{b.text}}</span></a>
            </div>
            <div fxFlex="none" *ngIf="!last" class="sw-breadcrumb-sep-wrapper">
                <mat-icon>chevron_right</mat-icon>
            </div>
        </div>

    </div>

</div>


<div class='sw-viewcontainer-contents sw-contentview'>

    <div layout-padding class="layout-padding">
        <h4 class="h4-adjustment">Download URLs</h4>
        <div *ngFor="let url of patchKB?.downloadUrls">
            {{url}}
        </div>
    </div>


    <div layout-padding class="layout-padding">
        <h4 class="h4-adjustment">Filenames</h4>
        <div fxLayout="row" layout-wrap>
            <div flex="25" *ngFor="let f of patchKB?.filenames">
                <div class="sw-hor-pad">{{f}}</div>
            </div>
        </div>
    </div>

</div>  
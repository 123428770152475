import { Injector } from '@angular/core';
import { take } from 'rxjs/operators';
import { CurrentAssessmentGroupService } from "../svc/currentAssessmentGroupService";
import { MenuService } from '../svc/menuService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';
import { DialogService } from '../svc/dialogService';

export class EnableAssessmentGroupCommand {

    constructor(
        private injector: Injector
    ) {
        this.menuService = this.injector.get(MenuService);
        this.dialogService = this.injector.get(DialogService);
        this.currentAssessmentGroup = this.injector.get(CurrentAssessmentGroupService);

        this.menuService.register(Constants.MENU_ENABLE_ASSESSMENTGROUP, this.command, Constants.ROUTE_PROJECTREPORTAG_MAIN )
    }

    private menuService: MenuService;
    private dialogService: DialogService;
    private currentAssessmentGroup: CurrentAssessmentGroupService;

    private command: AppCommand = AppCommand.createWithShow(
        ()=> {
            if (!this.currentAssessmentGroup.dataContext.isReady)
                return false;
            return !this.currentAssessmentGroup.dataItem.isEnabled;
        },
        ()=> {
            var txts =  [ `Are you sure that you want to enable the assessment group '${this.currentAssessmentGroup.dataItem.name}'`];
            this.dialogService.showYesNoMessage("Enable Assessment Group",txts)
            .pipe(take(1))
            .subscribe((ans)=>{
                if (ans){
                    this.currentAssessmentGroup.enableAssessmentGroup(this.currentAssessmentGroup.dataContext.id);
                }
            });
        },
        ()=> {
            return this.currentAssessmentGroup.dataContext.isReady && !this.currentAssessmentGroup.dataItem.isEnabled;
        }
    );


}



<!-- needs the sw-viewcontainer for its hover styling -->

<div class="full-height-bs" fxLayout="column" fxFill fxLayoutGap="20px">

    <div fxFlex="none">Drag one or more files below:</div>

    <div fxFlex class="drop-area" ng2FileDrop [uploader]="fileUploads.uploader" over-class='drop-area-over'>

        <p *ngIf="application && application.companionFiles.length == 0">DRAG HERE</p>

        <mat-chip-list [(ngModel)]="application.companionFiles" name="filename" max-chips="5" placeholder="Drag companion files">
            <mat-chip *ngFor="let chip of application.companionFiles"  [removable]="canRemoveChip(chip)" (removed)="removeChip(chip)" >
                <div fxLayout="row" class="chip-inner">
                    <div fxFlex>{{chip.filename}}</div>
                    <div fxFlex="none">
                        <mat-icon matChipRemove style="color:black">cancel</mat-icon>
                    </div>
                </div>
            </mat-chip>
        </mat-chip-list>

    </div>

    <div fxFlex="none">
        <p>Additional documents, configuration or installation files added here are copied to the testing/conversion environment alongside the application media. These files may be useful to assist with manual modifications, discovery etc.</p>
    </div>
    
</div>
  
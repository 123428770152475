import { Component, OnInit, OnDestroy} from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../svc/authService';
import { Router } from '@angular/router';

@Component({
    selector: "no-auth",
    templateUrl: './no-auth.html',
})
export class NoAuth implements OnInit, OnDestroy {

    private subscriptions : Array<Subscription> = new Array<Subscription>();

    constructor(
        public authService: AuthService,
    ) {
      
    }

    ngOnInit(): void {
        // if (!this.authService.isLoggedIn) {
        //     this.subscriptions.push(this.authService.onLoggedIn().subscribe(()=>{
        //         console.debug("no-auth");
        //     }));
        //   }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(x=>x.unsubscribe());
    }

}
import { Injectable } from '@angular/core';
import { UtilService } from './utilService';
import { Subject } from 'rxjs';
import { first} from 'rxjs/operators';
import * as CRS from '../api/CRS';
import * as SVC from '../api/SVC';

@Injectable({
    providedIn: 'root'
})
export class StylingService {
    
    private _styling : CRS.RSGetStyling=null;
    private _loaded: boolean = false;
    private _errored: boolean=false;

    constructor(
        private userService: SVC.UserService,
        private utilService: UtilService
    ) {
        this._onLoad=new Subject<CRS.RSGetStyling>();
    }

    public ensureLoaded(): void {
        if (this._loaded)
            this._onLoad.next(this._styling);
        else
            this.load();
    }

    private _onLoad : Subject<CRS.RSGetStyling>;
    public onLoaded() {
        return this._onLoad.asObservable();
    }

    public isLoaded(): boolean {
        return this._loaded;
    }

    public errored(): boolean {
        return this._errored;
    }

    public hasCustomHeaderImage(): boolean {
        return this.hasStyling() &&
             !this.utilService.isEmptyAny( this._styling.value.headerImage ) && 
             !this.utilService.isEmpty(this._styling.value.headerImage);
    }

    public getCustomHeaderImageData() : string {
        return (this.hasStyling()) ? "data:image/png;base64," + this._styling.value.headerImage : null;
    }

    public getCustomHeaderBgColor(): string {
        return (this.hasStyling()) ? this._styling.value.headerBackgroundColour:null;
    }

    public getCustomHeaderFgColor(): string {
        return (this.hasStyling()) ? this._styling.value.headerForegroundColour:null;
    }

    public waitForApiEndpoint(action: ()=>void) {
        if (this._loaded)
            action();
        else {
            this.onLoaded().pipe(first()).subscribe(()=> action());
        }
    }

    private hasStyling() : boolean {
        return this.isLoaded() && this._styling != null && this._styling.value != null;
    }

    private load(): void {
        this.userService.getStyling(window.location.hostname).then((response)=> {
            this._styling = response.data;
            this.utilService.setApiEndpoint(response.data.apiEndpoint);
            this._loaded=true;
            this._onLoad.next(this._styling);
        }, (r)=>{
            this._loaded=true;
            this._errored=true;
            this._styling = new CRS.RSGetStyling();
            this._styling.value=new CRS.RSStyling();
            this._onLoad.next(this._styling);
        });
    }
}

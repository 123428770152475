import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { UtilService } from '../../svc/utilService';
import { ProjectService } from '../../api/SVC';
import { CurrentProjectService} from '../../svc/currentProjectService';
import { UserCacheService } from '../../svc/userCacheService';

import { XCheckVirtualMachineChangeItem } from '../../model/XCheckVirtualMachineChangeItem';
import * as CRS from '../../api/CRS';

@Component({
  selector: 'xcheck-virtual-machine-list',
  templateUrl: './xcheck-virtual-machine-list.html',
  styleUrls: []
})
export class XCheckVirtualMachineList implements OnInit {

    constructor(
        private router:Router, 
        private projectService: ProjectService, 
        private currentProjectService: CurrentProjectService,
        private userCacheService: UserCacheService,
        private utilService:UtilService) { }

    @Input("virtual-machines") virtualMachines:Array<CRS.RSXCheckVirtualMachine>;

    @Output('on-import-virtual-machine') onImportVirtualMachine= new EventEmitter<XCheckVirtualMachineChangeItem>();
    @Output('on-delete-virtual-machine') onDeleteVirtualMachine=new EventEmitter<XCheckVirtualMachineChangeItem>();
    
    ngOnInit(): void {
    }

    public isReady() : boolean {
        return false;
    }

    public openRemotingLink(vm : CRS.RSXCheckVirtualMachine) {
        window.open(vm.remotingUrl);
    }
 
    public import(item: CRS.RSXCheckVirtualMachine) {
        this.onImportVirtualMachine.emit(XCheckVirtualMachineChangeItem.create(item));
    }
 
    public delete(item: CRS.RSXCheckVirtualMachine) {
        this.onDeleteVirtualMachine.emit(XCheckVirtualMachineChangeItem.create(item));
    }
 
}


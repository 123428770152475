import { Component, OnDestroy, OnInit } from '@angular/core';
import { of, Subscription } from 'rxjs';
import { UtilService } from '../../svc/utilService';
import { CurrentConversionJobService } from '../../svc/currentConversionJobService';
import { StateHelperService } from '../../svc/stateHelperService';
import { AppCommand } from '../../model/AppCommand';
import { CurrentApplicationService } from '../../svc/currentApplicationService';
import { CommandCollection } from 'src/app/commands/command-collection';

@Component({
  selector: 'conversion-task-publish',
  templateUrl: './conversion-task-publish.html',
  styleUrls:['./conversion-task-publish.scss', "../css/convert.scss"]
})
export class ConversionTaskPublish implements OnInit, OnDestroy {

    constructor(
        public currentConversionJob: CurrentConversionJobService,
        private currentApplication: CurrentApplicationService,
        public stateHelperService: StateHelperService,
        private utilService: UtilService
    ) { 
        this.refreshMenu();

        this.currentConversionJob.dataContext.onRefreshed().subscribe((v)=>{
            this.refreshMenu();
        })
    }

    private subscriptions : Array<Subscription> = new Array<Subscription>();

    ngOnInit(): void {
    }

    ngOnDestroy():void {
        this.subscriptions.forEach(x=>x.unsubscribe());
    }

    public get publishCommand(): AppCommand {
        return CommandCollection.Publish.cmd;
    }

    public get includeEvalResultsInReadme() :boolean {
        return this.utilService.includeEvalResultsInReadme;
    }

    public set includeEvalResultsInReadme(value:boolean) {
        this.utilService.includeEvalResultsInReadme=value;
    }

    public get mergeTransforms(): boolean {
        return this.utilService.mergeTransformsDuringPublish;
    }

    public set mergeTransforms(value:boolean) {
        this.mergeTransforms=value;
    }

    public get cannotPublishReasons(): Array<string> {
        if (!this.currentApplication.dataContext.isReady)
            return null;
        return this.currentApplication.dataItem.cannotPublishOriginalReasons;
    }

    public get applicationMissingAppId(): boolean {
        return this.currentApplication.dataContext.isReady && !this.currentApplication.dataItem.hasValidAppId;
    }

    public get invalidAppIdError(): string {
        return this.currentApplication.dataContext.isReady ? this.currentApplication.dataItem.invalidAppIdError : null;
    }

    public get applicationSilentMissing(): boolean {
        return this.currentApplication.dataContext.isReady && this.currentApplication.dataItem.needsSilentArgs && !this.currentApplication.dataItem.hasSilentArgs;
    }

    public getPromoBullets(): Array<string> {
        return [
            "Integration with SCCM and Intune",
            "Publishing documentation is automatically generated",
            "Automated MSIX digital signing",
            "Integrated workflow tools"
        ];
    }

    private refreshMenu():void {
        this.publishCommand.refresh();
    }

}

<table class='sw-table full-width-bs'>
    <thead>
        <tr>
            <th class='sw-table-ragcolumn' style="width:30px"></th> <!--table-util-value="rag"-->
            <th style="width:280px">Name</th>
            <th [hidden]="true">Enabled</th>
            <th [hidden]="narrow" class='sw-table-collapsablecolumn'>Application RAG Distribution</th>  <!--table-util-value="green,blue,amber,red"-->
            <th [hidden]="narrow" style="width:10px"></th>  <!--<table-utils></table-utils>-->
        </tr>
    </thead>
    <tbody>
        <tr *ngFor='let assessmentGroup of project.assessmentGroups;let i = index' (click)='openAssessmentGroup(assessmentGroup)' 
                 [ngClass]="{'sw-activerow' : isSelected(assessmentGroup), 'sw-oddrow' : isOdd(i)}"
                 [hidden]='!isValidForPlatform(assessmentGroup)'>
            <td class='sw-table-ragcolumn'> <!--table-util-value="{{getRagForAssessmentGroup(assessmentGroup)}}"-->
                <rag-column [rag-element]='assessmentGroup' custom-class="sw-rag-wide" [neutralise]='assessmentGroup.isDisabled'></rag-column>
            </td>
            <td [ngClass]='{"sw-disabled": assessmentGroup.isDisabled}'>
                <span class="sw-pad-right">{{assessmentGroup.name}}</span>
            </td>
            <td *ngIf="false">
                {{!assessmentGroup.isDisabled}}
            </td>
            <td *ngIf="!narrow"> <!-- table-util-value="{{vm.getRagDistributionSerialised(assessmentGroup)}}">-->
                <rag-distribution [rag]="assessmentGroup" enabled="true"></rag-distribution> 
            </td> 
        </tr>
    </tbody>
</table>

<div fxLayout="column">

    <mat-tab-group fxFlex>
        <mat-tab label="VM Preparation">
            <div class="sw-big-pad">
                <input-edit datatype="MSTRING" [rows]="15" label="Type or paste Powershell script" [value]="vmPreparationScript" 
                    (on-change)="vmPreparationScriptChanged($event)" [disable]="!canEdit('vmprep')"></input-edit> 
                <div fxLayout="row" fxLayoutGap="10px" *ngIf="isPending">
                    <div fxFlex="none">
                        <mat-progress-spinner diameter="20" mode="indeterminate"></mat-progress-spinner>
                    </div>
                    <div fxFlex>
                        Script is being validated. Please wait...
                    </div>
                </div>
                <p class="sw-ok" *ngIf="isOk">Script validated successfully</p>
                <div class="sw-error" *ngIf="!isPending && !isOk">
                    <div *ngFor="let e of errors">{{e}}</div>
                </div>
            </div>
        </mat-tab>
      </mat-tab-group>

</div>  


<h1 mat-dialog-title>Save Query</h1>
<div mat-dialog-content>
  <p>Enter query name</p>
  <mat-form-field>
    <mat-label>Query Name</mat-label>
    <input matInput [(ngModel)]="data.queryName" class="full-width">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="data" [disabled]="!isValid()" cdkFocusInitial>OK</button>
  <button mat-button (click)="onNoClick()">Cancel</button>
</div>

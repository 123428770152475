<div mat-dialog-title>
    <h2>ABOUT</h2>
</div>

<div mat-dialog-content>
    <div fxLayout="row" fxLayoutAlign="start center" *ngIf="!isDataReady()">
        <spinner diameter="20"></spinner>
    </div>
    <div *ngIf="isDataReady()" fxLayout="column" fxLayoutGap="10px">
        <div fxFlex>Copyright © 2017 - 2023 Application Readiness Ltd.</div>    
        <div fxFlex>The use of this product is subject to the terms of the Application Readiness End User Agreement, unless other specified therein.</div>
        <div fxFlex *ngIf="isDataReady()">Software Version {{version}}</div>
        <div fxFlex *ngIf="isDataReady() && backEndVersion">Back-end Services Version {{backEndVersion}}</div>
        <div fxFlex>Api Url&nbsp;{{apiUrl}}</div>
        <div fxFlex>JRN&nbsp;{{jobRouterName}}</div>
        <div fxFlex><a href="" (click)="openTerms($event)">Terms of use</a></div>
    </div>
</div>

<div mat-dialog-actions fxLayout='row'>
    <div fxFlex></div>
    <button mat-button (click)='cancelDialog()'>
        <mat-icon>done</mat-icon>
        <span>Close</span>
    </button>
</div>


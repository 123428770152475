<div fxLayout="column">

    <div fxFlex="none" fxLayout="row">
        <div fxflex="50">
            <mat-form-field appearance="outline" style="min-width: 300px;" *ngIf="readmeDocumentationScriptsReady">
                <mat-label>Readme Script</mat-label>
                <mat-select [(ngModel)]="project.readmeDocumentationScript"
                    (selectionChange)="readmeDocumentationScriptChanged()" [disabled]="!canEdit()" style="min-width:200px">
                    <mat-option value="{{null}}">Default Script</mat-option>
                    <mat-option [value]="r" *ngFor="let r of readmeDocumentationScripts">{{r}}</mat-option>
                </mat-select>
            </mat-form-field>

        </div>
    </div>

    <div fxFlex="none">
        <div class="sw-pad">
            <h3>PUBLISHING COMMANDS</h3>
        </div>
    </div>

    <mat-tab-group fxFlex>
        <mat-tab label="Msi">
            <div class="sw-big-pad">
                <input-edit datatype="MSTRING" [rows]="15" label="Publishing Commands" [value]="currentProject.publishCommandsSplit['DFLT']" 
                    (on-change)="publishingCommandsChanged($event)" [disable]="!canEdit()"></input-edit> 
            </div>
        </mat-tab>
        <mat-tab label="EXE">
            <div class="sw-big-pad">
                <input-edit datatype="MSTRING" [rows]="15" label="Publishing Commands (Exe)" [value]="currentProject.publishCommandsSplit['EXE']" 
                (on-change)="publishingCommandsExeChanged($event)" [disable]="false ||!canEdit()"></input-edit>
            </div>  
        </mat-tab>
        <mat-tab label="App-V">
            <div class="sw-big-pad">
                <input-edit datatype="MSTRING" [rows]="15" label="Publishing Commands (App-V)" [value]="currentProject.publishCommandsSplit['VIRT']" 
                (on-change)="publishingCommandsVirtChanged($event)" [disable]="!canEdit()"></input-edit>
            </div>
        </mat-tab>
        <mat-tab label="MSIX">
            <div class="sw-big-pad">
                <input-edit datatype="MSTRING" [rows]="15" label="Publishing Commands (Msix)" [value]="currentProject.publishCommandsSplit['MSIX']" 
                    (on-change)="publishingCommandsMsixChanged($event)" [disable]="!canEdit()"></input-edit>
            </div>
        </mat-tab>
        <mat-tab label="Liquidware">
            <div class="sw-big-pad">
                <input-edit datatype="MSTRING" [rows]="15" label="Publishing Commands (Liquidware)" [value]="currentProject.publishCommandsSplit['LIQD']" 
                    (on-change)="publishingCommandsLiqdChanged($event)" [disable]="!canEdit()"></input-edit>
            </div>
        </mat-tab>
      </mat-tab-group>

</div>  


import { Component, OnInit, Input, Output, OnDestroy, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Constants } from '../../api/Constants';
import { CurrentConversionJobService } from 'src/app/svc/currentConversionJobService';
import { CurrentApplicationService } from 'src/app/svc/currentApplicationService';
import { PollingService } from '../../svc/pollingService';
import { StateHelperService } from 'src/app/svc/stateHelperService';
import { UtilService } from 'src/app/svc/utilService';

import * as CRS from '../../api/CRS';

@Component({
  selector: "application-action-tree",
  templateUrl: './application-action-tree.html',
  styleUrls: ['./application-action-tree.scss']
})
export class ApplicationActionTree implements OnInit, OnDestroy {

  constructor(
    private utilService: UtilService,
    private currentApplication: CurrentApplicationService,
    private currentConversionJob: CurrentConversionJobService,
    private pollingService: PollingService,
    private stateHelperService: StateHelperService,
  ) { }

  @Output('onSelect') onSelect = new EventEmitter<CRS.RSAction>();

  public treeControl :NestedTreeControl<CRS.RSAction>;
  public dataSource: MatTreeNestedDataSource<CRS.RSAction>;
  public currentNode: CRS.RSAction=null;

  private subscriptions: Subscription[] = [];

  ngOnInit(): void {
    this.pollingService.startPollingSequence(Constants.POLLING_ID_ACTIONVIEW, Constants.POLLING_ACTIONVIEW_INTVL, 0, ()=> {
      this.update();
    });
    this.subscriptions.push(this.currentApplication.dataContext.onRefreshed().subscribe(x=>this.update()));
    this.subscriptions.push(this.currentApplication.dataContext.onLoaded().subscribe(x=>this.update()));
    this.treeControl = new NestedTreeControl<CRS.RSAction>(node => node.children);
    this.dataSource = new MatTreeNestedDataSource<CRS.RSAction>();
  }

  ngOnDestroy(): void {
    this.pollingService.stopSequence(Constants.POLLING_ID_ACTIONVIEW);
    this.subscriptions.forEach((x)=>x.unsubscribe());
  }

  public isDataReady(): boolean {
    return this.currentApplication.dataContext.isReady;
  }

  // Maintain a list of the expanded nodes to be used on next refresh
  private expandedNodes: Array<string> = [];
  public nodeExpandChanged(nodes?: Array<CRS.RSAction>) {
    if (!nodes)
      this.expandedNodes =[];
    var n = (nodes) ? nodes : this.dataSource.data;
    n.forEach((n)=>{
      if (this.treeControl.isExpanded(n) && n.children.length> 0) {
        this.expandedNodes.push(`${n.action}+${n.parentageId}`);
        this.nodeExpandChanged(n.children);
      }
    });
    if (!nodes) {
      this.utilService.localStorageHelper.expandedActionTreeNodes = this.expandedNodes;
    }
  }

  public openAction(action: CRS.RSAction) {
    this.onSelect.emit(action);
  }

  public isCurrent(action: CRS.RSAction) :boolean {
    return action.id == this.stateHelperService.details.jobId || action.id == this.stateHelperService.details.actionId;
  }

  public hasChild = (_: number, node: CRS.RSAction) => !!node.children && node.children.length > 0;

  private update() {
      if (this.currentApplication.dataContext.isReady){
        var actions = this.currentApplication.dataItem.actions;
        this.dataSource.data=actions;
        this.expandRootTreenodes(actions);
        this.expandXCheckNode(actions);
      }
  }

  private expandRootTreenodes(data: Array<CRS.RSAction>) {
    var expandedSet = this.utilService.localStorageHelper.expandedActionTreeNodes;
    data.forEach((n)=> {
      var toExpand = (expandedSet) ? expandedSet.indexOf(`${n.action}+${n.parentageId}`) >= 0 : true;
      if (toExpand) {
        this.treeControl.expand(n);
        if (n.children)
          this.expandRootTreenodes(n.children);
      }
    })
  }

  private expandXCheckNode(data: Array<CRS.RSAction>) {
    if (this.currentConversionJob.isXCheckJobType()) {
      var origLst = data.filter(x=>x.action == "O");
      if (origLst.length>0) {
        var xchk = origLst[0].children.filter(x=>x.action == "X");
        if (xchk.length>0)
          this.treeControl.expand(xchk[0]);
      }
    }
  }

}

<div fxLayout="row" fxFill class='full-height-bs'>

    <div [fxFlex]="stateHelperInstance.isCurrent() ? '': '0 0 200px'" class="sw-segment" >
        <div>
            <table class="sw-table-collapsable">
                <tr *ngFor="let pc of patchCats?.items" [ngClass]='{"sw-table-activerow": classReference.selectedPatch == pc}' class="sw-table-hover" (click)="openPatchCat(pc)">
                    <td>{{pc}}</td>
                </tr>
            </table>
        </div>
    </div>

    <div fxLayout='column' fxFlex *ngIf='stateHelperInstance.isNotCurrent()'>
        <div class="full-height-bs">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
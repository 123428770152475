export class AuthConfig {
    public static clientId :string= "e33fe8c8-7667-49c7-973b-c765baf7433c";
    public static tenantId:string= "a32e5095-3796-42cd-86cc-14be001f4072";
    public static resourceScope:string = "api://8fe5173f-203f-4fa4-bbf5-613f2eecc5b8/access_as_user";

    public static get resourceUri()
    {
        switch(window.location.host.toLowerCase())
        {
            case "qsvcxtest2.azurewebsites.net":
                return "https://qsvcxtest-api.azurewebsites.net/api/";
            case "dev.applicationreadiness.com":
            case "qsvcxtest2-dev.azurewebsites.net":
            case "appreadinessdev.azurewebsites.net":
                return "https://appreadinessapi.azurewebsites.net/api/";
            case "qsvcxtest2-demo.azurewebsites.net":
            case "eval.applicationreadiness.com":
                return "https://qsvcxtest-api-demo.azurewebsites.net/api/";
            case "dell.applicationreadiness.com":
                return "https://api-dell.applicationreadiness.com/api/";
            case "scc.applicationreadiness.com":
                return "https://api-scc.applicationreadiness.com/api/";
            case "dxc.applicationreadiness.com":
                return "https://api-dxc.applicationreadiness.com/api/";
            default:
                //return "https://appreadinessapi.azurewebsites.net/api/";
                return "https://localhost:7257/api/";  //"https://appreadinessapi.azurewebsites.net/api/"; // "https://localhost:44351/api/"; //"https://localhost:7257/api/"; 
        }
    }
}
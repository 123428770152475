import * as STHS from '../../svc/stateHelperService';

import * as CRS from '../../api/CRS';
import * as SVC from '../../api/SVC';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Constants } from 'src/app/api/Constants';
import { INPUTEDITARGS } from 'src/app/model/INPUTEDITARGS';
import { AdminPatchCats } from '../admin-patch-cats/admin-patch-cats';

@Component({
    selector: "admin-patch-cat-files",
    templateUrl: './admin-patch-cat-files.html',
    styleUrls: ['../../shared/css/admin.scss', '../../shared/css/tables.scss', '../../shared/css/view.scss']
})

export class AdminPatchCatFiles implements OnInit {

    constructor(
        private route:ActivatedRoute, 
        private xadminService: SVC.AdminService,
        private stateHelperService: STHS.StateHelperService,
    ) {
    }

    public patchCat: CRS.RSAdminGetPatchCategory = null;
    public newFilename:string=null;
    private catId: string = '';

    public stateHelperInstance: STHS.StateHelperInstance;

    ngOnInit() {
        this.stateHelperInstance= this.stateHelperService.register(Constants.ROUTE_PATCHCATEGORYFILES);
        this.update();
    }

    private update(): void {
        this.route.params.subscribe(params => {
            this.catId = params[Constants.ROUTE_CATEGORY_ID]
            AdminPatchCats.updateSelectedPatch(this.catId)
        this.xadminService.adminGetPatchCategory(this.catId).then((response) => {
            this.patchCat = response.data;
        }, () => {
            console.error('Patch cat data couldn\'t be loaded');
        });
        })
    }
   
    public fileAdded(args:INPUTEDITARGS) : void { 
        const value = args.value
        this.xadminService.adminAddFilenameToPatchCategory(this.catId, value).then(()=> {
            let fnObj = new CRS.RSAdminPatchFilename();
            fnObj.filename = value;
            this.patchCat.items.push(fnObj);
            this.newFilename=null;
        });
    }

    public deletePatchFilename(file: CRS.RSAdminPatchFilename) {
        this.xadminService.adminDeleteFilenameFromPatchCategory(this.catId, file.filename).then(()=> {
            let idx = this.patchCat.items.indexOf(file);
            this.patchCat.items.splice(idx,1);
        });
    }
}

import {AppCommand} from '../../model/AppCommand';
import {MenuService} from '../../svc/menuService';
import { DialogService } from '../../svc/dialogService';

import * as UTIL from '../../svc/utilService';
import * as SVC from '../../api/SVC';
import { ActivatedRoute, Router } from "@angular/router";

import { Component, OnInit, AfterContentChecked } from '@angular/core';
import { Constants } from 'src/app/api/Constants';

@Component({
    selector: Constants.ROUTE_ADMINDASHBOARD,
    templateUrl: './admin-dashboard.html',
    styleUrls: ['../../shared/css/header.scss', '../../shared/css/admin.scss']
  })

export class AdminDashboard implements AfterContentChecked, OnInit {
    
    // Show the Remote Connect header item 
    public showAdd: boolean;
    public showCreateVm: boolean;
    public showImportWindowsUpdate: boolean;
    public showRemotingReset: boolean;
    public showAddPatchCategory: boolean;
    public showRemoteConnect: boolean;
    public showCancel: boolean;
    public showEvalRelease: boolean;


    // Make constants available in template
    public Constants = Constants;

    constructor(
        public router: Router, 
        public route:ActivatedRoute, 
        private xadminService: SVC.AdminService,
        private utilService: UTIL.UtilService,
        public menuService: MenuService,
        private dialogService: DialogService
    ) {
        // Set initial header item display values
        this.showAdd = this.menuService.canInvoke(Constants.MENU_ADD);
        this.showCreateVm = this.menuService.canInvoke(Constants.MENU_CREATE_VM)
        this.showImportWindowsUpdate = this.menuService.canInvoke(Constants.MENU_IMPORT_WINDOWS_UPDATE);
        this.showRemotingReset = this.menuService.canInvoke(Constants.MENU_REMOTING_RESET);
        this.showAddPatchCategory = this.menuService.canInvoke(Constants.MENU_ADD_PATCH_CATEGORY);
        this.showRemoteConnect = this.menuService.canInvoke(Constants.MENU_REMOTE_CONNECT);
        this.showCancel = this.menuService.canInvoke(Constants.MENU_CANCEL);
        this.showEvalRelease = this.menuService.canInvoke(Constants.MENU_EVAL_RELEASE);
    }

    ngOnInit():void {
        this.menuService.register(Constants.MENU_REMOTING_RESET, this.remotingReset, "P-dashboard/admin");   

        if (this.router.url == "/dashboard/admin")
            this.router.navigate([Constants.ROUTE_ADMINCHECKLIBRARY], {relativeTo: this.route});
    }

    ngAfterContentChecked() {
        // Update header item display values
        this.showAdd = this.menuService.canInvoke(Constants.MENU_ADD);
        this.showCreateVm = this.menuService.canInvoke(Constants.MENU_CREATE_VM)
        this.showImportWindowsUpdate = this.menuService.canInvoke(Constants.MENU_IMPORT_WINDOWS_UPDATE);
        this.showRemotingReset = this.menuService.canInvoke(Constants.MENU_REMOTING_RESET);
        this.showAddPatchCategory = this.menuService.canInvoke(Constants.MENU_ADD_PATCH_CATEGORY);
        this.showRemoteConnect = this.menuService.canInvoke(Constants.MENU_REMOTE_CONNECT);
        this.showCancel = this.menuService.canInvoke(Constants.MENU_CANCEL);
        this.showEvalRelease = this.menuService.canInvoke(Constants.MENU_EVAL_RELEASE);
    }

    private remotingReset : AppCommand = AppCommand.create(()=> {
        return true;
    },
    ()=> {
        this.xadminService.adminRemotingReset().then(()=> {
            this.dialogService.showInfoMessage("Remoting Reset", ["Reset request issued"]);
        });
    });

}


<div *ngIf="!hasItems()">
    No environment variables or path changes occurred during this installation
</div>

<div *ngIf="hasUserEnvVars()">
    <h4>User Variables </h4>
    <table style="padding-left:10px;width:100%" *ngIf="hasItems()">
        <thead>
            <tr>
                <th>Name</th>
                <th>Value</th>
            </tr>
        </thead>
        <tr *ngFor="let cp of userEnvVars">
            <td style="min-width: 150px;" class="sw-column-top">{{cp.name}}</td>
            <td style="min-width: 80px;" class="sw-column-top">{{cp.value}}</td>
        </tr>
    </table>
    <br>
    <br>
</div>

<div *ngIf="hasMachineEnvVars()">
    <h4>Machine Variables </h4>
    <table style="padding-left:10px;width:100%" *ngIf="hasItems()">
        <thead>
            <tr>
                <th>Name</th>
                <th>Value</th>
            </tr>
        </thead>
        <tr *ngFor="let cp of machineEnvVars">
            <td style="min-width: 150px;" class="sw-column-top">{{cp.name}}</td>
            <td style="min-width: 80px;" class="sw-column-top">{{cp.value}}</td>
        </tr>
    </table>
    <br>
    <br>
</div>

<div *ngIf="hasPathItems()">
    <h4>Path</h4>
    <table style="padding-left:10px;width:100%">
        <thead>
            <tr>
                <th>Type</th>
                <th>Path</th>
            </tr>
        </thead>
        <tr *ngFor="let cp of pathItems">
            <td style="min-width: 150px;" class="sw-column-top">{{cp.type}}</td>
            <td style="min-width: 80px;" class="sw-column-top">{{cp.value}}</td>
        </tr>
    </table>
    <br>
    <br>
</div>

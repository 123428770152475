import { Injector } from '@angular/core';
import { take } from 'rxjs/operators';
import { MenuService } from '../svc/menuService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';
import { DialogService } from '../svc/dialogService';
import { CurrentApplicationService } from "../svc/currentApplicationService";

import * as Enum from '../api/Enum';

export class UpdateAppIdCommand {

    constructor(
        private injector: Injector
    ) {
        this.menuService = this.injector.get(MenuService);
        this.currentApplication = this.injector.get(CurrentApplicationService)
        this.dialogService = this.injector.get(DialogService);
 
        this.menuService.register(Constants.MENU_UPDATE_APPID, this.command, "*"+Constants.ROUTE_PROJECTAPPLICATIONLANDING_MAIN );

        this.currentApplication.dataContext.onStateChanged().subscribe(x=> {
            this.isAppReady=x.loadState == Enum.LoadState.Ready;
        });

    }

    private isAppReady=false;
    private menuService: MenuService;
    private dialogService: DialogService;
    private currentApplication: CurrentApplicationService;

    private command: AppCommand = AppCommand.createWithShow(
        ()=> {
            return this.isAppReady;
        },
        ()=> {
            if (this.currentApplication.dataItem.hasValidAppId) {
                this.dialogService.showYesNoMessage("Update AppId", ["Are you sure that you want to update the AppId for " + this.currentApplication.dataItem.name + "?"])
                .pipe(take(1))
                .subscribe((ans) => {
                    if (ans) {
                        this.currentApplication.updateAppId();
                    }
                });
                
            }
            else {
                this.currentApplication.updateAppId().then((ret)=>{
                    if(ret)
                        this.dialogService.showInfoMessage("Update AppId", [ "The AppId for this application has been updated"]);
                });
            }
        },
        ()=> {
            return true;
        }
    )

}
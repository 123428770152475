import { Component, ElementRef, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProjectOutputsDialog } from './project-outputs-dialog';
import { CurrentProjectService} from '../../svc/currentProjectService';
import { CurrentApplicationService } from '../../svc/currentApplicationService';

@Component({
  selector: 'project-outputs-selector',
  templateUrl: './project-outputs-selector.html',
  styleUrls: ['./project-outputs-selector.scss','../../shared/css/buttons.scss']
})
export class ProjectOutputSelector implements OnInit {

  constructor(
    private dialog: MatDialog, 
    private currentProject: CurrentProjectService,
    private currentApplication: CurrentApplicationService
  ) { }

  ngOnInit() {
    this.refresh();
    this.currentProject.dataContext.onLoaded().subscribe(()=> {
      this.refresh();
    });
    this.currentApplication.dataContext.onLoaded().subscribe(()=> {
      this.refresh();
    });
  }

  public openOutputsSelectorDialog(event:Event) {
      const target = new ElementRef(event.currentTarget);
      const dialogRef = this.dialog.open(ProjectOutputsDialog, {
        data: { element: target }
      });
      dialogRef.afterClosed().subscribe( prj => {
      });
  }

  public anyInProgress : boolean=false;
  public hasDownloads : boolean =false;
  public infoText:string;

  private refresh() {
    var prjCount:number=0;
    var prjInProg:number=0;
    var appCount:number = 0;
    var appInProg:number=0;
    if (this.currentProject.dataContext.isReady) {
      prjCount = this.currentProject.outputTypes.filter(x=>x.getStatus()>1).length;
      prjInProg = this.currentProject.outputTypes.filter(x=>x.getStatus()<=1 && x.getStatus() >= 0).length;
    }
    if (this.currentApplication.dataContext.isReady) {
      appCount = this.currentApplication.outputTypes.filter(x=>x.getStatus()>1).length;
      appInProg = this.currentApplication.outputTypes.filter(x=>x.getStatus()<=1 && x.getStatus() >=0).length;
    }
    var txt = "";
    if (prjInProg + appInProg > 0) {
      txt=`${prjInProg + appInProg} running`;
      this.anyInProgress=true;
    }
    else {
      this.anyInProgress=false;
    }
    if (prjCount + appCount > 0) {
      if (txt!="")
        txt +=". ";
      txt=txt + `${prjCount+appCount} completed`;
    }
    this.infoText=txt;
    this.hasDownloads = prjCount + appCount > 0;
  }

}


import { Component, OnInit } from '@angular/core';
import { Constants } from '../../api/Constants';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { INPUTEDITARGS } from '../../model/INPUTEDITARGS';
import * as CRS from '../../api/CRS';

@Component({
  selector: Constants.ROUTE_PROJECTSETTINGSPUBLISH,
  templateUrl: './project-settings-publishing.html',
  styleUrls: [ './project-settings.scss', '../../shared/css/nav-bar.scss']
})
export class ProjectSettingsPublishing implements OnInit {

  constructor(
    public currentProject: CurrentProjectService) { }

  ngOnInit(): void {
  }

  public get project() : CRS.RSGetProject {
    return this.currentProject.dataItem;
  }

  public get projectIsReady(): boolean {
    return this.currentProject.dataContext.isReady;
  }

  public canEdit(): boolean {
    return this.currentProject.canEdit();
  }

  public get readmeDocumentationScripts() : Array<any> {
    return this.currentProject.readmeDocumentationScripts;
  }

  public get readmeDocumentationScriptsReady() : boolean {
    return this.currentProject.readmeDocumentationScriptsReady;
  }

  public publishingCommandsChanged(args : INPUTEDITARGS) {
    this.currentProject.updatePublishingCommands(args.value);
  }

  public publishingCommandsVirtChanged(args : INPUTEDITARGS) {
    this.currentProject.updatePublishingVirtCommands(args.value);
  }

  public publishingCommandsMsixChanged(args : INPUTEDITARGS) {
    this.currentProject.updatePublishingMsixCommands(args.value);
  }
  
  public publishingCommandsLiqdChanged(args : INPUTEDITARGS) {
    this.currentProject.updatePublishingLiqdCommands(args.value);
  }

  public publishingCommandsExeChanged(args : INPUTEDITARGS) {
    this.currentProject.updatePublishingExeCommands(args.value);
  }

  public readmeDocumentationScriptChanged() {
    var v = this.project.readmeDocumentationScript;
    if (v=="[NULL]")
      v=null; 
    this.currentProject.updateReadmeDocumentationScript(v);
  }
}

import { CurrentApplicationService } from '../svc/currentApplicationService';
import { UtilService } from '../svc/utilService';

import * as Enum from '../api/Enum';
import * as CRS from '../api/CRS';

export class OutputItemInfoApp {

    constructor(
        private currentApplication: CurrentApplicationService,
        private utilService: UtilService,
        private type:number,
        private outputType:Enum.OutptType,
        private warning?: ()=>string
    ) {
        this._type = type;
        this._outputType= outputType;
        this._warning= warning;
        this._item = this.getItem();
        this.currentApplication.dataContext.onLoaded().subscribe(()=> {
            this._item=this.getItem();
        });
    }

    public _type:number;
    public _outputType:Enum.OutptType;
    public _warning: ()=>string;

    private _item: CRS.RSGeneratedOutputItem;

    public getTitle() : string {
        return (this._item) ? this._item.title.toUpperCase(): '--Unknown--';
    }

    public hasWarning() : boolean {
        return !this.utilService.isEmptyAny(this._warning);
    }

    public getWarning() : string {
        return this._warning ? this._warning() : null;
    }

    public getStatus(): number {
        return (this._item) ? this._item.status : -1;
    }

    public getOutputTypeDescription() : string {
        if (!this._item) 
            return null;
        switch(this._outputType) {
            case Enum.OutptType.Html : return "Download HTML";
            case Enum.OutptType.PDF: return "Download PDF";
            case Enum.OutptType.Zip: return "Download Zip";
        }
    }

    public getStatusDescription():string {
        if (!this._item)
            return null;
        switch(this._item.status){
            case 0: return "Not started";
            case 1: return "In progress";
            case 2: return "Failed";
            case 3: return "Cancelled";
            case 4: return "Succeeded";
            case 5: return "Timed out";
            default:
                return "Unknown";
        }
    }

    public getUrl(): string {
        if (!this._item)
            return null;
        if (this._item.isSas)
            return this._item.url;
        return this.utilService.buildAPIUrl(this._item.url);
    }

    public getDateExpression() :string {
        return this._item?.generated;
    }

    private getItem() {
        if (this.currentApplication && this.currentApplication.dataContext.isReady) {
            var lst = this.currentApplication.dataItem.generatedOutputItems.filter(x=>x.type == this.type);
            return (lst.length>0) ? lst[0] : null;
        }
    }
}
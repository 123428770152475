import { Component, Input, OnInit } from '@angular/core';
import { CommandCollection } from '../../commands/command-collection';
import { ClickerScriptEditCommand } from 'src/app/commands/clicker-script-edit';

@Component({
  selector: 'conversion-clicker-panel',
  templateUrl: './conversion-clicker-panel.html'
})
export class ConversionClickerPanel implements OnInit {

    constructor(
    ) { }

    ngOnInit(): void {
    }

    public get clickerCommand(): ClickerScriptEditCommand {
        return CommandCollection.ClickerScriptEdit;
    }

}

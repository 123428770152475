<div *ngIf="!hasItems()">
    No Compat Flag items were created by this installation
</div>

<table style="padding-left:10px;width:100%" *ngIf="hasItems()">
    <thead>
        <th>Type</th>
        <th>Name</th>
        <th>Further Info</th>
    </thead>
    <tr *ngFor="let cp of item.compatFlagItems">
        <td style="min-width: 150px;" class="sw-column-top">{{cp.type}}</td>
        <td style="min-width: 150px;" class="sw-column-top">{{cp.name}}</td>
        <td class="sw-column-top" style="text-align: right;">
            {{getFlagItemValue(cp.value)}}
        </td>
    </tr>
</table>
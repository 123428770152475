import { Injectable } from '@angular/core';
import { NavigationStart, NavigationEnd, ActivatedRoute, Router } from "@angular/router";
import { CurrentProjectService } from './currentProjectService';
import { CurrentApplicationService } from './currentApplicationService';
import { CurrentAssessmentGroupService } from './currentAssessmentGroupService';
import { EventService } from './eventService';
import { Constants } from '../api/Constants';
import { CrumbChangeState } from '../api/Enum';
import { Crumb } from '../model/Crumb';
import { RoutePathSegment } from '../model/RoutePathSegment';
import { CurrentConversionJobService } from './currentConversionJobService';
import { CommandCollection } from '../commands/command-collection';

import * as Enums from '../api/Enum';
import * as CRS from '../api/CRS'

@Injectable({
    providedIn: 'root'
})
export class BreadcrumbService {

    private application: CRS.RSGetApplication;
    private assessmentGroup: CRS.RSGetAssessmentGroup;
    private conversionTask: CRS.RSGetConversionTask;
    private edPkg: CRS.RSMsiGetPackageDetails;
    private patchGroup: CRS.RSAdminGetPatchGroupItem;
    public crumbs: Array<Crumb> = [];

    // Leave all of the dataContextProviders in here so they are all ready to respond to 
    // route changes early in the hierarchy.
    
    constructor( 
        private router: Router,
        private route: ActivatedRoute,
        private eventService: EventService,
        private currentProject: CurrentProjectService,
        private currentApplication: CurrentApplicationService,
        private currentAssessmentGroup: CurrentAssessmentGroupService
    ) {
        let xthis : BreadcrumbService = this;
        this.router.events.subscribe(ev => {
            if (ev instanceof NavigationStart) {
                xthis.update(CrumbChangeState.Pre);
            }
            if (ev instanceof NavigationEnd) {
                xthis.update(CrumbChangeState.Post);
            }
        });
        this.currentProject.dataContext.onLoaded().subscribe(()=> { this.update(CrumbChangeState.Data); });
        this.currentApplication.dataContext.onLoaded().subscribe((a)=> { this.setApplication(a);} );
        this.currentAssessmentGroup.dataContext.onLoaded().subscribe((a)=> { this.setAssessmentGroup(a);} );
        this.currentProject.dataContext.onStateChanged().subscribe((a)=>{ 
            if (a.loadState == Enums.LoadState.InError) 
            this.update(CrumbChangeState.Data)
        }); //condition here is to minimise potential impact of change - no known impact
        this.eventService.onProjectDetailsUpdateRequest().subscribe((a)=> this.update(CrumbChangeState.Data));
        this.update(CrumbChangeState.Initial);
    }

    public projectReady(): void {
        this.update(CrumbChangeState.Data);
    }

    public setApplication(application: CRS.ResponseWrapper<CRS.RSGetApplication>) : void {
        if (!this.application || !application || this.application.id != application.data.id) {
            this.application = application.data;
            this.update(CrumbChangeState.Data);
        }
    }

    public setAssessmentGroup(assGroup: CRS.ResponseWrapper<CRS.RSGetAssessmentGroup>): void {
        if (!this.assessmentGroup || !assGroup || this.assessmentGroup.id != assGroup.data.id) {
            this.assessmentGroup = assGroup.data;
            this.update(CrumbChangeState.Data);
        }
    }

    public setConversionTask(task: CRS.ResponseWrapper<CRS.RSGetConversionTask>) : void {
        if (!this.conversionTask || !task || this.conversionTask.id != task.data.id) {
            this.conversionTask = task.data;
            this.update(CrumbChangeState.Data);
        }
    }

    public setEdPkg(edPkg: CRS.RSMsiGetPackageDetails) : void {
        if (!this.edPkg || !edPkg || this.edPkg.applicationId != this.edPkg.applicationId) {
            this.edPkg = edPkg;
            this.update(CrumbChangeState.Data);
        }
    }

    public setPatchGroup(pg: CRS.RSAdminGetPatchGroupItem) : void {
        this.patchGroup = pg;
        this.update(CrumbChangeState.Data);
    }

    private update(state:CrumbChangeState) : void {
        let path = this.getStatePath();
        let prj : CRS.RSGetProject = this.currentProject.dataItem;
        let clist: Array<Crumb> = [];
        path.forEach(p=> {
            this.updateElement(p, clist, prj, this.application, this.assessmentGroup, this.conversionTask, this.edPkg);
        });
        this.crumbs = clist;
    }

    private getStatePath() : Array<RoutePathSegment> {
        let states : Array<any> = [];
        var s : ActivatedRoute = this.route.firstChild;
        while (s){
            s.url.subscribe((x)=>{
                if (x.length>0) {
                    var x0Path =x[0].path;
                    var x1Path =(x.length>1) ? x[1].path : null;
                    if (states.length == 0 && x0Path==Constants.ROUTE_PROJECTDETAILS_MAIN)
                        states.push(new RoutePathSegment("prj-dash",null));
                    states.push(new RoutePathSegment(x0Path,x1Path));
                }
            });           
            s = (s.children.length>0) ? s.children[0] : null;
        }
        return states;
    }

    private updateElement(state:RoutePathSegment, clist: Array<Crumb>,
             prj: CRS.RSGetProject, 
             app: CRS.RSGetApplication, 
             assGroup: CRS.RSGetAssessmentGroup,
             convTask: CRS.RSGetConversionTask,
             edPkg: CRS.RSMsiGetPackageDetails) {

        switch(state.id) {
            case "dashboard/admin":
                clist.push(new Crumb('Admin', ["dashboard/admin"], null, true));
                break;
            case Constants.ROUTE_ADMINCHECKLIBRARY:
                clist.push(new Crumb('Check Library', [Constants.ROUTE_ADMINCHECKLIBRARY], null, true));
                break;
            case Constants.ROUTE_ADMINPATCHGROUPS:
                clist.push(new Crumb('Patch Groups',[ Constants.ROUTE_ADMINPATCHGROUPS ], null, true));
                break;
            case Constants.ROUTE_ADMINPATCHCATS:
                clist.push(new Crumb('Patch Categories',[ Constants.ROUTE_ADMINPATCHCATS ], null, true));
                break;
            case Constants.ROUTE_ADMINPATCHCVECATS:
                clist.push(new Crumb('CVE Categories',[ Constants.ROUTE_ADMINPATCHCVECATS ], null, true));
                break;
            case Constants.ROUTE_ADMININSTALLATIONS:
                clist.push(new Crumb('Installations',[Constants.ROUTE_ADMININSTALLATIONS], null, true));
                break;
            case Constants.ROUTE_ADMINASSESSMENTGROUPS:
                clist.push(new Crumb("Assessment Groups", [Constants.ROUTE_ADMINASSESSMENTGROUPS], null, true));
                break;
            case Constants.ROUTE_ADMINWINDOWSUPDATES:
                clist.push(new Crumb("Windows Updates", [Constants.ROUTE_ADMINWINDOWSUPDATES], null, true));
                break;
            case Constants.ROUTE_ADMINPROJECTS:
                clist.push(new Crumb("Projects", [Constants.ROUTE_ADMINPROJECTS], null, true));
                break;
            case Constants.ROUTE_PATCHGROUPMONTH:
                if (this.patchGroup)
                    clist.push(new Crumb(this.patchGroup.title,[ Constants.ROUTE_PATCHGROUPMONTH], {pgId: this.patchGroup.patchGroupId}, true));
                break;
            case Constants.ROUTE_PROJECTDETAILS_MAIN:
                if (prj)
                    clist.push(Crumb.createLink(prj.name, [Constants.ROUTE_PROJECTDETAILS_MAIN, prj.id, Constants.ROUTE_PROJECTAPPLICATIONS ]));
                break;
            case "prj-dash": // can't use selector here because it would create circular reference
                let prjcr = Crumb.createLabel("Projects", null);
                if (CommandCollection.OpenProjectSelector)
                    prjcr.command = CommandCollection.OpenProjectSelector.command;
                clist.push(prjcr);
                break;
            case Constants.ROUTE_PROJECTREPORTS:
                if (prj)
                    clist.push(Crumb.createLink("Reports", [Constants.ROUTE_PROJECTDETAILS_MAIN, prj.id, Constants.ROUTE_PROJECTREPORTS]));
                break;
            case Constants.ROUTE_PROJECTAPPLICATIONS:
                if (prj) 
                    clist.push(Crumb.createLink("Applications", [Constants.ROUTE_PROJECTDETAILS_MAIN, prj.id, Constants.ROUTE_PROJECTAPPLICATIONS]));
                break;
            case Constants.ROUTE_PROJECTAPPLICATIONDETAILS:
            case Constants.ROUTE_PROJECTAPPLICATIONISSUES:
            case Constants.ROUTE_PROJECTAPPLICATIONNOTES:
            case Constants.ROUTE_PROJECTAPPLICATIONHISTORY:
            case Constants.ROUTE_PROJECTAPPLICATIONQA:
                if (app)
                    clist.push(Crumb.createLink(app.friendlyName||app.name, [Constants.ROUTE_PROJECTDETAILS_MAIN, prj.id, Constants.ROUTE_PROJECTAPPLICATIONLANDING_MAIN, app.id]));
                break;
            case Constants.ROUTE_PROJECTSETTINGSGENERAL:
                if (prj)
                    clist.push(Crumb.createLabel("Settings", [Constants.ROUTE_PROJECTDETAILS_MAIN, prj.id, Constants.ROUTE_PROJECTSETTINGS, Constants.ROUTE_PROJECTSETTINGSGENERAL]));
                break;
            // case "conversionTaskView":
            //     if (convTask && prj) {
            //         let qual: string = convTask.type[0] =="R" ? "Repackage" : "Virtualise";
            //         let txt: string = qual + " "  + convTask.applicationName;
            //         clist.push(new Crumb(txt, "conversionTaskView", {projectId: prj.id, taskId: convTask.id}, false));
            //     }
            //     break;
            // case "editPackage":
            //     if (edPkg) {
            //         let txt :string = "View";
            //         let txta: string = (edPkg.applicationFriendlyName) ? edPkg.applicationFriendlyName: edPkg.applicationName;
            //         let txtp: string = edPkg.projectName;
            //         clist.push(new Crumb('Projects', "projectList", null, true));
            //         clist.push(new Crumb(txtp, "projectApplications", {projectId:edPkg.projectId}, false));
            //         clist.push(new Crumb(txta, "applicationView", {projectId:edPkg.projectId, applicationId:edPkg.applicationId}, false));
            //         clist.push(new Crumb(txt, null, {projectId: edPkg.projectId}, true));
            //     }
            //     break;
            // case Constants.ROUTE_PROJECTAPPLICATIONLANDING_MAIN:
            //     if (app && prj) {
            //         let txt = (app.friendlyName) ? app.friendlyName : app.name;
            //         if (app.importedTransforms)
            //             txt += " (imported with " + app.importedTransforms + ")";
            //         clist.push(new Crumb(txt, "applicationView", {projectId:prj.id, applicationId:app.id}, false));
            //     }
            //     break;
             case Constants.ROUTE_PROJECTREPORTAG_MAIN:
                if (assGroup && prj)
                     clist.push(Crumb.createLink(assGroup.name, [Constants.ROUTE_PROJECTDETAILS_MAIN, prj.id, Constants.ROUTE_PROJECTREPORTS, Constants.ROUTE_PROJECTREPORTAG_MAIN, assGroup.id]));
                break;
            case Constants.ROUTE_PROJECTREPORTCHECKDETAIL_MAIN:
                if (assGroup && prj && state.param) {
                    var chks = assGroup.checks.filter(x=>x.checkId == state.param);
                    if (chks.length>0) {
                        var c0 =chks[0];
                        clist.push(Crumb.createLink(c0.name, []));
                    }
                }
                break;
        }
    }
    
}




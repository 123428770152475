<div fxLayout="row" style="max-width:1800px">

    <div fxFlex fxLayout="column" *ngIf="projectIsReady && projectVhdsReady">
        <div fxFlex="none">
            <h2>Conversion VHDs</h2>
        </div>
        <div fxFlex="none">
            <task-disk-selector [(ngModel)]="project.virtualisationVhd" 
                label="App-V Conversion VHD" (selectionChange)="vhdChange(2,null)"></task-disk-selector>
        </div>
        <div fxFlex="none">
            <task-disk-selector [(ngModel)]="project.msixVhd" 
                label="MSIX Conversion VHD" (selectionChange)="vhdChange(20,null)"></task-disk-selector>
        </div>
        <div fxFlex="none">
            <task-disk-selector [(ngModel)]="project.liquidwareVhd" 
                label="Liquidware Conversion VHD" (selectionChange)="vhdChange(25,null)"></task-disk-selector>
        </div>
        <div fxFlex="none">
            <task-disk-selector [(ngModel)]="project.repackagingVhd" 
                label="Repackaging VHD" (selectionChange)="vhdChange(1,null)"></task-disk-selector>
        </div>
        <div fxFlex="none">
            <task-disk-selector [(ngModel)]="project.defaultTestingVhd" 
                label="Testing VHD" (selectionChange)="vhdChange(1003,null)"></task-disk-selector>
        </div>
        <div fxFlex="none">
            <task-disk-selector [(ngModel)]="project.msixTestingVhd" 
                label="Msix Testing VHD" (selectionChange)="vhdChange(1003,20)"></task-disk-selector>
        </div>
        
        <div fxFlex="none" class="full-width">
            <input-edit datatype="STRING" label="VHD Usernames" [value]="project.vhdUsernameMap" 
                (on-change)="vhdUsernameMapChanged($event)" [disable]="!canEdit()"></input-edit>
        </div>

    </div>

</div>
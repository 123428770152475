import { Injector } from '@angular/core';
import { first } from 'rxjs/operators';
import { MenuService } from '../svc/menuService';
import { UtilService } from '../svc/utilService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';
import { DialogService } from '../svc/dialogService';
import { CurrentApplicationService } from '../svc/currentApplicationService';
import { CurrentProjectService } from '../svc/currentProjectService';

export class ApplyFixesCommand {

    constructor(
        private injector: Injector
    ) {
        this.menuService = this.injector.get(MenuService);
        this.utilService = this.injector.get(UtilService);
        this.dialogService = this.injector.get(DialogService);
        this.currentApplication = this.injector.get(CurrentApplicationService);
        this.currentProject = this.injector.get(CurrentProjectService);

        this.menuService.register(Constants.MENU_FIX, this.command, "*"+Constants.ROUTE_PROJECTAPPLICATIONLANDING_MAIN )
    }

    private menuService: MenuService;
    private currentApplication: CurrentApplicationService;
    private currentProject: CurrentProjectService;
    private dialogService: DialogService;
    private utilService: UtilService;

    private command: AppCommand = AppCommand.createWithShow(
        ()=> {
            return this.canFix();
        },
        ()=> {
            this.applyFixCommand();
        }, 
        ()=> { 
            return this.showFixCommand();
         }

    );

    private canFix(): boolean {
        if (!this.currentApplication.dataContext.isReady || this.currentApplication.isFixInProgress()) {
            return false;
        }
        var ilh = this.currentApplication.getIssuesDirect();
        if (!ilh)
            return false;   
        if (!ilh.issuesForCurrentPlatform.some(x=>x.isSelected && x.custom2.indexOf('a')==0))  // At least one with auto fix
            return false;
        return ilh.issuesForCurrentPlatform.some(x=>x.isSelected);
    }

    private showFixCommand() : boolean {
        return this.utilService.privileges && this.utilService.privileges.canFix;
    }

    private applyFixCommand() : void {

        let platform: number = this.currentProject.getCurrentPlatformInfo().id;

        this.currentApplication.getIssues(platform).then((ilHelper)=>{

            let selectedIssues: Array<string> = 
                ilHelper.issuesForCurrentPlatform.filter(x=>x.isSelected && x.custom2.indexOf('a')==0).map(x=>x.id);
            let toConfirm=false;
            let showFixed = this.utilService.localStorageHelper.fixedIssuesVisible;
            if (!showFixed) {
                let previouslyFixed = ilHelper.fixedIssues;
                if (previouslyFixed.length > 0) {
                    toConfirm=true;
                    this.dialogService.showYesNoMessage("Apply Fixes", [ "Fixes have been applied to this application previously. Do you want to apply the previously applied fixed issues as well?" ])
                    .pipe(first())
                    .subscribe((ans)=> {
                        if (ans)
                            previouslyFixed.forEach((o)=>selectedIssues.push(o));
                        this.currentApplication.applyFixes(platform, selectedIssues);
                    });
                }
            }
            if (!toConfirm)
                this.currentApplication.applyFixes(platform, selectedIssues);
            });
    }
}
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { Constants } from './../../api/Constants';
import { StateHelperService } from 'src/app/svc/stateHelperService';
import * as CRS from '../../api/CRS';

@Component({
  selector: Constants.ROUTE_PROJECTQUERIES,
  templateUrl: './project-queries.html'
})
export class ProjectQueries implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private currentProject:CurrentProjectService,
    private stateHelperService: StateHelperService
  ) { }

  ngOnInit(): void {
    this.checkRedirect();
  }

  public isFullWidth:boolean;

  public get project(): CRS.RSGetProject {
    return this.currentProject.dataItem;
  }

  public isLoaded() : boolean {  
    get: {
        return this.currentProject.dataContext.isReady;
    }
  }

  private checkRedirect() {
    if (this.currentProject.dataContext.isReady && this.stateHelperService.details.isStatePathCurrent(Constants.ROUTE_PROJECTQUERIES)) {
      var qryRoute = localStorage.getItem(Constants.LOCALSET_LAST_QUERY_ROUTE) || Constants.ROUTE_PROJECTQUERIESSTANDARDS;
      this.router.navigate([qryRoute], {relativeTo: this.route});
    }
  }

}

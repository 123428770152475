<div fxLayout="row" class="sw-dialog-header">
    <div fxFlex>
        <h2 mat-dialog-title>{{data.title}}</h2>
    </div>  
</div>  

<mat-dialog-content>
    <p *ngFor='let text of data.texts' ng-bind-html="text" style="width:800px;height:100px">{{text}}</p>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row">
    <div fxFlex></div>
    <div fxFlex="none">
        <button mat-button (click)='cancelDialog()'>
            <mat-icon>cancel</mat-icon>
            <span>Close</span>
        </button>
    </div>
</mat-dialog-actions>

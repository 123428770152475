import { Column, ColumnEditor, Editor, EditorValidator, EditorValidatorOutput, KeyCode } from 'angular-slickgrid';
import { DLITEMSEC } from '../../model/DLITEMSEC';

// using external non-typed js libraries
declare var $: any;

/*
 * An example of a 'detached' editor.
 * KeyDown events are also handled to provide handling for Tab, Shift-Tab, Esc and Ctrl-Enter.
 */
export class SecondariesInputEditor implements Editor {
  private _lastInputEvent: KeyboardEvent;
  $input: any;
  defaultValue: any;

  constructor(private args: any) {
    this.init(args);
  }

  /** Get Column Definition object */
  get columnDef(): Column {
    return this.args && this.args.column || {};
  }

  /** Get Column Editor object */
  get columnEditor(): ColumnEditor {
    return this.columnDef && this.columnDef.internalColumnEditor || {};
  }

  get hasAutoCommitEdit() {
    return this.args.grid.getOptions().autoCommitEdit;
  }

  /** Get the Validator function, can be passed in Editor property or Column Definition */
  get validator(): EditorValidator {
    return this.columnEditor.validator || this.columnDef.validator;
  }

  private $wrapper: any= null;
  private $tableHtml: any=null;
  private item:any=null;

  init(args:any): void {
    const placeholder = this.columnEditor && this.columnEditor.placeholder || '';
    const title = this.columnEditor && this.columnEditor.title || '';

    // this.$input = $(divWithSelect)
    //   .appendTo($container)
    //   .on('keydown.nav', (event: KeyboardEvent) => {
    //     this._lastInputEvent = event;
    //     if (event.keyCode === KeyCode.LEFT || event.keyCode === KeyCode.RIGHT) {
    //       event.stopImmediatePropagation();
    //     }
    //   });
    // this.$input.focus();

    this.item = args.item;

    var $container = $("body");
    this.$wrapper = $("<DIV style='height:100px;overflow:auto;z-index:10000;position:absolute;background-color:white;padding:5px;border:3px solid gray'/>")
      .appendTo($container);

    this.$tableHtml = $("<TABLE style='width:100%;height:100%'/>").appendTo(this.$wrapper);
    this.args.item.secondaries.forEach((v,i) => {
        var $tr = $("<TR/>").appendTo(this.$tableHtml);
        var $td = $("<TD style='width:30px'></TD>").appendTo($tr);
        $td.on("click", (s)=> this.updateRowState(s.currentTarget));
        var $i = $("<INPUT type='checkbox'>").appendTo($td);
        var $td2 = $(`<TD></TD>`).appendTo($tr);
        var $td3 = $(`<TD><button><img src='/img/misc/upArrow.png'></img></button></TD>`).appendTo($tr);
        $td3.children("button").on("click", (s)=>this.moveUp(s.currentTarget));
    });
    this.refreshItems();

    this.$input = this.$tableHtml;
    this.$wrapper
      .css("top", args.position.top + 35)
      .css("left", args.position.left )
      .css("width", args.container.offsetWidth - 15)

      // the lib does not get the focus out event for some reason
    // so register it here
    if (this.hasAutoCommitEdit) {
      this.$input.on('focusout', () => this.save());
    }

    setTimeout(() => {
      this.$input.focus().select();
    }, 50);
  }

  moveUp(currentTarget:HTMLElement) {
    var rowIndex = (<HTMLTableRowElement>currentTarget.parentElement.parentElement).rowIndex;
    this.item.moveUp(rowIndex);
    this.refreshItems();
  }

  updateRowState(currentTarget:HTMLElement) {
    var rowIndex = (<HTMLTableRowElement>currentTarget.parentElement).rowIndex;
    var si = this.item.secondaries[rowIndex];
    var input = <HTMLInputElement>currentTarget.childNodes[0]; //input
    if (input.checked) 
      this.item.addSecondary(si);
    else 
      this.item.removeSecondary(si);
    this.refreshItems();
  }

  refreshItems() {
    var before=false;
    this.args.item.secondaries.forEach((v,i) => {
      var $tr = $(this.$tableHtml.children("tr").get(i));
      var $tds = $tr.children("td");
      var $td1 = $($tds.get(0));
      var $td2 = $($tds.get(1));
      var $td3 = $($tds.get(2));
      var $btn = $($td3.children("button"));
      var $cbx = $(<HTMLInputElement>$td1.children("input"));
      var isSelected= this.args.item.isSelected(v);
      if ($td2.text() != v.hash)
        $td2.text(v.hash);
      $cbx.prop("checked",isSelected);
      $btn.css("visibility", isSelected && before ? "visible" : "hidden" );
      before ||= isSelected;
    });
  }

  destroy() {
    this.$input.off('keydown.nav').remove();
    if (this.$wrapper)
      this.$wrapper.remove();
  }

  focus() {
    this.$input.focus();
  }

  getValue() {
    return this.$input.val();
  }

  setValue(val: string) {
    this.$input.val(val);
  }

  loadValue(item: any) {
    var before=false;
    this.defaultValue = item[this.args.column.field] || '';
    this.item.selectedSecondaries.forEach((v,i)=>{
      var idx = this.item.getIndex(v);
      var tr = this.$tableHtml.children().get(idx);
      var cbx = tr.getElementsByTagName('input')[0];
      cbx.checked = true;
      var tds = tr.getElementsByTagName("td")
      var $td3 = $(tds[tds.length-1]) ;
      $td3.css("visibility", (before) ? "visible": "hidden");
      before=true;
     });

    // this.$input.val(this.defaultValue);
    // this.$input[0].defaultValue = this.defaultValue;
    // this.$input.select();
  }

  serializeValue() {
    return this.$input.val();
  }

  applyValue(item: any, state: any) {
    const validation = this.validate(state);
    item[this.args.column.field] = (validation && validation.valid) ? state : '';
  }

  isValueChanged() {
    const lastEvent = this._lastInputEvent && this._lastInputEvent.keyCode;
    if (this.columnEditor && this.columnEditor.alwaysSaveOnEnterKey && lastEvent === KeyCode.ENTER) {
      return true;
    }
    return (!(this.$input.val() === '' && this.defaultValue === null)) && (this.$input.val() !== this.defaultValue);
  }

  save() {
    const validation = this.validate();
    if (validation && validation.valid) {
      if (this.hasAutoCommitEdit) {
        this.args.grid.getEditorLock().commitCurrentEdit();
      } else {
        this.args.commitChanges();
      }
    }
  }

  validate(inputValue?: any): EditorValidatorOutput {
    if (this.validator) {
      const value = (inputValue !== undefined) ? inputValue : this.$input && this.$input.val && this.$input.val();
      return this.validator(value, this.args);
    }

    return {
      valid: true,
      msg: null
    };
  }
}

import { Component, Input} from '@angular/core';
import { take} from 'rxjs/operators';
import { CurrentConversionJobService } from '../../svc/currentConversionJobService';
import { DialogService } from '../../svc/dialogService';
import * as CRS from '../../api/CRS';

@Component({
    selector: "process-list",
    templateUrl: './process-list.html',
    styleUrls: ['./process-list.scss']
})
export class ProcessList {

    constructor(
        private currentConversionJobService: CurrentConversionJobService,
        private dialogService: DialogService
    ) {
    }

    @Input('items') public items:Array<CRS.RSProcess>;
    @Input('killAllowed') public killAllowed:boolean;

    public currentItem: CRS.RSProcess = null;

    public get itemCountStr() : string {
        if (!this.items)
            return null;
        if(this.items.length == 0) 
            return `(1 process)`;
        else
            return `(${this.items.length} processes)`;
    }

    public setCurrent(item: CRS.RSProcess){
        this.currentItem = item;
    }

    public kill(process: CRS.RSProcess) : void {
        this.dialogService.showYesNoMessage("Kill Process", ["Are you sure that you want to stop this process?"])
        .pipe(take(1))
        .subscribe((ans)=>{
            if (ans) {
                this.currentConversionJobService.killRemoteProcess(process.processId, process.commandLine).then((r)=> {
                     //this.$rootScope.$broadcast(Constants.EVENT_PROJECTDETAILSUPDATEREQUEST, {fullRefresh:true});
                });
            }
        });    
  
    }
}
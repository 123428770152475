import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, timer} from 'rxjs';
import { first} from 'rxjs/operators';
import { UtilService } from './svc/utilService';
import { UserCacheService } from './svc/userCacheService';
import { AppUploadService } from './svc/appUploadService';
import { Constants } from './api/Constants';
import { AuthService } from './svc/authService';
import { CurrentApplicationService } from './svc/currentApplicationService';
import { CurrentProjectService } from './svc/currentProjectService';
import { CurrentConversionJobService } from './svc/currentConversionJobService';
import { AboutCommand } from './commands/about';
import { HelpCommand } from './commands/help';
import { StylingService } from './svc/stylingService';
import { StateHelperService } from './svc/stateHelperService';
import { NotifyItem } from './model/NotifyItem';
import { DriveScanService } from './svc/driveScanService';

import * as CRS  from './api/CRS';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss', './shared/css/buttons.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  isIframe = false;
  constructor(
    private injector: Injector,
    private router:Router,
    private currentApplication: CurrentApplicationService,
    private currentProject: CurrentProjectService,
    private currentConversionJob: CurrentConversionJobService,
    private stylingService : StylingService,
    private driveScanService: DriveScanService,
    private stateHelperService: StateHelperService,
    public userCacheService: UserCacheService,
    public utilService: UtilService,
    public authService: AuthService,
    public appUploadService: AppUploadService,

  ) {}

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;

    this.stylingService.onLoaded().pipe(first()).subscribe((data)=>{
      this.userCheck();
    });
    this.stylingService.ensureLoaded();
  }

  private userCheck() {

    if (this.authService.isLoggedIn) {
      this.userCacheService.ensureUser();
    }
    else {
      this.authService.onLoggedIn().subscribe((state)=> {
        this.userCacheService.ensureUser();
      });
    }
    this.userCacheService.onUserLoaded().subscribe(u=> {
      this.user=u;
    });

    // Need to update uploader URL after styling has given us correct url
    this.appUploadService.refresh();

    this.currentProject.dataContext.onLoaded().subscribe(()=> {
      this.refreshToasts();
    });
    this.currentProject.dataContext.onRefreshed().subscribe(()=> {
      this.refreshToasts();
    });

    this.authService.onTimedOut().subscribe((state)=>{
      this.router.navigateByUrl("/");
    });

  }

  ngOnDestroy(): void {
  }

  goHome() {
    this.router.navigateByUrl("/");
  }

  public isStylingReady() : boolean {
    return this.stylingService.isLoaded();
  }

  getHeaderImage() : string {
    if (this.stylingService.hasCustomHeaderImage())
      return this.stylingService.getCustomHeaderImageData();
    else
      return "img/header/logoRDark.png";
  }

  getHeaderImageClass(): string {
    return (this.stylingService.hasCustomHeaderImage()) ? null :"header-logo-img";
  }

  getHeaderBgColor(): string {
    return this.stylingService.getCustomHeaderBgColor();
  }

  getHeaderFgColor(): string {
    return this.stylingService.getCustomHeaderFgColor();
  }

  showHelp() {
    var ac = new HelpCommand(this.injector);
    ac.command.execute();
  }

  showAbout() {
    var ac = new AboutCommand(this.injector);
    ac.command.execute();
  }

  goAdmin() {
    this.router.navigate([Constants.ROUTE_ADMINDASHBOARD])
  }

  public isAdminUser(): boolean {
    return this.utilService.privileges?.admin;
  };

  public goErr() {
    this.router.navigateByUrl("xxx");
  }

  public isReady() : boolean {
    return this.user !=null;
  }

  public user : CRS.RSGetUserInformation=null;

  public get projectImageUrl() : string {
    var ui = this.userCacheService.GetUserInfo();
    if (ui) {
      var url = this.currentProject.getProjectImageUrl(ui.contextId);
      return url;
    }
    return null;
  }

  public get canShowRemotingUrlLink(): boolean {
    if (!this.currentConversionJob.isInProgress())
      return false;
    if (this.currentConversionJob.isCancelling())
      return false;
    if (!this.currentConversionJob.getRemotingMachineUrlRaw())
      return false;
    if (this.utilService.privileges?.remotingUrl)
      return true;

    var tsk = this.currentConversionJob.dataItem.task;
    return tsk.canRelease && tsk.remotingUrl && this.utilService.privileges?.copyVmUrl;
  }

  public get uiDetected(): boolean {
    if (!this.currentConversionJob.dataContext.isReady)
      return false;
    if (!this.currentConversionJob.isInProgress())
      return false;
    if (!this.currentConversionJob.isCancelling())
      return false;
    return this.currentConversionJob.uiDetected();
  }

  public copyRemotingUrlToClipboard() : void {
    var $temp_input = $("<input>");
    $("body").append($temp_input);
    $temp_input.val(this.currentConversionJob.getRemotingMachineUrlRaw()).select();
    document.execCommand("copy");
    $temp_input.remove();
  }

  public get currentAppSourceMissing(): boolean {
    if (!this.currentApplication.dataContext.isReady)
      return false;
    return this.currentApplication.dataItem?.sourceAvailabilityState == Constants.SOURCE_AVAILABILITY_MISSING;
  }

  public get currentAppSourceUndefined(): boolean {
    if (!this.currentApplication.dataContext.isReady)
      return false;
    return this.currentApplication.dataItem?.sourceAvailabilityState == Constants.SOURCE_AVAILABILITY_NOTDEFINED;
  }

  public get currentJobVerified(): boolean {
    if (!this.currentConversionJob.dataContext.isReady)
      return false;
    if (!this.currentConversionJob.isInProgress())
      return false;
    var j = this.currentConversionJob.dataItem;
    return j&&j.task&&j.task.isExecutingVerified;
  }

  public currentNotification: NotifyItem;

  public timerSource : Observable<number>= timer(5000,5000);
  public timerSourceSubscription: Subscription = this.timerSource.subscribe(()=>this.notificationRefresh());
  public _notificationQueue: Array<NotifyItem> = [];

  // This runs every 5s and manages the current notification to displayed in the footer. There is a 3s delay
  // between each one becoming active. Fifo.
  public endTime:Date =null;
  private notificationRefresh() {
    
    var now = new Date();
    if (this.endTime == null || this.endTime < now) {
      if (this.currentNotification == null) {
        this.currentNotification = this._notificationQueue.shift();
        if (this.currentNotification==null) {
          this.endTime = null;
        }
        else {
          this.endTime = new Date();
          this.endTime.setSeconds(this.endTime.getSeconds() + 10);
        }
      }
      else { // Give it 3 seconds before taking next one
        this.currentNotification = null;
        this.endTime = new Date();
        this.endTime.setSeconds(this.endTime.getSeconds() + 3);
      }
    };
  }

  public getNotClass() {
    return (this.currentNotification!=null) ? "slide-up" : null;
  }

  public get currentProjectOwner(): string {
    if (this.currentProject.dataContext.isReady && !this.currentProject.dataContext.isInError && !this.currentProject.dataItem.isOwner) 
      return this.currentProject.dataItem.owner;
    return null;
  }

  public get currentProjectLastScanned() {
    if (this.stateHelperService.details.statePathFind(Constants.ROUTE_PROJECTDRIVES)) {
      if (this.stateHelperService.details.statePathCurrent) {
        if (this.driveScanService.isDataReady && this.driveScanService.lastStartedExpression) {
          return "Last scanned " + this.driveScanService.lastStartedExpression;
        }
      }
    }
    return null;
  }

  private refreshToasts() {
    var notifications: Array<NotifyItem> = this.currentProject.getNotifications();
    if (notifications) {
      notifications.forEach(x=>this._notificationQueue.push(x));

      //var notstr = notifications.map(x=>x.text);
      //this.snackBar.openFromComponent(SnackBarComponent, { duration: 5000, data: notstr, verticalPosition: 'bottom' });
    }
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { PlatformService } from '../../svc/platformService'

import * as CRS from '../../api/CRS';

@Component({
    selector: "rag-distribution",
    templateUrl: './rag-distribution.html',
    styleUrls: ['./rag-distribution.scss']
})
export class RagDistribution {

    @Input('rag') rag : CRS.RSRAGElement;
    @Input('enabled') enabled: boolean;

    public ragValues: Array<number> = [ 0,50,100,200 ];

    constructor(
        private platformService: PlatformService,
        private currentProject: CurrentProjectService
    ) {


    }

    public getCssName(r: number) : string {
        if (!this.enabled)
            return "noAssess";
        var pfx = "sw-rag-dist-";
        switch(r)
        {
            case 0:
                return pfx+"green";
            case 50:
                return pfx+"blue";
            case 100:
                return pfx+"amber";
            case 200:
                return pfx+"red";
            case 995:
                return pfx+"yellow";
            case 999:
                return pfx+"grey";
        }    
    }

    public isEmpty(r:number): boolean {
       if (!this.rag)
            return true;
        let prjId = this.currentProject.dataItem.id;
        let rag : CRS.RSRAG= this.rag.assessmentStatesDictionary[this.platformService.getCurrent(prjId)];
        return this.getStatePercentage(r,rag) < 1;
    }

    public getWidthExpression(r :number)  : string {

        var val=0;
        if (this.rag){
            let prjId = this.currentProject.dataItem.id;
            let ret : CRS.RSRAG= this.rag.assessmentStatesDictionary[this.platformService.getCurrent(prjId)];
            val =this.getStatePercentage(r,ret);
        }
        return (val==0) ? "0px" : val + "%";
    }

    public getAffectedCount(r: number) : string {
        let prjId = this.currentProject.dataItem.id;
        let rag : CRS.RSRAG= this.rag.assessmentStatesDictionary[this.platformService.getCurrent(prjId)];
        var cnt = this.getAppCount(r,rag);
        return String(cnt) + " applications affected";
    }

    private getStatePercentage(state:number, rag: CRS.RSRAG) : number {
        if (rag) {
            var total = rag.greenAppCount + rag.blueAppCount +  rag.amberAppCount + rag.redAppCount;
            var appCount = this.getAppCount(state, rag);
            if (appCount > 0)
                return Math.floor((appCount / total)*100);
        }
        return 0;
    }

    private getAppCount(state:number, rag: CRS.RSRAG) : number {
        if (rag) {
            switch(state)
            {
                case 0:
                    return rag.greenAppCount;
                case 50:
                    return rag.blueAppCount ;
                case 100:
                    return rag.amberAppCount;
                case 200:
                    return rag.redAppCount;
            }
        }
        return 0;
    }

}
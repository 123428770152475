<div fxLayout="row" fxFill class='full-height-bs'>

    <div [fxFlex]="stateHelperInstance.isNotCurrent() ? '0 0 250px' : ''" class="sw-segment">

        <div>
            <table class="sw-table-collapsable" [ngClass]='{"sw-viewcontainer-collapsed": stateHelperInstance.isNotCurrent()}'>
                <thead>
                    <tr>
                        <td>Remediation Id</td>
                        <td class='sw-table-collapsablecolumn'>Product Family</td>
                        <td class='sw-table-collapsablecolumn'>Platform Elements</td>
                        <td class='sw-table-collapsablecolumn'>Remediation Url</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let cc of patchGroupItem?.checkCandidateGroups" 
                    [ngClass]='{"sw-table-activerow": classReference.selectedKB == cc?.remediationId}'
                    (click)="openKB(cc)">
                        <td>{{cc?.remediationId}}</td>
                        <td class='sw-table-collapsablecolumn'>{{cc?.productFamily}}</td>
                        <td class='sw-table-collapsablecolumn'>{{cc?.platformElements}}</td>
                        <td class='sw-table-collapsablecolumn'>{{cc?.remediationUrl}}</td>
                    </tr>
                </tbody>
            </table>
        </div>        

    </div>  

    <div fxFlex *ngIf='stateHelperInstance.isNotCurrent()'>
        <router-outlet></router-outlet>
    </div>
    
</div>
import { Component, Input} from '@angular/core';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { AppNote } from '../../model/AppNote';

@Component({
    selector: "notes-editor",
    templateUrl: './notes-editor.html',
})
export class NotesEditor {

    constructor(
        private currentProject: CurrentProjectService
    ) {
    }

    @Input('items') public items:Array<AppNote>;

    public isDataReady(): boolean {
        return this.currentProject.dataContext.isReady;
    }

    public add() {
        this.currentProject.notes.add();
    }

    public apply() {
        this.currentProject.notes.apply();
    }

    public cancel() {
        this.currentProject.notes.reset();
    }

    public isEmpty(index:number) {
        return this.currentProject.notes.isEmpty(index);
    }

    public remove(index:number) {
        this.currentProject.notes.remove(index);
    }

    public markDirty(): void {
        this.currentProject.notes.markDirty();
    }

    public isValid() : boolean {
        return this.currentProject.notes.isValid();
    }

    public isDirty(): boolean {
        return this.currentProject.notes.isDirty;
    }

    public canEdit(): boolean {
        return this.currentProject.canEdit();
    }

}
import { Injector } from '@angular/core';
import { UtilService } from '../svc/utilService';
import { CurrentApplicationService } from '../svc/currentApplicationService';
import { CurrentConversionJobService } from '../svc/currentConversionJobService';
import { SafeResourceUrl } from '@angular/platform-browser';
import { AppCommand } from '../model/AppCommand';
import { Constants } from '../api/Constants';
import * as CRS from '../api/CRS';

export class RemotingWindowCommand {

    constructor(
        private injector: Injector
    ) {
        this.utilService = this.injector.get(UtilService);
        this.currentApplication = this.injector.get(CurrentApplicationService);
        this.currentJob = this.injector.get(CurrentConversionJobService);
    }

    private utilService: UtilService;
    private currentApplication : CurrentApplicationService;
    private currentJob: CurrentConversionJobService;
    
    public get job(): CRS.RSGetActionDetails {
        return this.currentJob.dataItem;
    }

    private _remotingUrl:SafeResourceUrl=null;
    public get jobRemotingUrl(): SafeResourceUrl {
        if (this._remotingUrl==null)
            this._remotingUrl= this.currentJob.getRemotingMachineUrl();
        else if (this._remotingUrl && !this.currentJob.getRemotingMachineUrl()) // no longer available
            this._remotingUrl=undefined;
        return this._remotingUrl;
    }

    public cmd: AppCommand = AppCommand.createWithShow(
        ()=> {
            return false;
        },
        ()=> {
        } ,
        ()=> {         
            if (!this.job || !this.job.task)
                return false;
            if (this.job.task.typeApproach == Constants.TESTINSTALL_SUBTYPE_RUNTIME && !this.job.task.uiDetected)
                return false;
            return this.job.task.canShowRemoteWindow && this.job.task.embedRemoteWindow;
        }
    );

}
import { Component } from '@angular/core';

@Component({
  selector: 'project-application-qa',
  templateUrl: './project-application-qa.html',
  styleUrls: [ ]
})
export class ProjectApplicationQa {

  constructor(
  ) { }

}

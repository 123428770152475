<div fxLayout="row" class="sw-dialog-header">
    <div fxFlex>
        <h2 mat-dialog-title>{{data.title}}</h2>
    </div>  
</div>  

<mat-dialog-content>
    <p *ngFor='let text of data.texts' ng-bind-html="text" style="width:600px;height:100px">{{text}}</p>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row">
    <div fxFlex></div>
    <div fxFlex="none">
        <button mat-button color="primary" (click)='closeDialog(true)'>
            <mat-icon>check</mat-icon>
            <span>Yes</span>
        </button>
        <button mat-button (click)='closeDialog(false)'>
            <mat-icon>close</mat-icon>
            <span>No</span>
        </button>
        <button mat-button (click)='cancelDialog()' *ngIf="data.showCancel">
            <mat-icon>cancel</mat-icon>
            <span>Cancel</span>
        </button>
    </div>
</mat-dialog-actions>

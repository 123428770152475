<div fxLayout="row wrap" *ngIf="task && task.retainedFiles">
    <div fxFlex="25" *ngFor="let rf of task.retainedFiles">
        <button mat-button (click)="openRetainedLog($event, rf)">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <div fxFlex="none" class="sw-pad-top">
                    <mat-icon class="siz-150">library_books</mat-icon>
                </div>
                <div fxFlex [ngSwitch]="rf.name">
                    <span>{{rf.description}}</span>
                </div>
            </div>
        </button>
    </div>
</div>
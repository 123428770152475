<div fxLayout="column" id="control_root">

    <div fxFlex="none" fxLayout="row">
        <div fxFlex></div>
        <div fxFlex="none">
            <a mat-icon-button href="" target="_blank" (click)="close($event)" id="close-button">
                <mat-icon>close</mat-icon>
            </a>
        </div>
    </div>
    
    <div fxFlex *ngFor="let p of items;let i = index">
         <div class="sw-pad" style="min-width:300px"  *ngIf="p.data[0]>0">
            <div fxLayout="row" style="height:200px" [ngClass]="{'chart-box':i<items.length-1}">
                <div fxFlex>
                    <canvas id="pie{{i}}" class="chart chart-bar full-height-bs full-width-bs"></canvas>
                </div>  
            </div>  
        </div> 
    </div> 
    
</div>

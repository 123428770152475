import { Injector } from '@angular/core';
import { MenuService } from '../svc/menuService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';
import { MatDialog } from '@angular/material/dialog';
import { ApplicationBrowseSummaryInfoDialog } from 'src/app/dialogs/application-browse-summary-info/application-browse-summary-info-dialog';

export class ViewPackageSummaryInformationCommand {

    constructor(
        private injector: Injector
    ) {
        this.menuService = this.injector.get(MenuService);
        this.menuService.register(Constants.MENU_PACKAGE_VIEW_SUMMARY_INFORMATION, this.cmd, "*"+Constants.ROUTE_PROJECTAPPLICATIONBROWSE);
    }

    private menuService: MenuService;
    private dialog: MatDialog;

    public cmd: AppCommand = AppCommand.create(
        ()=> {
            return true;
        },
        ()=> {
            this.dialog = this.injector.get(MatDialog);
            var dialogRef = this.dialog.open(ApplicationBrowseSummaryInfoDialog, { width : '1000px', height:'670px'});
                dialogRef.afterClosed().subscribe((ret)=> {    
            });
        }
    );

}
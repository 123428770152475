import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { CurrentApplicationService } from '../../svc/currentApplicationService';
import { UtilService } from '../../svc/utilService';
import { Constants } from '../../api/Constants';

import * as CRS from '../../api/CRS';

@Component({
  selector: 'project-application-documentation',
  templateUrl: './project-application-documentation.html',
})
export class ProjectApplicationDocumentation implements OnInit, OnDestroy {

  constructor(
      private currentProject: CurrentProjectService,
      private currentApplication: CurrentApplicationService, 
      private utilService: UtilService,
  ) { }

  public documentationFiles: CRS.RSGetApplicationDocumentationFiles=null;

  private subscriptions : Array<Subscription> = new Array<Subscription>();

  ngOnInit(): void {
    this.loadData();
    this.subscriptions.push( this.currentProject.dataContext.onLoaded().subscribe(()=> {
      this.loadData();
    }));
    this.subscriptions.push(this.currentApplication.dataContext.onLoaded().subscribe(()=> {
      this.loadData();
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x=>x.unsubscribe());
  }

  public isDataReady() : boolean {
    return this.currentProject.dataContext.isReady && this.currentApplication.dataContext.isReady && this._dataLoaded;
  }

  public hasSome(): boolean {
    return this.documentationFiles && this.documentationFiles.items.length>0;
  }

  public downloadDocumentationFile($event: Event, file: CRS.RSDocumentationFile) : boolean {
    $event.stopPropagation();
    let url = this.getDocUrl(file);
    window.open(url,"_blank");
    return false;
  }

  public getDocUrl(file: CRS.RSDocumentationFile) : string {
    return this.utilService.getAPIUrl( 
     "Application/DownloadApplicationDocumentation?ApplicationId=" + this.currentApplication.dataContext.id + "&Id=" + file.key
        + "&serverId=" + this.utilService.serverId); 
  }

  private _dataLoaded:boolean=false;
  private loadData() {
    if (this._dataLoaded)
      return;
    this.currentApplication.getDocumentationFiles().then((data)=> {
        this.documentationFiles=data;
        this._dataLoaded=true;
    });
  }
}

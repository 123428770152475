import { Component, Input, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { UtilService } from '../../svc/utilService';
import { StateHelperService } from '../../svc/stateHelperService';

import * as CRS from '../../api/CRS';

@Component({
  selector: 'xcheck-output-com',
  templateUrl: './xcheck-output-com.html',
})
export class XCheckOutputCom implements OnInit {

  constructor(
      private router:Router, 
      private route:ActivatedRoute, 
      private stateHelperService: StateHelperService,
      private utilService:UtilService) { }

      @Input('item') public item:CRS.RSXCheckResult;

  ngOnInit(): void {
  }

  public hasItems() : boolean {
    return this.item && this.item.complusItems.length > 0;
  }

  public getScreenshot(item: CRS.RSXCheckComplusResult) {
    return `data:image/jpeg;base64,${item.screenshot}`;
  }

}

import {PollingService} from '../../svc/pollingService';
import {NavStateService} from '../../svc/navStateService';
import {AppCommand} from '../../model/AppCommand';
import {MenuService} from '../../svc/menuService';
import { ActivatedRoute, Router } from '@angular/router';
import * as STHS from '../../svc/stateHelperService';

import * as UTIL from '../../svc/utilService';
import * as CRS from '../../api/CRS';
import * as SVC from '../../api/SVC';
import { Component, OnInit } from '@angular/core';
import { Constants } from 'src/app/api/Constants';
import { AdminPatchGroupItem } from '../admin-patch-group-item/admin-patch-group-item';

@Component({
    selector: "admin-patch-kb",
    templateUrl: './admin-patch-kb.html',
    styleUrls: ['../../shared/css/admin.scss', '../../shared/css/view.scss']
})

export class AdminPatchKB implements OnInit {

    public patchgroupId: string = '';
    public kbId: string = '';

    constructor(
        private router: Router, 
        private route: ActivatedRoute, 
        private pollingService: PollingService,
        private xadminService: SVC.AdminService,
        private utilService: UTIL.UtilService,
        private stateHelperService: STHS.StateHelperService,
        private menuService: MenuService
    ) {
        const urlValues = this.router.url.split("/")
        this.patchgroupId = urlValues[5]
        this.kbId = urlValues[urlValues.length-1]
    }

    public stateHelperInstance: STHS.StateHelperInstance;
    public patchKB: CRS.RSAdminGetKB=null;

    ngOnInit() {
        this.stateHelperInstance= this.stateHelperService.register(Constants.ROUTE_PATCHGROUPITEMDETAIL);
        this.update();
        this.menuService.register(Constants.MENU_BACK, this.backCommand, `P-${Constants.ROUTE_PATCHGROUP_MAIN + "/" + this.patchgroupId}`);
    }

    ngAfterContentChecked(){
        const urlValues = this.router.url.split("/")
        if(this.kbId != urlValues[urlValues.length-1]){
            this.kbId = urlValues[urlValues.length-1]
            AdminPatchGroupItem.updateSelectedServer(this.kbId)
         this.xadminService.adminGetKB(this.patchgroupId, this.kbId).then((response) => {
             this.patchKB = response.data;
        }, () => {
             console.error('Patch KB data couldn\'t be loaded');
        });
        }
    }

    private update(): void {
        AdminPatchGroupItem.updateSelectedServer(this.kbId)
         this.xadminService.adminGetKB(this.patchgroupId, this.kbId).then((response) => {
             this.patchKB = response.data;
        }, () => {
             console.error('Patch KB data couldn\'t be loaded');
        });
    }

    private backCommand: AppCommand = AppCommand.create(
        ()=> {
            return true;
        },
        ()=> {
            AdminPatchGroupItem.updateSelectedServer('')
            this.router.navigate(["/" + Constants.ROUTE_ADMINDASHBOARD + "/" + Constants.ROUTE_ADMINPATCHGROUPS + "/" + Constants.ROUTE_PATCHGROUP_MAIN + "/" + this.patchgroupId]);
        }
    )

}

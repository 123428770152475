import { Component, OnDestroy, OnInit } from '@angular/core';
import { of, Subscription } from 'rxjs';
import { UtilService } from '../../svc/utilService';
import { CurrentConversionJobService } from '../../svc/currentConversionJobService';
import { StateHelperService } from '../../svc/stateHelperService';
import { AppCommand } from '../../model/AppCommand';
import { CurrentApplicationService } from '../../svc/currentApplicationService';
import { CommandCollection } from 'src/app/commands/command-collection';

@Component({
  selector: 'conversion-task-edit',
  templateUrl: './conversion-task-edit.html',
})
export class ConversionTaskEdit implements OnInit, OnDestroy {

    constructor(
        public currentConversionJob: CurrentConversionJobService,
        private currentApplication: CurrentApplicationService,
        public stateHelperService: StateHelperService,
        private utilService: UtilService
    ) { 
    }

    private subscriptions : Array<Subscription> = new Array<Subscription>();

    ngOnInit(): void {
        this.refreshMenu();
    }

    ngOnDestroy():void {
        this.subscriptions.forEach(x=>x.unsubscribe());
    }

    public get editCommand(): AppCommand {
        return CommandCollection.EditPackage.cmd;
    }

    private refreshMenu():void {
        CommandCollection.EditPackage.cmd.refresh();
    }

}

import { Component, Input, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { UtilService } from '../../svc/utilService';
import { Constants } from '../../api/Constants';
import { StateHelperService } from '../../svc/stateHelperService';

import * as CRS from '../../api/CRS';

@Component({
  selector: 'xcheck-output-scheduled-tasks',
  templateUrl: './xcheck-output-scheduled-tasks.html',
  styleUrls: ['../xcheck-main/xcheck-output.scss']
})
export class XCheckOutputScheduledTasks implements OnInit {

  constructor(
      private router:Router, 
      private route:ActivatedRoute, 
      private stateHelperService: StateHelperService,
      private utilService:UtilService) { }

  @Input('item') public item:CRS.RSXCheckResult;

  ngOnInit(): void {
  }

  public hasItems() : boolean {
    return this.item && this.item.scheduledTaskItems.length > 0;
  }

  public getScreenshot(item: CRS.RSXCheckScheduledTaskResult) {
    console.log("hello2 ");
    var r = `data:image/jpeg;base64,${item.screenshot}`;
    return r;
  }

}

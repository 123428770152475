import { Injectable } from '@angular/core';
import { first} from 'rxjs/operators';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { CurrentProjectService } from './currentProjectService';
import { AuthService } from './authService';
import { UtilService } from './utilService';


@Injectable({
    providedIn: 'root'
})
export class AppUploadService {

    private AUTH: string = "Authorization";

    public uploader: FileUploader;
    private baseUrl:string;

    constructor(
        private authService: AuthService,
        private utilService: UtilService,
        private currentProject: CurrentProjectService
    ){ 

        this.currentProject.dataContext.onLoaded().subscribe((data)=> {
            this.refresh();
            var idx = this.uploader.options.url.lastIndexOf('?');
            this.uploader.options.url = this.baseUrl + `?projectId=${this.currentProject.dataContext.id}&serverId=${this.utilService.serverId}` ;
        });
    }

    public refresh(){
        this.baseUrl = this.utilService.getAPIUrl( "application/upload");
        var url =  this.baseUrl + `?projectId=${this.currentProject.dataContext.id}&serverId=${this.utilService.serverId}` ;

        this.uploader = new FileUploader(
            {
                url:url,
                headers: [
                    { name: this.AUTH, value: `Bearer ${this.authService.authToken}`},
                ],
                maxFileSize:100 * 1024 * 1024,
                autoUpload:true
            }
        );
        this.uploader.onBeforeUploadItem = (item) => {
            var h0 = this.uploader.options.headers.filter(x=>x.name == "Authorization"); // Cope with token change
            if (h0)
                h0[0].value =`Bearer ${this.authService.authToken}`;
            this.beforeUpload(item);
        }
        this.initialiseUploader();
    }   

    public containsItems() : boolean {
        return this.uploader && this.uploader.queue.length>0;
    }

    public registerApplicationList(items : Array<string>) {
        let toRemove: Array<any> =[];
        this.uploader.queue.forEach((x)=> {
            if (items.indexOf(x.file.name) != -1)
                toRemove.push(x);
        });

        toRemove.forEach((x)=> {   
            x.remove()});
    }

    public containsFailures() : boolean {
        return this.uploader.queue.filter((x)=>x.isCancel).length> 0;
    }

    public clearFailures() : void {
        let toRemove: Array<any> = [];
        this.uploader.queue.forEach((x)=> {
            if (x.isCancel)
                toRemove.push(x);
        })
        toRemove.forEach((x)=> {
            x.remove();
        });
    }

    private uploadSuccess(item, response) {
        let idx =this.uploader.queue.indexOf(item);
        if (idx!=-1) {
            this.uploader.queue.splice(idx, 1);
            console.log("uploaded " + item.file.name);
        }
        if (this.uploader.queue.length == 0)
            this.currentProject.dataContext.refreshCurrent();
    }

    private uploadError(item, response) {
        if (response && response.exceptionMessage)
            item.errorMessage = response.exceptionMessage;
        else
            item.errorMessage = "Upload failed. Unknown reason";
        item.cancel();
        console.log('item');
        console.log(item);
        console.log('response');
        console.log(response);
        console.log(`error for ${item.file.name}`);
    }

    private beforeUpload(item) {
        item.withCredentials=false;
        if (!this.utilService.privileges?.canImportApp) {
            item.errorMessage ="Application imports are currently suspended within this project";
            item.cancel();
        }
    }

    public isCompletedSuccessfully(name: string) : boolean {
        let ret: boolean=false;
        this.uploader.queue.forEach(item => {
            if (item.isSuccess && item.file.name.toLowerCase() === name.toLowerCase()) {
                ret=true;
            }
        });
        return ret;
    }

    private initialiseUploader() {
        this.uploader.onAfterAddingFile = (fileItem: FileItem)=> {
            let isInvalidType:boolean=false;
            let idx:number = fileItem.file.name.lastIndexOf('.');
            if (idx!=-1)
            {
                let ext = fileItem.file.name.substring(idx);
                if (ext.toLowerCase() != ".msi" && ext.toLowerCase() != ".msp" && ext.toLowerCase() != ".exe" && ext.toLowerCase() != ".zip" && ext.toLowerCase() != ".json" && ext.toLowerCase() != ".qcpkg")
                    isInvalidType=true;
            }
            if (isInvalidType) {
                this.uploader.removeFromQueue(fileItem);        
            }
        };

        this.uploader.autoUpload = true;
        this.uploader.onSuccessItem = (item, response) => {
            this.uploadSuccess(item, response);
        }

        this.uploader.onErrorItem = (item, response) => {
            this.uploadError(item, response);
        }


    }
}
    


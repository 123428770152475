<div *ngIf="applicationIsReady && application.companionFiles.length>0" fxLayout="column">
    <h4>Additional Files</h4>
    <div fxFlex="none" *ngIf="application.companionFiles.length>1">
        <mat-checkbox [(ngModel)]="includeAllCompanionFiles" (change)="includeAllCompanionsChanged()">Select All</mat-checkbox>
    </div>
    <div class="sw-conversion-companion-list" fxFlex fxLayout="row">
        <div *ngFor="let cf of application.companionFiles" fxFlex="50">
            <mat-checkbox [checked]="isCompanionFileSelected(cf)" (click)="toggleCompanionFileSelection(cf)">{{cf.filename}}</mat-checkbox>
        </div>
    </div>
</div>

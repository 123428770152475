import { LOCALHASH } from './LOCALHASH';

import * as CRS from '../api/CRS';

export class PlatformDefn {
    public osFrom:string;
    public osTo:string;
    public office:string;
    public virtualisationTechnology:string;
    public browser:string;
    public dotnet:string;
    public monthlyUpdate:string;

    public getLocalHash(platformGuid:string) : LOCALHASH {
        return PlatformDefn.getLocalHashFromIds(
            platformGuid,
            this.osFrom, 
            this.osTo, 
            (this.virtualisationTechnology) ? this.virtualisationTechnology : "NONE",
            (this.browser) ? this.browser : "NONE", 
            (this.office) ? this.office :  "NONE", 
            (this.dotnet) ? this.dotnet : "NONE");
    }

    public static getLocalHashFromIds(id:string, osId: string, osToId: string, virtId: string, brwsId: string, offId: string, dotnetId: string) : LOCALHASH {
        let k :string = `${id}-${osId}-${osToId}-${virtId}-${brwsId}-${offId}-${dotnetId}`;
        let hsh: LOCALHASH = new LOCALHASH(id, k);
        return hsh;
    }

    public static getLocalHashFromPlatform(platform:CRS.RSPlatform) : LOCALHASH {
        if (platform) {
            return PlatformDefn.getLocalHashFromIds(platform.platformGuid, platform.osId, platform.osIdTo, 
                        platform.virtualisationTechnology || "NONE", 
                        platform.browser || "NONE",
                        platform.officeSuite || "NONE", 
                        platform.dotNetVersion || "NONE");
        }
        return null;

    }


}
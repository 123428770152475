import { Component, OnInit, OnDestroy, Input, Injector } from '@angular/core';
import { Router,ActivatedRoute, ActivationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { CurrentApplicationService } from '../../svc/currentApplicationService';
import { Constants } from '../../api/Constants';
import { MenuService } from '../../svc/menuService';
import { StateHelperService } from '../../svc/stateHelperService';
import { UtilService } from 'src/app/svc/utilService';

import * as CRS from '../../api/CRS';

@Component({
  selector: Constants.ROUTE_PROJECTAPPLICATIONLANDING,
  templateUrl: './project-application-landing.html',
  styleUrls:['./project-application-landing.scss', '../../shared/css/view.scss']
})
export class ProjectApplicationLanding implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private utilService: UtilService,
    public currentProject:CurrentProjectService, 
    public currentApplication: CurrentApplicationService,
    public stateHelperService: StateHelperService
  ) { }

  private subscriptions: Array<Subscription> =new Array<Subscription>();

  ngOnInit(): void {
      this.subscriptions.push(this.stateHelperService.onStateChange().subscribe(()=> this.checkRedirect()));
      this.subscriptions.push(this.currentApplication.dataContext.onLoaded().subscribe((data)=> this.checkRedirect()));
      this.checkRedirect();
  }

  ngOnDestroy() : void {
      this.subscriptions.forEach(x=>x.unsubscribe());
  }

  public isDataReady() : boolean {  
    get: {
        return this.currentApplication.dataContext.isReady;
    }
  }

  public isInError(): boolean {
    get: {
      return this.currentApplication.dataContext.isInError;
    }
  }

  public get errorCode(): number {
      return this.currentApplication.dataContext.ErrorCode;
  }

  public get errorReason(): string {
    if (this.currentApplication.dataContext.ErrorCode == Constants.ERR_APPLICATION_NOT_FOUND)
      return "Application not found";
    return this.currentApplication.dataContext.ErrorReason;
  }

  public isDataReadyAndValid() : boolean {  
    get: {
        return this.currentApplication.isLoadedSuccessfully;
    }
  }

  public isDataReadyAndPending() : boolean {  
    get: {
        return this.currentApplication.isLoadedAndPending;
    }
  }
  public isDataReadyAndImporting() : boolean {  
    get: {
      return this.currentApplication.isLoadInProgress;
    }
  }
  
  public isDataReadyWithError() : boolean {  
    get: {
        return this.currentApplication.dataContext.isReady && this.currentApplication.dataItem.status == Constants.STATUS_FAIL;
    }
  }

  public isActive(): boolean {
      return !this.stateHelperService.isAppBrowsing();
  }

  public openAction(action: CRS.RSAction) {

    let parm=action.jobId;
    if (action.action == "X") {
        parm = action.id;
    }
    else if (this.utilService.isEmptyGuid(action.jobId)) {
        let idx=action.id.indexOf(";")
        parm=action.id.substring(idx+1);
    }

    this.utilService.localStorageHelper.setLastApplicationAction(this.currentApplication.dataContext.id, action.baseId);

    this.router.navigate([
        Constants.ROUTE_PROJECTDETAILS_MAIN, 
        this.utilService.compressGuidWithSvr(this.currentApplication.dataItem.projectId),
        Constants.ROUTE_PROJECTAPPLICATIONS,
        Constants.ROUTE_PROJECTAPPLICATIONLANDING_MAIN, 
        this.utilService.compressGuid(this.currentApplication.dataContext.id),
        Constants.ROUTE_PROJECTAPPLICATIONACTIONSDETAIL_MAIN, 
        action.id ]);        
      return;    
  }

  private openBrowse() {
    var route = [
        Constants.ROUTE_PROJECTDETAILS_MAIN, 
        this.utilService.compressGuid(this.currentApplication.dataItem.projectId),
        Constants.ROUTE_PROJECTAPPLICATIONS,
        Constants.ROUTE_PROJECTAPPLICATIONLANDING_MAIN, 
        this.utilService.compressGuid(this.currentApplication.dataItem.id),
        Constants.ROUTE_PROJECTAPPLICATIONBROWSE,
        Constants.ROUTE_PROJECTAPPLICATIONBROWSETABLE_MAIN,
      ];
      route.push(localStorage.getItem(Constants.LOCALSET_LAST_BROWSE_TABLE)??"File");

      this.router.navigate(route);
  }

  private checkRedirect() {
    if (this.currentApplication.dataContext.isReady && this.stateHelperService.details.isStatePathCurrent(Constants.ROUTE_PROJECTAPPLICATIONLANDING_MAIN)) {
      switch(this.currentApplication.dataItem.status){
        case Constants.STATUS_SUCCESS:
          this.successRedirect();
          break;
        case Constants.STATUS_PENDING:
          this.pendingRedirect();
      }
    }
  }
  
  private successRedirect() {
      var actId = this.utilService.localStorageHelper.getLastApplicationAction(this.currentApplication.dataContext.id);
      if (actId==Constants.ACTION_BROWSE && this.currentApplication.browser.canBrowsePackage) {
        this.openBrowse();
      }
      else {
        var la = (actId) ? this.currentApplication.findActionByBaseId(actId) : null;
        if (!la)
          la = this.currentApplication.findActionByAction("O") // should always exist
        if (la)
          this.openAction(la);
      }
  }

  private pendingRedirect() {
    var la = this.currentApplication.findActionByAction("O") // should always exist
    this.openAction(la);
  }

}

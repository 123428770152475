import { Injectable } from '@angular/core';
import { first} from 'rxjs/operators';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { CurrentProjectService } from './currentProjectService';
import { UtilService } from './utilService';
import { AuthService } from './authService';

@Injectable({
    providedIn: 'root'
})
export class CertUploadService {

    public uploader: FileUploader;
    private baseUrl:string;

    constructor(
        private authService: AuthService,
        private utilService: UtilService,
        private currentProject: CurrentProjectService
    ){ 
        this.baseUrl = this.utilService.getAPIUrl( "project/uploadCertificate");
        var url =  this.baseUrl + `?projectId=${this.currentProject.dataContext.id}` ;

        this.uploader = new FileUploader(
            {
                url:url,
                headers: [
                    { name: 'Authorization', value: `Bearer ${this.authService.authToken}`},
                ],
                maxFileSize:100 * 1024 * 1024,
                autoUpload:true
            }
        );

        this.currentProject.dataContext.onLoaded().subscribe((data)=> {
            var idx = this.uploader.options.url.lastIndexOf('?');
            this.uploader.options.url = this.baseUrl + `?projectId=${this.currentProject.dataContext.id}` ;
        });

        this.uploader.onAfterAddingFile = (fileItem: FileItem)=> {
            let isInvalidType:boolean=false;
            let idx:number = fileItem.file.name.lastIndexOf('.');
            if (idx!=-1)
            {
                let ext = fileItem.file.name.substring(idx);
                if (ext.toLowerCase() != ".pfx")
                    isInvalidType=true;
            }
            if (isInvalidType) {
                this.uploader.removeFromQueue(fileItem);        
            }
        };

        this.uploader.autoUpload = true;
        this.uploader.onSuccessItem = (item, response) => {
            this.uploadSuccess(item, response);
        }

        this.uploader.onErrorItem = (item, response) => {
            this.uploadError(item, response);
        }

        this.uploader.onBeforeUploadItem = (item) => {
            this.beforeUpload(item);
        }
    }

    public containsFailures() : boolean {
        return this.uploader.queue.filter((x)=>x.isCancel).length> 0;
    }

    private uploadSuccess(item, response) {
        let idx =this.uploader.queue.indexOf(item);
        if (idx!=-1) {
            this.uploader.queue.splice(idx, 1);
        }
    }

    private uploadError(item, response) {
        if (response && response.exceptionMessage)
            item.errorMessage = response.exceptionMessage;
        else
            item.errorMessage = "Upload failed. Unknown reason";
        item.cancel();
    }

    private beforeUpload(item) {
        item.withCredentials=false;
    }

    public isCompletedSuccessfully(name: string) : boolean {
        let ret: boolean=false;
        this.uploader.queue.forEach(item => {
            if (item.isSuccess && item.file.name.toLowerCase() === name.toLowerCase()) {
                ret=true;
            }
        });
        return ret;
    }

}
    


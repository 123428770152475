<div fxLayout="row wrap" *ngIf="task.outputItems.length > 0">
    <div fxFlex="30" *ngFor="let op of task.outputItems">
        <button mat-button (click)="openJobOutput($event, op.url)" [disabled]="op.status != 4">
            <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px" title="{{op.tooltip}}">
                <div fxFlex="none" class="sw-pad-top">
                    <mat-icon class="siz-150" *ngIf="op.status == 4">download</mat-icon>
                    <mat-icon class="siz-150 sw-error" *ngIf="op.status == 2">error</mat-icon>
                    <mat-spinner diameter="20" *ngIf="op.status <= 1"></mat-spinner>
                </div>
                <div fxFlex [ngClass]='{"sw-error": op.status == 2, "sw-inprogress": op.status <= 1 }'>{{op.title}}</div>
            </div>
        </button>
    </div>  
</div>

<div class="full-height-bs full-width-bs" style="overflow:auto">

    <table class="sw-big-pad sw-simple-table full-width-bs sw-viewcontainer-contents">
        <thead>
            <tr>
                <th style="width:120px">Date</th>
                <th style="width:250px">Image</th>
                <th style="width:120px">Operating System</th>
                <th style="width:80px">Status</th>
                <th style="width:120px">Remoting Link</th>
                <th style="min-width:60px">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let vmi of virtualMachines" style="height:32px">
                <td>{{vmi.dateCreated | date:'dd-MMM-yyyy HH:mm'}}</td>
                <td *ngIf="vmi.progressPercentage == 0 || vmi.progressPercentage == 100">{{vmi.imageDisplay}}</td>
                <td *ngIf="vmi.progressPercentage > 0 && vmi.progressPercentage < 100">
                    <div fxLayout="column">
                        <div fxFlex>{{vmi.imageDisplay}}</div>
                        <div fxFlex="none" class="sw-right-pad">
                            <mat-progress-bar mode="determinate" [value]="vmi.progressPercentage"></mat-progress-bar>
                        </div>
                    </div>

                </td>
                <td>{{vmi.operatingSystemId}}</td>
                <td>{{vmi.status}}</td>
                <td>
                    <a href (click)="openRemotingLink(vmi)" *ngIf="vmi.remotingUrl">Click to open</a>
                </td> 
                <td>
                    <div fxLayout="row" fxLayoutGap="10px">
                        <button mat-button fxFlex="none" (click)="import(vmi)" class="md-raised sw-button-thin" title="Import Image" aria-label="Import Image" *ngIf="vmi.status =='Ready'">
                            <i class="material-icons" image-size="16pt">download</i>
                        </button>
                        <button mat-button fxFlex="none" (click)="delete(vmi)" class="md-raised sw-button-thin" title="Delete Image" aria-label="Delete Image" *ngIf="vmi.status =='Ready' || vmi.status == 'Stopped'">
                            <i class="material-icons" image-size="16pt">delete</i>
                        </button>
                        <div fxFlex></div>
                    </div>
                </td>
            </tr> 
        </tbody>
    </table>
    
</div>  
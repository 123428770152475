import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilService } from '../../svc/utilService';
import { ProjectService } from '../../api/SVC';

import * as CRS from '../../api/CRS';

@Component({
    selector: 'msi-installer-info',
    templateUrl: 'msi-installer-info-dialog.html'
})
export class MsiInstallerInfoDialog {
   
    constructor(
        private dialogRef: MatDialogRef<MsiInstallerInfoDialog>,
        @Inject(MAT_DIALOG_DATA) public item: CRS.RSExtractedMsi
    ) {
    }

    closeDialog() {
        this.dialogRef.close();
    }

}



import { Injectable } from '@angular/core';
import { NavigationEnd, ActivatedRoute, Router } from "@angular/router";
import { Observable, Subject } from 'rxjs';
import { first }  from 'rxjs/operators';
import { Constants } from '../api/Constants';
import { IdChangeArgs } from '../model/IdChangeArgs';
import { RoutePathSegment } from '../model/RoutePathSegment';
import { UtilService } from '../svc/utilService';
import { StateElements } from '../model/StateElements';

import * as Enum from '../api/Enum';

@Injectable({
    providedIn: 'root'
})
export class StateHelperService {

    constructor(
        private route: ActivatedRoute,
        private utilService: UtilService,
        private router: Router)
    { 
        this.sub =new Subject<any>();
        this.idChg = new Subject<IdChangeArgs>();
        this.paramLoadup();
        this.router.events.subscribe(ev => {    
            if (ev instanceof NavigationEnd) {
                var preState = this._stateElements.Clone();
                this.paramLoadup();
                this.sub.next(null);
                this.checkParamStateChange(preState);
            }
        });

    }

    private sub: Subject<any>;
    public onStateChange() : Observable<any> {
        return this.sub.asObservable();
    }
    private idChg:Subject<IdChangeArgs>
    public onIdChange() : Observable<IdChangeArgs> {
        return this.idChg.asObservable();
    }

    private _stateElements: StateElements = new StateElements(this.utilService);

    public get details(): StateElements {
        return this._stateElements;
    }

    public isAtRootProjectView() : boolean {
        var pd = this.details.statePath.filter(x=>x.id == Constants.ROUTE_PROJECTDETAILS_MAIN);
        if (pd.length==0)
            return false;
        let prjIdx = this.details.statePath.indexOf(pd[0]);
        return (prjIdx>=0 && this.details.statePath.length - prjIdx < 2);
    }

    private instances: { [id:string] : StateHelperInstance} = {};

    public register(id:string) : StateHelperInstance {

        let i = this.instances[id];
        if (!i) {
             i = this.instances[id] = new StateHelperInstance(id, this);
        }
        return i;
    }

    public isAppBrowsing() : boolean {
        var rs = this.details.statePath;
        var isAppBrowsing = rs.filter(x=>x.id == Constants.ROUTE_PROJECTAPPLICATIONBROWSE).length == 1;
        return isAppBrowsing;
    }

    private checkParamStateChange(preState:StateElements) : void {
        if (preState.projectId != this._stateElements.projectId)
            this.idChg.next(new IdChangeArgs(Enum.IdType.Project, this._stateElements.projectId));
        if (preState.applicationId != this._stateElements.applicationId)
            this.idChg.next(new IdChangeArgs(Enum.IdType.Application, this._stateElements.applicationId));
        if (preState.jobId != this._stateElements.jobId)
            this.idChg.next(new IdChangeArgs(Enum.IdType.Job, this._stateElements.jobId));
        if (preState.actionId != this._stateElements.actionId)
            this.idChg.next(new IdChangeArgs(Enum.IdType.Action, this._stateElements.actionId));
        if (preState.checkId != this._stateElements.checkId)
            this.idChg.next(new IdChangeArgs(Enum.IdType.Check, this._stateElements.checkId));
        if (preState.tableId != this._stateElements.tableId)
            this.idChg.next(new IdChangeArgs(Enum.IdType.Table, this._stateElements.tableId));
        if (preState.assessmentGroupId != this._stateElements.assessmentGroupId)
            this.idChg.next(new IdChangeArgs(Enum.IdType.AssessmentGroup, this._stateElements.assessmentGroupId));
    }

    private getStatePath() : Array<RoutePathSegment> {
        let states : Array<RoutePathSegment> = [];
        var s : ActivatedRoute = this.route;
        while (s){
            s.url.subscribe((x)=>{
                if (x && x.length>0) {
                    var x0Path =x[0].path;
                    var x1Path = (x.length>1) ? x[1].path:null;
                    if (states.length == 0 && x0Path=="")
                        x0Path=Constants.ROUTE_PROJECTDASHBOARD;
                    states.push(new RoutePathSegment(x0Path, x1Path));
                }
            });           
            s = (s.children.length>0) ? s.children[0] : null;
        }
        let qm : { [id: string] : string; } = null;
        this.route.queryParams.subscribe((x)=>{
            Object.getOwnPropertyNames(x).forEach(y=>{
                if (qm==null)
                    qm = {};
                qm[y.toLocaleLowerCase()] = x[y];
            });
        });
        states[states.length - 1].query = qm;
        return states;
    }

    private paramLoadup() {
        this.route.queryParams.pipe(first()).subscribe((q)=>{ 
            this.details.updateStatePath(this.getStatePath());
        });
    }

}
    
const RULETYPE_MINIMISE = "MINIMISE";
const RULETYPE_CURRENT = "CURRENT";

export enum StateContext {
    Current,
    Parent
}

export enum StateRuleOperator {
    Equal,
    NotEqual
}

export class StateHelperInstance {
    constructor(
        id:string, 
        stateHelperService: StateHelperService ) 
    {
        this.id=id;
        this.rules[RULETYPE_CURRENT] = new SHRule(StateContext.Current, StateRuleOperator.Equal, id);
        this.rules[RULETYPE_MINIMISE] = new SHRule(StateContext.Current, StateRuleOperator.Equal, id);

        this.stateHelperService =stateHelperService;
    }
    private stateHelperService : StateHelperService;
    private rules : { [id: string] : SHRule; } = {};
    private id: string;

    public minimiseWhen(context: StateContext, operator: StateRuleOperator, id:string): void  {
        this.rules[RULETYPE_MINIMISE] = new SHRule(context, operator, id);
    }
    public isNotCurrent() : boolean {
        return !this.isCurrent();
    }
    public isCurrent(): boolean {
        return this.stateHelperService.details.isStatePathCurrent(this.id);
    }
    public shouldMinimise() : boolean {
        let r: SHRule = this.rules[RULETYPE_MINIMISE];
        if (!r)
            return false;
        var routeParts =  this.stateHelperService.details.statePath;
        var rl = routeParts.filter(x=>x.id == r.Id);
        if (rl.length==0)
            return false;
        var idx =routeParts.indexOf(rl[0]);
        return idx != -1 && idx < routeParts.length - 1;
    }
}

class SHRule {
    constructor(context: StateContext, operator: StateRuleOperator, id:string) {
        this.Context = context;
        this.Operator = operator;
        this.Id =id;
    }
    public Context: StateContext;
    public Operator: StateRuleOperator;
    public Id: string;
}

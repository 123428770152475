import { CurrentProjectService } from '../svc/currentProjectService';
import { UtilService } from '../svc/utilService';

import * as Enum from '../api/Enum';
import * as CRS from '../api/CRS';

export class OutputItemInfoProj {

    constructor(
        private currentProject: CurrentProjectService,
        private utilService: UtilService,
        private type:number,
        private outputType:Enum.OutptType
    ) {
        this._type = type;
        this._outputType= outputType;
        this._item = this.getItem();
        this.currentProject.dataContext.onLoaded().subscribe(()=> {
            this._item=this.getItem();
        });
    }

    public _type:number;
    public _outputType:Enum.OutptType;
    public _warning: ()=>string;

    private _item: CRS.RSGeneratedOutputItem;

    public getTitle() : string {
        return (this._item) ? this._item.title : '--Unknown--';
    }

    public getStatus(): number {
        return (this._item) ? this._item.status : -1;
    }

    public getOutputTypeDescription() : string {
        if (!this._item) 
            return null;
        return this._item.tooltip;
    }

    public getStatusDescription():string {
        if (!this._item)
            return null;
        switch(this._item.status){
            case 0: return "Not started";
            case 1: return "In progress";
            case 2: return "Failed";
            case 3: return "Cancelled";
            case 4: return "Succeeded";
            case 5: return "Timed out";
            default:
                return "Unknown";
        }
    }

    public getUrl(): string {
        if (!this._item)
            return null;
        if (this._item.isSas)
            return this._item.url;
        return this.utilService.buildAPIUrl(this._item.url);
    }

    public getDateExpression() :string {
        return this._item?.generated;
    }

    private getItem() {
        if (this.currentProject && this.currentProject.dataContext.isReady) {
            var lst = this.currentProject.dataItem.projectOutputItems.filter(x=>x.type == this.type);
            return (lst.length>0) ? lst[0] : null;
        }
    }
}
<div fxLayout="column" fxLayoutGap="5px" class="full-width-bs full-height-bs">

    <div fxFlex="none" class="sw-big-vert-pad-top">                       
        <mat-checkbox [(ngModel)]="project.disableXCheck" (change)="disableXCheckChanged()" [disabled]="!canEdit()">Disable X-Check</mat-checkbox>
    </div>

    <br>
    <br>

    <fieldset fxFlex class="sw-pad">
        <legend>Tracked Operating Systems</legend>
        <angular-slickgrid gridId="grid1" gridHeight="100%" gridWidth="100%"
            [columnDefinitions]="gridColumns" 
            [gridOptions]="gridOptions"
            [dataset]="trackableOperatingSystems" 
            (onGridCreated)="gridReady($event)" >
        </angular-slickgrid>
    </fieldset>

</div>

import { CurrentProjectService } from '../svc/currentProjectService';
import { StdRule, StdRulePart } from './StdRule';

import * as CRS from '../api/CRS';

export class CustomRuleSet {

    constructor(currentProject: CurrentProjectService, ruleSetId:number) {
        this.currentProject = currentProject;
        this.ruleSetId=ruleSetId;
        this.load();
    }

    private currentProject: CurrentProjectService;
    private updated:boolean=false;
    private ruleSetId:number;

    public customRuleGroup: CRS.RSCustomRuleGroup = null;
    public rules: Array<StdRule> =null;

    public get isReady() : boolean {
        return this.rules !=null;
    }

    public load() : void {
        this.currentProject.standardsService.getCustomRules(this.currentProject.dataContext.id).then(response=> {
            var rsIds = this.ruleSetId.toString();
            let lst =response.data.groups.filter((g) => {return g.id == rsIds});
            this.customRuleGroup = (lst.length == 0) ? null: lst[0];
            this.refresh();
        });
    }

    public deleteRule(r: StdRule): void {
        if (r.id == null) {
            this.deleteLocal(r);
        }
        else {
            this.currentProject.deleteRule(r.ruleId).then((ret)=>{
                this.deleteLocal(r);
                if (this.rules.length == 0)
                    this.addRule();
            }) ;
        }
    }
    
    private deleteLocal(r:StdRule) : void {
        let i:number = this.rules.indexOf(r);
        if (i!=-1)
            this.rules.splice(i, 1);
    }
    
    private refresh() : void {
        var prj: CRS.RSGetProject = this.currentProject.dataContext.item().data;
        if (prj && !this.updated && this.customRuleGroup) {
            this.updated=true;
            this.rules = [];
            let rulesForGroup: Array<CRS.RSProjectRule> =prj.rules.filter(r=>r.ruleType.toString() == this.customRuleGroup.id); 
            rulesForGroup.forEach(v=> {
                let r: StdRule = StdRule.deserialise(v.targetId, this.customRuleGroup);
                r.ruleId = v.projectRuleId; 
                r.dirty=false;
                r.isValid= this.isValid(r);
                this.rules.push(r);
            });
            if (this.rules.length == 0)
                this.addRule();
        }
    }

    public addRule(): void {
        let nr: StdRule = new StdRule();
        nr.id = null;
        nr.name = "";
        nr.dirty=true;
        nr.expressionChain = [ StdRulePart.create(nr, null, null, "")];
        this.rules.push(nr);
    }
 
    public saveRule(r: StdRule) : void {
        if (r.ruleId==null)
            this.currentProject.createRule(this.ruleSetId, r.serialise()).then(ret=> {
                r.id = ret.id;
                r.ruleId = ret.projectRuleId;
                r.dirty=false;
            });
        else {
            this.currentProject.updaterule(r.ruleId, r.serialise()).then(ret=> {
                r.dirty=false;
            });
        }
    }
 
    public registerChange(r: StdRulePart): void {
        let i: number = r.rule.expressionChain.indexOf(r);
        r.rule.expressionChain.splice(i+1);
        let lst = this.getSubElements(r);
        if (lst.length>0) {
            let elem = null;
            if (r.element.children.length == 1)
                elem = r.element.children[0];
            let nr: StdRulePart = StdRulePart.create(r.rule, elem, r, "");
            r.rule.expressionChain.push(nr);
            if (elem!=null)
                this.registerChange(nr);
        }
        r.rule.dirty=true;
        r.hasContextValue = ((r.element)?r.element.hasContextValue:false);
        r.rule.isValid=this.isValid(r.rule);
    }

    public getSubElements(r: StdRulePart) : Array<CRS.RSCustomRuleElement> {
        if (r && r.element) {
            return r.element.children;
        }
        else if (this.customRuleGroup) {
            return this.customRuleGroup.elements;
        }
        return null;
    }
    
    private isValid(r:StdRule) : boolean {
        if (r.expressionChain.length < 2)
            return false;
        let lst = r.expressionChain.filter(c=>c.element==null );
        if (lst.length > 0 && !(r.expressionChain.length > 2 && lst.length==1 && r.expressionChain.indexOf(lst[0]) == r.expressionChain.length - 1)) {
            return false;
        }
        if (r.name.trim().length == 0)
            return false;
        lst= r.expressionChain.filter(c=>c.element!=null && c.hasContextValue && c.contextValue.trim().length == 0)
        if (lst.length>0)
            return false;
    
        return true;   
    }
    
}
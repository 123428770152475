import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'simple-dialog',
    templateUrl: './simple-dialog.html',
    styleUrls: ['./dialogs.scss']
})
export class SimpleDialog implements OnInit {

    public title:string;
    public texts:Array<string>;

    constructor(
        private dialogRef: MatDialogRef<SimpleDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
    }

    public cancelDialog() {
        this.dialogRef.close(false);
    }
}
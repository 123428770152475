import { first} from 'rxjs/operators';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { UtilService } from './utilService';
import { AuthService } from './authService';

export class FileUploads {

    private AUTH: string = "Authorization";
    private apiUrl:string;

    public uploader: FileUploader;
    public beforeUpload: ()=>boolean;
    public afterUploadSuccess: ()=>void;
    private getId: ()=>string;

    constructor(
        apiUrl:string,
        private authService: AuthService,
        private utilService: UtilService,
        getIdFunc: ()=>string
    ){ 
        this.apiUrl = apiUrl;
        this.getId = getIdFunc;

        this.uploader = new FileUploader(
            {
                url:this.getUrl(),
                headers: [
                    { name: this.AUTH, value: `Bearer ${this.authService.authToken}`},
                ],
                maxFileSize:100 * 1024 * 1024,
                autoUpload:true
            }
        );

        this.uploader.onAfterAddingFile = (fileItem: FileItem)=> {
            let isInvalidType:boolean=false;
            let idx:number = fileItem.file.name.lastIndexOf('.');
            if (idx!=-1)
            {
                let ext = fileItem.file.name.substring(idx).toLowerCase();
                var valid = !this._extensions || this._extensions.indexOf(ext) != -1;
                if (!valid)
                    isInvalidType=true;
            }
            if (isInvalidType) {
                this.uploader.removeFromQueue(fileItem);        
            }
        };

        this.uploader.autoUpload = true;
        this.uploader.onSuccessItem = (item, response) => {
            this.uploadSuccess(item, response);
        }

        this.uploader.onErrorItem = (item, response) => {
            this.uploadError(item, response);
        }

        this.uploader.onBeforeUploadItem = (item) => {
            var h0 = this.uploader.options.headers.filter(x=>x.name == "Authorization"); // Cope with token change
            if (h0)
                h0[0].value =`Bearer ${this.authService.authToken}`;
            this.onBeforeUpload(item);
        }
    }

    private _extensions : Array<string> = null;
    public setValidExtensions(items: Array<string>) {
        this._extensions = items;
    }

    public contextUpdated() {
        this.uploader.options.url = this.getUrl();
    }

    public get queueLength(): number {
        return this.uploader.queue.length;
    }

    private getUrl() {
        if (!this.getId)
            throw new Error( "GetId not defined on Fileuploads");
        var id = this.getId();
        return this.apiUrl + `?projectId=${id}&serverId=${this.utilService.serverId}` ;
    }

    public registerApplicationList(items : Array<string>) {
        let toRemove: Array<any> =[];
        this.uploader.queue.forEach((x)=> {
            if (items.indexOf(x.file.name) != -1)
                toRemove.push(x);
        });

        toRemove.forEach((x)=> {   
            x.remove()});
    }

    public containsFailures() : boolean {
        return this.uploader.queue.filter((x)=>x.isCancel).length> 0;
    }

    public clearFailures() : void {
        let toRemove: Array<any> = [];
        this.uploader.queue.forEach((x)=> {
            if (x.isCancel)
                toRemove.push(x);
        })
        toRemove.forEach((x)=> {
            x.remove();
        });
    }

    private uploadSuccess(item, response) {
        let idx =this.uploader.queue.indexOf(item);
        if (idx!=-1) {
            this.uploader.queue.splice(idx, 1);
            console.log("uploaded " + item.file.name);
        }
        if (this.afterUploadSuccess)
            this.afterUploadSuccess()
    }

    private uploadError(item, response) {
        if (response && response.exceptionMessage)
            item.errorMessage = response.exceptionMessage;
        else
            item.errorMessage = "Upload failed. Unknown reason";
        item.cancel();
        console.log('item');
        console.log(item);
        console.log('response');
        console.log(response);
        console.log(`error for ${item.file.name}`);
    }

    private onBeforeUpload(item) {
        item.withCredentials=false;
        if (this.beforeUpload && !this.beforeUpload())
        {
            item.errorMessage ="Application imports are currently suspended within this project";
            item.cancel();
        }
    }

    public isCompletedSuccessfully(name: string) : boolean {
        let ret: boolean=false;
        this.uploader.queue.forEach(item => {
            if (item.isSuccess && item.file.name.toLowerCase() === name.toLowerCase()) {
                ret=true;
            }
        });
        return ret;
    }

}
    



<div fxLayout="row" class="full-width-bs full-height-bs">

    <spinner fxFlex *ngIf="!isDataReady && !isInError"></spinner>
    
    <div fxFlex *ngIf="isInError" class="sw-segment">
        <div fxFlayout="column" fxLayoutAlign="center center">
            <div fxFlex fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center" class="sw-gen-error-panel">
                <div fxFlex="none"><img src="/img/misc/warning.png"></div>
                <div fxFlex="none">
                    <h2 class="sw-error">Error Code: {{errorCode}}</h2>
                    <h3>{{errorReason}}</h3>
                </div>
            </div>
        </div>
    </div>

    <div fxFlex *ngIf="isDataReady" class="sw-segment">
        
    <conversion-task-converted *ngIf="jobHasTask && !jobIsCompletedRuntimeTest && !jobIsFailed && !jobIsInProgress"></conversion-task-converted>
    <qa-worksheet *ngIf="jobIsCompletedRuntimeTest"></qa-worksheet>

    <conversion-task-test *ngIf="jobIsUnstartedTestJob"></conversion-task-test>
    <conversion-task-convert *ngIf="jobIsUnstartedConversionJob"></conversion-task-convert>
    <conversion-task-publish *ngIf="jobIsUnstartedPublishJob"></conversion-task-publish>
    <conversion-task-publish-test *ngIf="jobIsUnstartedPublishTestJob"></conversion-task-publish-test>
    <conversion-task-failed *ngIf="jobIsFailed"></conversion-task-failed>
    <conversion-task-inprogress *ngIf="jobIsInProgress"></conversion-task-inprogress>
    <conversion-task-edit *ngIf="jobIsEditJob"></conversion-task-edit>

    <project-application-core *ngIf="jobIdParts.isRoot && jobIdParts.rootType!='OUT'"></project-application-core>
    <project-application-output *ngIf="jobIdParts.isRoot && jobIdParts.rootType=='OUT'"></project-application-output>

    <project-application-notes *ngIf="jobIdParts.type=='N'"></project-application-notes>
    <project-application-history *ngIf="jobIdParts.type=='H'"></project-application-history>
    <project-application-companions *ngIf="jobIdParts.type=='C'"></project-application-companions>
    <project-application-documentation *ngIf="jobIdParts.type=='D'"></project-application-documentation>
    <project-application-related-apps *ngIf="jobIdParts.type=='RA'"></project-application-related-apps>
    <project-application-issues *ngIf="jobIdParts.type =='IS'"></project-application-issues>
    <project-application-prerequisites *ngIf="jobIdParts.type == 'PRE'"></project-application-prerequisites>
    <project-application-qa *ngIf="jobIdParts.type=='QA'"></project-application-qa>
    
    <xcheck-main *ngIf="isXCheckJob"></xcheck-main>

    </div>

    <div fxFlex="none" *ngIf="jobIsCompletedRepackage">
        <conversion-task-outcome-summary></conversion-task-outcome-summary>
    </div>

</div>  

import { Component, OnInit, Input } from '@angular/core';
import { CurrentProjectService } from 'src/app/svc/currentProjectService';
import { INPUTEDITARGS } from '../../model/INPUTEDITARGS';
import { Constants } from '../../api/Constants';

import * as CRS from '../../api/CRS';

@Component({
  selector: Constants.ROUTE_PROJECTSETTINGSWORKFLOW,
  templateUrl: './project-settings-workflow.html',
  styleUrls: ['../../shared/css/nav-bar.scss']
})
export class ProjectSettingsWorkflow implements OnInit {

  constructor(
      public currentProject: CurrentProjectService) { }

  ngOnInit(): void {
  }


  public get project():  CRS.RSGetProject {
    return this.currentProject.dataItem;
  }
  
  public get projectIsReady(): boolean {
    return this.currentProject.dataContext.isReady;
  }

  public canEdit(): boolean {
    return this.currentProject.canEdit();
  }

  public updateWorkflowStages(args: INPUTEDITARGS): void {
    var parts = args.value.split('\n').filter(x=>x && typeof(x.length) != "undefined" && typeof(x.trim) != "undefined" && x.length>0);
    this.currentProject.updateWorkflowStageList(parts);
  }
}

import { Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuService } from '../svc/menuService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';
import { CurrentProjectService } from '../svc/currentProjectService';
import { ProjectActivityDialog } from '../project/project-activity/project-activity-dialog';

export class ViewActivityCommand {

    constructor(
        private injector: Injector
    ) {
        this.menuService = this.injector.get(MenuService);
        this.dialog = this.injector.get(MatDialog);
        this.currentProject = this.injector.get(CurrentProjectService);

        this.menuService.register(Constants.MENU_VIEW_ACTIVITY, this.viewActivity, "*"+ Constants.ROUTE_PROJECTDETAILS_MAIN )
    }

    private menuService: MenuService;
    private dialog: MatDialog;
    private currentProject: CurrentProjectService;

    private viewActivity: AppCommand = AppCommand.createWithShow(
        ()=> {
            return this.currentProject.dataContext.isReady;
        },
        ()=> {
            let dialogData = { projectId: this.currentProject.dataContext.id };
            let dialogRef = this.dialog.open(ProjectActivityDialog, { width:'1000px', height:'500px', data: dialogData });
            dialogRef.afterClosed().subscribe((result)=> {
            });
        },
        ()=> {
            return true;
        }
    )

}
import { Column, SlickGrid, GridStateChange, GridOption, ExtensionName } from 'angular-slickgrid';
import { Grids } from './Grids';
import { Injector } from '@angular/core';
import { UtilService } from '../svc/utilService';
import { StylingService } from 'src/app/svc/stylingService';
import { Subscription } from 'rxjs';
import { IUpdatable, MergeHelper } from '../api/DataMerger';
import { IGridAssistant } from './IGridAssistant';

import * as STHS from '../svc/stateHelperService';
import * as CRS from '../api/CRS';

export class GridAssistant<R,I> implements IGridAssistant {

    constructor(
        id:string,
        injector: Injector) {
            var noState= id.startsWith("#");
            this.grids = injector.get(Grids);
            this.utilService = injector.get(UtilService);
            this.stylingService = injector.get(StylingService);
            this.stateHelperService= injector.get(STHS.StateHelperService);

            this.id = id;
            if (!noState)
                this.stateHelperInstance= this.stateHelperService.register(id);
    }

    public dispose() {
        this.subscriptions.forEach(x=>x.unsubscribe());
    }

    private id: string;
    private grids: Grids;
    private utilService: UtilService;
    private stylingService: StylingService;
    private grid: SlickGrid=null;
    private rowSelectionDelegate: (row:any)=>void;
    private updateDelegate: ()=>void;
    private executeGridCommandDelegate: (a,e)=>void;
    private columns: Array<Column> =[];
    private detailColumns: Array<string>=[];
    private subscriptions : Array<Subscription> = new Array<Subscription>();
    private stateHelperInstance: STHS.StateHelperInstance=null;
    private noDataMsg:string="No items found";
    private errMsg:string=null;
    
    public stateHelperService: STHS.StateHelperService;
    public items: Array<I>= null;

    public get gridId(): string {
        return this.id.replace('#','');
    }

    public get hasAnyItems(): boolean {
        return this.items && this.items.length>0;
    }

    public get isDataReady(): boolean {
        return !this.utilService.isEmptyAny( this.items)
    }

    public get gridOptions(): GridOption {
        return this.grids.options(this.id);
    }

    public get isDetailState(): boolean {
        return this.stateHelperInstance && this.stateHelperInstance.isNotCurrent();
    }

    public get errorMessage(): string {
        return this.errMsg;
    }
    public set errorMessage(value:string) {
        this.errMsg = value;
    }

    public get noDataMessage(): string {
        return this.noDataMsg;
    }

    public setNoDataMessage(value:string): void {
        this.noDataMsg=value;
    }

    private _columnDefs: Column[]=null;
    public get columnDefs(): Column[]{
        if (this._columnDefs)
            return this._columnDefs;
        this._columnDefs = [];
        this.columns.forEach((c)=>{
            if (!this.isDetailState || this.detailColumns.indexOf(c.name) != -1)
                this.columnDefs.push(c);
        });
        return this._columnDefs;
    }

    public setColumns(columns: Array<Column>) {
        this.columns = columns;
    }

    public setDetailColumns(columns: Array<string>) {
        this.detailColumns = columns;
    }

    public setUpdate(updateDelegate: ()=>void){
        this.updateDelegate = updateDelegate;
        this.stylingService.waitForApiEndpoint(()=>this.updateDelegate());
    }

    public allowRowSelection(rowSelection: (row:any)=>void) {
        this.rowSelectionDelegate = rowSelection

        this.subscriptions.push(this.stateHelperService.onStateChange().subscribe(()=>{
            this._columnDefs = null;
            this.grid.invalidateAllRows();
            this.grid.render();
        }));

        if (this.grid && this.rowSelectionDelegate)
            this.grid.getOptions().enableRowSelection=true;
    }

    public gridReady(angularGrid:any) {
        if (this.grid) {
          this.grid.destroy();
        }
        this.grid = angularGrid.slickGrid;
        if (this.rowSelectionDelegate)
            this.grid.getOptions().enableRowSelection=true;

        const gmi = angularGrid.extensionService.getSlickgridAddonInstance(ExtensionName.gridMenu);
        if (gmi)
            gmi.onCommand.subscribe((e,a)=>this.executeGridCommandDelegate(e,a));
    }

    public gridStateChanged(changes: GridStateChange) {
        this.grids.gridStateChanged(this.grid, changes, (r)=>this.rowSelectionDelegate(r));
    }

    public mergeDataResponse(response: CRS.ResponseWrapper<R>, itemsProperty:string, keyProperty:string, factory:new()=>IUpdatable<I> & I) {
        if (this.items==null)
            this.items = [];
        var responseItems = <Array<I>>response.data[itemsProperty];
        MergeHelper.ListMerge(responseItems, <Array<IUpdatable<I> & I>>this.items,  (i) => { return i[keyProperty] }, factory);
    }

}
<div fxLayout="row" fxFill>

    <div fxFlex fxLayout="column" fxLayoutGap="5px">

        <div fxFlex="none"></div> <!-- Pads about radios -->

        <div fxFlex="none" fxLayout="row" fxLayoutGap="15px" *ngIf="isReady()">
            <div fxFlex></div>
            <mat-radio-group [(ngModel)]="updateListFilterType" (change)="changeUpdateListFilterType()" fxFlex="none">
                <mat-radio-button value="0">
                    <div class="sw-pad-right">Show All Updates</div>
                </mat-radio-button>
                <mat-radio-button value="1">
                    <div class="sw-pad-right">Auto Only</div>
                </mat-radio-button>
                <mat-radio-button value="2">
                    <div class="sw-pad-right">Manual Only</div>
                </mat-radio-button>
            </mat-radio-group>
        </div>

        <spinner fxFlex *ngIf="!isReady()"></spinner>
    
        <windows-update-list fxFlex *ngIf="isReady()"
            [windows-updates]="windowsUpdates" 
            [update-list-filter-type]="updateListFilterType" 
            (on-set-running-state)="setRunningState($event)"
            (on-build-update)="buildUpdate($event)"
            (on-retire-update)="retireUpdate($event)">
        </windows-update-list> 

        <div fxFlex="none" *ngIf="showVmsPanel" class="sw-virtual-machine-container">
            <spinner *ngIf="!isReadyVMs()"></spinner>
            <xcheck-virtual-machine-list *ngIf="isReadyVMs()" 
                    [virtual-machines]="virtualMachines"
                    (on-import-virtual-machine)="importVirtualMachine($event)"
                    (on-delete-virtual-machine)="deleteVirtualMachine($event)">
            </xcheck-virtual-machine-list>
        </div>
    
    </div>

</div>  
import { Component, Input, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { UtilService } from '../../svc/utilService';
import { Constants } from '../../api/Constants';
import { StateHelperService } from '../../svc/stateHelperService';

import * as CRS from '../../api/CRS';

@Component({
  selector: 'xcheck-output-compatibility',
  templateUrl: './xcheck-output-compatibility.html',
})
export class XCheckOutputCompatibility implements OnInit {

  constructor(
      private router:Router, 
      private route:ActivatedRoute, 
      private stateHelperService: StateHelperService,
      private utilService:UtilService) { }

  @Input('item') public item:CRS.RSXCheckResult;

  ngOnInit(): void {
  }

  public hasItems() : boolean {
    return this.item && this.item.compatFlagItems.length > 0;
  }

  public getFlagItemValue(value: CRS.RSXCheckCompatFlagValue) : string {
    if (value) {
      var isAdmin = (value.runAsAdmin) ? " / ADMIN" : "";
      var compat = (value.displaySettings && value.displaySettings.length> 0) ? " / " + value.displaySettings.join('+') : "";
      return value.compatibilityMode + isAdmin + compat;
    }
    return null;
  }
}

<div class='sw-errorContainer' 
        fxFlexFill 
        fxLayout="column" 
        fxLayoutGap="20px"
        fxLayoutAlign="start center">

    <div fxFlex="10"></div>

    <div fxFlex="none">
        <div class='sw-error-image'>
            <img src="/img/misc/error.png" alt="error" *ngIf='!isnotfound && !isauth'>
            <img src="/img/misc/404.png" alt="error" *ngIf='isnotfound'>
            <img src="/img/misc/auth.png" alt="error" *ngIf='isauth'>
        </div>
    </div>

    <div fxFlex="none" class='sw-error-title' *ngIf="!isServerError()">{{error.title}}</div>
    <div fxFlex="none" class='sw-error-text' *ngIf="!isServerError()">{{error.text}}</div>
    <div fxFlex="none" class="sw-error-text" *ngIf="isServerError()">An error has occurred communicating with the server. Try again later</div>   

    <div fxFlex="none" class='sw-error-description' *ngIf='error.description && !isServerError()'>
        {{error.description}}
    </div>
    <div fxFlex="none" class='sw-errorReference' *ngIf='error.code'>
        <h4>Support reference code: {{error.code}}</h4>
    </div>

    <div fxFlex class='sw-errorButtons' fxLayout="row" fxLayoutGap="10px">
        <div fxFlex>
            <button mat-raised-button color="primary" (click)='goHome()'>Dashboard</button>
        </div>
        <div fxFlex>
            <button mat-raised-button (click)='logout()' *ngIf="!isServerError()">Log out</button>
        </div>
    </div>

</div>
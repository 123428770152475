import * as CRS from '../api/CRS';
import * as ENUM from '../api/Enum'

export class MsiTableExt {

    public static Create(tbl: CRS.RSMsiTable): MsiTableExt {
        return new MsiTableExt(tbl);
    }

    constructor(tbl: CRS.RSMsiTable) {
        this.table = tbl;
        let pkcols = this.table.columns.filter((c) => c.primaryKeyOrdinal != -1);
        this.primaryKeyColumns = pkcols.sort((a,b) => a.primaryKeyOrdinal - b.primaryKeyOrdinal);
        this.flattenRows();
     }

    public table : CRS.RSMsiTable;
    public rows: Array<any>;
    public primaryKeyColumns : Array<CRS.RSMsiColumn>;

    private flattenRows() : void {
        let coldict : { [id: number] : CRS.RSMsiColumn; } = {};
        this.table.columns.forEach((cx, i) => {
            coldict[cx.columnId] = cx;
        });
        this.rows = this.table.rows.map((v,i) => {
            let r: any = {};
            v.columns.forEach((cv,i) => {
                let cn = coldict[cv.columnId].name;
                r[cn] = cv.value;
            });
            var id="";
            this.primaryKeyColumns.forEach((pk,i)=>{
                var v = r[pk.name];
                if (v)
                    id+=((i==0)?"":"+") +r[pk.name];
            })
            r["id"] = id;
            return r;
        });
    }

    public getColumn(columnName:string) : CRS.RSMsiColumn {
        let cfilt= this.table.columns.filter((c,i) => { return c.name == columnName});
        return (cfilt.length > 0)? cfilt[0]: null;
    }

    public getColumnById(id:number) : CRS.RSMsiColumn {
        return this.table.columns[id-1];
    }

    public getColumnForeignKey(columnName: string, row: CRS.RSMsiRow): CRS.RSMsiForeignKey{
        let fk = null
        let col = this.getColumn(columnName);
        if (col && col.foreignKeys.length>0) {
            if (col.foreignKeys.length > 1) 
                fk=this.chooseMultiFK(col,row);
            else
                fk = col.foreignKeys[0];
        }
        return fk;
    }

    private chooseMultiFK(col : CRS.RSMsiColumn, row:CRS.RSMsiRow):CRS.RSMsiForeignKey {
        let fk: CRS.RSMsiForeignKey=col.foreignKeys[0];
        let ky = col.table+ "+" +col.name;
        switch(ky)
        {
            case "Component+KeyPath":
                let tbl="File";
                if ((row["Attributes"] & 4) > 0)
                    tbl="Registry";
                else if ((row["Attributes"] &32) & 0)
                    tbl = "ODBCDataSource";

                let fks =col.foreignKeys.filter(f=>f.table == tbl);
                if (fks.length>0) {
                    fk = (fks.length>0)? fks[0]: null;
                }
                break;
        }
        return fk;
    }

    public getColumnWidth(column: CRS.RSMsiColumn) : number {
        switch (column.dataType) {
            case 's':
                let s = Math.ceil(column.size/16);
                return (s<1) ? 1: s;
            case 'i':
                return 1;
            case 'b':
                return 2;
            default:
                return -1;
        }
    };

    public findRow(primaryKeys: Array<string>) {
        var mtches = this.rows.filter((r)=> {
            var ret = true;
            var idx: number = 0;
            this.primaryKeyColumns.forEach((pkc) => {   
                if (primaryKeys[idx] != r[pkc.name])
                    ret = false;
                idx++;
            });
            return ret;
        });
        return (mtches.length > 0) ? mtches[0] : null;
    };

    public getPrimaryKey(row: any) {
        let ret = "";
        let isFirst:boolean=true;
        this.primaryKeyColumns.forEach(c=> {
            if (!isFirst)
                ret+="|";
            ret += row[c.name].replace("|", "||");
            isFirst=false;
        });
        return ret;
    }
}

            
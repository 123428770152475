import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router,ActivatedRoute } from '@angular/router';

import * as Enum from '../../api/Enum';
import * as CRS from '../../api/CRS';

@Component({
  selector: 'progress-blocks',
  templateUrl: './progress-blocks.html',
  styleUrls: ['./progress-blocks.scss']
})
export class ProgressBlocks {

  constructor(
      private route:ActivatedRoute) { }

  @Input('value') value:number;
  
}

export class SpineError {

    public code: string;

    constructor(
        public title: string,
        public text: string,
        public description: string,
        public info: any = null
    ) {
        this.code = this.generateErrorCode();
    }

    private generateErrorCode(): string {
        let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
        let result = '';

        for (let i = 0; i < 6; i++) {
            result = result + chars.charAt(Math.floor(Math.random() * (chars.length - 1)));
        }

        return result;
    }

    public static createDefaultError(): SpineError {
        let result = new SpineError(
            `An error has occurred`,
            `The operation couldn't be completed`,
            null,
            null
        );

        result.code = null;
        
        return result;
    }
}
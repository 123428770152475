<mat-dialog-actions>
    <h2 mat-dialog-title>{{title}}</h2>
    <div fxLayout="row">
        <div fxFlex>
            <mat-form-field appearance="fill">
                <mat-label>Username</mat-label>
                <input matInput [(ngModel)]='username'>
            </mat-form-field>
        </div>  
    </div>  
    <div fxLayout="row" class="full-width">
        <div fxFlex></div>
        <div fxFlex="none">
            <button mat-raised-button (click)="closeDialog()">
                <mat-icon>done</mat-icon>
                Accept
            </button>
            <button mat-raised-button (click)="cancelDialog()">
                <mat-icon>cancel</mat-icon>
                Close
            </button>
        </div>
    </div>
</mat-dialog-actions>
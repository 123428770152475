import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MenuService } from '../svc/menuService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';
import { CreateVmDialog } from '../project/project-xcheck/create-vm-dialog';

import { CurrentProjectService } from '../svc/currentProjectService';
import { DialogService } from '../svc/dialogService';

export class CreateXCheckVirtualMachineCommand {

    constructor(
        private injector: Injector,
    ) {
        this.menuService = this.injector.get(MenuService);
        this.currentProject = this.injector.get(CurrentProjectService);
        this.dialogService = this.injector.get(DialogService);
        
        this.menuService.register(Constants.MENU_CREATE_XCHECK_VIRTUAL_MACHINE, this.cmd, Constants.ROUTE_PROJECTXCHECK )
    }

    private menuService: MenuService;
    private currentProject: CurrentProjectService;
    private dialogService: DialogService;
    private dialog: MatDialog;

    private cmd: AppCommand = AppCommand.create(
    ()=> { 
        return this.currentProject.dataContext.isReady;
    },
    ()=> {
        this.dialog = this.injector.get(MatDialog);
        var dialogRef = this.dialog.open(CreateVmDialog, { width : '600px', height:'250px'});
        dialogRef.afterClosed().subscribe((ret)=> {
            if (ret && ret.imageId) {
                this.currentProject.createVirtualMachineFromBaseImage(ret.imageId).then((data)=> {
                    this.dialogService.showInfoMessage("Create Virtual Machine", [ "The machine is being created. You will be sent an email when it is ready for use. This will take a few minutes" ]);
                });
            }

        });
    
    });
}


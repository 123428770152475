import { Component, OnInit, OnDestroy, Input, Injector } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Constants } from '../../api/Constants';
import { CurrentApplicationService } from 'src/app/svc/currentApplicationService';
import { CurrentConversionJobService } from 'src/app/svc/currentConversionJobService';
import { StateHelperService } from 'src/app/svc/stateHelperService';
import { ActionIdParts } from 'src/app/model/ActionIdParts';

@Component({
  selector: Constants.ROUTE_PROJECTAPPLICATIONACTIONSDETAIL,
  templateUrl: './project-application-actions-detail.html',
  styleUrls: ['./project-applications.scss']
})
export class ProjectApplicationActionsDetail implements OnInit, OnDestroy {

  constructor(
    private route: ActivatedRoute,
    private currentApplication: CurrentApplicationService,
    private stateHelperService: StateHelperService,
    private currentConversionJob: CurrentConversionJobService,
  ) { 
  }
 
  private subscriptions: Array<Subscription> =new Array<Subscription>();

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x=>x.unsubscribe());
  }

  public jobId() : string {
    return this.stateHelperService.details.jobId;
  }

  public get jobIdParts() : ActionIdParts {
    return this.currentConversionJob.idParts;
  }

  public get jobHasTask(): boolean {
    return this.currentConversionJob.hasTask();
  }

  public get jobIsCompletedRepackage(): boolean {
    return this.currentConversionJob.hasTask() 
      && this.currentConversionJob.dataItem.task.status == Constants.STATUS_SUCCESS 
      && this.currentConversionJob.dataItem.task.type == 'Repackage';
  }

  public get jobIsCompletedRuntimeTest(): boolean {
    return this.currentConversionJob.hasTask()
    && this.currentConversionJob.dataItem.task.status == Constants.STATUS_SUCCESS
    && this.currentConversionJob.dataItem.task.type == 'Test'
    && this.currentConversionJob.dataItem.task.typeApproach == 'Runtime'
  }
  
  public get jobIsEditJob(): boolean {
    return this.currentConversionJob.isEditJob();
  }

  public get jobIsUnstartedTestJob(): boolean {
    return this.currentConversionJob.isUnstartedTestJob();
  }

  public get jobIsUnstartedConversionJob(): boolean {
    return this.currentConversionJob.isUnstartedConversionJob();
  }

  public get jobIsUnstartedPublishJob(): boolean {
    return this.currentConversionJob.isUnstartedPublishJob();
  }

  public get jobIsUnstartedPublishTestJob(): boolean {
    return this.currentConversionJob.isUnstartedPublishedTestJob();
  }

  public get jobIsFailed(): boolean {
    return this.currentConversionJob.isFailed();
  }

  public get jobIsInProgress(): boolean {
    return this.currentConversionJob.isInProgress() && !this.currentConversionJob.isCancelling();
  }

  public get isInError(): boolean {
    return this.currentConversionJob.dataContext.isInError;
  }

  public get errorCode()  :number {
    return this.currentConversionJob.dataContext.ErrorCode;
  }

  public get errorReason()  :string {
    if (this.currentConversionJob.dataContext.ErrorCode == Constants.ERR_JOB_NOT_FOUND)
      return "Job not found";
    return this.currentConversionJob.dataContext.ErrorReason;
  }

  public get isXCheckJob(): boolean {
    return this.currentConversionJob.isXCheckJob();
  }

  public get isDataReady(): boolean {
    return this.currentApplication.dataContext.isReady && this.currentConversionJob.dataContext.isReady;
  }

}

<div fxLayout="column" *ngIf="jobHasTask" class="full-height-bs">

    <div fxFlex="none" fxLayout="row" fxLayoutGap="10px" class="sw-event-header-inner" >

        <div fxFlex="none" class="sw-event-content-icon">
            <mat-icon image-size size="42pt">{{job.task.currentStateIcon}}</mat-icon>
        </div>
    
        <div fxFlex fxLayout="column" fxLayoutGap="5px">
            <div fxFlex="none" class="sw-event-content-title">{{job.task.currentStateTitle}}</div>
            <div fxFlex="none" class="sw-event-content-subtitle">{{job.task.currentStateSubTitle}}</div>
        </div>

        <div fxFlex fxLayout="row" class="sw-conversion-clock-container sw-waiting" fxLayoutGap="5px" *ngIf="!job.task.isExecuting">
            <div fxFlex="none" class="sw-schedule">
                <mat-icon inline="true">schedule</mat-icon>
            </div>
            <div fxFlex>{{timeRunning}}</div>
        </div>

        <div fxFlex fxLayout="row" class="sw-conversion-clock-container" fxLayoutGap="5px" *ngIf="job.task.isExecuting">
            <div fxFlex="none" class="sw-schedule">
                <mat-icon inline="true">schedule</mat-icon>
            </div>
            <div fxFlex>{{timeRunning}}</div>
        </div>

        <div fxFlex="none">
            <progress-blocks [value]="job.task.percentageComplete"></progress-blocks>
        </div>

        <div fxFlex="none" *ngIf="remotingWindowCommand.cmd.show && canShowClicker">
            <mat-slide-toggle color="primary" [checked]="clickerCommand.panelVisible" (change)="clickerStateChanged($event)">Clicker</mat-slide-toggle>
        </div>

        <div fxFlex="none" *ngIf="canReleaseTask()">
            <button mat-raised-button color="primary" title="{{releaseTaskText()}}" (click)="execReleaseTask()">Release</button>
        </div>

        <div fxFlex="none" *ngIf="canCancelTask()">
            <button mat-raised-button color="warn" (click)="cancelTask()">Cancel</button>
        </div>

    </div>

    <div fxFlex="none" *ngIf="job.task.runningProcesses && job.task.runningProcesses.length> 0 && !remotingWindowCommand.cmd.show">
        <process-list [items]="job.task.runningProcesses" killAllowed="true"></process-list>
    </div>

    <div fxFlex="none" class="sw-error-container" *ngIf="job.task.installFailureIndicated">
        <div class="sw-upper">WARNING: THE PRIMARY APPLICATION OR ONE OF IT'S PREREQUITES MAY NOT HAVE INSTALLED SUCCESSFULLY</div>
    </div>

    <div fxFlex *ngIf="remotingWindowCommand.cmd.show" class="sw-segment">
        <div class="full-width-bs full-height-bs">
            <iframe [src]="remotingWindowCommand.jobRemotingUrl" style="width:1280px;height:800px" scrolling="yes"></iframe>
        </div>
    </div>

    <div fxFlex="none" *ngIf="remotingWindowCommand.cmd.show && clickerCommand.panelVisible">
        <conversion-clicker-panel></conversion-clicker-panel>
    </div>

    <!--Hide these blocks while the iframe is available -->

    <div fxFlex="none" *ngIf="!canReleaseTask() && job.task.currentStateDesc" class="sw-event-content" fxLayout="column">
        <div class="sw-pad"></div>
        <div fxFlex>{{job.task.currentStateDesc}}</div>
        <div fxFlex *ngIf="job.task.currentStateDesc">{{job.task.currentStateDesc2}}</div>
        <div fxFlex class="sw-pad"></div>
    </div>

    <div fxFlex *ngIf="!canReleaseTask() && !remotingWindowCommand.cmd.show">
        <conversion-task-log-view [light]="true" class="log-view"></conversion-task-log-view>
    </div>

</div>
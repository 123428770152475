import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { UtilService } from '../../svc/utilService';
import { ProjectService } from '../../api/SVC';
import { CurrentProjectService} from '../../svc/currentProjectService';
import { UserCacheService } from '../../svc/userCacheService';
import { Constants } from '../../api/Constants';

import * as Model from '../../model/WindowsUpdateChangeItem';
import * as CRS from '../../api/CRS';

@Component({
  selector: 'windows-update-list',
  templateUrl: './windows-update-list.html',
  styleUrls: []
})
export class WindowsUpdateList implements OnInit {

    constructor(
        private router:Router, 
        private projectService: ProjectService, 
        private currentProjectService: CurrentProjectService,
        private userCacheService: UserCacheService,
        private utilService:UtilService) { }

    @Input("windows-updates") windowsUpdates:Array<CRS.RSWindowsUpdate>=null;
    @Input("update-list-filter-type") updateListFilterType:number=0;

    @Output('on-set-running-state') onSetRunningState= new EventEmitter<Model.WindowsUpdateChangeItem>();
    @Output('on-build-update') onBuildUpdate=new EventEmitter<Model.WindowsUpdateChangeItem>();
    @Output('on-retire-update') onRetireUpdate=new EventEmitter<Model.WindowsUpdateChangeItem>();
    
    ngOnInit(): void {
    }

    public filterUpdateType = (update: CRS.RSWindowsUpdate) : boolean => {
        let ulft :number = parseInt(this.updateListFilterType.toString());
        switch(ulft)
        {
             case 1:
                 return update.origin == 0;
             case 2:
                 return update.origin == 1;
             default:
                 return true;
        }
    }

    public testRunning:boolean=false;

    public getDistributionStyling() : {[id:number]:string} {
        return {
            1:"green",
            995:"inprogress",
            999:"remaining",
            0:"red"
        }
    }

    public getDistribution(update : CRS.RSWindowsUpdate) : Array<CRS.RSDistributionItem> {
        var p1 = new CRS.RSDistributionItem();
        p1.id = 1;
        p1.label = "Succeeded";
        p1.count = update.succeededCount;
        p1.title = this.buildTitle(update.succeededCount, "succeeded");
        var p2 = new CRS.RSDistributionItem() ;
        p2.id = 995;
        p2.label = "In Progress";
        p2.count = update.inProgressCount;
        p2.title = this.buildTitle(update.inProgressCount, "in progress");
        var p3 = new CRS.RSDistributionItem() ;
        p3.id = 999;
        p3.label = "Remaining";
        p3.count = update.remainingCount;
        p3.title = this.buildTitle(update.remainingCount, "remaining");
        var p4 = new CRS.RSDistributionItem();
        p4.id = 0;
        p4.label = "Failed";
        p4.count = update.failedCount;
        p4.title = this.buildTitle(update.failedCount, "failed");
        return [p1,p2,p3,p4];
    }

    public getBuildingText(update: CRS.RSWindowsUpdate) :string {
        var ret=null;
        if (update.buildingCount > 0) {
            var plural = update.buildingCount != 1 ? "s" : "";
            ret= `Building ${update.buildingCount} machine${plural}`;
        }
        if (update.buildingFailureCount > 0) {
            var pluralf = update.buildingFailureCount != 1 ? "s" :"";
            if (ret!=null)
                ret += `. ${update.buildingFailureCount} machine${pluralf} failed to build`;
        }
        return ret;
    }

    public setRunningState = (update: CRS.RSWindowsUpdate) => {
        this.onSetRunningState.emit(Model.WindowsUpdateChangeItem.create(update));
    }

    public buildUpdate = (update: CRS.RSWindowsUpdate) =>{
        this.onBuildUpdate.emit(Model.WindowsUpdateChangeItem.create(update));
    }

    public retireUpdate = (update: CRS.RSWindowsUpdate) =>{
        this.onRetireUpdate.emit(Model.WindowsUpdateChangeItem.create(update));
    }

    private buildTitle(count:number, type:string) : string {
        if (count == 0)
            return null;
        var pl = (count==1) ? "": "s";
        return `${count} application${pl} ${type}`;
    }
}


<div class="log-view" fxLayout="column" fxFill>
    <div fxFlex="none">
        <mat-checkbox [(ngModel)]="showLog" [ngClass]="{'light' : !light}" (change)="initiateLogLoad()">Show Detailed Log</mat-checkbox>
    </div>
    <div fxFlex="none" class="sw-pad"></div>
    <div fxFlex class="sw-segment">
        <div fxFlex class="log-view-inner" [ngClass]="light ? 'log-view-light' : 'log-view-dark'" *ngIf="showLog">
            <div *ngFor="let t of log">
                <div fxLayout="row">
                    <div fxFlex="10">{{t.timestamp}}</div>
                    <div fxFlex>{{t.text}}</div>
                </div>  
            </div> 
            <div *ngIf="!log || log.length==0">
                Waiting for information...
            </div>
        </div>
    </div>
</div>  

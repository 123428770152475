<div fxLayout='row' fxFill>

    <div [fxFlex]="hasCheckIdSpecified() ? '30' : ''" fxLayout='column'>
        
            <spinner fxFlex *ngIf="!isReady()"></spinner>
        
            <div fxFlex fxLayout="column" *ngIf="isReady()">
               
                <normal-checks-table fxFlex *ngIf="!assessmentGroup.isMonthlyUpdateGroup" [assessment-group]='assessmentGroup' 
                        [narrow]="hasAssGroupSpecified() && hasCheckIdSpecified()"
                        (on-click)="openCheck($event)" style="overflow:scroll;height:100%"></normal-checks-table>
                <patch-checks-table fxFlex *ngIf="assessmentGroup.isMonthlyUpdateGroup" 
                        [platforms]='assessmentGroup.platforms' 
                        [is-ready]='isReady()'
                        [patch-group]="currentPatchGroup"
                        [narrow]="hasAssGroupSpecified() && hasCheckIdSpecified()"
                        style="overflow:scroll;height:100%"
                        (on-click)="openCheck($event)"></patch-checks-table> 
            </div>  

    </div>

    <div fxFlex *ngIf="hasCheckIdSpecified()">
        <div class="full-width-bs full-height-bs">
            <router-outlet></router-outlet>
        </div>
    </div>

</div>


<div mat-dialog-content style="height:500px">

    <div fxLayout="column" fxFlexFill>

        <div fxFlex="none" fxLayout="row">
            <h1>Welcome to Readiness</h1>
        </div>

        <div fxFlex class="sw-segment">
            <mat-accordion>
                <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>        
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <sw-button-text icon-size="32pt" class="sw-color-pr-200" icon="add_circle" (click)="setActivePage(0)"></sw-button-text>
                            <h4>Where do I start?</h4>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                    <p>Start by creating a new project with the + New Project button above. Enter the project title, a brief description 
                    if you want, and you can upload an image to easily identify the project. At present we only support PNG files for the 
                    project image.</p>
                    <p>It takes a few seconds to create the new project area which includes creating:</p>
                    <ul>
                        <li>A file repository to store your packages, reports and conversions.</li>
                        <li>All the currently available Readiness assessment checks for your packages.</li>
                        <li>Secure access to the Readiness application conversion infrastructure.</li>
                    </ul>
                    <p>Once you have a project, you can import your packages to be analysed.</p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>        
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <sw-button-text icon-size="32pt" class="sw-color-pr-200" icon="add_circle" (click)="setActivePage(0)"></sw-button-text>
                                <h4>How do I import applications?</h4>
                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>There are a few ways to load application packages into the Readiness assessment and 
                        conversion engine. You can simply "drag and drop” a single MSI on the applications pane in the Readiness app. 
                        This works for single MSI and EXE packages, but for more complex scenarios (multiple files or MST combinations) 
                        you can use the cloud file storage provided by Readiness. Using this approach you can copy your packages and their associated files
                        to this storage area, and then scan it in order to find supported packages. Packages should be isolated from each other by creating a 
                        directory for each one.
                    </p>
                    <p>The storage scan can be initiated from the 'Drives' tab in Readiness. Once a scan has completed, a list of packages that can be
                        imported will be listed, along with any transforms associated with each package (where applicable). To import the packages, click the
                        checkboxes alongside and select the 'Queue Import' menu option.</p>
                    <p>The simplest way to access the storage area provided for a Readiness project is via a SAS key in 'Microsoft Azure Storage Explorer'. The SAS key can be obtained from the menu in the 'Drives' tab
                        within Readiness. 
                    </p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>        
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <sw-button-text icon-size="32pt" class="sw-color-pr-200" icon="add_circle" (click)="setActivePage(0)"></sw-button-text>
                                <h4>Where can I access my reports?</h4>
                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>There are two primary views for your applications in the Readiness system: applications and reports. Applications 
                        and their “rolled up" RAG (Red Amber Green) status are displayed along with package 
                        information (version, manufacturer). The second view is driven by the Readiness assessment checks. Compatibility, 
                        security, suitability, and quality assessment results are grouped by 
                        Assessment groups (i.e. Windows 10 compatibility). Further detail can be accessed by drilling down to individual 
                        checks against each application. Finally, each application that has generated a result against a 
                        particular check is listed, allowing for a quick reference back to the application view.</p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>        
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <sw-button-text icon-size="32pt" class="sw-color-pr-200" icon="add_circle" (click)="setActivePage(0)"></sw-button-text>
                                <h4>How do I convert my applications and download the conversion?</h4>
                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>Converting an application is simple. After loading an application, you can convert it from an MSI to a 
                        Microsoft App-V package. If you loaded a legacy SETUP.EXE installation routine, then you can convert that 
                        file using our conversion infrastructure (Azure based Virtual Machines) to convert that file to a MSI 
                        Installer package. And then from there, you can convert that resultant MSI to an App-V package. Just click on 
                        the Convert button on the menu bar.</p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>        
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <sw-button-text icon-size="32pt" class="sw-color-pr-200" icon="add_circle" (click)="setActivePage(0)"></sw-button-text>
                                <h4>How can I get help?</h4>
                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>For any questions or concerns, or you just need some help please contact us through email at: <a href="support@ApplicationReadiness.com">support@ApplicationReadiness.com</a></p>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        
    </div>

</div>

<div mat-dialog-actions fxLayout='row'>
    <div fxFlex></div>
    <button mat-button (click)='cancelDialog()'>
        <mat-icon>done</mat-icon>
        <span>Close</span>
    </button>
</div>




<div fxLayout="row" fxFill class='full-height-bs'>

    <div [fxFlex]="!gridAssist.isDetailState ? '' : '0 0 200px'" class="sw-segment">
        <grid-assist-panel [grid]="gridAssist"></grid-assist-panel>
     </div>
    
    <div fxFlex *ngIf='gridAssist.isDetailState'>
        <router-outlet></router-outlet>
    </div>
    
</div>


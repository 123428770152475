<div class='full-height-bs full-width-bs' 
        fxFlexFill 
        fxLayout="column" 
        fxLayoutGap="40px"
        fxLayoutAlign="start center">

    <div fxFlex="10"></div>

    <div fxFlex="none">
        <div class="sw-error-image-50" style="text-align:center">
            <img src="/img/misc/noserver.png" alt="error">
        </div>
    </div>

    <div fxFlex="none">
        <h2 class="sw-error">THE SERVER IS CURRENTLY NOT RESPONDING. PLEASE WAIT A FEW MOMENTS AND RETRY</h2>
    </div>

    <div fxFlex="none">
        <h3 class="sw-error">if this problem persists please contact your system administrator</h3>
    </div>

    <div fxFlex="none">
        <div fxLayout="row">
            <div fxFlex *ngFor="let c of [0,1,2,3,4,5,6,7,8,9]" [ngStyle]="{'color':checkCountAtInterval>c?'red':'black'}">
                <mat-icon>stop_sign</mat-icon> 
            </div>
        </div>
    </div>

    <div fxFlex class='sw-errorButtons' fxLayout="row" fxLayoutGap="10px">
        <div fxFlex>
            <button mat-raised-button color="primary" [disabled]="!good" (click)='goHome()'>Dashboard</button>
        </div>
    </div>

</div>
import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { of, Subscription } from 'rxjs';
import { delay, repeat } from 'rxjs/operators';
import { CurrentConversionJobService } from '../../svc/currentConversionJobService';
import { CommandCollection } from '../../commands/command-collection';
import { UtilService } from 'src/app/svc/utilService';
import { ClickerScriptEditCommand } from 'src/app/commands/clicker-script-edit';

import * as CRS from '../../api/CRS';
import { RemotingWindowCommand } from 'src/app/commands/remoting-window';

@Component({
  selector: 'conversion-task-inprogress',
  templateUrl: './conversion-task-inprogress.html',
  styleUrls:['./conversion-task-inprogress.scss']
})
export class ConversionTaskInProgress implements OnInit, OnDestroy {

    constructor(
        private utilService: UtilService,
        private currentConversionJob: CurrentConversionJobService,
    ) { }

    public timeRunning: string;

    private subscriptions : Array<Subscription> = new Array<Subscription>();
    private startDateSet : boolean=false;
    private startDate:Date;

    ngOnInit(): void {
        const delayedThing = of('timer').pipe(delay(1000));
        this.subscriptions.push(delayedThing.pipe(repeat())
          .subscribe(this.updateClock));
          CommandCollection.ClickerScriptEdit.cmd.refresh();
          CommandCollection.RemotingWindow.cmd.refresh();
          this.subscriptions.push(this.currentConversionJob.dataContext.onRefreshed().subscribe(()=>{
            CommandCollection.ClickerScriptEdit.cmd.refresh();
            CommandCollection.RemotingWindow.cmd.refresh();
        }));
    }

    ngOnDestroy():void {
        this.subscriptions.forEach(x=>x.unsubscribe());
    }

    public get job(): CRS.RSGetActionDetails {
        return this.currentConversionJob.dataItem;
    }

    public get jobHasTask(): boolean {
        return this.currentConversionJob.hasTask();
    }

    public get clickerCommand(): ClickerScriptEditCommand {
        return CommandCollection.ClickerScriptEdit;
    }

    public get remotingWindowCommand(): RemotingWindowCommand {
        return CommandCollection.RemotingWindow;
    }

    public clickerStateChanged($event: MatSlideToggleChange) {
        CommandCollection.ClickerScriptEdit.panelVisible=$event.checked;
    }

    public get canShowClicker(): boolean {
        return CommandCollection.ClickerScriptEdit.cmd.show;
    }

    public getSubTitle() {
        var suff = (this.job.task.typeApproach) ? ` (${this.job.task.typeApproach})` : "";
        return `${this.job.task.type}${suff}`;
    }

    public canCancelTask() : boolean {
        return this.currentConversionJob.isInProgress() && !this.currentConversionJob.dataItem.task.cancelling;
    }

    public cancelTask(): void {
        this.currentConversionJob.cancelCurrentTask().then((ret)=> {
        });
    }

    public canReleaseTask() : boolean {
        return CommandCollection.TaskRelease.cmd.can;
    }

    public releaseTaskText(): string {
        var tsk = this.job.task;
        if (!tsk.isWaitingManualModStage && !tsk.isPreparingManualModStage)
            return "Click to release this machine, and record findings (or changes)";
        else if (tsk.isWaitingManualModStage)
            return "Environment is ready to record manual modifications. Click when these have been completed"; 
        return "";
    }

    public execReleaseTask() : void {
        CommandCollection.TaskRelease.cmd.execute();
    }

    private updateClock = () : void => {

        if (this.currentConversionJob.dataContext.isReady && this.currentConversionJob.dataItem.task.secondsInProgress != -1 && !this.startDateSet) {
            this.startDate = new Date();
            this.startDate.setSeconds(this.startDate.getUTCSeconds() - this.currentConversionJob.dataItem.task.secondsInProgress);
            this.startDateSet=true;
        }

        if (this.startDate == null) {
            this.timeRunning=null;
        }
        else {
            var cdlocal :number= new Date().valueOf()/1000;
            var sd : Date = this.startDate;
            var cdrunning: number = sd.valueOf()/1000;
            var dts:number = (cdlocal - cdrunning);
            var h = Math.floor(dts/(3600));
            var m = Math.floor(dts/60) - (h*60);
            var s = Math.floor(dts) - (h*3600) - (m*60);
            this.timeRunning = ((h<10?"0":"") + h) + ":" + ((m<10?"0":"") + m) + ":" + ((s<10?"0":"") + s);
        }
    }
}

import { Component, OnInit } from '@angular/core';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { UtilService } from '../../svc/utilService';
import { INPUTEDITARGS } from '../../model/INPUTEDITARGS';

import * as CRS from '../../api/CRS';

@Component({
    selector: "workflow-status-communication",
    templateUrl: './workflow-status-communication.html'
})
export class WorkflowStatusCommunication implements OnInit {

    constructor(
        private currentProject: CurrentProjectService,
        private utilService: UtilService
    ) {
    } 

    ngOnInit() {

    }

    public isDataReady() { 
        return this.currentProject.dataContext.isReady;
    }

    public canEdit(): boolean {
        return this.currentProject.canEdit();
    }

    public get project() : CRS.RSGetProject {
        return this.currentProject.dataItem;
    }

    public workflowStateChangeEmailTitleChanged(args:INPUTEDITARGS) {
        this.currentProject.updateWorkflowStateChangeEmailTitle(args.value);
    }

    public workflowStateChangeEmailHtmlChanged(args:INPUTEDITARGS) {
        this.currentProject.updateWorkflowStateChangeEmailHtml(args.value);
    }

    public workflowStateChangeEmailRecipientChanged(args:INPUTEDITARGS) {
        this.currentProject.updateWorkflowStateChangeEmailRecipient(args.value);
    }

    public sendWorkflowStateChangeEmailChanged() {
        var vv = (this.project.sendWorkflowStateChangeEmail) ? "true" : null;
        this.currentProject.updateSendWorkflowStateChangeEmails(vv);
    }
}
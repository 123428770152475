import { ApplicationService } from '../api/SVC';
import * as CRS from '../api/CRS'

export class ApplicationHelper {

    constructor(appSvc: ApplicationService) {
        this.appSvc = appSvc;
    }

    private appSvc: ApplicationService;
    private dataCurrentId:string=null;

    private _documentationFiles: CRS.RSGetApplicationDocumentationFiles=null;
    public getDocumentationFiles(id:string) : Promise<CRS.RSGetApplicationDocumentationFiles> {

        return new Promise<CRS.RSGetApplicationDocumentationFiles>((resolve, reject) => {
            
            if (this.dataCurrentId != id) {
                this.dataCurrentId = id;
                this.appSvc.getApplicationDocumentationFiles(id).then((response)=> {
                    this._documentationFiles=response.data;
                    resolve(response.data);
                }, (err) => {
                    reject(err);
                });
            }
            else {
                resolve(this._documentationFiles);
            }
        });
    }

}
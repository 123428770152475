<div id="page_root" class="full-width-bs full-height-bs" fxLayout="column">

    <spinner fxFlex *ngIf="!project.isReady"></spinner>
        
    <div fxFlex fxLayout="row" *ngIf="projectIsReady">

        <div [fxFlex]="stateHelperInstance.shouldMinimise() ? '20' : ''" fxLayout='column' *ngIf="!isHidden()">

            <div fxFlex="none">
                <assessment-group-summary-table (on-click)="openAssessmentGroup($event)"
                     [narrow]="stateHelperInstance.shouldMinimise()"></assessment-group-summary-table>
            </div>

            <div fxFlex *ngIf="!stateHelperInstance.shouldMinimise()">
                <assessment-group-charts></assessment-group-charts>
            </div> 

        </div>

        <div fxFlex *ngIf="stateHelperInstance.shouldMinimise()">
            <router-outlet></router-outlet>
        </div>

    </div>  

</div>
import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { Subscription } from 'rxjs';
import { PlatformService } from '../../svc/platformService'
import { LOCALHASH } from '../../model/LOCALHASH';
import { UtilService } from '../../svc/utilService';
import { PlatformDefn } from '../../model/PlatformDefn';

import * as PL from '../../model/PL';
import * as CRS from '../../api/CRS';

@Component({
    selector: "platform-editor",
    templateUrl: './platform-editor.html',
    styleUrls: ['./platform-editor.scss']
})
export class PlatformEditor implements OnInit, OnDestroy {

    constructor(
        private platformService: PlatformService,
        private utilService:UtilService,
        private currentProject: CurrentProjectService
    ) {
    }

    private subscriptions: Array<Subscription> =new Array<Subscription>();

    ngOnInit() {
        this.currentProject.dataContext.onLoaded().subscribe((data)=>{
            this.ensure();
        });
    }

    ngOnDestroy(){
        this.subscriptions.forEach(x=>x.unsubscribe());
    }

    public platform: PL.PL=null;
    private platformHsh: LOCALHASH = null;

    public osSlider: PL.PLSLIDERRANGE = null;
    public officeSlider: PL.PLSLIDER=null;
    public browserSlider: PL.PLSLIDER =null;
    public virtualisationSlider: PL.PLSLIDER=null;
    public dotNetSlider: PL.PLSLIDER=null;
    public updateDateSlider: PL.PLSLIDER=null;

    @Output() onClose = new EventEmitter<boolean>();

    public isDataReady(): boolean {
        return this.platform && this.currentProject.dataContext.isReady;
    }

    public close($event: Event) {
        this.onClose.emit();
        $event.preventDefault();
    }

    private ensure() : void {

        if (!this.currentProject.dataContext.isReady)
            return;

        let sp: CRS.RSPlatform = this.currentProject.getCurrentPlatformInfo();
        if (this.platformHsh != null && this.platformHsh.PlatformGuid == sp.platformGuid && this.platformHsh.Hash != sp.hash)
            return;

        this.updateOSSlider();
        this.updateOfficeSlider();
        this.updateBrowserSlider();
        this.updateVirtualisationSlider();
        this.updateDotNetSlider();
        this.updateUpdateDateSlider();

        this.platformHsh = this.getHashByPlatform();
    }

    public currentOSHasUpdates() : boolean {
        if (!this.currentProject.dataContext.isReady)
            return false;

        let os : CRS.RSOperatingSystem = this.getOS(false);
        if (!os)
            return false;
            
        let osl = os.id.toLowerCase();
        let idx = osl.indexOf("win10");
        if (idx==-1)
            idx = osl.indexOf("win11");
        if (idx==-1)
            idx = osl.indexOf("win7");
        if(idx==-1)
            idx= osl.indexOf("win8");
        if (idx==-1)
            idx = osl.indexOf("svr2012");
        if (idx==-1)
            return false;
        return true;
    }

   private updateOSSlider() {
        let sp :CRS.RSPlatform =this.currentProject.getCurrentPlatformInfo();
        let ops: Array<CRS.RSOperatingSystem> = this.currentProject.dataItem.operatingSystems;
        let lst =ops.filter(v=> sp.osId.toUpperCase() == v.id.toUpperCase());
        let osType = lst.length==0 ? "D" : lst[0].type;
        let opsForType = this.getOpsForOSType(osType); 
        let min : number = this.indexOfItem(opsForType, sp.osId);
        let max: number = this.indexOfItem(opsForType, sp.osIdTo);
        this.osSlider = new PL.PLSLIDERRANGE(min, max, 0, opsForType.length - 1, opsForType, ()=> { this.applyUpdate() });
        this.platform = new PL.PL();
        this.platform.osType = osType;
    }

    private getOS(isFrom: boolean) : CRS.RSOperatingSystem {
        let ops: Array<CRS.RSOperatingSystem> = this.currentProject.dataItem.operatingSystems;
        let sp: CRS.RSPlatform = this.currentProject.getCurrentPlatformInfo();
        let lst =ops.filter(v=> sp.osId.toUpperCase() == v.id.toUpperCase());
        let osType = lst.length==0 ? "D" : lst[0].type;
        let opsForType: Array<CRS.RSOperatingSystem> = this.getOpsForOSType(osType);
        let osIdx :number= isFrom ? this.osSlider.min : this.osSlider.max;
        return this.getItemByIndex(opsForType, osIdx);
    }

    private updateOfficeSlider() {
        this.officeSlider = this.updateGeneralSlider(this.currentProject.dataItem.officeSuites, "officeSuite");
    }

    private updateVirtualisationSlider() {
        this.virtualisationSlider = this.updateGeneralSlider(this.currentProject.dataItem.virtualisationTechnologies, "virtualisationTechnology");
    }

    private updateDotNetSlider() {
        this.dotNetSlider = this.updateGeneralSlider(this.currentProject.dataItem.dotNetVersions, "dotNetVersion");
    }

    private updateBrowserSlider() {
        this.browserSlider = this.updateGeneralSlider(this.currentProject.dataItem.browsers, "browser");
    }

    private updateUpdateDateSlider() {
        this.updateDateSlider = this.updateGeneralSlider(this.currentProject.dataItem.monthlyUpdates, "updateDate");
    }

    private updateGeneralSlider(items: Array<any>, propertyName:string) : PL.PLSLIDER{
        var sp : CRS.RSPlatform= this.currentProject.getCurrentPlatformInfo();
        var isSvr = this.platform.osType=="S";
        let steps: Array<any> = (items && items.length>0) ? this.getSteps(items).filter((x)=>!(!isSvr && x.isServerOnly)) : null;
        let min: number = (steps) ? this.indexOfItem(steps, sp[propertyName]) : 0;
        let stepCnt = (steps) ? steps.length - 1: 0;
        if (stepCnt === undefined)
            throw "Bad step count";
        return new PL.PLSLIDER(min, 0, stepCnt, steps, ()=> { this.applyUpdate() });
    }

    private getSteps(items: Array<any>): Array<any> {
        if (items)
        {
            let lst: Array<any> = [];
            lst.push( { id: null, display:"N/A" });
            items.forEach((x) => lst.push(x));
            return lst;
        }
        else {
            return null;
        }
    }

    private getUpdateDates() : Array<any> {
        let updateDates: Array<any> = [];
        let dtb: Date = new Date();
        for(var i=3; i>=0; i--) {
            let dt = new Date(dtb.getFullYear(), dtb.getMonth() - i, dtb.getDate(), 1, 0, 0);
            let mn:string = this.utilService.getMonthName(dt.getMonth());
            updateDates.push( { id: (mn + dt.getFullYear()).toUpperCase(), month:i });
        }
        return updateDates;
    }

    private getOpsForOSType(type:string) : Array<CRS.RSOperatingSystem> {
        let ops: Array<CRS.RSOperatingSystem> = this.currentProject.dataItem.operatingSystems;
        return ops.filter(v=>v.type == type); 
    }

    private indexOfItem(ops:Array<any>, osId: string) {
        let uOsId = osId ? osId.toUpperCase() : null;
        let val : number = -1;
        let max: number = 0;
        ops.forEach((v,i)=> {
            let uid = (v.id) ? v.id.toUpperCase() : null;
            if (uid == uOsId)
                val=i;
        });
        return val;
    }

    private getItemById(ops:Array<any>, id: string) {
        let searchuid = id ? id.toUpperCase() : null;
        let val : number = -1;
        ops.forEach((v,i)=> {
            let uid = (v.id) ? v.id.toUpperCase() : null;
            if (uid == searchuid)
                val=v;
        });
        return val;
    }

    private getItemByIndex(items: Array<any>, index: number) {
        if (items)
            return items[index];
        else
            return -1;
    }

    private getItemIdByIndex(items: Array<any>, index: number) {
        if (items) {
            var t= items[index];
            return (t) ? t.id : null;
        }
        else {
            return null;
        }
    }

    private applyUpdate() {

        let opsForOsType = this.getOpsForOSType(this.platform.osType);
        let pl = this.currentProject.getCurrentPlatformInfo();
        if (pl) {
            var defn = new PlatformDefn();
            defn.osFrom = this.getItemIdByIndex(opsForOsType, this.osSlider.min);
            defn.osTo = this.getItemIdByIndex(opsForOsType, this.osSlider.max);
            defn.office = this.getItemIdByIndex(this.officeSlider.items, this.officeSlider.value);
            defn.virtualisationTechnology = this.getItemIdByIndex(this.virtualisationSlider.items, this.virtualisationSlider.value);
            defn.browser = this.getItemIdByIndex(this.browserSlider.items, this.browserSlider.value);
            defn.dotnet = this.getItemIdByIndex(this.dotNetSlider.items, this.dotNetSlider.value);
            defn.monthlyUpdate = this.getItemIdByIndex(this.updateDateSlider.items, this.updateDateSlider.value);
            this.platformHsh = defn.getLocalHash(pl.platformGuid);
            this.currentProject.updatePlatform(pl.platformGuid, defn);
        }
    }

    private getHashByPlatform() : LOCALHASH {
        let opsForOsType = this.getOpsForOSType(this.platform.osType);
        let pl = this.currentProject.getCurrentPlatformInfo();
        return PlatformDefn.getLocalHashFromPlatform(pl);
    }

}
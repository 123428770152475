<spinner *ngIf="!driveScanService.isDataReady()"></spinner>

<angular-slickgrid  gridId="griddrv" gridHeight="100%" gridWidth="100%" *ngIf="driveScanService.isDataReady() && !driveScanService.isScanInProgress()"
    [columnDefinitions]="columnDefinitions" 
    [gridOptions]="gridOptions"
    [dataset]="driveScanService.items" 
    (onAngularGridCreated)="handleGridReady($event)"
    (onGridStateChanged)="handleGridStateChanged($event)">
</angular-slickgrid>

<div fxLayout="column" fxFlexFill fxLayoutAlign="start center" fxLayoutGap="20px" *ngIf="driveScanService.isDataReady() && driveScanService.isScanInProgress()">
    <div fxFlex></div>
    <div flex="none" *ngIf="driveScanService.getScanProgressPercentage()==0">
        <div class="sw-loading-text">STARTING SCAN...</div>
    </div>
    <div flex="none" *ngIf="driveScanService.getScanProgressPercentage() > 0">
        <div class="sw-loading-text">SCANNING. {{driveScanService.getScanProgressPercentage()}}% COMPLETE.</div>
    </div>
    <div flex="none" *ngIf="driveScanService.getScanProgressPercentage() > 0">
        <mat-progress-spinner mode="determinate" value="{{driveScanService.getScanProgressPercentage()}}" diameter="60"></mat-progress-spinner>
    </div>
    <div flex="none" *ngIf="driveScanService.getScanProgressPercentage() > 0">
        <div class="sw-loading-text">{{driveScanService.getScanFolderCount()}} FOLDERS SEARCHED</div>
    </div>
    <div fxFlex></div>
</div>

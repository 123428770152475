import { Component, OnInit } from '@angular/core';
import { INPUTEDITARGS } from '../../model/INPUTEDITARGS';
import { Constants } from '../../api/Constants';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { CertUploadService } from 'src/app/svc/certUploadService';
import * as CRS from '../../api/CRS';

@Component({
  selector: Constants.ROUTE_PROJECTSETTINGSCONVERT,
  templateUrl: './project-settings-convert.html',
  styleUrls: ['../../shared/css/nav-bar.scss', '../../shared/css/view.scss', './project-settings.scss']
})
export class ProjectSettingsConvert implements OnInit {

  constructor(
    public currentProject: CurrentProjectService,
    public certUploadService: CertUploadService) { }

  ngOnInit(): void {
  }

  public get project() : CRS.RSGetProject {
    return this.currentProject.dataItem;
  }

  public get projectIsReady(): boolean {
    return this.currentProject.dataContext.isReady;
  }

  public canEdit(): boolean {
    return this.currentProject.canEdit();
  }

  public dragFileOver(state): void {
    var ele = document.getElementsByClassName("sw-cert-upload-container");
    if (state)
      $(ele).addClass("sw-cert-upload-container-over")
    else
      $(ele).removeClass("sw-cert-upload-container-over");
  }

  public showReportingConfigImageInReadmeChanged() {
    var vv = (this.project.showReportingConfigImageInReadme) ? "true" : null;
    this.currentProject.updateShowReportingConfigImageInReadme(vv);
  }

  public appvFullWriteModeChanged() {
    var vv = (this.project.appvFullWriteMode) ? "true" : null;
    this.currentProject.updateAppvFullWriteMode(vv);
  }

  public xcheckRequiresSilentArgsChanged() {
    var vv = (this.project.xcheckRequiresSilentArgs) ? "true": null;
    this.currentProject.updateXCheckRequiresSilentArgs(vv);
  }

  public disable8Dot3FilenameCreationChanged() {
    var vv = (this.project.disable8Dot3FilenameCreation) ? "true" : null;
    this.currentProject.updateDisable8Dot3FilenameCreation(vv);
  }

  public disableAutomaticDetectedDependencyInstallChanged() {
    var vv = (this.project.disableAutomaticDetectedDependencyInstall) ? "true" : null;
    this.currentProject.updateDisableAutomaticDetectedDependencyInstall(vv);
  }

  public msixPublisherNameChanged(args:INPUTEDITARGS) {
    this.currentProject.updateMsixPublisherName(args.value);
  }
 
  public msixPublisherDisplayNameChanged(args:INPUTEDITARGS) {
    this.currentProject.updateMsixPublisherDisplayName(args.value);
  }

  public xcheckImageToolsKeyChanged(args:INPUTEDITARGS) {
    this.currentProject.updateXCheckImageToolsKey(args.value);
  }

  public disableXCheckChanged() {
    var vv = (this.project.disableXCheck) ? "true" : null;
    this.currentProject.updateDisableXCheck(vv);
  }

  public snapshotDuringDiscoveryChanged() {
    var vv = (this.project.snapshotDuringDiscovery) ? "true" : null;
    this.currentProject.updateSnapshotDuringDiscovery(vv)
  }

  public sendXCheckVmCreationEmailChanged() {
    var vv = (this.project.sendXCheckVmCreationEmail) ? "true" : null;
    this.currentProject.updateSendXCheckVmCreationEmail(vv);
  }

  public updatePasswordsOnVmsChanged() {
    var vv = (this.project.updatePasswordsOnVms) ? "true" : null;
    this.currentProject.updatePasswordsOnVms(vv);
  }

  public preferInstallCommandFileChanged() {
    var vv = (this.project.preferInstallCommandFile) ? "true" : null;
    this.currentProject.updatePreferInstallCommandFile(vv);
  }

  // public videoRecordTestingJobsChanged() {
  //   var vv = (this.currentProject.dataContext.item().videoRecordTestingJobs) ? "true" : null;
  //   this.currentProject.updateVideoRecordTestingJobs(vv);
  // }

  public installCommandFilesChanged(args: INPUTEDITARGS) {
    this.currentProject.updateInstallCommandFiles(args.value);
  }

  public appIdCustomisationsChanged(args: INPUTEDITARGS) {
    this.currentProject.updateAppIdCustomisations(args.value);
  }

  public setApplyDelayedFixesChange() {
    var vv = (this.project.applyDelayedFixes) ? "true" : null;
    this.currentProject.applyDelayedFixes(vv);
  }

  public setUseVendorForPublisherDisplayNameChange() {
    var vv = (this.project.useVendorForPublisherDisplayName) ? "true" : null;
    this.currentProject.updateUseVendorForPublisherDisplayName(vv);
  }

  public msixManifestModificationsChanged(args:INPUTEDITARGS) {
    this.currentProject.updateMsixManifestModifications(args.value);
  }

  public setMsixPackagingToolVersionChange() {
    var vv = this.project.msixPackagingToolVersion;
    this.currentProject.updateMsixPackagingToolVersion(vv);
  }

  public signingTimestampUrlChanged(args: INPUTEDITARGS) {
    this.currentProject.updateSigningTimestampUrl(args.value);
  }

  public msixCertificatePasswordChanged(args: INPUTEDITARGS) {
    this.currentProject.updateMsixCertificatePassword(args.value);
  }

  public testUserTypeChanged() {
    this.currentProject.updateTestUserType(this.project.testUserType);
  }

}

import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserCacheService } from '../../svc/userCacheService';
import { AuthConfig } from 'src/app/authConfig';

@Component({
    selector: 'about-dialog',
    templateUrl: 'about-dialog.html'
})
export class AboutDialog {

    constructor(
        private dialogRef: MatDialogRef<boolean>,
        private userCacheService: UserCacheService
    ) {
    }

    $onInit() {
    }

    public get version() : string {
        return this.userCacheService.GetUserInfo().versionNo;
    }

    public get backEndVersion(): string {
        return this.userCacheService.GetUserInfo().backEndVersionNo;
    }

    public get apiUrl(): string {
        return AuthConfig.resourceUri;
    }

    public get jobRouterName(): string {
        var uinf = this.userCacheService.GetUserInfo();
        return uinf!=null?uinf.jobRouterName:null;
    }

    public openTerms($event) {
        $event.stopPropagation();
        window.open("https://applicationreadiness.com/terms-of-use/");
        return false;
    }

    isDataReady() : boolean {
        return this.userCacheService.isReady;
    }

    cancelDialog() {
        this.dialogRef.close();
    }
}


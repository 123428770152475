export interface IUpdatable<DtoType> {
    update(dto: DtoType): void;
}

export interface IDictionary<ValueType> {
    [key: string]: ValueType;
}

export class MergeHelper {

    public static ListMerge<T extends IUpdatable<D> & D, D>(listFrom: Array<D>, listTo: Array<T>, keyExtractor: (item: D) => any, type: { new(): T ;}) { 
        var mgr = new ListUpdater<T, D>();
        mgr.update(
            listFrom,
            listTo,
            (item: D) => { 
                let n = keyExtractor(item);
                let r = String(n);
                return r; 
            },
            () => { return new type() }
        );
    }

}

export class ListUpdater<InstanceType extends IUpdatable<DtoType> & DtoType, DtoType> {
    public update(
        dtoList: Array<DtoType>,
        itemList: Array<InstanceType>,
        keyExtractor: (item: DtoType) => string,
        factory: () => InstanceType,
    ): IDictionary<InstanceType> {

        if (!dtoList) { dtoList = []; }
        if (!itemList) { itemList = []; }
        
        let dtoDictionary: IDictionary<DtoType> = this.getDictionary(dtoList, keyExtractor);
        let itemDictionary: IDictionary<InstanceType> = this.getDictionary(itemList, <(item: InstanceType) => string>keyExtractor);

        dtoList.forEach((dto: DtoType) => {
            let key: string = keyExtractor(dto);
            let item: InstanceType = itemDictionary[key];

            if (item) {
                item.update(dto);
            } else {
                let newItem: InstanceType = factory();
                newItem.update(dto);
                itemList.push(newItem);
                itemDictionary[key] = newItem;
            }
        });

        let toDelete: Array<InstanceType> = [];
        itemList.forEach((item: InstanceType) => {
            let key: string = keyExtractor(item);
            let dto: DtoType = dtoDictionary[key];

            if (!dto) {
                toDelete.push(item);
            }
        });

        toDelete.forEach((item: InstanceType) => {
            let key: string = keyExtractor(item);
            itemList.splice(itemList.indexOf(item), 1);
            delete itemDictionary[key];
        });

        return itemDictionary;
    }

    public getDictionary<ListType>(list: Array<ListType>, keyExtractor: (item: ListType) => string): IDictionary<ListType> {
        let result: IDictionary<ListType> = {};

        list.forEach((item: ListType) => {
            result[keyExtractor(item)] = item;
        });

        return result;
    }
}

export class DataMerger<InstanceType extends IUpdatable<DtoType>, DtoType>  {
    public update(
        dtoList: Array<DtoType>,
        itemList: Array<InstanceType>,
        itemDict: { [key: string]: InstanceType },
        dtoKeyExtractor: (dtoItem: DtoType) => string,
        itemKeyExtractor: (item: InstanceType) => string,
        itemFactory: () => InstanceType
    ){
        if (!dtoList || !dtoList.length) {
            itemList.length = 0;
            for (var member in itemDict) {
                delete itemDict[member];
            }
            return;
        }

        let dtoDict: { [key: string]: DtoType } = {};
        // add/update
        dtoList.forEach(dto => {
            let key = dtoKeyExtractor(dto);
            dtoDict[key] = dto;

            let item = itemDict[key];
            if (item) {
                item.update(dto);
            } else {
                let newItem = itemFactory();
                newItem.update(dto);

                itemList.push(newItem);
                itemDict[key] = newItem;
            }
        });

        // delete
        let toDelete: Array<InstanceType> = [];
        itemList.forEach(item => {
            let key = itemKeyExtractor(item);
            let dto = dtoDict[key];

            if (!dto) {
                toDelete.push(item);
            }
        });

        toDelete.forEach(item => {
            itemList.splice(itemList.indexOf(item), 1);
            delete itemDict[itemKeyExtractor(item)];
        });
    }
}
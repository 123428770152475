import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { CurrentProjectService } from "../svc/currentProjectService";
import { MenuService } from '../svc/menuService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';
import { DialogService } from '../svc/dialogService';

import * as Enum from '../api/Enum';
import { UtilService } from '../svc/utilService';
import { UserCacheService } from '../svc/userCacheService';

export class DeleteProjectCommand {

    constructor(
        private injector: Injector,
    ) {
        this.router = this.injector.get(Router);
        this.menuService = this.injector.get(MenuService);
        this.dialogService = this.injector.get(DialogService);
        this.currentProject = this.injector.get(CurrentProjectService);
        this.utilService = this.injector.get(UtilService);
        this.userCacheService =this.injector.get(UserCacheService);
        
        this.menuService.register(Constants.MENU_DELETE_PROJECT, this.deleteCommand, "P-"+Constants.ROUTE_PROJECTSETTINGS )

        this.currentProject.dataContext.onStateChanged().subscribe(x=> {
            this.isProjectReady=x.loadState == Enum.LoadState.Ready;
            this.menuService.refresh(Constants.MENU_DELETE_PROJECT);
        });

    }

    private isProjectReady=false;
    private router: Router;
    private menuService: MenuService;
    private dialogService: DialogService;
    private currentProject: CurrentProjectService;
    private utilService: UtilService;
    private userCacheService: UserCacheService;

    private deleteCommand: AppCommand = AppCommand.create(
    ()=> { 
        return this.isProjectReady;
    },
    ()=> {
        this.dialogService.showYesNoMessage("Delete Project", ["Are you sure that you want to delete " + this.currentProject.dataItem.name + "?"])
        .pipe(take(1))
        .subscribe((ans) => {
            if (ans) {
                this.currentProject.delete().then((ret)=>{
                    if (ret) {
                        var uinf=this.userCacheService.GetUserInfo();
                        this.utilService.localStorageHelper.clearLastProject(uinf);
                        this.router.navigateByUrl("/");
                    }
                });
            }
        });
    });
}


import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'test-complete-dialog',
    templateUrl: 'test-complete-dialog.html',
    styleUrls:['./test-complete.scss']
})
export class TestCompleteDialog implements OnInit {

    constructor(
        private dialogRef: MatDialogRef<boolean>,
    ) {
    }

    public values: any= {outcome:"+", text:""};
 
    ngOnInit() {
    }

    isDataReady() : boolean {
        return true;
    }

    isValid(): boolean {
        return true;
    }

    closeDialog() {
        this.dialogRef.close(this.values);
    }

    cancelDialog() {
        this.dialogRef.close();
    }
}


export class RPTCHK {
    constructor(subItems: Array<string>) {
        subItems.forEach((x)=> {
            this.subChecked[x] =false;
        });
    }
    public mainChecked: boolean=false;
    public subChecked: { [key: string]: boolean } = {};
    public disabled: Array<string>=[];
    public asString() :string { 
        let ret :string = "";
        Object.keys(this.subChecked).forEach((k)=> {
            if (this.subChecked[k]) {
                ret+= ((ret=="")?"":",") + k;
            }
        });
        return ret;
    }
    public whenChanged: (i:string)=>void;
    public change(sub: string) :void {
        if (sub) {
            let anyChecked:boolean=false;
            Object.keys(this.subChecked).forEach((k)=> { if(this.subChecked[k]) anyChecked=true; })
            this.mainChecked = anyChecked;
        }
        else {
            Object.keys(this.subChecked).forEach((k)=> {
                if (this.disabled.indexOf(k) == -1)
                    this.subChecked[k]=this.mainChecked ;
            });
        }
        if (this.whenChanged)
            this.whenChanged(sub);
    }
    public checkSet(items: Array<string>) {
        let oneChecked:boolean=false;
        Object.keys(this.subChecked).forEach((k)=> {
            let v = items.indexOf(k) != -1;
            this.subChecked[k] = v ;
            if (v)
                oneChecked=true;
        });
        this.mainChecked = oneChecked;
    }
}
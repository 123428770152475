<table class="rag-distribution-line">
    <tr>
        <td *ngFor="let k of values" 
            [ngStyle]="{'display':isEmpty(k)?'none':'', 'width':getWidthExpression(k)}"
            title="{{k.title}}"
            class="sw-rag-dist rag-distribution-part">
            <div *ngIf="getWidthExpression(k)" [ngClass]="getCssName(k)">
                {{getPercentageDisplay(k)}}
            </div>
        </td>
    </tr>
</table>

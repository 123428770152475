import { Injectable, Injector } from '@angular/core';
import { AssessmentGroupService } from '../api/SVC';
import { Observable, Subject } from 'rxjs';
import { UtilService } from './utilService';
import { StateHelperService } from './stateHelperService';
import { DataContextProvider } from './DataContextProvider';
import { CurrentProjectService } from './currentProjectService';
import { EventService } from '../svc/eventService';
import { Constants } from '../api/Constants';

import * as SVC from '../api/SVC';
import * as CRS from '../api/CRS'
import * as Enum from '../api/Enum';

@Injectable({
    providedIn: 'root'
})
export class CurrentAssessmentGroupService {

    constructor( 
        private assessmentGroupService: AssessmentGroupService,
        private currentProject: CurrentProjectService,
        private projectService: SVC.ProjectService,
        private eventService: EventService,
        private utilService: UtilService,
        private stateHelperService: StateHelperService
    ) { 
        this.dataContext = new DataContextProvider<CRS.ResponseWrapper<CRS.RSGetAssessmentGroup>>(
            this.utilService,
            this.stateHelperService, 
            ()=>{return this.stateHelperService.details.assessmentGroupId},
            null, 
            (id,refresh)=>{return this.mainLoad(id, refresh)});

        this.dataContext.setName("AssessmentGroups");
    }

    public dataContext: DataContextProvider<CRS.ResponseWrapper<CRS.RSGetAssessmentGroup>>;

    public get dataItem(): CRS.RSGetAssessmentGroup {
        return this.dataContext.isReady ? this.dataContext.item().data: null;
    }

    public isCurrentCheckEnabledResolved:boolean = false;
    public currentCheckEnabled:boolean=false;
    public currentCheckEnabledRuleId:string=null;

    public getIssuesForCheck(checkId:string) : Observable<CRS.RSGetIssuesForCheck> {
        this.isCurrentCheckEnabledResolved=false;
        this.currentCheckEnabledRuleId=null;
        var obs =new Subject<CRS.RSGetIssuesForCheck>();
        this.assessmentGroupService.getIssuesForCheck(this.currentProject.dataContext.id, checkId, true).then((response) => {
            this.isCurrentCheckEnabledResolved=true;
            this.currentCheckEnabled=response.data.isEnabled;
            this.currentCheckEnabledRuleId=response.data.enabledRuleId;
            obs.next(response.data);
        });
        return obs;
    }

    public disableAssessmentGroup(id:string) {
        this.projectService.createProjectRule(this.currentProject.dataContext.id, Enum.ProjectRuleType.AssessmentGroupDisable, id).then((response)=> {
            this.dataItem.isEnabled=false;
            this.dataItem.assessmentGroupDisableRuleId=response.data.projectRuleId;
            this.eventService.__projectDetailsUpdateRequestTrigger();
            this.dataContext.refreshCurrent();
        })
    }

    public enableAssessmentGroup(id:string) {
        if(this.dataContext.isReady && this.dataContext.id == id) {
            this.projectService.removeProjectRule(this.dataItem.assessmentGroupDisableRuleId).then((data)=> {
                this.dataItem.assessmentGroupDisableRuleId=null;
                this.dataItem.isEnabled=true;
                this.eventService.__projectDetailsUpdateRequestTrigger();
                this.dataContext.refreshCurrent();
            });
        }
    }

    public disableCheck(id:string) {
        this.projectService.createProjectRule(this.currentProject.dataContext.id, Enum.ProjectRuleType.CheckDisable, id).then((data)=> {
            var clst =this.dataItem.checks.filter(x=>x.checkId == id);
            if (clst.length>0) 
                clst[0].isEnabled=false;
            this.eventService.__checkEnabledStateChangedTrigger();
        });
    }

    public enableCheck(id:string) {
        this.projectService.removeProjectRule(this.currentCheckEnabledRuleId).then((data)=> {
            var clst =this.dataItem.checks.filter(x=>x.checkId == id);
            if (clst.length>0)
                clst[0].isEnabled=true;
            this.eventService.__checkEnabledStateChangedTrigger();
        });
    }


    private mainLoad(id:string, refreshOnly: boolean) : Promise<CRS.ResponseWrapper<CRS.RSGetAssessmentGroup>> {
        return this.assessmentGroupService.getAssessmentGroup(this.currentProject.dataContext.id, this.dataContext.id, refreshOnly)
    }

}
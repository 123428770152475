<h2 mat-dialog-title>SAVE MSI CHANGES</h2>
  
<div mat-dialog-content class='sw-dialog-padding' style="width:550px">
    <div fxFlex>
        <p>Persist changes made to this package and use as the media for this application?</p>
    </div>
</div>

<div mat-dialog-actions fxLayout='row' fxLayoutGap="10px">
    <div fxFlex></div>
    <div fxFlex="none">
        <button mat-raised-button color="primary" (click)='closeDialog(1)' [disabled]="!isValid()">
            <mat-icon>save</mat-icon>
            <span>&nbsp;Save</span>
        </button>
    </div>
    <div fxFlex="none">
        <button mat-raised-button color="secondary" (click)='closeDialog(2)' [disabled]="!isValid()">
            <mat-icon>undo</mat-icon>
            <span>&nbsp;Discard</span>
        </button>
    </div>
    <div fxFlex="none">
        <button mat-raised-button (click)='cancelDialog()'>
            <mat-icon>cancel</mat-icon>
            <span>&nbsp;Cancel</span>
        </button>
    </div>
</div>

import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserCacheService } from '../../svc/userCacheService';

@Component({
    selector: 'help-dialog',
    templateUrl: 'help-dialog.html'
})
export class HelpDialog {

    constructor(
        private dialogRef: MatDialogRef<boolean>,
        private userCacheService: UserCacheService
    ) {
    }

    public activePage: number=0;

    public setActivePage(value:number) {
        this.activePage=value;
    }

    $onInit() {
    }

    cancelDialog() {
        this.dialogRef.close();
    }
}


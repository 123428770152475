<div #con1 fxLayout="row" fxFlexFill>

    <div id="#sg1" fxFlex *ngIf="!hasApplicationContext" ng2FileDrop [uploader]="appUploadService.uploader" >
        <angular-slickgrid gridId="grid1" gridHeight="100%" gridWidth="100%" 
            [columnDefinitions]="gridColumnDefs" 
            [gridOptions]="gridOptionsFull"
            [dataset]="applications"
            (onAngularGridCreated)="appAngularGridReady($event)"
            (onGridStateChanged)="appGridStateChanged($event)">
         </angular-slickgrid>        
    </div>  

    <div id="#sg2" fxFlex="none" *ngIf="hasApplicationContext && !isAppBrowsing()"> 
        <div style="width:500px;height:100%;overflow: hidden">
            <angular-slickgrid gridId="grid2" gridHeight="100%" gridWidth="100%"
                [columnDefinitions]="gridColumnDefsSide" 
                [gridOptions]="gridOptionsSide"
                [dataset]="applications"
                (onAngularGridCreated)="appAngularGridReady($event)"
                (onGridStateChanged)="appGridStateChanged($event)">
            </angular-slickgrid>        
        </div>
    </div> 

    <div fxFlex *ngIf="!stateHelperInstance.isCurrent()" >
        <router-outlet></router-outlet>  
    </div> 

</div>

<input id='projectUploadElement' multiple type="file" ng2FileSelect [uploader]="appUploadService.uploader" style='display: none' />


<table class="full-width-bs">

    <tr *ngFor="let rule of qrh.rules">

        <td>
            <div class="sw-pad full-width-bs sw-rule">
                <div>
                    <label class="sw-pad-bottom">{{rule.id}}</label>
                </div>
                <div class="sw-pad-right">
                    <input type="text" [(ngModel)]="rule.name" class="full-width" (change)="qrh.ruleChanged(rule)">
                </div>
            </div>
        </td>
        <td *ngFor="let r of rule.expressionChain" class="sw-pad sw-standards-rule-part" style="width:200px">
            <mat-select [(ngModel)]="r.element" placeholder="Select filter" (selectionChange)="qrh.expressionChanged(r)" class="sw-nomargin" title="{{r.element?.description}}">
                <mat-option *ngFor="let item of qrh.getSubElements(r.previous)" [value]="item" title="{{item.description}}">{{item.title}}</mat-option>
            </mat-select>
            <input [(ngModel)]="r.contextValue" (change)="qrh.ruleChanged(rule)" *ngIf="r.hasContextValue" style="width:200px">
        </td>
        <td *ngIf="qrh.getColumnFiller(rule) > 0" [attr.colspan]="qrh.getColumnFiller(rule)">
        </td>
        <td style="width:100px">
            <button *ngIf="qrh.canDeleteRule()" mat-icon-button (click)="qrh.deleteRule(rule)" class="sw-tbl-btn" style="min-width:24px;min-height: 24px;">
                <mat-icon>cancel</mat-icon>
            </button>
            <button *ngIf="rule.isValid && rule.dirty" mat-icon-button (click)="qrh.saveRule(rule)" class="sw-tbl-btn">
                <mat-icon>save</mat-icon>
            </button>
            <button *ngIf="rule.isValid" mat-icon-button (click)="qrh.executeRule(rule)" class="sw-tbl-btn">
                <mat-icon>play_arrow</mat-icon>
            </button>
            <button *ngIf="qrh.isLastRule(rule)" mat-icon-button (click)="qrh.addRule()" class="sw-tbl-btn">
                <mat-icon>add</mat-icon>
            </button>
        </td>

    </tr>

</table>


import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { Subscription } from 'rxjs';
import { Chart } from 'chart.js';
import { UtilService } from '../../svc/utilService';
import { WorkflowStageStatus } from '../../api/Enum';

import * as PatchExt from '../../model/PatchExt';
import * as CRS from '../../api/CRS';

@Component({
    selector: "workflow-bar",
    templateUrl: './workflow-bar.html',
    styleUrls: ['./workflow-bar.scss']
})
export class WorkflowBar implements OnInit, OnDestroy {

    constructor(
        private utilService: UtilService,
        private currentProject: CurrentProjectService
    ) {
    } 

    public items: Array<PatchExt.XChartData>=null;
    private colours: Array<string>= [ '#FF8028', '#E01D1E', '#00C630', '#008000', '#46BFBD', '#8060D8', '#949FB1', '#4D5360']
    private obs: MutationObserver=null;
    private initialisedCharts = {};
    private readyToBuild : boolean = false;
    private subscriptions: Array<Subscription> =new Array<Subscription>();

    @Output() onClose = new EventEmitter<boolean>();

    ngOnInit() {
        this.currentProject.dataContext.onLoaded().subscribe((response)=>{
            this.buildChartData(response.data);
            if (this.readyToBuild)
                this.buildCharts();
        });

        this.createObserver();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(x=>x.unsubscribe());
        this.obs.disconnect();
    }

    public close($event: Event) {
        this.onClose.emit();
        $event.preventDefault();
    }

    private createObserver() {
        this.obs = new MutationObserver((x) => {
            this.buildCharts();
        });
        let c = { attributes: true, subtree: true, childList: true};
        var dr = document.getElementById("control_root");
        this.obs.observe(dr, c);
    }

    private buildChartData(prj:CRS.RSGetProject) {

        if (this.items==null)
            this.items = [];

        var dict = {};
        prj.workflowStageList.forEach((wf,i)=> {
            let cdlst = this.items.filter(x=>x.title == wf.name);
            if (cdlst.length==0) {
                let cd = new PatchExt.XChartData();
                var cidx = i % this.colours.length;
                cd.colours = this.getColours(this.colours[cidx]);
                cd.labels =  this.utilService.getEnumNames(WorkflowStageStatus);
                cd.data = wf.counts.map(x=>[x.count]);
                cd.title= wf.name;
                cd.title2 = `${wf.percentage}%`;
                this.items.push(cd);
            }
            else {
                let cd = cdlst[0];
                cd.data = wf.counts.map(x=>[x.count]);
            }
        });
        this.readyToBuild=true;
    }

    private buildCharts() {
        if (!this.readyToBuild)
            return;
        var chts = document.getElementsByClassName("chart");
        for(var i=0; i<chts.length; i++) {
            var idnum = parseInt(chts[i].id.replace(/[a-z]/g,""));
            var c = <HTMLCanvasElement>document.getElementById(chts[i].id);
            var cht = this.initialisedCharts[c.id];
            if (cht) {
                let chartData = this.items[idnum];
                cht.data.datasets[0].data = chartData.data;
                cht.update();
            }
            else {
                this.initialisedCharts[c.id] = this.setupChart(c, [this.items[idnum].title, this.items[idnum].title2], this.items[idnum]);
            }
        }
    }

    private getColours(base:string)
    {
        return [ base + "40", base +"80", base + "a0", base + "f0"];
    }

    private setupChart(ctx:HTMLCanvasElement, titles: string[], chartData: PatchExt.XChartData) : Chart {
        var data = {
                labels: chartData.labels,
                datasets: [
                    { data: chartData.data, backgroundColor: chartData.colours }
                ]
            };

        var options =  {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            title: {
                display: true,
                text: titles,
                fontSize:14,
                fontFamily:'Ubuntu'
            },
            plugins: {
                 labels: { render: 'label' },
        //         //centretitle: {title: titles, font: '16px Ubuntu', fillStyle:'#000'}
            },
        };
        
        return new Chart(ctx, {
            type: 'doughnut',
            data:data,
            options: options
        });
    }
}
<table *ngIf='hasChecksForCurrentPlatform()' class="full-width" [ngClass]="isAssessmentGroupEnabled() ? 'sw-table' : 'sw-table-disabled'">
    <thead>
        <tr>
            <th></th> <!--table-util-value="rag"-->
            <th>Name</th>
            <th *ngIf=false>Enabled</th>
            <th *ngIf="!narrow" style="width:200px">Application RAG Distribution</th> <!--table-util-value="green,blue,amber,red"-->
            <!-- <th style="width:30px"><table-utils></table-utils></th> -->
        </tr>
    </thead>
    <tbody>
        <tr *ngFor='let check of checksForCurrentPlatform' (click)='openCheck(check)' [ngClass]="{'sw-activerow' : isSelected(check)}"> 
            <td class='sw-right-pad'> <!-- table-util-value="{{getCheckRag(check)}}">-->
                <rag-column [rag-element]='check' [neutralise]='!check.isEnabled'></rag-column>
            </td>
            <td>
                <div>
                    {{check.name}}
                </div>
                <div class="sw-secondary-text" *ngIf="!narrow && check.name != check.description && check.description"> 
                    {{check.description}}
                </div>
            </td>
            <td *ngIf="false">{{check.isEnabled}}</td>  
            <td *ngIf="!narrow">  <!--table-util-value="{{getRagDistributionSerialised(check)}}" -->
                <rag-distribution [rag]="check" [enabled]="check.isEnabled"></rag-distribution>
            </td>
        </tr>
    </tbody>
</table> 

<div *ngIf="!hasChecksForCurrentPlatform()" class="sw-big-pad">
    There are no applicable checks for the current platform within this assessment group
</div>
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from "rxjs";
import { UtilService } from '../../svc/utilService';
import { CurrentConversionJobService } from '../../svc/currentConversionJobService';
import { Constants } from '../../api/Constants';
import { Router } from '@angular/router';
import { StateHelperService } from '../../svc/stateHelperService';

import * as CRS from '../../api/CRS';

@Component({
  selector: Constants.ROUTE_PROJECTACTIONDETAILS_MAIN,
  templateUrl: './project-action-details.html'
})
export class ProjectActionDetails implements OnInit, OnDestroy {

  constructor(
    private utilService: UtilService,
    private router: Router,
    private stateHelperService: StateHelperService,
    private currentConversionJob: CurrentConversionJobService
    ) { }

  private subscriptions: Array<Subscription> =new Array<Subscription>();

  ngOnInit(): void {
      this.update();
      this.subscriptions.push(this.stateHelperService.onStateChange().subscribe(()=> this.update()));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x=>x.unsubscribe());
  }

  public get jobHasTask(): boolean {
    return this.currentConversionJob.hasTask();
  }

  public get jobIsCompletedRuntimeTest(): boolean {
    return this.currentConversionJob.isCompletedRuntimeTest();
  }

  public get jobIsUnstartedTestJob(): boolean {
    return this.currentConversionJob.isUnstartedTestJob();
  }

  public get jobIsEditJob(): boolean {
    return this.currentConversionJob.isEditJob();
  }

  public get jobIsUnstartedConversionJob(): boolean {
    return this.currentConversionJob.isUnstartedConversionJob();
  }

  public get jobIsUnstartedPublishJob(): boolean {
    return this.currentConversionJob.isUnstartedPublishJob();
  }

  public get jobIsFailed(): boolean {
    return this.currentConversionJob.isFailed();
  }

  public get jobIsInProgress(): boolean {
    return this.currentConversionJob.isInProgress();
  }

  public isDataReady() : boolean {
    return this.currentConversionJob.dataContext.isReady;
  }
  
  private update() {
    var jobId = this.stateHelperService.details.actionId;
  }

}

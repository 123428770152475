import { AppNote } from './AppNote';
import { KeyValuePair } from './KeyValuePair';
import { CurrentProjectService } from '../svc/currentProjectService';

export class ProjectNotes {

    constructor(currentProject: CurrentProjectService) {
        this.currentProject = currentProject;
    }

    private _dirty: boolean =false;
    private _currentItems: Array<AppNote>=null;
    private currentProject: CurrentProjectService;

    public get currentItems() : Array<AppNote> {
        return this._currentItems;
    }

    public get isDataReady() {
        return this._currentItems != null;
    }

    public markDirty() : void {
        this._dirty=true;
    }

    public get isDirty(): boolean {
        return this._dirty;
    }

    public reset() : void {
        this.initialise();
    }

    public add() : void {
        this._currentItems.push(new AppNote("","","",null));
    }

    public remove(index:number) : void {
        if (index>=0 && index <this._currentItems.length) {
            this._currentItems.splice(index, 1);
            this._dirty=true;
        }
    }

    public isEmpty(index:number) : boolean {
        var item = this._currentItems[index];
        return (!item.id || item.id.trim().length == 0)&& (!item.value || item.value.trim().length ==0 );
    }

    public apply() {
        var flattened="";
        this._currentItems.forEach((v)=>{
            if (flattened!="")
                flattened+=";";
            flattened += `${v.id}:${v.label}=${v.value}`;
        })
        this.currentProject.updateProjectNotes(flattened);
        this._dirty=false;
    }

    public isValid() :boolean {
        if (!this._currentItems)
            return false;
        var ids: Array<string> =[];
        var ret=true;
        this._currentItems.forEach((x)=>{
            if (x.id || x.label || x.value) {
                var idHas = x.id && x.id.trim().length>0;
                var lblHas = x.label && x.label.trim().length>0;
                var valHas = x.value && x.value.trim().length>0;
                if (idHas && ids.indexOf(x.id.toLowerCase()) != -1)
                    ret= false;
                    ids.push(x.id.toLowerCase());
                    if (!idHas || !lblHas)
                        ret=false;
                    if (ret && idHas) {
                        var regex = new RegExp(/^[A-Za-z0-9_]+$/);
                        try {
                            if(x.id.match(regex) == null)
                                ret= false;
                        }
                        catch(ex) {
                            ret= false;
                        }
                    }
                    if (ret && lblHas) {
                        var regex = new RegExp(/^[A-Za-z0-9_\s]+$/);
                        try {
                            if(x.label.match(regex) == null)
                                ret= false;
                        }
                        catch(ex) {
                            ret= false;
                        }
                    }
                }
        });
        return ret;
    }

    public initialise() :void {
        var ret= new Array<AppNote>();
        this.currentProject.dataContext.item().data.projectNotes.forEach((v)=> {
            ret.push(new AppNote(v.id,v.name,v.value, null));
        });
        ret.push(new AppNote("","","", null));
        this._currentItems=ret;
        this._dirty=false;
    }

}
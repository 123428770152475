import { Injector } from '@angular/core';
import { DriveScanService } from "../svc/driveScanService";
import { MenuService } from '../svc/menuService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';

export class QueueImportCommand {

    constructor(
        private injector: Injector
    ) {
        this.menuService = this.injector.get(MenuService);
        this.driveScanService = this.injector.get(DriveScanService);

        this.menuService.register(Constants.MENU_QUEUE_IMPORT, this.queueImport, Constants.ROUTE_PROJECTDRIVES )

        this.driveScanService.onSelectedItemsChanged().subscribe(()=>{
            this.menuService.refreshAll();
        })
    }

    private menuService: MenuService;
    private driveScanService: DriveScanService;

    private queueImport: AppCommand = AppCommand.createWithShow(
        ()=> {
            if (this.driveScanService.isScanInProgress())
                return false;
            return this.driveScanService.anySelectedItems();
        } ,
        ()=> {
            this.driveScanService.queueCheckedItems();
        }, 
        ()=> {
            return true;
        }
      );
  
}

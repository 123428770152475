<div mat-dialog-title>
  <div fxLayout="row">
    <div fxFlex>
      <h2>Project Activity</h2>
    </div>
    <div fxFlex="none">
      <mat-select [(ngModel)]="duration" (ngModelChange)="durationChanged($event)">
        <mat-option value="D-1">1 day</mat-option>
        <mat-option value="D-7">7 days</mat-option>
        <mat-option value="M-1">1 month</mat-option>
        <mat-option value="M-2">2 months</mat-option>
      </mat-select>
    </div>
  </div>
</div>

<div mat-dialog-content class="full-width-bs" style="height:320px;overflow: hidden">
  <grid-assist-panel [grid]="gridAssist"></grid-assist-panel>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Close</button>
</div>


<div class='sw-segment full-height-bs'>

    <div class="sw-pad">
        <div *ngFor="let f of patchCat?.items">
            <div fxLayout="row" style="max-width:300px">
                <div fxFlex class="sw-patch-file sw-pad-small" style="font-size: 10pt;">{{f?.filename}}</div>
                <div fxFlex="none" class="sw-delete-file-button">
                    <div (click)='deletePatchFilename(f)' title="Delete" style="height: 5px;"> 
                        <mat-icon class="delete-icon">cancel</mat-icon>
                    </div>
                </div>
            </div>
        </div>

        <div style="max-width: 200px" *ngIf="patchCat" class="sw-vert-pad-top">
            <input-edit datatype="STRING" label="Additional Filename" [value]="newFilename" (on-change)="fileAdded($event)"></input-edit>
        </div>

    </div>

</div>  


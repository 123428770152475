import { Injector } from '@angular/core';
import { CurrentConversionJobService } from "../svc/currentConversionJobService";
import { MatDialog } from '@angular/material/dialog';
import { AppCommand } from '../model/AppCommand';
import { DialogService } from '../svc/dialogService';
import { TestCompleteDialog } from '../dialogs/test-complete/test-complete-dialog';
import { SaveMsiEditDialog } from '../dialogs/save-msi-edit/save-msi-edit-dialog';
import { MenuService } from '../svc/menuService';
import { Constants } from '../api/Constants';

export class TaskReleaseCommand {

    constructor(
        private injector: Injector
    ) {
        this.dialog = this.injector.get(MatDialog);
        this.menuService = this.injector.get(MenuService);
        this.currentConversionJob = this.injector.get(CurrentConversionJobService);
        this.dialogService = this.injector.get(DialogService);

        this.menuService.register(Constants.MENU_TASK_RELEASE, this.cmd, Constants.ROUTE_PROJECTAPPLICATIONACTIONSDETAIL_MAIN )
    }

    private dialog: MatDialog;
    private dialogService: DialogService;
    private currentConversionJob: CurrentConversionJobService;
    private menuService: MenuService;

    public cmd: AppCommand = AppCommand.create(
    ()=> {
        if(!this.currentConversionJob.hasTask())
            return false;
        var tsk =this.currentConversionJob.dataItem.task;
        return tsk.canRelease &&  tsk.canShowRemoteWindow && (tsk.isWaitingManualModStage || tsk.uiData !=null);
    },
    ()=> {
        var tsk =this.currentConversionJob.dataItem.task;
        if (tsk.isWaitingManualModStage) {
            this.currentConversionJob.setManualModificationStageState(Constants.CONV_EVTEXT_MANUALMOD_DONE);
        }
        else if (this.currentConversionJob.dataItem.jobType == Constants.JOBTYPE_MSIEDIT) {
            this.launchSaveMsiEditDialog();
        }
        else {
            this.launchTestCompleteDialog();
        }
    });

    private launchTestCompleteDialog() :void {
        const dialogRef = this.dialog.open(TestCompleteDialog);
        dialogRef.afterClosed().subscribe( answer => {
            if(answer) {
                let testOutcome: string = answer["outcome"];
                let testText: string = answer["text"];
                this.currentConversionJob.releaseTestMachine(testOutcome,testText).then((result) => {
                    this.menuService.refreshAll();
                });
            }
        });
    }
    
    private launchSaveMsiEditDialog() :void {
        const dialogRef = this.dialog.open(SaveMsiEditDialog);
        dialogRef.afterClosed().subscribe( answer => {
            if(answer) {
                this.currentConversionJob.releaseTestMachine('+','').then((result) => {
                    this.menuService.refreshAll();
                });
            }
        });
    }
}

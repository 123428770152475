<div class='sw-nav-bar' fxLayout="column">
    <ul fxFlex>
        <li *ngFor="let ni of conditionFilter(navItems)" class="{{getClass(ni)}}" (click)="disableClick() || invoke(ni)">
            <sw-button-text *ngIf="!ni.alert || !ni.alert()" [text]="ni.text" [icon]="ni.icon" [collapsed]="!isMaximised()" [disabled]="!isReady()"></sw-button-text>
            <sw-button-text *ngIf="ni.alert && ni.alert()" [text]="ni.text" [icon]="'keyboard'" [icon-class]="'alert'" [collapsed]="!isMaximised()"></sw-button-text>
        </li>
    </ul>

    <div fxFlex="none" *ngIf="isReady() && isMaximised()">
        <div fxLayout="column" fxLayoutGap="7px" class="sw-drawer-panel sw-pad">
            <button mat-mini-fab [color]="contextViewValue=='WF'?'current':'disable'" fxFlex (click)="onDrawerClick('WF')">
                <mat-icon>pie_chart</mat-icon>
            </button>
            <button mat-mini-fab [color]="contextViewValue=='PE'?'current':'disable'" fxFlex (click)="onDrawerClick('PE')">
                <mat-icon>tune</mat-icon>
            </button>
        </div>  
    </div> 

    <div fxFlex="none"><div class="sw-pad"></div>

</div>

<div fxLayout="column" fxLayoutAlign="center stretch" class='sw-image-upload'>

    <div fxFlex [hidden]='!projectHasImage()'>
        <div fxLayout="column" fxFill fxLayoutAlign="center center">
            <div fxFlex></div>
            <div fxFlex="none">
                <img [src]='image' class="sw-image-upload"> 
            </div>
            <div fxFlex></div>
        </div>
    </div> 

    <div fxFlex [hidden]='projectHasImage()' class="full-width">
        <!-- <mat-icon>wallpaper</mat-icon> -->
         <div fxLayout="column" fxFill fxLayoutAlign="center center">
            <div fxFlex></div>
            <div fxFlex="none">
                <mat-icon>wallpaper</mat-icon>
            </div> 
            <div fxFlex></div>
         </div>  
    </div>

    <div fxFlex="none" class="full-width">

        <button (click)='openFile()' [disabled]="disable" class="full-width">
            <i class='material-icons'>photo</i>
            Select image
        </button> 

        <input #uploadButton type='file' (change)="filesChanged()" accept=".png,.jpg" class="sw-hide">

    </div>

</div>
import { Component, Input, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { UtilService } from '../../svc/utilService';
import { Constants } from '../../api/Constants';
import { StateHelperService } from '../../svc/stateHelperService';

import * as CRS from '../../api/CRS';

@Component({
  selector: 'xcheck-output-firewall',
  templateUrl: './xcheck-output-firewall.html',
})
export class XCheckOutputFirewall implements OnInit {

  constructor(
      private router:Router, 
      private route:ActivatedRoute, 
      private stateHelperService: StateHelperService,
      private utilService:UtilService) { }

  @Input('item') public item:CRS.RSXCheckResult;

  ngOnInit(): void {
  }

  public hasItems() : boolean {
    return this.item && this.item.firewallItems.length > 0;
  }

}

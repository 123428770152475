import { Constants } from '../api/Constants';
import { SpineError } from '../model/SpineError';
import { UtilService } from '../svc/utilService';

import * as CRS from '../api/CRS';

export class LocalStorageHelper {

    constructor(utilService: UtilService, getHost: ()=>string ) {
        this._getHost = getHost;
        this._utilService = utilService;
    }

    private _getHost: ()=>string;
    private _utilService: UtilService;

    public getLastProject(userInfo: CRS.RSGetUserInformation ) : string {
        var pwiths = localStorage.getItem(this.HostWrap(Constants.LOCALSET_LAST_REFERENCED_PROJECT));
        var spl = pwiths? pwiths.split('@') :[];
        if (spl.length >0)
            return spl[0];
        return null;
    }

    public getLastServerId(userInfo: CRS.RSGetUserInformation ) : string {
        var pwiths = localStorage.getItem(this.HostWrap(Constants.LOCALSET_LAST_REFERENCED_PROJECT));
        var spl = pwiths? pwiths.split('@') :[];
        if (spl.length == 2)
            return spl[1];
        return null;
    }

    public setLastProject(userInfo: CRS.RSGetUserInformation, projectId:string, serverId:string) : void {
        if (projectId) {
            var pwiths = `${projectId}@${serverId}`;
            localStorage.setItem(this.HostWrap(Constants.LOCALSET_LAST_REFERENCED_PROJECT), pwiths);
        }
    }

    public clearLastProject(userInfo: CRS.RSGetUserInformation) : void {
        if (this._utilService.serverId)
            localStorage.removeItem(this.HostWrap(Constants.LOCALSET_LAST_REFERENCED_PROJECT));
    }

    public get LastError() : SpineError {
        var le= localStorage.getItem(this.HostWrap(Constants.LOCALSET_LAST_ERROR));
        if (!le)
            return null;
        else
            return <SpineError>JSON.parse(le);
    }

    public set lastError(value: SpineError) {
        if (!value)
            localStorage.removeItem(this.HostWrap(Constants.LOCALSET_LAST_ERROR));
        else
            localStorage.setItem(this.HostWrap(Constants.LOCALSET_LAST_ERROR), JSON.stringify(value));
    }

    public get activityListDuration() : string {
        return localStorage.getItem(this.HostWrap(Constants.LOCALSET_ACTIVITY_LIST_DURATION))??"D-7";
    }

    public set activityListDuration(value:string) {
        if (!value || value.length == 0)
            localStorage.removeItem(this.HostWrap(Constants.LOCALSET_ACTIVITY_LIST_DURATION));
        else
            localStorage.setItem(this.HostWrap(Constants.LOCALSET_ACTIVITY_LIST_DURATION), value);
    }

    public get projectSearchText() : string {
        return localStorage.getItem(this.HostWrap(Constants.LOCALSET_PROJECT_SEARCH_TEXT));
    }

    public set projectSearchText(value: string) {
        if (!value || value.length == 0)
            localStorage.removeItem(this.HostWrap(Constants.LOCALSET_PROJECT_SEARCH_TEXT));
        else
            localStorage.setItem(this.HostWrap(Constants.LOCALSET_PROJECT_SEARCH_TEXT), value);
    }

    public get includeEvalResultsInReadme() : boolean {
        return localStorage.getItem(this.HostWrap(Constants.LOCALSET_SHOW_EVAL_RESULTS_IN_README)) == "Y"; 
    }

    public set includeEvalResultsInReadme(value:boolean) {
        localStorage.setItem(this.HostWrap(Constants.LOCALSET_SHOW_EVAL_RESULTS_IN_README), (value) ? "Y" : "N");
    }

    public get showClickerPanel() : boolean {
        return localStorage.getItem(this.HostWrap(Constants.LOCALSET_SHOW_CLICKER_PANEL)) == "Y"; 
    }

    public set showClickePanel(value:boolean) {
        localStorage.setItem(this.HostWrap(Constants.LOCALSET_SHOW_CLICKER_PANEL), (value) ? "Y" : "N");
    }
    public get useArpProductProperties() :boolean {
        return localStorage.getItem(this.HostWrap(Constants.LOCALSET_USE_ARP_PRODUCT_PROPS)) == "true";
    }

    public set useArpProductProperties(value:boolean){
        localStorage.setItem(this.HostWrap(Constants.LOCALSET_USE_ARP_PRODUCT_PROPS),(value)?"true":null);
    }
    
    public get fixedIssuesVisible() : boolean {
        return localStorage.getItem(this.HostWrap(Constants.LOCALSET_FIXED_ISSUES_VISIBLE)) == "true";
    }

    public set fixedIssuesVisible(value: boolean) {
        localStorage.setItem(this.HostWrap(Constants.LOCALSET_FIXED_ISSUES_VISIBLE), (value) ? "true" : null);
    }

    public get viewEmptyTable() : boolean{
        return localStorage.getItem(this.HostWrap(Constants.LOCALSET_VIEW_EMPTY_TABLES)) == "true";
    }

    public set viewEmptyTables(value:boolean) {
        localStorage.setItem(this.HostWrap(Constants.LOCALSET_VIEW_EMPTY_TABLES), (value) ? "true" : null);
    }

    public get showVmsPanel() :boolean {
        return localStorage.getItem(this.HostWrap(Constants.LOCALSET_SHOW_VMS_PANEL)) == "true";
    }

    public set showVmsPanel(value:boolean) {
        localStorage.setItem(this.HostWrap(Constants.LOCALSET_SHOW_VMS_PANEL), String(value));
    }

    public get viewInternalTables() : boolean {
        return localStorage.getItem(this.HostWrap(Constants.LOCALSET_VIEW_INTERNAL_TABLES)) == "true";
    }

    public set viewInternalTables(value:boolean) {
        localStorage.setItem(this.HostWrap(Constants.LOCALSET_VIEW_INTERNAL_TABLES),String(value));
    }

    public get contextViewer(): string {
        return localStorage.getItem(this.HostWrap(Constants.LOCALSET_CONTEXT_VIEWER)) || Constants.CTXT_VIEWER_PLATFORM;
    }

    public set contextViewer(value:string){
        localStorage.setItem(this.HostWrap(Constants.LOCALSET_CONTEXT_VIEWER), value);
    }

    public get mergeTransformsDuringPublish() :boolean {
        var v=localStorage.getItem(this.HostWrap(Constants.LOCALSET_MERGE_TRANSFORMS_DURING_PUBLISH));
        if (v === undefined || v == null)
            return true;
        return localStorage.getItem(this.HostWrap(Constants.LOCALSET_MERGE_TRANSFORMS_DURING_PUBLISH)) == "Y";
    }

    public set mergeTransformsDuringPublish(value:boolean) {
        localStorage.setItem(this.HostWrap(Constants.LOCALSET_MERGE_TRANSFORMS_DURING_PUBLISH), value?"Y":"N");
    }

    public get selectedAssessmentGroupCharts(): Array<string> {
        var val = localStorage.getItem(this.HostWrap(Constants.LOCALSET_ASSESSMENT_GROUP_CHARTS));
        return (val) ? val.split(',') : [];
    }
    public set selectedAssessmentGroupCharts(value:Array<string>) {
        var agg = value.join(",");
        localStorage.setItem(this.HostWrap(Constants.LOCALSET_ASSESSMENT_GROUP_CHARTS), agg);
    }

    public get expandedActionTreeNodes(): Array<string> {
        var val = localStorage.getItem(this.HostWrap(Constants.LOCALSET_EXPANDED_ACTION_TREE_NODES));
        return (val) ? val.split(',') : [];
    }
    public set expandedActionTreeNodes(value: Array<string>) {
        var agg = value.join(",");
        localStorage.setItem(this.HostWrap(Constants.LOCALSET_EXPANDED_ACTION_TREE_NODES), agg);
    }

    public getLastApplicationAction(applicationId:string) {
        var id = localStorage.getItem(this.HostWrap(Constants.LOCALSET_LAST_ACTION));
        id = this._utilService.removeApplicationIdFromAction(id);
        if (id)
            id = id + Constants.CONV_ACTION_DELIM + applicationId;
        return id;
    }
    public setLastApplicationAction(applicationId:string, actionId:string) {
        if (actionId) {
            var id = actionId.replace(":"+applicationId, "");
            localStorage.setItem(this.HostWrap(Constants.LOCALSET_LAST_ACTION), id);
        }
    }

    public get appGridMenuHeaderRowVisible(): boolean {
        var val = localStorage.getItem(this.HostWrap(Constants.LOCALSET_APPGRIDMENU_HEADER_ROW_VISIBLE));
        return val=="Y";
    }
    public set appGridMenuHeaderRowVisible(value: boolean) {
        localStorage.setItem(this.HostWrap(Constants.LOCALSET_APPGRIDMENU_HEADER_ROW_VISIBLE), value?"Y":"N");
    }

    public get drivesGridMenuHeaderRowVisible(): boolean {
        var val = localStorage.getItem(this.HostWrap(Constants.LOCALSET_DRIVESGRIDMENU_HEADER_ROW_VISIBLE));
        return val=="Y";
    }
    public set drivesGridMenuHeaderRowVisible(value: boolean) {
        localStorage.setItem(this.HostWrap(Constants.LOCALSET_DRIVESGRIDMENU_HEADER_ROW_VISIBLE), value?"Y":"N");
    }

    public get lastOutputActionId(): string {
        var v =localStorage.getItem(this.HostWrap(Constants.LOCALSET_OUTPUTACTIONID)); 
        return v;
    }

    public set lastOutputActionId(value: string) {
        localStorage.setItem(this.HostWrap(Constants.LOCALSET_OUTPUTACTIONID), value);
    }

    public get expandedOutputActionTreeNodes(): Array<string> {
        var val = localStorage.getItem(this.HostWrap(Constants.LOCALSET_EXPANDED_OUTPUT_ACTION_TREE_NODES));
        return (val) ? val.split(',') : [];
    }
    public set expandedOutputActionTreeNodes(value: Array<string>) {
        var agg = value.join(",");
        localStorage.setItem(this.HostWrap(Constants.LOCALSET_EXPANDED_OUTPUT_ACTION_TREE_NODES), agg);
    }

    private HostWrap(key: string): string {
        return this._getHost() + "@" + key;
    }

}
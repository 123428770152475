import { Component, Input } from '@angular/core';

@Component({
    selector: 'bullet',
    templateUrl: './bullet.html',
    styleUrls:['./bullet.scss']
}) 
export class Bullet {
    static $inject = ['$scope','$state'];

    @Input('text') public text: string;
    @Input('warn') public warn: boolean;
    @Input('severe') public severe: boolean;

    constructor(
    ) {
    }

}
import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as CRS from '../../api/CRS';

@Component({
    selector: 'distribution-block',
    templateUrl: './distribution-block.html',
    styleUrls: ['../rag-distribution/rag-distribution.scss']
})

export class DistributionBlock {

    private defaultStyling : {[key:number] : string } = { 1: "green", 0 : "red" };

    public showLabel:boolean;

    @Input('values') values : Array<CRS.RSDistributionItem>;
    @Input('styling') styling : {[key:number]: string };

    constructor(
    ) {
    }
    public log(value) {console.log(value)}

    public getCssName(r: CRS.RSDistributionItem) : string {
        if (this.styling) {
            return this.styling[r.id];
        }
        else {
            return this.defaultStyling[r.id];
        }
    }

    public getPercentage(r :CRS.RSDistributionItem)  : number {

        var val:number =0;
        var total = 0;
        var dict: { [id: number] : number; } = {};

        this.values.forEach(x=> {
            total+=x.count;
            dict[x.id] = x.count;
        });

        if (total!=0)
        {
            var v = dict[r.id];
            v= !v ? 0 : v;
            val = (v / total)*100;
        }
        return val;
    }

    public getPercentageDisplay(r:CRS.RSDistributionItem) : string {
        var p =this.getPercentage(r);
        if (p> 0)
            return Math.round(p).toString() +"%";
        else
            return null;
    }

    public getWidthExpression(r :CRS.RSDistributionItem)  : string {
        var val:number = this.getPercentage(r);
        return (val==0) ? null : (this.showLabel?r.label+" - ":"") +  val + "%";
    }

    public isEmpty(r:CRS.RSDistributionItem): boolean {
        return r.count == 0;
     }
 
}
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'prompt-dialog',
    templateUrl: './prompt-dialog.html',
    styleUrls: ['./dialogs.scss']
})
export class PromptDialog implements OnInit {

    public title:string;
    public texts:Array<string>;
    public form: FormGroup;

    constructor(
        private dialogRef: MatDialogRef<PromptDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
        this.title = this.data.title;
        this.texts = this.data.texts;
    }

    public closeDialog(save:boolean) {
        this.dialogRef.close(save);
    }

    public cancelDialog() {
        this.dialogRef.close(false);
    }
}
import { Injector } from '@angular/core';
import { MenuService } from '../svc/menuService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';
import * as SVC from '../api/SVC';

export class AdminRefreshMetricsCommand {

    constructor(
        private injector: Injector
    ) {
        this.menuService = this.injector.get(MenuService);
        this.adminService = this.injector.get(SVC.AdminService);

        this.menuService.register(Constants.MENU_REFRESH_METRICS, this.cmd, "P-" + Constants.ROUTE_ADMINDASHBOARD);
    }

    private menuService: MenuService;
    private adminService : SVC.AdminService;

    public cmd: AppCommand = AppCommand.create(
        ()=> {
            return true;
        },
        ()=> {
            this.adminService.refreshMetrics();
        }
    )
    
}
import { Injector } from '@angular/core';
import { DriveScanService } from "../svc/driveScanService";
import { MenuService } from '../svc/menuService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';
import { UtilService } from '../svc/utilService';

export class InitiateDriveScanCommand {

    constructor(
        private injector: Injector
    ) {
        this.menuService = this.injector.get(MenuService);
        this.driveScanService = this.injector.get(DriveScanService);
        this.utilService = this.injector.get(UtilService);
        
        this.menuService.register(Constants.MENU_INITIATE_DRIVE_SCAN, this.initiateDriveScan, Constants.ROUTE_PROJECTDRIVES );
        this.menuService.register(Constants.MENU_INITIATE_DRIVE_SCAN_QUICK, this.initiateDriveScanQuick, Constants.ROUTE_PROJECTDRIVES );
    }

    private menuService: MenuService;
    private driveScanService: DriveScanService;
    private utilService: UtilService;

    private initiateDriveScan: AppCommand = AppCommand.createWithShow(
        ()=> { return !this.driveScanService.isScanInProgress() && this.utilService.privileges?.canLaunchSourceScan; },
        ()=> { this.driveScanService.initiateScan(false); },
        ()=> { return this.menuService.isRunnable(Constants.MENU_INITIATE_DRIVE_SCAN); }
    );

    private initiateDriveScanQuick: AppCommand = AppCommand.createWithShow(
        ()=> { return !this.driveScanService.isScanInProgress() && this.utilService.privileges?.canLaunchSourceScan;; },
        ()=> { this.driveScanService.initiateScan(true);  },
        ()=> { return this.menuService.isRunnable(Constants.MENU_INITIATE_DRIVE_SCAN_QUICK);  }
    );


}

import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { UtilService } from '../../svc/utilService';
import { ProjectService } from '../../api/SVC';
import { CurrentProjectService} from '../../svc/currentProjectService';
import { UserCacheService } from '../../svc/userCacheService';
import { Constants } from '../../api/Constants';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as SVC from '../../api/SVC';
import * as CRS from '../../api/CRS';

@Component({
    selector: 'create-vm-dialog',
    templateUrl: './create-vm-dialog.html',
    styleUrls: []
  })
export class CreateVmDialog {

    public selectedMachine : CRS.RSVirtualMachine=null;
    public selectedDate: string=null;
    public selectedBaseImage: CRS.RSVhd2=null;
    public updateName:string=null;

    private baseImages: CRS.RSGetBaseImages=null;

    constructor(
        private dialogRef: MatDialogRef<CreateVmDialog>,
        public utilService: UtilService,
        public conversionService: SVC.ConversionService,
        @Inject(MAT_DIALOG_DATA) public data: CreateVmDialog) { 

        this.conversionService.getBaseImages().then((response)=> {
            this.baseImages = response.data;
        });
    
    }

    public getBaseImages(): Array<CRS.RSVhd2> {
        return (this.baseImages) ? this.baseImages.images: [];
    }

    public isValid() : boolean {
        return this.selectedBaseImage != null;
    }

    public closeDialog() {
        this.dialogRef.close({ imageId: this.selectedBaseImage.id });
    }

    public cancelDialog() {
        this.dialogRef.close(null);
    }
}

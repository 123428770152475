import { Injector } from '@angular/core';
import { UtilService } from '../svc/utilService';
import { ApplicationService } from '../api/SVC';
import { ApplicationHelper } from 'src/app/svc/applicationHelper';

import * as CRS from '../api/CRS';
import { AppIssuesTable } from '../project/project-applications/app-issues-table';

export class ApplicationDocumentationCommand {

    constructor(
        private injector: Injector
    ) {
        this.applicationService = this.injector.get(ApplicationService);
        this.utilService = this.injector.get(UtilService);
        this.appHelper = new ApplicationHelper(this.applicationService);
    }

    public documentationFiles: CRS.RSGetApplicationDocumentationFiles=null;
    private applicationService : ApplicationService;
    private utilService: UtilService;
    private appHelper: ApplicationHelper;
    private applicationId: string;

    public get isDataReady() : boolean {
        return this._dataLoaded;
      }
    
      public get hasSome(): boolean {
        return this.documentationFiles && this.documentationFiles.items.length>0;
      }
    
      public downloadDocumentationFile($event: Event, file: CRS.RSDocumentationFile) : boolean {
        $event.stopPropagation();
        let url = this.getDocUrl(file);
        window.open(url,"_blank");
        return false;
      }
    
      public getDocUrl(file: CRS.RSDocumentationFile) : string {
        return this.utilService.getAPIUrl( 
         `Application/DownloadApplicationDocumentation?ApplicationId=${this.applicationId}&Id=${file.key}&serverId=${this.utilService.serverId}`);
      }

      private _dataLoaded:boolean=false;
      public load(applicationId:string) {
        this.applicationId = applicationId;
        this._dataLoaded=false;
        if (this._dataLoaded)
          return;
        this.appHelper.getDocumentationFiles(applicationId).then((data)=>{
            this.documentationFiles = data;
            this._dataLoaded=true;
        })
      }
        
}
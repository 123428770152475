import * as CRS from '../api/CRS';

export class WindowsUpdateChangeItem {

    public static create(item: CRS.RSWindowsUpdate) : WindowsUpdateChangeItem {
        var ret = new WindowsUpdateChangeItem();
        ret.item = item;
        return ret;
    }

    public item: CRS.RSWindowsUpdate;
}
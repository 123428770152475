import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'spinner',
  templateUrl: './spinner.html'
})
export class Spinner implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}

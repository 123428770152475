import * as IRS from '../api/IRS';
import * as CRS from '../api/CRS';
import * as Enum from '../api/Enum';

export class DISOApplication {
    constructor(app: IRS.IRSApplication) {
        this.id = app.id;
    }

    public update(project: CRS.RSGetProject, platform:number, app: CRS.RSApplication) {
        this.item =app;

        if (app.hasFailed) {
            this.ragValue="ERROR";
        }
        else if (!app.hasBeenAssessed) {
            this.ragValue="NOTASSESSED";
        }
        else {
            let asp : CRS.RSRAG = app.assessmentStatesDictionary[platform];
            if (asp !== undefined) {
                this.ragValue = "0x" + asp.assessmentState.toString(16);
            }
        }

        this.XImportDate = app.isComplete ? app.importDate : null;
    
        this.workflowStage = app.workflowStage;
        this.workflowStageState = app.workflowStageState;
        this.updateWorkflowNames(project);
        this.assignee =app.assignedToId;

        this.name = app.name;
        this.importPercentComplete  = app.importPercentComplete;
        this.productName = app.productName;
        //this.compoundVersion = app.compoundVersion;
        this.manufacturer = app.manufacturer;
        this.path = app.path;
        this.installerType = app.installerType;
        this.isMiddleware = app.isMiddleware;
        this.isVendor = app.isVendor;
        this.dotNetVersion = app.dotNetVersion;
        this.javaVersion = app.javaVersion;
        this.originatedFrom = app.originatedFrom;
        this.hasTest = app.hasTest;
        this.hasTestSuccess = app.hasTestSuccess;
        this.hasTestFail = app.hasTestFail;
        this.repackagedStatus = app.repackagedStatus;
        this.virtualisedStatus = app.virtualisedStatus;
        this.publishedState = app.publishedState;
        this.stagedOnly = app.stagedOnly;
    }

    public updateWorkflowNames(project: CRS.RSGetProject) {
        this.workflowStageStateName = Enum.WorkflowStageStatus[this.workflowStageState];
        if (this.workflowStage < project.workflowStageList.length)
            this.workflowStageName = project.workflowStageList[this.workflowStage].name;
    }

    public isChecked:boolean;
    public id: string;
    public item: IRS.IRSApplication;
    public XImportDate:String;
    public ragValue: string;
    public name:string;
    public importPercentComplete: number;
    public productName: string;
    public compoundVersion: string;
    public manufacturer: string;
    public path: string;
    public architecture: string;
    public appId: string;
    public installerType: string;
    public isMiddleware: boolean;
    public isVendor: boolean;
    public dotNetVersion: string;
    public javaVersion: string;
    public originatedFrom: string;
    public hasTest: boolean;
    public hasTestSuccess: boolean;
    public hasTestFail: boolean;
    public repackagedStatus: number;
    public virtualisedStatus: number;
    public publishedState:number;
    public stagedOnly:boolean;
    public workflowStageStateName:string;
    public workflowStageName:string;
    public workflowStage:number;
    public workflowStageState:number;
    public assignee:string;

    public static merge(project: CRS.RSGetProject, platform: number, incoming: Array<CRS.RSApplication>, mergeSet: Array<DISOApplication>)
    {
        var merDict : { [id: string] : DISOApplication } = {};
        mergeSet.forEach(x=> { merDict[x.id] = x; })
        var incDict:  { [id: string] : IRS.IRSApplication } = {};
        incoming.forEach(x=> { incDict[x.id] = x; })

        incoming.forEach(x=> {
            let diso = merDict[x.id];
            if (diso) {
                diso.update(project, platform, x);
            }
            else {
                let app = new DISOApplication(x);
                app.update(project, platform, x);
                mergeSet.push(app);
            }
        });

        let toDelete: Array<DISOApplication> = [];
        mergeSet.forEach(x=> {
            if (!incDict[x.id])
                toDelete.push(x);
        });

        toDelete.forEach(x=> {
            mergeSet.splice(mergeSet.indexOf(x), 1);
            delete mergeSet[x.id];
        });
    }
}


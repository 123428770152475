<div *ngIf="!hasItems()">
    No printers were added by this installation
</div>

<table style="padding-left:10px" *ngIf="hasItems()">
    <tr *ngFor="let pr of item.printerItems">
        <td style="min-width: 150px;" class="sw-column-top">{{pr.printerName}}</td>
        <td style="min-width: 150px;" class="sw-column-top">{{pr.driverName}}</td>
        <td>
            <div fxLayout="column" fxLayoutAlign="center center"> 
                <div *ngIf="!pr.screenshot">NO IMAGE</div>
                <img *ngIf="pr.screenshot" [src]="getScreenshot(pr)" style="max-width:300px">
            </div>        
        </td>
    </tr>
</table>

     
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { CurrentApplicationService } from '../../svc/currentApplicationService';
import { PlatformService } from '../../svc/platformService';
import { UtilService } from 'src/app/svc/utilService';
import { StateHelperService } from 'src/app/svc/stateHelperService';

import * as CRS from '../../api/CRS';


@Component({
  //selector: Constants.ROUTE_PROJECTAPPLICATIONISSUES,
  selector:'project-application-issues',
  styleUrls: ['./project-application-issues.scss'],
  templateUrl: './project-application-issues.html',
})
export class ProjectApplicationIssues implements OnInit, OnDestroy {

  constructor(
      private currentProject: CurrentProjectService,
      private currentApplication: CurrentApplicationService, 
      private stateHelperService : StateHelperService,
      private platformService: PlatformService,
      private utilService: UtilService
  ) { }

  public applicationIssues: CRS.RSGetApplicationIssues;
  public summarisation : CRS.RSIssueSummarisation=null;
  public issuesForCurrentPlatform: Array<CRS.RSIssue>=null;
 
  private subscriptions : Array<Subscription> = new Array<Subscription>();

  ngOnInit(): void {
      this.ensure();
      this.subscriptions.push(this.currentApplication.dataContext.onLoaded().subscribe(()=> {
        this.ensure();
      }));
      this.subscriptions.push(this.stateHelperService.onStateChange().subscribe(()=> {
        this.ensure();
      }));
       this.subscriptions.push(this.currentApplication.onFixingDone().subscribe(()=> {
         this.ensure(true);
       }));
  }

  ngOnDestroy() : void {
    this.subscriptions.forEach(x=>x.unsubscribe());
  }

  public isDataReady() : boolean {
    return this.currentProject.dataContext.isReady && this.currentApplication.dataContext.isReady;
  }

  public hasErrors(): boolean {
    return this.isDataReady() && this.application.fixingJobInfo && this.application.fixingJobInfo.errors && this.application.fixingJobInfo.errors.length> 0;
  }

  public get application() : CRS.RSGetApplication {
    return (this.currentApplication.dataContext.isReady) ? this.currentApplication.dataItem: null;
  }

  public userCanFix() {
    return this.utilService.privileges && this.utilService.privileges.canFix;
  }

  private ensure(forceRefresh?: boolean) {
      var platformNo = this.platformService.getCurrent(this.stateHelperService.details.projectId);
      this.currentApplication.getIssues(platformNo, forceRefresh).then((data)=> {
        this.issuesForCurrentPlatform = data.issuesForCurrentPlatform;
          this.currentApplication.getIssueSummarisations(platformNo).then((data)=> {
            this.summarisation = data;
          });
      });
  }

}

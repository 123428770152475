import { AppCommand } from './AppCommand';

export class Crumb {
    constructor(text: string, goToState:Array<string>, args: any, isLabel: boolean) {
        this.text = text;
        this.args = args;
        this.goToState = goToState;
        this.isLabel = isLabel;
        this.command = null;
    }

    public static createLabel(text:string, goToState: Array<string>) :Crumb {
        return new Crumb(text, goToState, null, true);
    }

    public static createLink(text:string, goToState: Array<string>) : Crumb {
        return new Crumb(text, goToState, null, false);
    }

    text: string;
    args:any;
    isLabel: boolean;
    goToState:Array<string>;

    command: AppCommand;
}
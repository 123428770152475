<spinner *ngIf="!isReady"></spinner>
<div class="full-height-bs" *ngIf="!hasData()"style="height:100%;overflow:auto">
    <div class="sw-big-pad">
        Ensure an update date has been specified
    </div>
</div>
<div class="full-height-bs" *ngIf="hasData()" style="height:100%;overflow:auto">
    <table ng-class='{"sw-viewcontainer-collapsed": collapsed}'>
        <thead>
            <tr>
                <th style="width:1%">RAG<!--<table-utils></table-utils>--></th>
                <th style="width:10%">CVE</th>
                <th *ngIf="!narrow" class='sw-table-collapsablecolumn' style="width:15%">Product Family</th>
                <th *ngIf="!narrow" class='sw-table-collapsablecolumn' style='width:25%'>Name</th>
                <th *ngIf="!narrow" class='sw-table-collapsablecolumn' style='width:10%'>Severity</th>
                <th *ngIf="!narrow" style='width:10%'>Impact</th>
                <th *ngIf="!narrow" class='sw-table-collapsablecolumn' style='width:9%'>NIST Rating</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor='let patch of sortedPatches | callback : applicableOsFilter :[]'
                    (click)='openCheck(patch)'
                    [ngClass]='{"sw-table-activerow": isCurrentCheckId(patch.checkId)}' >
                <td class='patch-table-cell'>
                    <rag-column [explicit-value]="patch.impactRag"></rag-column>
                </td>
                <td class="patch-table-cell">{{patch.id}}</td>
                <td *ngIf="!narrow" class="patch-table-cell">{{patch.productFamily}}</td>
                <td *ngIf="!narrow" class="patch-table-cell sw-table-collapsablecolumn">{{patch.name}}</td>
                <td *ngIf="!narrow" class="patch-table-cell sw-table-collapsablecolumn">{{getSeverity(patch)}}</td>
                <td *ngIf="!narrow" class='patch-table-cell sw-table-collapsablecolumn'>
                    <div *ngIf="getBaseScore(patch) != -1">{{getBaseScore(patch)}} </div>
                </td>
            </tr> 
        </tbody>
    </table>
</div>

 <!-- table-util-value="{{vm.getSeverity(patch)}}"-->
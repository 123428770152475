import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { RSMsiSummaryInformation } from 'src/app/api/CRS';
import { CurrentApplicationService } from 'src/app/svc/currentApplicationService';
import { UserCacheService } from '../../svc/userCacheService';
import * as CRS from '../../api/CRS';

@Component({
    selector: 'application-browse-summary-info-dialog',
    templateUrl: 'application-browse-summary-info-dialog.html',
    styleUrls:['./application-browse-summary-info-dialog.scss']
})
export class ApplicationBrowseSummaryInfoDialog  implements OnInit {

    constructor(
        private dialogRef: MatDialogRef<boolean>,
        private userCacheService:UserCacheService,
        private currentAplicationService: CurrentApplicationService
    ) {
    }

    ngOnInit() {
        
        this.title = this.getValue("Title");
        this.author = this.getValue("Author");
        this.subject = this.getValue("Subject");
        this.comments = this.getValue("Comments");
        this.keywords = this.getValue("Keywords");
        this.revisionNumber = this.getValue("RevisionNumber");
        this.creatingApplication =this.getValue("CreatingApplication");
        this.codePage = this.getValue("CodePage");
        this.pageCount = this.getValue("PageCount");
        this.lastPrinted = this.getValue("LastPrinted");
        this.createTime = this.getValue("CreateTime");
        this.lastSaveTime = this.getValue("LastSaveTime");

        let wc: number= Number(this.getValue("WordCount")) ;
        this.longFilenames = ((wc&1)==0);
        this.compressedFiles = ((wc&2)==2);
        this.adminInstall =  ((wc&4)==4);    
        this.elevatedPrivilegeNotRequired =  ((wc&8)==8);

        let sec:number = Number(this.getValue("Security"));
        switch(sec)
        {
            case 0:
                this.security = "No retriction";
                break;
            case 2:
                this.security = "Read-only recommended";
                break;
            case 4:
                this.security = "Read-only enforced";
                break;
        }

        if (this.getValue("Template")){
            let tparts: Array<string> = this.getValue("Template").split(';');
            if (tparts.length == 2) {
                this.platform= tparts[0];
                this.languages=tparts[1]
            }
            else{
            this.platform = tparts[0];
            }
        }


    }

    public get SummaryInfo() : RSMsiSummaryInformation {
        return this.currentAplicationService.browser.packageDetails.summaryInformation;
    }

    public summary:  CRS.RSMsiSummaryInformation;
    public title:string;
    public author: string;
    public subject:string;
    public comments: string;
    public keywords:string;
    public languages:string;
    public platform:string;
    public revisionNumber:string;
    public pageCount:string;
    public imageType:string;
    public security:string;
    public creatingApplication: string;
    public codePage:string;
    public lastPrinted:string;
    public createTime:string;
    public lastSaveTime:string;
    public longFilenames:boolean;
    public compressedFiles:boolean;
    public adminInstall:boolean;
    public elevatedPrivilegeNotRequired:boolean;

    public cancelDialog() {
        this.dialogRef.close();
    }

    private getValue(key:string) : string {
        let lst = this.SummaryInfo.data.filter(f=>f.key == key);
        return (lst.length==0) ? null: lst[0].value;
    }
}

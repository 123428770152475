import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'image-view-dialog',
    templateUrl: 'image-view.html'
})
export class ImageViewDialog implements OnInit {

    constructor(
        private dialogRef: MatDialogRef<boolean>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngOnInit() {
    }


    closeDialog() {
        this.dialogRef.close();
    }

    cancelDialog() {
        this.dialogRef.close();
    }
}


import { Component } from '@angular/core';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { CurrentApplicationService } from 'src/app/svc/currentApplicationService';
import { UtilService } from '../../svc/utilService';
import { AuthService } from 'src/app/svc/authService';
import { FileUploads } from '../../svc/fileUploads';
import * as CRS from '../../api/CRS';

@Component({
    selector:'app-companion-list',
    templateUrl: './app-companion-list.html',
    styleUrls: ['./app-companion-list.scss']
})
export class AppCompanionList {

    public fileUploads: FileUploads;

    constructor(
        private currentApplication: CurrentApplicationService,
        private projectService: CurrentProjectService,
        private authService: AuthService,
        private utilService: UtilService
    ) {
        var url = this.utilService.getAPIUrl( 'application/companionUpload');
        this.fileUploads = new FileUploads(url, this.authService, this.utilService, ()=> { return this.currentApplication.dataItem.id; });
        this.fileUploads.afterUploadSuccess = ()=> this.currentApplication.dataContext.refreshCurrent();
    }

    public get application() : CRS.RSGetApplication {
        return this.currentApplication.dataItem;
    }

    public canRemoveChip(item: CRS.RSApplicationCompanionFile) : boolean {
        return this.currentApplication.dataContext.isReady;
    }

    public removeChip(item: CRS.RSApplicationCompanionFile) : void {
        this.currentApplication.deleteCompanionFile(item.companionFileId).then((ret)=>{
            if (ret) {
                this.currentApplication.dataContext.refreshCurrent();
            }
        });
    }

}
import { Injector } from '@angular/core';
import { UtilService } from '../svc/utilService';
import { PollingService } from '../svc/pollingService';
import { MatDialog } from '@angular/material/dialog';
import { CurrentApplicationService } from '../svc/currentApplicationService';
import { CurrentConversionJobService } from '../svc/currentConversionJobService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';
import { ImageViewDialog } from '../dialogs/image-view/image-view'

export class ClickerScriptEditCommand {

    constructor(
        private injector: Injector
    ) {
        this.utilService = this.injector.get(UtilService);
        this.currentApplication = this.injector.get(CurrentApplicationService);
        this.currentJob = this.injector.get(CurrentConversionJobService);
        this.pollingService = this.injector.get(PollingService);
        this.dialog = this.injector.get(MatDialog);
        this.currentApplication.dataContext.onStateChanged().subscribe(()=>{
            this._runningScript=null;
        });
        this.currentJob.dataContext.onStateChanged().subscribe(()=>{
            if (this.currentJob.hasTask() && this.currentJob.dataItem.task.clickerExecution && this.currentJob.dataItem.task.clickerExecution.progressStatus != Constants.CLICKER_EXCHANGE_STATUS_DONE)
                this.startPolling();
        })
    }

    private utilService: UtilService;
    private currentApplication : CurrentApplicationService;
    private currentJob: CurrentConversionJobService;
    private pollingService: PollingService;
    private inTransition: boolean=false;
    private dialog: MatDialog; 

    public viewIndex : number = 0;

    public get panelVisible(): boolean {
        return this.utilService.localStorageHelper.showClickerPanel;
    }

    public set panelVisible(value:boolean) {
        this.utilService.localStorageHelper.showClickePanel= value;
    }

    public get hasResponse(): boolean {
        return !this.utilService.isEmptyAny(this.response);
    }

    public getImageUrl(index:number) {
        return this.currentJob.getClickerExchangeImageUrl(index);
    }

    public setViewIndex(index:number) {
        this.viewIndex = index;
    }

    public showExpandedImage(imageIndex:number) {
        this.dialog.open(ImageViewDialog, { width:'1000px', height:'700px', data: {image:this.getImageUrl(imageIndex)}});
    }

    private _runningScript:string = null;
    public get script() : string {
        if (this._runningScript==null && this.currentApplication.dataContext.isReady)
            this._runningScript = this.currentApplication.dataItem.clickerScript;
        return this._runningScript;
    }

    public set script(value:string) {
        this._runningScript = value;
    }

    public get response(): string {
        if (this.currentJob.dataContext.isReady && this.currentJob.dataItem) {
            var tsk = this.currentJob.dataItem.task;
            if (tsk && tsk.clickerExecution && tsk.clickerExecution.response) {
                return tsk.clickerExecution.response;
            }
        }
        return null;
    }

    public get progressStatus(): number {
        if (this.currentJob.dataContext.isReady && this.currentJob.dataItem) {
            var tsk = this.currentJob.dataItem.task;
            if (tsk && tsk.clickerExecution) {
                return tsk.clickerExecution.progressStatus;
            }
        }
        return null;
    }

    public get progressStatusText(): string {
        switch(this.progressStatus) {
            case Constants.CLICKER_EXCHANGE_STATUS_CREATED:
                return "Task created";
            case Constants.CLICKER_EXCHANGE_STATUS_ACKNOWLEDGED:
                return "Task acknowledged";
            case Constants.CLICKER_EXCHANGE_STATUS_INITIALISING:
                return "Initialising execution of script";
            case Constants.CLICKER_EXCHANGE_STATUS_RUNNING:
                return "Task Running";
            case Constants.CLICKER_EXCHANGE_STATUS_RETRIEVINGOUTPUT:
                return "Retrieving output";
            default:
                return null;
        }
    }

    public get imageCount(): number {
        if (this.currentJob.hasTask) {
            var tsk = this.currentJob.dataItem.task;
            if (tsk && tsk.clickerExecution) {
                return tsk.clickerExecution.imageCount;
            }
        }
        return 0;
    }

    public get hasImages(): boolean {
        return this.imageCount>0;
    }

    public get imageIndices(): Array<number> {
        let arr = new Array<number>();
        for(var i=0;i<this.imageCount; i++)
            arr.push(i);
        return arr;
    }

    public saveScript() {
        var scriptLines =(this.script)
            ? this.script.split('\r').map(x=>x.replace('\n',''))
            : null;
        this.currentApplication.saveClickerScript(scriptLines);
    }

    public cmd: AppCommand = AppCommand.createWithShow(
        ()=> {
            return !this.inTransition && this.currentJob.isInProgress() &&
                (!this.currentJob.dataItem.task.clickerExecution || this.currentJob.dataItem.task.clickerExecution.progressStatus == Constants.CLICKER_EXCHANGE_STATUS_DONE);
        },
        ()=> {
            this.inTransition=true;
            this.currentJob.executeClicker(this.script).then((r)=>{
                this.inTransition=false;
                this.startPolling();
            }, (e)=>this.inTransition=false);
        } ,
        ()=> { return this.utilService.privileges 
                && this.utilService.privileges.canEditClickerScripts
                && this.currentApplication.dataContext.isReady; }
    );

    private startPolling() :void {
        if (!this.pollingService.isRunning(Constants.POLLING_ID_CLICKERPROGRESS)) {
            this.pollingService.startPollingSequence(
                Constants.POLLING_ID_CLICKERPROGRESS,
                Constants.POLLING_CLICKERPROGRESS_INTVL,
                -1,
                (resolve, reject) => { this.updateStatus(resolve, reject); }
            );
        }
    }

    private updateStatus(resolve, reject): void {
        this.currentJob.refreshClickerExecutionStatus().then((response) => {
            if (!this.currentJob.dataItem.task.clickerExecution || this.currentJob.dataItem.task.clickerExecution.progressStatus == Constants.CLICKER_EXCHANGE_STATUS_DONE) {
                this.pollingService.stopSequence(Constants.POLLING_ID_CLICKERPROGRESS);
            }
            this.cmd.refresh();
            resolve();
        }, () => {
            reject();
        });
    }
}
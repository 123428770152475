import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { UtilService } from '../../svc/utilService';
import { StateHelperService } from '../../svc/stateHelperService';
import { Constants } from '../../api/Constants';
import * as CRS from '../../api/CRS';

@Component({
  selector: 'project-settings-sidenav',
  templateUrl: './project-settings-sidenav.html',
  styleUrls: ['../../shared/css/nav-bar.scss']
})
export class ProjectSettingsSideNav implements OnInit , OnDestroy {

  constructor(
    private route:ActivatedRoute, 
    private router:Router,
    private utilService:UtilService,
    private stateHelperService: StateHelperService) { }

  private subscriptions: Array<Subscription> =new Array<Subscription>();

  @Input('project') project : CRS.RSGetProject;

  ngOnInit(): void {
    this.gotoSub();
    // this line causing out of memory issues???
    // this.subscriptions.push(this.stateHelperService.onStateChange().subscribe(()=> this.gotoSub()));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x=>x.unsubscribe());
  }

  public navItems = [
    { id:"GEN", text: "General", route: Constants.ROUTE_PROJECTSETTINGSGENERAL, icon: "traffic" },
    { id:"WKF", text: "Workflow",route: Constants.ROUTE_PROJECTSETTINGSWORKFLOW, icon: "directions"},
    { id:"VHD", text: "VHDs", route: Constants.ROUTE_PROJECTSETTINGSVHDS, icon:"personal_video"},
    { id:"SCR", text: "Scripts", route: Constants.ROUTE_PROJECTSETTINGSSCRIPTS, icon:"description"},
    { id:"PUB", text: "Publishing", route: Constants.ROUTE_PROJECTSETTINGSPUBLISH, icon: "publish" },
    { id:"VIR", text: "Conversion", route: Constants.ROUTE_PROJECTSETTINGSCONVERT, icon: "desktop_windows" },
    { id:"RPT", text: "Reporting", route: Constants.ROUTE_PROJECTSETTINGSREPORTING, icon: "poll" },
 //   { id:"SHR", text: "Shared Projects", route: Constants.ROUTE_PROJECTSETTINGSSHARED, icon: "share" },
  ]

  public getClass(item: any) : string{
    if (this.route.children.length>0 && this.route.children[0].routeConfig.path == item.route)
        return "sw-nav-option-active";
    else
        return "sw-nav-option-inactive";
  }

  public isReady() : boolean {
    return !this.utilService.isEmptyAny(this.project);
  }

  public disableClick(): boolean {
    return this.utilService.isEmptyAny(this.project);
  }

  public invoke(item:any)
  {
    localStorage.setItem(Constants.LOCALSET_PROJECT_SET_SUB, item.id);
    this.router.navigate([item.route],  {relativeTo: this.route, queryParamsHandling: "merge" });        
  }

  public conditionFilter(itemList: any[]): any[] {
    let result: any[] = [];
    itemList.forEach((v)=>{
        if (!v.condition || v.condition())
            result.push(v);
    })
    return result;
  }

  private gotoSub() {
    if (this.stateHelperService.details.isStatePathCurrent( Constants.ROUTE_PROJECTSETTINGS)){
        var subId = localStorage.getItem(Constants.LOCALSET_PROJECT_SET_SUB);
        if (!subId)
            subId = "GEN";
        var navs = this.navItems.filter(x=>x.id == subId);
        if (navs.length>0)
            this.invoke(navs[0]);
    }
}

}

<div fxLayout="row" class="complexity-container">
    <div fxFlex class='complexity' [ngClass]='{"complexity-on": value>0}'>
        <mat-icon image-size size="18pt" class="sw-star">star_rate</mat-icon>
    </div>
    <div fxFlex class='complexity' [ngClass]='{"complexity-on": value>1}'>
        <mat-icon image-size size="18pt" class="sw-star">star_rate</mat-icon>
    </div>  
    <div fxFlex class='complexity' [ngClass]='{"complexity-on": value>2}'>
        <mat-icon image-size size="18pt" class="sw-star">star_rate</mat-icon>
    </div>  
    <div fxFlex class='complexity' [ngClass]='{"complexity-on": value>3}'>
        <mat-icon image-size size="18pt" class="sw-star">star_rate</mat-icon>
    </div>
    <div fxFlex class='complexity' [ngClass]='{"complexity-on": value>4}'>
        <mat-icon image-size size="18pt" class="sw-star">star_rate</mat-icon>
    </div>
</div>

import { StylingService } from '../../svc/stylingService';
import { AppCommand } from '../../model/AppCommand';
import { MenuService } from '../../svc/menuService';
import { DialogService } from '../../svc/dialogService';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, AfterContentChecked } from '@angular/core';
import { Constants } from 'src/app/api/Constants';

import * as STHS from '../../svc/stateHelperService';
import * as UTIL from '../../svc/utilService';
import * as CRS from '../../api/CRS';
import * as SVC from '../../api/SVC';

@Component({
    selector: "admin-patch-cats",
    templateUrl: './admin-patch-cats.html',
    styleUrls: ['../../shared/css/admin.scss', '../../shared/css/tables.scss', '../../shared/css/view.scss']
})

export class AdminPatchCats implements OnInit, AfterContentChecked {

    static selectedPatch: string = '';
    public classReference = AdminPatchCats;

    constructor(
        private router: Router, 
        private route:ActivatedRoute, 
        private xadminService: SVC.AdminService,
        private stateHelperService: STHS.StateHelperService,
        private menuService: MenuService,
        private stylingService: StylingService,
        private dialogService: DialogService
    ) {
        this.classReference.selectedPatch = ''
    }

    public patchCats: CRS.RSAdminGetPatchCategories = null;
    public stateHelperInstance: STHS.StateHelperInstance;

    ngOnInit() {
        this.stateHelperInstance= this.stateHelperService.register(Constants.ROUTE_ADMINPATCHCATS);
        this.menuService.register(Constants.MENU_ADD_PATCH_CATEGORY, this.addPatchCategoryCommand, Constants.ROUTE_ADMINPATCHCATS);
        this.stylingService.waitForApiEndpoint(()=>this.update());
    }

    ngAfterContentChecked(){
        if(this.router.url == "/" + Constants.ROUTE_ADMINDASHBOARD + "/" + Constants.ROUTE_ADMINPATCHCATS){
            this.classReference.selectedPatch = ''
        }
    }

    private async update(): Promise<void> {
        await this.xadminService.adminGetPatchCategories().then((response) => {
            this.patchCats = response.data;
        }, () => {
            console.error('Patch cat data couldn\'t be loaded');
        });
    }
    
    public isCurrent(){
        return this.router.url.includes("/" + Constants.ROUTE_ADMINDASHBOARD + "/" + Constants.ROUTE_ADMINPATCHCATS);
    }

    public openPatchCat(pc: string) {
        this.classReference.selectedPatch = pc;
        this.router.navigate([Constants.ROUTE_PATCHCATEGORYFILES_MAIN, pc], {relativeTo: this.route})
    }

    static updateSelectedPatch(id: string) {
        this.selectedPatch = id;
    }
   
    private addPatchCategoryCommand: AppCommand = AppCommand.createWithShow(
        ()=> {
            return this.patchCats != null;
        },
        async ()=> {
            const result = await this.dialogService.showAddPatchCategory("Add Patch Category");

            this.xadminService.adminAddPatchCategory(result).then(()=>{
                this.update();
             },
                 (fail) => {
                    let msg = (fail && fail.data && fail.data.exceptionMessage) ? fail.data.exceptionMessage : "An unknown error has occurred";
                    this.dialogService.showInfoMessage("Add Patch Category", [ msg]);
                }
            );
        },
        ()=> {
            return this.patchCats != null;
        }
    );

}


<div mat-dialog-title>
    <h2>ADD APPLICATION</h2>
</div>

<div mat-dialog-content style="height:450px">
    <div fxLayout="row" fxLayoutAlign="start center" *ngIf="!isDataReady()">
        <spinner diameter="20"></spinner>
    </div>
    <div *ngIf="isDataReady()" fxLayout="column" fxLayoutGap="10px">
        <div fxFlex>
            <mat-form-field class="full-width-bs">
                <label>Product Name</label>
                <input matInput type="text" maxlength="200" [(ngModel)]="details.productName">
            </mat-form-field>  
        </div>
        <div fxFlex>
            <mat-form-field class="full-width-bs">
                <label>Product Version</label>
                <input matInput type="text" maxlength="20" [(ngModel)]="details.productVersion">
            </mat-form-field> 
        </div>
        <div fxFlex>
            <mat-form-field style="width:200px">
                <label>Priority</label>
                <mat-select [(ngModel)]="details.priority" >
                    <mat-option [value]="2">High</mat-option>
                    <mat-option [value]="1">Medium</mat-option>
                    <mat-option [value]="0">Low</mat-option>
                </mat-select>
            </mat-form-field> 
        </div>
        <div fxFlex>
            <mat-form-field class="full-width-bs">
                <input matInput [(ngModel)]="details.sourceMediaPath" class="full-width-bs" placeholder="Select Installer Path" aria-label="Installer Path" [matAutocomplete]="auto" [formControl]="stateCtrl">
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let value of filteredStates | async" [value]="value">
                        <span>{{value}}</span>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div fxflex="none">
            <div class="sw-error">{{errorMsg}}</div>
        </div>
    </div>
</div>

<div mat-dialog-actions fxLayout='row'>
    <div fxFlex></div>
    <button mat-button (click)='closeDialog()' [disabled]="!isValid()">
        <mat-icon>done</mat-icon>
        <span>Save</span>
    </button>
    <button mat-button (click)='cancelDialog()'>
        <mat-icon>done</mat-icon>
        <span>Close</span>
    </button>
</div>


import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { UtilService } from '../../svc/utilService';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { Constants } from '../../api/Constants';

import * as CRS from '../../api/CRS';

@Component({
  selector: Constants.ROUTE_PROJECTSETTINGS,
  templateUrl: './project-settings.html'
})
export class ProjectSettings implements OnInit {

  constructor(
    private route:ActivatedRoute, 
    private currentProject: CurrentProjectService, 
    private utilService:UtilService) { }

  ngOnInit(): void {
  }

  public isFullWidth:boolean;

  public get project(): CRS.RSGetProject {
    return this.currentProject.dataItem;
  }

  public isLoaded() : boolean {  
    get: {
        return this.currentProject.dataContext.isReady;
    }
}
}

<h2 mat-dialog-title>MSI Installer Information - {{item.filename}}</h2>

<div mat-dialog-content class='sw-dialog-padding' style="min-width:800px">
    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px">
        <div fxFlex="15" class="sw-bold">Arguments</div>
        <div fxFlex>
            <div *ngFor="let a of item.arguments">
                <div layout="row">
                    <div flex>{{a.name}}</div>
                    <div flex>{{a.value}}</div>
                </div>
            </div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px">
        <div fxFlex="15" class="sw-bold">Product Code</div>
        <div fxFlex>
            {{item.productCode}}
        </div>
    </div>
</div>

<div mat-dialog-actions fxLayout='row'>
    <div fxFlex></div>
    <div fxFlex>
        <button mat-raised-button (click)='closeDialog()'>
            <mat-icon>done</mat-icon>
            <span>Close</span>
        </button>
    </div>
</div>
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { CurrentApplicationService } from '../../svc/currentApplicationService';
import { INPUTEDITARGS } from '../../model/INPUTEDITARGS';
import { Constants } from '../../api/Constants';
import { UtilService } from '../../svc/utilService';

import * as Enums from '../../api/Enum';
import * as CRS from '../../api/CRS';
import { UserCacheService } from 'src/app/svc/userCacheService';

@Component({
  selector: 'project-application-core',
  templateUrl: './project-application-core.html',
})
export class ProjectApplicationCore implements OnInit, OnDestroy {

  constructor(
      private userCacheService: UserCacheService,
      private currentProject: CurrentProjectService,
      public utilService: UtilService,
      public currentApplication: CurrentApplicationService, 
  ) { }

  public assignees: Array<CRS.RSUser> = [];

  private subscriptions : Array<Subscription> = new Array<Subscription>();

  ngOnInit(): void {
    this.userCacheService.getUserGroup(this.currentProject.userGroup).then((data)=> {
      this.assignees=data;
    })
  }

  ngOnDestroy(): void { 
    this.subscriptions.forEach(x=>x.unsubscribe());
  }

  public get application(): CRS.RSGetApplication {
    return this.currentApplication.dataItem;
  }

  public get applicationId(): string {
    return this.currentApplication.dataContext.id;
  }

  public get applicationIsReady(): boolean {
    return this.currentApplication.dataContext.isReady;
  }

  public get applicationIsUpdating() : boolean {
    return this.currentApplication.isUpdating;
  }

  public get applicationExeFlavourDescription(): string {
    if (this.currentApplication.dataContext.isReady)
      return this.application.exeFlavourDescription;
    else
      return null;
  }

  public get applicationExeFlavourHasUrl(): boolean {
    return this.currentApplication.dataContext.isReady && !this.utilService.isEmptyAny(this.currentApplication.dataItem.exeFlavourUrl); 
  }

  public get applicationIsPending(): boolean {
    return this.currentApplication.isLoadedAndPending;
  }

  public get mspText(): string {
    if (this.isMspAppReady){
      if (this.application.patchParts.length > 1)
        return "It will be fully imported when applications with the following product codes are imported into this project:";
      else
        return "It will be fully imported when an application with the following product code is imported into this project:";
    }
    else {
      if (this.application.patchParts.length > 1)
        return "The applications that this patch should be applied to have been detected. The import will be processed shortly";
      else
        return "The application that this patch should be applied to has been detected. The import will be processed shortly";
    }
  }

  public get isMspAppReady(): boolean {
    if (this.application.isMsp && this.application.isPartiallyImported) {
      return this.application.patchParts.filter(x=>x.status != -1).length>0;
    }
    return false;
  }

  public isDataReady() : boolean {
    return this.currentProject.dataContext.isReady && this.currentApplication.dataContext.isReady;
  }

  public getWorkflowStages():Array<string> {
    return this.currentProject.getWorkflowStages();
  }

  public openInstallerInfo() {
    window.open(this.application.exeFlavourUrl);
  }

  public getWorkflowStatuses():Array<string> {
    return this.currentProject.getWorkflowStatuses();
  }
  
  public getPriorities():Array<string> {
    var lst = [];
    for(var e in Enums.Priority)
        if (!parseInt(e) && e != "0")
            lst.push(e);
    return lst;
  }

  public appDetailsChanged(typeId:string) {
    switch(typeId) {
      case "WF":
        this.currentApplication.persistWorkflowStage();
        break;
      case "WFS":
        this.currentApplication.persistWorkflowStageState();
        break;
      case "AS":
        this.currentApplication.persistAssignee();
        break;
      case "PR":
        this.currentApplication.persistPriority();
        break;
      case "VS":
        this.currentApplication.persistVendorState();
        break;
      case "MID":
        this.currentApplication.persistMiddlewareState();
        break;
      case "ARCH":
        this.currentApplication.persistArchitecture();
    }
  }

  public manufacturerChanged = (args : INPUTEDITARGS) : void => {
    this.currentApplication.dataItem.manufacturer = args.value;
    this.currentApplication.persistManufacturer();
  }

  public friendlyNameChanged = (args : INPUTEDITARGS) : void => {
    this.currentApplication.dataItem.friendlyName = args.value;
    this.currentApplication.persistFriendlyName();
  }

  public productNameChanged = (args: INPUTEDITARGS) : void => {
    this.currentApplication.dataItem.productName = args.value;
    this.currentApplication.persistProductName();
  }

  public compoundVersionChanged = (args: INPUTEDITARGS) : void => {
    this.currentApplication.dataItem.compoundVersion = args.value;
    this.currentApplication.persistCompoundVersion();
  }

  public argumentsChanged = (args: INPUTEDITARGS) : void => {
    this.currentApplication.dataItem.arguments = args.value;
    this.currentApplication.persistArguments(Constants.ARGUMENT_UPDATE_TYPE_NORMAL);
  }

  public argumentsSilentChanged = (args: INPUTEDITARGS) : void => {
    this.currentApplication.dataItem.argumentsSilent = args.value;
    this.currentApplication.persistArguments(Constants.ARGUMENT_UPDATE_TYPE_SILENT);
  }

  public getAppLink(id:string): string {
    return `/${Constants.ROUTE_PROJECTDETAILS_MAIN}/${this.currentApplication.dataItem.projectId}/${Constants.ROUTE_PROJECTAPPLICATIONS}/${Constants.ROUTE_PROJECTAPPLICATIONLANDING_MAIN}/${id}`;
  }

}

import { Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { first} from 'rxjs/operators';
import { MenuService } from '../svc/menuService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';
import { HelpDialog } from '../dialogs/help/help-dialog';
import { UserCacheService } from '../svc/userCacheService';

export class HelpCommand {

    constructor(
        private injector: Injector
    ) {
        this.menuService = this.injector.get(MenuService);
        this.dialog = this.injector.get(MatDialog);
        this.userCacheService = this.injector.get(UserCacheService)

        this.menuService.register(Constants.MENU_HELP, this.command, null )
    }

    private userCacheService: UserCacheService;
    private menuService: MenuService;
    private dialog: MatDialog;

    public command: AppCommand = AppCommand.create(
        ()=> {
            return this.userCacheService.isReady;
        },
        ()=> {
            let dialogRef = this.dialog.open(HelpDialog,
                 { width:'1000px', height:'600px' });
            dialogRef.afterClosed().pipe(first()).subscribe((data)=>{
            });
        }
 
    );

}
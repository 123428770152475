import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { CurrentApplicationService } from './currentApplicationService';
import { CurrentConversionJobService } from './currentConversionJobService';
import { CurrentProjectService } from './currentProjectService';
import { CurrentAssessmentGroupService } from './currentAssessmentGroupService';
import { StateHelperService } from './stateHelperService';

@Injectable({
    providedIn: 'root'
})
export class CurrentDataService {

    constructor( 
        private currentApplication: CurrentApplicationService,
        private currentJob: CurrentConversionJobService,
        private currentProject: CurrentProjectService,
        private currentAssessmentGroup: CurrentAssessmentGroupService,
        private stateHelperService: StateHelperService
    ) { 
        this._onChange=new Subject<void>();

        this.stateHelperService.onStateChange().subscribe((state)=>{
            this._onChange.next();
        });
        this.currentApplication.dataContext.onLoaded().subscribe((state)=>{
            this._onChange.next();
        });
        this.currentApplication.dataContext.onRefreshed().subscribe((state)=>{
            this._onChange.next();
        });
        this.currentAssessmentGroup.dataContext.onLoaded().subscribe((state)=>{
            this._onChange.next();
        });
        this.currentAssessmentGroup.dataContext.onRefreshed().subscribe((state)=>{
            this._onChange.next();
        });
        this.currentJob.dataContext.onLoaded().subscribe((state)=>{
            this._onChange.next();
        });
        this.currentJob.dataContext.onRefreshed().subscribe((state)=>{
            this._onChange.next();
        });
        this.currentProject.dataContext.onLoaded().subscribe((state)=>{
            this._onChange.next();
        });
        this.currentProject.dataContext.onRefreshed().subscribe((state)=>{
            this._onChange.next();
        });
    }

    private _onChange : Subject<void>;

    public onChanged() : Observable<void> {
        return this._onChange.asObservable();
    }
 
}
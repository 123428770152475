<div fxLayout="column" fxFill fxLayoutGap="10px" [ngClass]="getPromoClass()">

    <div fxFlex="none" fxLayout="column" fxLayoutGap="10px" class="sw-horiz-pad">

        <div fxFlex="none">
            <h3 class="sw-upper">{{currentConversionJob.getTestHeading()}}</h3>
        </div>

        <div fxFlex="none" class="sw-vert-pad" *ngIf="applicationIsReady && application.hasResponseTransform && currentConversionJob.canApplyResponseTransform()">
            <mat-checkbox [(ngModel)]="useResponseTransform">Apply Response Transform</mat-checkbox>
        </div>
        <div fxFlex="none" class="sw-vert-pad" *ngIf="applicationIsReady && application.hasExtractTransform">
            <mat-checkbox [(ngModel)]="useExtractTransform">Apply Extract Transform</mat-checkbox>
        </div>
        <div fxFlex class="sw-vert-pad" *ngIf="currentConversionJob.supportsManualModificationStep() && currentConversionJob.idParts.conversionType!=16">
            <mat-checkbox [(ngModel)]="manualModificationRequired">Capture manual modification transform during task?</mat-checkbox> 
        </div>
        <div fxFlex class="sw-vert-pad" *ngIf="applicationIsReady && canUseRepackaged() && isRuntimeTest">
            <mat-checkbox [(ngModel)]="useRepack" disabled="true">Test Repackaged Application</mat-checkbox>
        </div>

        <div fxFlex class="sw-vert-pad" *ngIf="isMsiMod()">
            <application-companion-selector (on-change)="companionFilesChanged($event)"></application-companion-selector>
        </div>

        <div fxFlex="none" class="sw-pad-top" *ngIf="applicationIsReady">
            <button mat-raised-button color="primary" [disabled]="!canLaunchTask()" (click)="launchTask()">Launch</button>
        </div>

        <div fxFlex="none"><div class="sw-pad"></div></div>

        <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" *ngIf="!canLaunchTask()">
            <div fxFlex="none">
                <mat-icon class="sw-warning" image-size size="32pt" style="width:50px;height:50px">warning</mat-icon>
            </div>
            <div fxFlex>
                {{getCannotLaunchText()}}
            </div>
        </div>

    </div>

    <div fxFlex *ngIf="getPromoPara0()"> 
    <div fxFlex *ngIf="getPromoPara0()"> 
        <promo-info class="full-height-bs" 
                [top-paragraph]="getPromoPara0()"
                [bottom-paragraph]="getPromoPara1()"
                [bullets]="getPromoBullets()">
        </promo-info>
    </div>
    <!-- [img]="getPromoImage()"-->
    <!-- [img]="getPromoImage()"-->


</div>
<div fxLayout="column" class="full-height-bs" fxLayoutAlign="center center">
  <div fxFlex></div>

  <div fxFlex="none" *ngIf="!authService.isLoggedInResolved() || (authService.isLoggedIn && !hasProjectResolved)">
    <spinner></spinner>
  </div>

  <!-- <div fxFlex="none" fxLayout="row" class="full-height-bs" fxLayoutAlign="center center" *ngIf="authService.isLoggedInResolved() && !authService.isLoggedIn">
    <div fxFlex></div>
    <div fxFlex="none" fxLayout="row" fxLayoutGap="20px">
      <div fxFlex="none">
        <h2>Welcome To Application Readiness</h2>
        <div>You don't have an active connection to Readiness. Please click&nbsp;<a (click)="authService.login()" class="sw-link">here</a>&nbsp;to retry.</div>
      </div>
    </div>
    <div fxFlex></div>
  </div> -->

  <div fxFlex fxLayout="row" *ngIf="hasProjectResolved && !newestProject">
    <div fxFlex></div>
    <div fxFlex="none" fxLayout="row" fxLayoutGap="10px" class="sw-no-project-container">
      <div fxFlex="none">
        <div class="sw-no-project-icon-container">
          <mat-icon>dashboard_customize</mat-icon>
        </div>
      </div>
      <div fxFlex fxLayout="column">
        <div fxFlex="none">
          <h1>Projects</h1>
        </div>
        <div fxFlex>
          There aren't any projects currently associated with your account. In order to import and assess applications in Readiness, you will have to create a project first. 
        </div>
        <div fxFlex="none">
          <button mat-raised-button color="primary" (click)="addProject()">Add</button>
        </div>
      </div>
    </div>
    <div fxFlex></div>
  </div>
  <div fxFlex></div>

</div>


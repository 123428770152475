import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectListMenubarData} from './project-list-menubar-data';
import { UtilService } from '../../svc/utilService';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { ProjectService } from '../../api/SVC';
import { UserCacheService } from '../../svc/userCacheService';

import * as CRS from '../../api/CRS';

@Component({
    selector: 'project-list-menubar-dialog',
    templateUrl: 'project-list-menubar-dialog.html',
    styleUrls:['./project-list-menubar-dialog.scss']
  })
  export class ProjectListMenubarDialog implements OnInit {
  
    constructor(
      private dialogRef: MatDialogRef<ProjectListMenubarDialog>,
      private projectService: ProjectService,
      private userCacheServer: UserCacheService,
      public currentProject: CurrentProjectService, 
      public utilService: UtilService,
      @Inject(MAT_DIALOG_DATA) public data: ProjectListMenubarData) {}

      public searchText:string;
      public serverId:string;

    ngOnInit() {
      var wid=580;
      const matDialogConfig: MatDialogConfig = new MatDialogConfig();
      const rect = this.data.element.nativeElement.getBoundingClientRect();
      matDialogConfig.position = { left: `${rect.left}px`, top: `${rect.bottom}px` };
      matDialogConfig.width = `${wid}px`;
      matDialogConfig.height = '420px';
      this.dialogRef.updateSize(matDialogConfig.width, matDialogConfig.height);
      this.dialogRef.updatePosition(matDialogConfig.position);
      this.serverId = this.utilService.serverId;

      var pst = this.utilService.localStorageHelper.projectSearchText;
      this.searchText= pst;
      if (!pst) 
        pst = "USER:SELF";
      this.projectService.searchProjects(this.serverId, pst).then((response)=> {
        this.projectList = response.data;
      });

    }

    public projectList: CRS.RSGetProjects=null;

    public hasProjects() : boolean {
      return this.projectList != null && this.projectList.projects.length > 0;
    }

    public isDataReady() : boolean{ 
      return this.projectList !=null;
    }

    public isCurrent(project: CRS.RSProject) : boolean {
      return this.currentProject.dataContext.isReady && project.id == this.currentProject.dataContext.id;
    }

    public getAppCnt(project:CRS.RSProject) : string {
      return String(project.applicationCount) + " application" + ((project.applicationCount==1) ? "" : "s");
    }

    public get servers() : Array<CRS.RSServerItem>
    {
        return this.userCacheServer.GetUserInfo().servers;
    }

    public get hasServerOptions(): boolean {
      return this.userCacheServer.hasServerOptions;
    }

    public serverChanged(): void {
      this.projectList=null;
      this.search();
    }

    public getProjectInfo(project: CRS.RSProject) : string {
      var dtExpr=project.createdExpression;
      if (project.description)
        return project.description + " (created " + dtExpr + ")";
      else
        return "Created " +dtExpr;
    }

    public getProjectImageUrl(project: CRS.RSProject) {
      if (project.isReady) {
        if (project.imageId == "00000000-0000-0000-0000-000000000000")
            return "/img/project/NoProjectImage.png";
        else
            return this.utilService.getAPIUrl( "project/getImage?imageId=" + project.imageId + "&context=" + this.serverId);
    }
      else { 
        return null;
      }
    }

    public selectProject(project:CRS.RSProject) {
        this.dialogRef.close({"server":this.serverId, "project":project});
    }

    public search() {
      this.searchInner(this.searchText);
    }

    private searchInner(inpTxt:string){
      if (inpTxt && inpTxt.length >= 3) {
        this.utilService.localStorageHelper.projectSearchText = inpTxt;
        this.projectService.searchProjects(this.serverId, inpTxt).then((response)=> {
          this.projectList = response.data;
        });
      }
      else if (!inpTxt || inpTxt.trim().length == 0) {
        this.utilService.localStorageHelper.projectSearchText = "";
        this.projectService.searchProjects(this.serverId, "user:self").then((response)=>{
          this.projectList = response.data;
        })
      }
    }

    public close(result:string): void {
      if (result == '+')
        this.dialogRef.close({"server": this.serverId, "project": '+'})
      else
        this.dialogRef.close(result);
    }
  
    public isValid() : boolean {
      return true;
    }
  }
import { Component, OnInit } from '@angular/core';
import { UtilService } from '../../svc/utilService';
import { CurrentConversionJobService } from '../../svc/currentConversionJobService';
import { AppCommand } from '../../model/AppCommand';
import { CurrentApplicationService } from '../../svc/currentApplicationService';
import { CommandCollection } from 'src/app/commands/command-collection';

@Component({
  selector: 'conversion-task-publish-test',
  templateUrl: './conversion-task-publish-test.html',
})
export class ConversionTaskPublishTest implements OnInit {

    constructor(
        public currentConversionJob: CurrentConversionJobService,
        private currentApplication: CurrentApplicationService,
        private utilService: UtilService
    ) { 
    }

    ngOnInit(): void {
    }

    public get publishedTestCommand(): AppCommand {
        return CommandCollection.PublishedTest.cmd;
    }

    public get applicationMissingAppId(): boolean {
        return this.currentApplication.dataContext.isReady && !this.currentApplication.dataItem.hasValidAppId;
    }

    public get invalidAppIdError(): string {
        return this.currentApplication.dataContext.isReady ? this.currentApplication.dataItem.invalidAppIdError : null;
    }

    public get applicationSilentMissing(): boolean {
        return this.currentApplication.dataContext.isReady && this.currentApplication.dataItem.needsSilentArgs && !this.currentApplication.dataItem.hasSilentArgs;
    }
}

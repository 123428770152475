import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UtilService } from '../../svc/utilService';
import { MetaService, ProjectService, QueryService } from '../../api/SVC';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { StateHelperService } from '../../svc/stateHelperService';
import { MenuService } from '../../svc/menuService';
import { DialogService } from '../../svc/dialogService';
import { Constants } from './../../api/Constants';
import { AppCommand } from '../../model/AppCommand';
import { QueryInput } from '../../model/QueryInput';
import { SaveCustomQueryDialog } from '../project-queries/save-custom-query-dialog';

import * as CRS from '../../api/CRS';

@Component({
  selector: Constants.ROUTE_PROJECTQUERIESCUSTOM,
  templateUrl: './project-queries-custom.html',
  styleUrls: ['./project-queries-custom.scss']
})
export class ProjectQueriesCustom implements OnInit {

  constructor(
    private route:ActivatedRoute,
    private dialog: MatDialog, 
    private projectService: ProjectService, 
    private metaService: MetaService,
    private queryService: QueryService,
    private utilService:UtilService,
    private menuService:MenuService,
    private dialogService: DialogService,
    private stateHelperService: StateHelperService,
    public currentProject:CurrentProjectService
  ) { 
    this.menuService.register(Constants.MENU_EXECUTE_QUERY, this.runCommand, Constants.ROUTE_PROJECTQUERIESCUSTOM);
    this.menuService.register(Constants.MENU_SAVE_CUSTOM_QUERY, this.saveCommand, Constants.ROUTE_PROJECTQUERIESCUSTOM)
    this.menuService.register(Constants.MENU_SELECT_CUSTOM_QUERY, this.selectCustomQueryCommand, Constants.ROUTE_PROJECTQUERIESCUSTOM);
  }

    public queryInput: QueryInput = new QueryInput();
    public metaData: CRS.RSGetMetaData;
    public queryResponse: CRS.RSExecuteQuery;
    public savedQueries: CRS.RSGetCustomQueries;
    public currentQuery: CRS.RSQuery;
    public jobData: CRS.RSGetCustomQueryResults=null;

    ngOnInit(): void {
        this.metaService.getMetaData(this.stateHelperService.details.projectId).then((response) => {
            this.metaData= response.data;
        }, () => {
            console.error('MetaData couldn\'t be loaded');
        });

        this.queryService.getCustomQueries().then((response) => {            
                this.savedQueries = response.data;        
            }, 
            () => { console.error("Queries couldn\'t be loaded");
        });

        this.selectCustomQueryCommand.subItems = ()=> {
            return this.savedQueries ? this.savedQueries.queries : [];
        };

        this.selectCustomQueryCommand.executeSubItem = (item: any) => {
            this.currentQuery = item;
            this.currentQueryChanged();
        };
    }

    public get isLoaded() : boolean {  
            return this.currentProject.dataContext.isReady;
    }

    private updateResults = () : void => {
        this.queryService.getCustomQueryResults(this.queryResponse.jobId).then((response) => {
            this.jobData = response.data;
            if (this.queryResponse != null) {
                if ( this.jobData.status < 2) {
                    setTimeout(() => {
                        this.updateResults();
                    }, 5000);
                }
                else {
                    this.queryResponse=null;
                }
            }       
        });
    }

    private currentQueryChanged() : void {
        let ent = this.metaData.getEntity(this.currentQuery.eid);
        let att = ent.getAttribute(this.currentQuery.typeId);
        let vals = this.currentQuery.values.join("\n\r");
        let outatts = ent.attributes.filter(a=>this.currentQuery.outputAttributes.indexOf(a.typeId) != -1);

        this.queryInput.entity = ent;
        this.queryInput.attribute = att;
        this.queryInput.values=vals;
        this.queryInput.outputAttributes = outatts;
        this.queryResponse = null;
    }

    private executeSave(name: string) : void {
        let eid = this.queryInput.entity.eid;
        let typeId = this.queryInput.attribute.typeId;
        let outputAtts = this.queryInput.outputAttributes.map((a) => a.typeId);  
        let values = this.queryInput.values.split('\n');
        this.queryService.saveCustomQuery(this.stateHelperService.details.projectId, name, eid, typeId, outputAtts, values)
            .then((response) => {   
                this.savedQueries = response.data;
                this.currentQuery = this.savedQueries.queries.filter(q=>q.id == response.data.savedId)[0];           
            }, ()=> {
                console.error('Query couldn\'t be saved');
        });
    }

    private selectCustomQueryCommand: AppCommand = AppCommand.createWithShow(
        ()=> {
            return true;
        },
        ()=> {

        },
        ()=> {
            return true;
        }
    )

    private runCommand: AppCommand = AppCommand.createWithShow(
        ()=> { 
            return this.queryInput.entity && this.queryInput.attribute && this.queryInput.outputAttributes.length>0 ; 
        },
        ()=> {
            let eid = this.queryInput.entity.eid;
            let typeId = this.queryInput.attribute.typeId;
            let outputAtts = this.queryInput.outputAttributes.map((a) => a.typeId);  
            let values = this.queryInput.values.split('\n');
            this.jobData = null;
            this.queryService.executeQuery(this.stateHelperService.details.projectId, eid, typeId, outputAtts, values).then((response) => {
                this.queryResponse = response.data;
                setTimeout(() => { this.updateResults(); }, 4000);
            }, ()=> {
                console.error('Query results couldn\'t be loaded');
                this.jobData=null;
            });
        },
        ()=> { 
            return true;
        }
    )

    private saveCommand: AppCommand = AppCommand.create(
        ()=> { 
            return this.queryInput.entity && this.queryInput.attribute && this.queryInput.outputAttributes.length>0 ; 
        },
        ()=> {

            let dialogData = { queryName: '' };
            let dialogRef = this.dialog.open(SaveCustomQueryDialog, { width:'350px', data: dialogData });
            dialogRef.afterClosed().subscribe((result)=> {
                if (result)
                    this.executeSave(result.queryName);
            });
        }
    )

}

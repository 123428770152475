import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import {ElementRef,Renderer2} from '@angular/core';
import { UtilService } from '../../svc/utilService';

@Component({
  selector: 'image-upload',
  templateUrl: './image-upload.html',
  styleUrls: ['./image-upload.scss']
})
export class ImageUpload implements OnInit {

    constructor(private utilService: UtilService) { }

    @ViewChild('uploadButton') el:ElementRef;

    @Input('image') image:string;
    @Input('disable') disable:boolean;

    @Output('on-change') onChange= new EventEmitter<any>();

    ngOnInit(): void {
    }

    public registerChange() : void {
        this.onChange.emit(null);
    }

    public projectHasImage() : boolean {
        return this.image != null;
    }

    public openFile() {
        this.el.nativeElement.click();
    }

    public filesChanged() {
        var file = this.el.nativeElement.files[0];
        var ext = this.utilService.getFileExtension(file.name);
        if (ext == "jpg" || ext == "png") {
            let reader = new FileReader();
            reader.onload = e => {
                this.image = reader.result.toString();
                this.onChange.emit(this.image);
            };
            reader.readAsDataURL(file);
        }
    }
}

import { Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { first} from 'rxjs/operators';
import { UtilService} from '../svc/utilService';
import { MenuService } from '../svc/menuService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';
import { CurrentApplicationService } from '../svc/currentApplicationService';
import { SetApplicationSourcePathDialog } from '../dialogs/set-application-source-path/setApplicationSourcePathDialog';
import { SetApplicationSourcePathData } from '../model/SetApplicationSourcePathData';

import * as CRS from '../api/CRS';

export class SetApplicationSourcePathCommand {

    constructor(
        private injector: Injector
    ) {
        this.menuService = this.injector.get(MenuService);
        this.currentApplication = this.injector.get(CurrentApplicationService);
        this.dialog = this.injector.get(MatDialog);
        this.utilService = this.injector.get(UtilService);

        this.menuService.register(Constants.MENU_SET_APPLICATION_SOURCE_PATH, this.command, "*"+Constants.ROUTE_PROJECTAPPLICATIONLANDING_MAIN  )
    }

    private menuService: MenuService;
    private currentApplication: CurrentApplicationService;
    private dialog: MatDialog;
    private utilService: UtilService;

    private _data: SetApplicationSourcePathData=null;
    public get data(): SetApplicationSourcePathData {
        if (!this._data)
            this._data = new SetApplicationSourcePathData();
        return this._data;
    }

    public resetData(): void {
        this._data = new SetApplicationSourcePathData();
    }

    public isDataValid(): boolean {
        return !this.utilService.isEmptyAny(this.data.sourceMediaPath);
    }

    public apply(path:string): Promise<CRS.ResponseWrapper<any>> {
        return this.currentApplication.setApplicationSourcePath(path);
    }

    public afterApply(): void {
        this.currentApplication.dataContext.refreshCurrent();
    }

    private command: AppCommand = AppCommand.createWithShow(
        ()=> {
            return this.currentApplication.dataContext.isReady;
        },
        ()=> {
            this.resetData();
            let dialogRef = this.dialog.open(SetApplicationSourcePathDialog,
                 { width:'800px', height:'280px' });
            dialogRef.afterClosed().pipe(first()).subscribe((data)=>{
                if (!data)
                    return;
                this.currentApplication.setApplicationSourcePath(data.sourceMediaPath);
            });
        }, 
        ()=> { 
            return this.utilService.privileges && this.utilService.privileges.canAddApplication 
                    && this.currentApplication.dataContext.isReady && this.currentApplication.dataItem.sourceAvailabilityState == Constants.SOURCE_AVAILABILITY_NOTDEFINED;
        }

    );

}
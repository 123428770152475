<div fxLayout="column" class="sw-file-upload-queue-container">

    <div fxFlex fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
        <div fxFlex="none" *ngIf="anyFailed">
            <mat-icon class="sw-warning">warning</mat-icon>
        </div>
        <div fxFlex="none">{{message}}</div>
        <div fxFlex>
            <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
        </div>
    </div>

</div>


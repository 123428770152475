export class DISOUserFeature {

    constructor(id:string, description:string, hasFeature: boolean) {
        this.id = id;
        this.description = description;
        this.hasFeature = hasFeature;
    }

    public id:string;
    public description:string;
    public hasFeature:boolean;

    public toggleFeatureState() {
        this.hasFeature = !this.hasFeature;
    }
}
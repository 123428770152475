import { Component } from '@angular/core';
import { UtilService } from '../../svc/utilService';
import { CurrentConversionJobService } from '../../svc/currentConversionJobService';

import * as CRS from '../../api/CRS';

@Component({
  selector: 'conversion-task-retained',
  templateUrl: './conversion-task-retained.html'
})
export class ConversionTaskRetained {

    constructor(
        private currentConversionJob: CurrentConversionJobService,
        private utilService: UtilService,
    ) { }

    public get task() : CRS.RSGetConversionTask {
        if (!this.currentConversionJob.dataContext.isReady)
            return null;
        if (!this.currentConversionJob.hasTask())
            return null;
        return this.currentConversionJob.dataItem.task;
    }
  
    public openRetainedLog($event, rf: CRS.RSRetainedFile) {
 
        var url = this.utilService.getAPIUrl( `Conversion/GetRetained?JobId=${this.currentConversionJob.dataContext.id}&FileId=${rf.name}&serverId=${this.utilService.serverId}`);
        this.utilService.downloadViaApi(url, null, rf.contentType);
 
        $event.stopPropagation();
        $event.preventDefault();
   }

}

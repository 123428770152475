import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from '../../svc/breadcrumbService';
import { Crumb } from '../../model/Crumb';

import * as CRS from '../../api/CRS';

@Component({
  selector: 'breadcrumb-bar',
  templateUrl: './breadcrumb-bar.html',
  styleUrls: ['./breadcrumb-bar.scss']
})
export class BreadcrumbBar implements OnInit {

  constructor(
    private router: Router,
    private route:ActivatedRoute, 
    public breadcrumbService:BreadcrumbService) { }

  ngOnInit(): void {
    this.isLoaded=false;
   }

  public isLoaded : boolean;
  public isFullWidth:boolean;

  public gotoCrumb(event: Event, item:Crumb) {
    event.stopPropagation();
    if (item.command) {
      item.command.executeViaEvent(event);
    }
    else {
      this.router.navigate(item.goToState);
    }
  }
}

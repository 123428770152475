import { Injectable } from '@angular/core';
import { ProjectService } from '../api/SVC';
import { CurrentProjectService } from '../svc/currentProjectService';
import { Subject, Subscription} from 'rxjs';
import { PollingService } from '../svc/pollingService';
import { Constants } from '../api/Constants';
import { UtilService  } from './utilService';
import { DLITEM } from '../model/DLITEM';

import * as CRS from '../api/CRS'

@Injectable({
    providedIn: 'root'
})
export class DriveScanService {

    constructor( 
        private currentProjectService: CurrentProjectService,
        private projectService: ProjectService,
        private pollingService: PollingService,
        private utilService: UtilService
    ) { 
        this._onSelectedItemsChanged =new Subject<void>();
    }

    public items: Array<DLITEM>= null;

    private _selectedIds: Array<String>=[];
    private _driveLink: CRS.RSGetDriveLink=null;
    private _loaded: Subject<boolean>=new Subject<boolean>();
    private _onSelectedItemsChanged: Subject<void>;
    private _isDataReady:boolean=false;

    private _subscriber : Subscription;
    private _projectId :string;

    public get lastStartedExpression() : string {
        return this._driveLink?.scanStartDateExpression;
    }

    public startPolling(projectId:string, next: (value:boolean)=>void) {
        this._selectedIds=[];
        this.pollingService.startPollingSequence(Constants.POLLING_ID_DRIVEVIEW, Constants.POLLING_DRIVEVIEW_INTVL, 0, ()=> {
            this._projectId = projectId;
            this.refreshDriveLink();
        })
        this.currentProjectService.dataContext.onLoaded().subscribe((r)=>{
            this._projectId = r.data.id;
            this.refreshDriveLink();
        });
        if (this._loaded.closed)
            this._loaded = new Subject<boolean>();
        this._subscriber = this._loaded.subscribe(next);
    }

    public stopPolling() {
        this.pollingService.stopSequence(Constants.POLLING_ID_DRIVEVIEW);
        this._loaded.unsubscribe();
    }

    public onSelectedItemsChanged(){
        return this._onSelectedItemsChanged.asObservable();
    }

    public isScanInProgress() : boolean {
        return this._driveLink && this._driveLink.scanInProgress;
    }

    public isDataReady(): boolean {
        return this._isDataReady;
    }

    public hasSASKey() : boolean {
        return this._driveLink && this._driveLink.sasKey && this._driveLink.sasKey.length> 0;
    }

    public getSASKey() :string {
        return this._driveLink ? this._driveLink.sasKey : null;
    }

    public getScanProgressPercentage() : number {
        return this._driveLink ? this._driveLink.scanProgressPercentage : -1;
    }

    public getScanFolderCount(): number {
        return this._driveLink ? this._driveLink.scanFolderCount : -1;
    }

    public getSelectedItems(): Array<DLITEM> {
        return this.items && this._selectedIds ? this.items.filter(x=>this._selectedIds.indexOf(x.id)>=0) : null;
    }

    public anySelectedItems() :boolean {
        return this._selectedIds && this._selectedIds.length > 0;
    }

    public setSelectedItems(items: Array<String>) {
        this._selectedIds=items;
        this._onSelectedItemsChanged.next();
    }

    public updateTransformSelections(item: DLITEM) {
        this.projectService.updateDriveLinkSecondaries(this._driveLink.projectId, item.item.applicationId, item.secondariesFlat, item.selectedSecondariesFlat);
    }

    public initiateScan(quickScan:boolean): void {
        this.projectService.initiateDriveScan(this._driveLink.projectId, quickScan).then((data) => {
            this._driveLink.scanInProgress=true;
            this._driveLink.scanStartDateExpression = "<1m";
        });
    }

    public queueCheckedItems() : void {
        var apps = this.items.filter(x=>this._selectedIds.indexOf(x.id)>=0 && x.item.status == 0).map(x=>{
            var ret = x.item.applicationId;
            if (x.secondaries.length>0) {
                ret += "[";
                let fst=true;
                x.secondaries.forEach(y=>{
                    if (!fst)
                        ret+=";"
                    ret+=(x.isSelected(y)?"+":"") + y.item;
                    fst=false;
                })
                ret+="]";
                x.item.status=3;
            }
            return ret;
        });
        this.projectService.queueDriveLinkImport(this._driveLink.projectId, apps);
    }

    private refreshDriveLink() {
        if (!this._driveLink || this._driveLink.projectId != this._projectId)
            this._isDataReady=false;
        var ret = new Subject<boolean>();
        this.projectService.getDriveLink(this._projectId).then((response)=> {
            this._driveLink=response.data;
            this.merge(response.data)
            this._loaded.next(true);
            this._isDataReady=true;
        }, ()=> {
            this._loaded.next(false);
            this._isDataReady=true;
        });
        return ret;
    }

    private merge(data: CRS.RSGetDriveLink) {

        if (this.items==null)
            this.items=[];

        var merDict : { [id: string] : DLITEM } = {};
        this.items.forEach(x=> { merDict[x.item.applicationId] = x; })
        var incDict:  { [id: string] : CRS.RSDriveLinkMapItem } = {};
        data.items.forEach(x=> { incDict[x.applicationId] = x; })
  
        data.items.forEach(x=> {
            let dli : DLITEM = merDict[x.applicationId];
            if (dli) {
                dli.item.status = x.status;
                dli.item.importPhase=x.importPhase;
            }
            else {
                dli = new DLITEM(x, this.utilService);
                this.items.push(dli);
            }
        });
  
        let toDelete: Array<DLITEM> = [];
        this.items.forEach(x=> {
            if (!incDict[x.item.applicationId])
                toDelete.push(x);
        });
  
        toDelete.forEach(x=> {
            this.items.splice(this.items.indexOf(x), 1);
        });
    }

}
<div fxLayout="column" *ngIf="projectIsReady">

    <div fxFlex>
        <h2>Drive Links</h2>
        <div [hidden]="project.driveLinks.length > 0">
            <h4>There are no drive currently links defined for this project</h4>
        </div>
        <div [hidden]="project.driveLinks.length == 0">
            <ul>
                <li *ngFor="let drv of project.driveLinks">{{drv.definition}}</li>
            </ul>
        </div>
    </div>

    <div fxFlex [hidden]="!isCurrentProjectOwnerAndIsShared()">
        <h2>Shared With</h2>
        <div style="max-width:600px">
            <div *ngFor="let share of getShares()">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div fxFlex>{{share.userEmail}}</div>
                    <div fxFlex="none">
                        <button (click)="deleteShare(share)" class="no-margin md-small">
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

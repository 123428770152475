import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { UtilService } from '../../svc/utilService';
import { StateHelperService } from '../../svc/stateHelperService';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { CurrentAssessmentGroupService } from '../../svc/currentAssessmentGroupService';
import { PlatformService } from '../../svc/platformService';
import { ProjectService } from '../../api/SVC';

import * as CRS from '../../api/CRS';

@Component({
  selector: "normal-checks-table",
  templateUrl: './normal-checks-table.html',
  styleUrls: ['./project-reports.scss']
})
export class NormalChecksTable implements OnInit, OnDestroy {

    constructor(
        private route:ActivatedRoute, 
        private projectService: ProjectService, 
        private utilService:UtilService,
        private platformService: PlatformService,
        public currentProject: CurrentProjectService,
        private currentAssessmentGroup: CurrentAssessmentGroupService,
        public stateHelperService: StateHelperService) { }

    @Input("assessment-group") public assessmentGroup: CRS.RSAssessmentGroup;
    @Input("narrow") public narrow: boolean;
    @Output('on-click') onClick = new EventEmitter<CRS.RSCheck>();

    private subscriptions : Array<Subscription> = new Array<Subscription>();

    ngOnInit(): void {
        this.subscriptions.push(this.platformService.onPlatformChanged().subscribe((pno)=> {
            this.rebuildChecksForCurrentPlatform();
        }));
        this.subscriptions.push(this.currentProject.dataContext.onLoaded().subscribe((p)=> {
            this.rebuildChecksForCurrentPlatform();
        }));
        this.rebuildChecksForCurrentPlatform();
    }

    ngOnDestroy() : void {
        this.subscriptions.forEach(x=>x.unsubscribe());
    }

    ngOnChanges(changes: SimpleChanges) : void {
        this.rebuildChecksForCurrentPlatform();
    }

    public checksForCurrentPlatform: Array<CRS.RSCheck>=null;

    public isSelected(item: CRS.RSCheck) : boolean {
        return item && this.stateHelperService.details.checkId === item.checkId;
    }

    public openCheck(check: CRS.RSCheck) {
        if (this.onClick)
            this.onClick.emit(check);
    }

    public isAssessmentGroupEnabled() {
        return this.currentAssessmentGroup.dataContext.isReady && this.currentAssessmentGroup.dataItem.isEnabled;
    }

    public hasChecksForCurrentPlatform() : boolean {
        return this.checksForCurrentPlatform !=null && this.checksForCurrentPlatform.length >0
    }

    private rebuildChecksForCurrentPlatform() : void {
        if (this.assessmentGroup && this.currentProject.dataContext.isReady) {
            let pln = this.platformService.getCurrent(this.stateHelperService.details.projectId);
            let pls =this.currentProject.dataItem.platforms.filter(x=>x.id == pln);
            let pl = (pls.length==0) ? null : pls[0];

            this.checksForCurrentPlatform =this.assessmentGroup.checks.filter((chk) => {
                if (chk.appliesToUpdatePeriod && chk.appliesToUpdatePeriod != pl.updateDate)
                    return false;
                return chk.assessmentStatesDictionary[pln] !== undefined;
            });
       }
    }

}

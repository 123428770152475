import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CurrentApplicationService } from 'src/app/svc/currentApplicationService';
import { StateHelperService } from '../../svc/stateHelperService';

import * as CRS from '../../api/CRS';

@Component({
    selector: 'application-browse-table-selector',
    templateUrl: './application-browse-table-selector.html',
    styleUrls: ['./application-browse-table-selector.scss']
}) 
export class ApplicationBrowseTableSelector {
    static $inject = ['$state', '$scope', '$rootScope', '$localStorage', 'stateHelperService'];

    @Input('package') public package:CRS.RSMsiGetPackageDetails;
    @Input('show-empty') public showEmpty:boolean;

    @Output('open-table') public openTable = new EventEmitter<CRS.RSMsiTable>();

    constructor(
        private currentApplication: CurrentApplicationService,
        public stateHelperService: StateHelperService
    ) {
    }

    public get tables() : Array<CRS.RSMsiTable> {
        if (!this.currentApplication.dataContext.isReady || !this.currentApplication.browser.isReady)
            return [];

        if (this.showEmpty) {
            return this.currentApplication.browser.packageDetails.tables;
        }
        else {
            return this.currentApplication.browser.packageDetails.tables.filter(x=>!x.isEmpty);
        }
    }

    private $onInit() {
    }

    public isCurrent(tbl: CRS.RSMsiTable) : boolean { 
        return tbl && this.stateHelperService.details.tableId == tbl.name;   
    }

    public hasIssues(tbl: CRS.RSMsiTable) : boolean {
        return tbl && this.currentApplication.browser.tableHasIssues(tbl.name);
    }

    public gotoTable(tbl: CRS.RSMsiTable) {
        this.openTable.emit(tbl);
    }

}
import * as CRS from '../api/CRS';
import * as ENUM from '../api/Enum';
import { ExploitabilityLevel } from './ExploitabilityLevel';

export enum ExploitabilityLevelType {
    Detected,
    MoreLikely,
    LessLikely,
    Unlikely,
    NotAffected
}

export enum MonthlyPatchSeverityType
{
    Unknown,
    None,
    Low,
    Moderate,
    Important,
    Critical
}

export enum MonthlyPatchImpactType
{
    Unknown,
    RemoteCodeExecution,
    ElevationOfPrivilege,
    SecurityFeatureBypass,
    InformationDisclosure,
    Spoofing,
    DenialOfService,
    DefenseInDepth
}

export enum CCGState
{
    Unprocessed,
    Partial,
    Completed,
    ContainsErrors
}


export class PatchExt {

    constructor(patch: CRS.RSPatch, products: Array<string>) {

        var pci = new CRS.RSPatchConflictInfo();
        this.conflictInfo = (patch.hasConflictInfoDefined && patch.conflictInfo) ? pci.deserialize(patch.conflictInfo) : null;

        if (patch.exploitD > 0)
            this.maxExploitability = ENUM.ExploitabilityLevelType.Detected;
        else if (patch.exploitML > 0)
            this.maxExploitability = ENUM.ExploitabilityLevelType.MoreLikely;
        else if (patch.exploitLL > 0)
            this.maxExploitability = ENUM.ExploitabilityLevelType.LessLikely;
        else if (patch.exploitUN > 0)
            this.maxExploitability = ENUM.ExploitabilityLevelType.Unlikely;
        else if (patch.exploitNA > 0)
            this.maxExploitability = ENUM.ExploitabilityLevelType.NotAffected;

        if (patch.hasConflictInfoDefined && this.conflictInfo && this.conflictInfo.distributionItems.length>0) {
            this.conflictChartData =new XChartData();
            let lbls = [];
            let data = [];
            this.conflictInfo.distributionItems.forEach((v)=>{lbls.push(v.filename)});
            this.conflictInfo.distributionItems.forEach((v)=>{data.push(v.applicationCount)});
            this.conflictChartData.labels = lbls;
            this.conflictChartData.data = data;
        }

        this.patch = patch;
        this.products = products;
        this.ragValue = "";

        // var threats = this.getProductAssociatedThreats();
        // if (threats.length > 0) {
        //     this.severityDescription = MonthlyPatchSeverityType[threats[0].severity];
        //     this.impactDescription = this.camelSplit(MonthlyPatchImpactType[threats[0].impact]);
        // }

    }

    public conflictInfo: CRS.RSPatchConflictInfo;
    public maxExploitability: ENUM.ExploitabilityLevelType;
    public conflictChartData: XChartData;
    public patch: CRS.RSPatch;
    public products: Array<string>;
    public severityDescription;
    public impactDescription;
    public ragValue:string;

    public isAssociatedWithSoftwareProduct(softwareId:string) : boolean {
        return this.patch.affectedSoftware.indexOf(softwareId) != -1;
    }

    public isAssociatedWithOS(osId: string) : boolean {
        return true;
        //return this.patch.associatedOperatingSystems.indexOf(osId) != -1;
    }

    public isAtExploitabilityLevel(exploitability: ExploitabilityLevel, osId:string, versionNo: number) : boolean {
        if (versionNo == 1) {
            return this.maxExploitability == exploitability.type;
        }
        else {
            let lst = this.patch.scores.filter((x) => x.osId == osId);
            if (lst.length==0)
                return false;
            let score :number = lst[0].temporarlScore;
            return score != -1 && score > exploitability.minScore && score <= exploitability.maxScore;
        }
    }

    private camelSplit(value:string) : string {
        let out:string ="";
        for(var i=0;i<value.length; i++) {
            let c :string = value[i];
            if (i> 0 && c == c.toUpperCase()) {
                out += " " + c;
            }
            else {
                out += c;
            }
        }
        return out.replace(" Of ", " of ");
    }

}

export class XChartData {
    labels: Array<string>;
    series: Array<string>;
    colours: Array<string>;
    data: Array<Array<number>>;
    title:string;
    title2:string;
}
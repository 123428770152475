export class GridIteratorItem {

    constructor(object: any, name:string, heading: string) {
        this.object = object;
        this.name =name;
        this.heading =heading;
    }
    
    public object: any;
    public name:string;
    public heading:string;
}

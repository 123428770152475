import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { CurrentConversionJobService } from '../../svc/currentConversionJobService';
import { CurrentApplicationService } from '../../svc/currentApplicationService';

import * as CRS from '../../api/CRS';

@Component({
selector: 'application-companion-selector',
templateUrl: './application-companion-selector.html',
styleUrls: ['../css/view.scss', '../css/core.scss', '../css/convert.scss']
})
export class ApplicationCompanionSelector implements OnInit , OnDestroy {

    constructor(
        public currentConversionJob: CurrentConversionJobService,
        private currentApplication: CurrentApplicationService,
    ) { }

    @Output('on-change') onChange= new EventEmitter<Array<string>>();

    public includeAllCompanionFiles:boolean=true;
    public selectedCompanionFiles: Array<string>=[];

    private subscriptions : Array<Subscription> = new Array<Subscription>();

    ngOnInit(): void {
        this.currentApplication.dataContext.onLoaded().subscribe(()=>{
            this.setSelectAll();
        });
        this.setSelectAll();
    }

    ngOnDestroy() : void {
        this.subscriptions.forEach(x=>x.unsubscribe());
    }

    public get application() : CRS.RSGetApplication {
        return this.currentApplication.dataItem;
    }

    public get applicationIsReady(): boolean {
        return this.currentApplication.dataContext.isReady;
    }

    public get job(): CRS.RSGetActionDetails {
        return this.currentConversionJob.dataItem;
    }

    public get jobIsReady(): boolean {
        return this.currentConversionJob.dataContext.isReady;
    }
    public includeAllCompanionsChanged() {
        if (this.includeAllCompanionFiles) {
                this.currentApplication.dataItem.companionFiles.forEach(x=>{
                    if (this.selectedCompanionFiles.indexOf(x.companionFileId) == -1)
                        this.selectedCompanionFiles.push(x.companionFileId);
                });
        }
        else {
            this.selectedCompanionFiles=[];
        }
        this.onChange.emit(this.selectedCompanionFiles);
    }

    public isCompanionFileSelected(item: CRS.RSApplicationCompanionFile) : boolean { 
        return this.selectedCompanionFiles.indexOf(item.companionFileId) != -1;    
    }

    public toggleCompanionFileSelection(item: CRS.RSApplicationCompanionFile) : void {
        let idx = this.selectedCompanionFiles.indexOf(item.companionFileId);
        if(idx==-1)
            this.selectedCompanionFiles.push(item.companionFileId);
        else
            this.selectedCompanionFiles.splice(idx, 1);

        if (this.selectedCompanionFiles.length < this.currentApplication.dataItem.companionFiles.length)
            this.includeAllCompanionFiles=false;

        this.onChange.emit(this.selectedCompanionFiles);
    }

    private setSelectAll() : void {
        if (this.currentApplication.dataContext.isReady) {
            this.includeAllCompanionFiles=true;
            this.selectedCompanionFiles = this.currentApplication.dataItem.companionFiles.map(x=>x.companionFileId);
            this.onChange.emit(this.selectedCompanionFiles);
        }
    }
}

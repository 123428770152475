import { Formatters, Editor, Column, Filters, FieldType,  getValueFromParamsOrFormatterOptions } from 'angular-slickgrid';
import { CLColumn } from '../../model/CLColumn';
import { CLGridApplicationTypes } from '../../model/CLGridApplicationTypes';
import { GridIteratorItem } from '../../model/GridIteratorItem';
import { GridColumnType, GridFilterType } from '../../api/Enum';
import { UtilService } from '../../svc/utilService';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { RagValueResolverService } from '../../svc/ragValueResolverService';
import { DISOApplication } from 'src/app/model/DISOApplication';
import { CustomInputFilter } from '../../shared/custom-input-filter';
import { Constants } from '../../api/Constants';
import { Priority } from '../../api/Enum'

import * as CRS from '../../api/CRS';

const NAME_TEMPLATE_APPLIST_XCHECK : string = "XCHECK_NAME";

export class ProjectApplicationsGrid {

    private static currentProject : CRS.RSGetProject;
    private static utilService: UtilService;

    public static getColumns(currentProject: CurrentProjectService, utilService: UtilService) : Array<Column> {
        ProjectApplicationsGrid.currentProject = currentProject.dataItem;
        this.utilService = utilService;
        var cols = new Array<Column>();
         CLGridApplicationTypes.items.forEach(x => {
             if (x.isDynamic) {
                ProjectApplicationsGrid.getIterator(x.id, currentProject).forEach(y=> {
                    var xc = this.buildColumn(currentProject, x);
                    xc.name = y.name;
                    cols.push(xc);
                });
             }
             else {
                cols.push(this.buildColumn(currentProject, x)); 
             }
        });
        return cols;
    }

    private static getIterator(id:string, currentProject: CurrentProjectService) : Array<GridIteratorItem> {
        switch(id)
        {
            case NAME_TEMPLATE_APPLIST_XCHECK:
                return ProjectApplicationsGrid.getXCheckNameIteratorItems(currentProject);
        }
    }

    private static getXCheckNameIteratorItems(currProject: CurrentProjectService) : Array<GridIteratorItem> {
        var ret = new Array<GridIteratorItem>();
        if (currProject.dataContext.isReady) {
            currProject.dataItem.operatingSystems.forEach((os)=> {
                os.trackedItems.forEach((ost)=> {
                    var nm = "os" + "~" + os.id + "~" + ost.checkpoint
                    var hdg = "Test " + ost.display;
                    ret.push(new GridIteratorItem(ost, nm, hdg));
                });
            });
        }
        return ret;
    }

    private static buildColumn(currProj: CurrentProjectService, x: CLColumn) : Column {
        var ret: Column= {
            id:x.id,
            name:x.name,
            maxWidth:x.width,       
            field:x.field,
            type: FieldType.string,
            sortable:x.canSort,
            filterable:x.filterType != GridFilterType.Disabled,
         };
        var fm = this.getFilterMeta(currProj,x);
        if (fm) 
            ret["filter"] = fm;
        var hdr = this.getHeaderMeta(currProj, x);
        if (hdr)
            ret["header"] = hdr;
        this.assignColumnTemplate(x, ret);
        return ret;
    }

    private static getHeaderMeta(currProj: CurrentProjectService, x: CLColumn) :any {
        var menuItems : Array<any> = [];
        if (x.canSort) {
            menuItems.push({iconCssClass: "fa fa-sort-alpha-asc", title: "Sort Ascending", command: "sort-asc"});
            menuItems.push({iconCssClass: "fa fa-sort-alpha-desc", title: "Sort Descending", command: "sort-desc"});
            menuItems.push({iconCssClass: "fa fa-object-group", title: "Group By", command: Constants.GRID_HDR_CMD_GROUP});
        }

        if (menuItems.length==0)
            return null;

        return {
            menu: {
                items: menuItems
            }
        };
    }

    private static getFilterMeta(currProj: CurrentProjectService, x: CLColumn) : any {
        switch(x.filterType)
        {
            case GridFilterType.Default:
                return {
                    model: new CustomInputFilter(),
                    enableTrimWhiteSpace: true
                }
            case GridFilterType.Values:
                return{
                    collectionAsync: this.getValueSet(currProj, x),
                    model:Filters.multipleSelect
                }
            case GridFilterType.YesNo:
                return {
                    collection: [ { value: '', label: '' }, { value: true, label: 'true' }, { value: false, label: 'false' } ],
                    model: Filters.multipleSelect
                }
            case GridFilterType.Status:
                return {
                    collection: [  { value: 5, label: 'Timed Out'}, { value: 4, label: 'Success' }, { value: 3, label: 'Cancelled'}, { value: 2, label: 'Fail' }, { value: 1, label: 'In Progress'} ],
                    model: Filters.multipleSelect
                }
            default:
                return null;
        }
    }

    private static getValueSet(currProj: CurrentProjectService, col: CLColumn) : Promise<any> {

        return new Promise<any>((resolve) => {
            var platNo = currProj.platformNumber;
            var vals = {};
            var ret: Array<any>=[];
            currProj.dataItem.applications.forEach((a)=> {
                var diso = new DISOApplication(a);
                diso.update(currProj.dataItem, platNo, a);
                var v = CLGridApplicationTypes.getValue(diso,col);
                if (v && !vals[v]) {
                    vals[v]=1;
                    ret.push({value:v, label:v});
                }
            })
            resolve(ret); 
        });
    }
    
    private static assignColumnTemplate(x: CLColumn, gridCol: Column) : void {
        if (x.fieldExtended) {
             gridCol.formatter =  (row, cell, value, columnDef, dataContext) => {
                 let fieldExtended = CLGridApplicationTypes.get(String(columnDef.id)).fieldExtended;
                 return value[fieldExtended];
             } 
        }
        switch(x.columnType)
        {
            case GridColumnType.Tick:
                gridCol.formatter = (r,c,v,cd,dc)=> {
                    return (dc.groupingKey) ? dc.value : ProjectApplicationsGrid.getTickTemplate(v, CLGridApplicationTypes.get(String(cd.id)).fieldExtended);
                };     
                gridCol.cssClass = "sw-grid-cell-middle"
                break;
            case GridColumnType.EvalState:
                gridCol.formatter = (r,c,v,cd,dc)=> {
                    return (dc.groupingKey) ? dc.value : ProjectApplicationsGrid.getEvalStateTemplate(v, CLGridApplicationTypes.get(String(cd.id)).fieldExtended, "");
                };
                gridCol.cssClass = "sw-grid-cell-middle"
                break;
            case GridColumnType.Priority:
                gridCol.formatter = (r,c,v,cd,dc)=>{
                    return (dc.groupingKey) ? dc.value : ProjectApplicationsGrid.getPriorityTemplate(v, CLGridApplicationTypes.get(String(cd.id)).fieldExtended);
                }
                gridCol.cssClass = "sw-grid-cell-middle"
                break;
            case GridColumnType.Status:
                gridCol.formatter = (r,c,v,cd,dc)=>{
                    return (dc.groupingKey) ? dc.value : ProjectApplicationsGrid.getStatusTemplate(v, CLGridApplicationTypes.get(String(cd.id)).fieldExtended);
                }
                gridCol.cssClass = "sw-grid-cell-middle"
                break;
            case GridColumnType.PubStatus:
                gridCol.formatter = (r,c,v,cd,dc)=>{
                    return (dc.groupingKey) ? dc.value : ProjectApplicationsGrid.getPubStatusTemplate(v, CLGridApplicationTypes.get(String(cd.id)).fieldExtended);
                }
                gridCol.cssClass = "sw-grid-cell-middle"
                    break;
                case GridColumnType.RAG:
                 gridCol.formatter = (r,c,v,cd,dc)=> { 
                    return (dc.groupingKey) ? dc.value : ProjectApplicationsGrid.getRagTemplate(dc);
                 };
                 break;
            case GridColumnType.Date:
                gridCol.formatter = (r,c,v,cd,dc)=> {
                    return (dc.groupingKey) ? dc.value : this.utilService.getDateAsString(v);
                }
                break;
            case GridColumnType.AppName:
                gridCol.formatter = (r,c,v,cd,dc)=> {
                    return (dc.groupingKey)? dc.value : ProjectApplicationsGrid.getNameTemplate(v, CLGridApplicationTypes.get(String(cd.id)).fieldExtended);
                }
                break;
        }
        return null;
    }

    public static getRagTemplate(item:DISOApplication) : string {
        if (item.item.hasFailed)
            return "<div class='sw-error-container'>Error</div>";
        var neutralise = !item.item.hasBeenAssessed;
        var val = (neutralise)
            ? RagValueResolverService.getNeutralised()
            : RagValueResolverService.resolveExplicit(item.ragValue);
        return "<div class='full-width-bs full-height-bs sw-rag-small sw-rag-" + val.colorClass + "'></div>";
    }

    public static getTickTemplate(app:CRS.RSApplication, property:string) : string {
        var html = '';
        if (app[property]) {
            html += '<div class="sw-ok md-24">';
            html += '<i class="material-icons" title="Loaded">check_circle</i>';
            html += '</div>';
        }
        return html;
    }

    public static getStatusTemplate(app:CRS.RSApplication, property:string) : string {
        var html = '';
        if (app[property] && app[property] != 99) {
            var pt="";
            switch(app[property]){
                case 1:
                    pt = "<i class='material-icons sw-ok md-24' title='In Progress'>hourglass_empty</i>";
                    break;
                case 2:
                    pt = "<i class='material-icons sw-error-no-bold md-24' title='Failed'>error</i>";
                    break;
                case 3:
                    pt ="<i class='material-icons sw-error-no-bold md-24' title='Cancelled'>cancel</i>";
                    break;
                case 4:
                    pt = "<i class='material-icons sw-ok md-24' title='Completed Sucessfully'>check_circle</i>";
                    break;
                case 5:
                    pt = "<i class='material-icons sw-error-no-bold md-24' title='Timed Out'>cancel</i>";
                    break;
            }
            html = `<div>${pt}</div>`;
        }
        return html;
    }

    public static getPubStatusTemplate(app:CRS.RSApplication, property:string) : string {
        var html = '';
        if (app[property] && app[property] != 99) {
            var pt="";
            switch(app[property]){
                case 1:
                    pt = "<i class='material-icons sw-ok md-24' title='In Progress'>hourglass_empty</i>";
                    break;
                case 2:
                    pt = "<i class='material-icons sw-ok md-24' title='Completed Sucessfully'>check_circle</i>";
                    break;
                case 3:
                    pt = "<i class='material-icons sw-error-no-bold md-24' title='Failed'>error</i>";
                    break;
            }
            html = `<div>${pt}</div>`;
        }
        return html;
    }

    public static getEvalStateTemplate(app:CRS.RSApplication, property:string, tooltip:string) :string {
        var html ='';
        if (app[property]) {
            switch(app[property])
            {
                case "+":
                    html += '<div class="sw-ok md-24">';
                    html += '<i class="material-icons" title="Success">check_circle</i>';
                    html += '</div>';
                    break;
                case "-":
                    html += '<div class="sw-error md-24">';
                    html += '<i class="material-icons" title="Failed">error</i>';
                    html += '</div>';
                    break;
                case "?":
                    html += '<div class="sw-warning md-24">';
                    html += '<i class="material-icons" title="Inconclusive">flaky</i>';
                    html += '</div>';
                    break;
            }
        }
        return html;
    }

    public static getPriorityTemplate(app:CRS.RSApplication, property:string): string {
        var v = app[property];
        var html='';
        html = '<div>' + Priority[v] + "</div>";
        return html;
    }

    public static getNameTemplate(app:CRS.RSApplication, property:string) : string {
        var progClass = this.getProgressClass(app);
        var progClassExpr = (progClass) ? ` class="${progClass}"` : "";
        var progTextClassExpr= (progClass) ? `class="sw-incomplete"` : "";
        if (progClassExpr) {
        return  "<div fxLayout='column'>" +
                    `<div fxFlex ${progTextClassExpr}>${app[property]}</div>` +
                    `<div fxFlex='none' style='height:3px' ${progClassExpr}></div>` +
                "</div>";
        }
        else {
            return `<div fxFlex>${app[property]}</div>`;
        }
    }

    private static getProgressClass(app:CRS.RSApplication): string {
        if (app && app.isPending) 
            return "sw-progress-pending";
        else if (app && !app.isComplete)
            return "sw-progress-x-" + String(app.importPercentComplete);
        return null;
    }

    public static getXCheckTemplate(app:CRS.RSApplication, property:string): string {
        return "<div class='ui-grid-cell-contents'>" +
                    "<div layout='column'>" + 
                        "<div flex>" + 
                            "<shortcut-test-result value='{{grid.appScope.vm.getShortcutTestResult(row,col)}}' comparison='{{grid.appScope.vm.getShortcutTestComparisonResult(row,col)}}'/>" + 
                        "</div>" + 
                    "</div>"+
                "</div>";
    } 
}


<div fxLayout="column" class="full-width-bs full-height-bs">

    <div fxFlex *ngIf="!isReady()" fxLayout="column">
        <div fxFlex></div>
        <spinner fxFlex="none"></spinner>
        <div fxFlex></div>
    </div>

    <div fxFlex="none" *ngIf="isReady()">
        <p>Use this page to define your standards for packaging by defining the rules that would cause a package to fail QA in your environment.</p> 
        <p>Use <mat-icon>add</mat-icon> to define a new rule and <mat-icon>cancel</mat-icon> to delete one.</p>
        <p>&nbsp;</p>
    </div>

    <div fxFlex class="sw-segment" *ngIf="isReady()">
        <div>
            <project-query-ruleset-table [qrh]="qrh"></project-query-ruleset-table>
        </div>
    </div>
    
</div>


import { Component, OnInit, Input } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { UtilService } from '../../svc/utilService';
import { StateHelperService } from '../../svc/stateHelperService';
import { Constants } from '../../api/Constants';

import * as CRS from '../../api/CRS';

@Component({
  selector: 'project-queries-sidenav',
  templateUrl: './project-queries-sidenav.html',
  styleUrls: ['../../shared/css/nav-bar.scss']
})
export class ProjectQueriesSideNav implements OnInit {

  constructor(private route:ActivatedRoute, private router:Router, private utilService:UtilService, stateHelperService: StateHelperService) { }

  @Input('project') project : CRS.RSGetProject;

  ngOnInit(): void {
  }

  public navItems = [
    { text: "Standards", route: Constants.ROUTE_PROJECTQUERIESSTANDARDS, icon: "subject" },
    { text: "Checksheet", route: Constants.ROUTE_PROJECTQUERIESCHECKSHEET, icon: "subject" },
    { text: "Custom", route: Constants.ROUTE_PROJECTQUERIESCUSTOM, icon: "traffic" }
  ]

  public getClass(item: any) : string{
    if (this.route.children.length>0 && this.route.children[0].routeConfig.path == item.route)
        return "sw-nav-option-active";
    else
        return "sw-nav-option-inactive";
  }

  public isReady() : boolean {
    return !this.utilService.isEmptyAny(this.project);
  }

  public disableClick(): boolean {
    return this.utilService.isEmptyAny(this.project);
  }

  public invoke(item:any)
  {
      this.router.navigate([item.route],  {relativeTo: this.route, queryParamsHandling: "merge" });        
  }

  public isMaximised() :boolean {
    return this.router.url /*.parent */== "projectQueries";
  }

  public navItemsFilter(item: any): boolean {
      return (!item.condition || item.condition())
  }

}

import { Injector } from '@angular/core';
import { MenuService } from '../svc/menuService';
import { CurrentProjectService } from '../svc/currentProjectService';
import { StateHelperService } from '../svc/stateHelperService';
import { PlatformService } from '../svc/platformService';
import { ProjectService } from '../api/SVC';
import { UtilService } from '../svc/utilService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';

import * as CRS from '../api/CRS';

export class ReportOptionsCommand {

    private stateHelperService: StateHelperService;
    private currentProjectService: CurrentProjectService;
    private platformService: PlatformService;
    private projectService: ProjectService;
    private utilService: UtilService;
    private menuService: MenuService;

    constructor(
        private injector: Injector
    ) {
        this.menuService = this.injector.get(MenuService);
        this.stateHelperService = this.injector.get(StateHelperService);
        this.currentProjectService = this.injector.get(CurrentProjectService);
        this.platformService = this.injector.get(PlatformService);
        this.projectService = this.injector.get(ProjectService);
        this.utilService = this.injector.get(UtilService);

        this.menuService.register(Constants.MENU_REPORT_OPTIONS, this.reportOptions, Constants.ROUTE_PROJECTREPORTS);
        this.reportOptions.subItems = ()=> {
            let ag = this.stateHelperService.details.assessmentGroupId;
            let hasAG = !this.utilService.isEmpty(ag);
            let prj = this.currentProjectService.dataItem;
            if (prj && prj.reportOptions) {
                var lst = prj.reportOptions.filter(x=> {
                     if (x.flags && x.flags.indexOf("AG") != -1 && !hasAG) { //only show if ag is present in view
                         return false;
                     }
                     return true;
                    });
                return lst;
            }
            return [];
        };

        this.reportOptions.executeSubItem = (item: CRS.RSReportOption) => {
            let prjId = this.stateHelperService.details.projectId;
            let pno = 0;
            if (item.platformBundleNumber==1) {
                pno = this.platformService.getCurrent(prjId);
            }
            this.projectService.runReport(prjId, this.utilService.getEmptyGuid(), item.id, pno, null);
        };

    }

    private reportOptions: AppCommand = AppCommand.createWithShow(
        ()=> {
            return this.currentProjectService.dataContext.isReady && this.currentProjectService.dataItem.applicationCount>0;
        },
        ()=> {
        },
        ()=> {
            return true;
        }
    );
    
}
import { Injectable,Inject } from '@angular/core';
import { Observable, Subject, timer , Subscription} from 'rxjs';
import { filter, takeUntil,first } from 'rxjs/operators';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { EventMessage, AuthenticationResult, EventType, InteractionType, PopupRequest, RedirectRequest, AccountInfo } from '@azure/msal-browser';
import { AuthConfig } from 'e2e/src/app/authConfig';
import { IPublicClientApplication, PublicClientApplication, BrowserCacheLocation } from '@azure/msal-browser';
import { MsalInterceptorConfiguration} from '@azure/msal-angular';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function MSALInstanceFactory(): IPublicClientApplication {

    var redir = window.location.origin + "/";
    return new PublicClientApplication({

        auth: {
            clientId: AuthConfig.clientId,
            authority: 'https://login.microsoftonline.com/' + AuthConfig.tenantId,
            redirectUri: redir //auth.configuration.redirectUri
        },
            cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: isIE, // set to true for IE 11
        },
    });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {

    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set(AuthConfig.resourceUri, [ AuthConfig.resourceScope ]);
  
    return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return { interactionType: InteractionType.Redirect };
}
  
@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private msalAuthService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
    ) {
        this.msalBroadcastService.msalSubject$
        .pipe(
          filter((msg: EventMessage) => {
            return msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS;
          }),
          takeUntil(this._destroying$)
        )
        .subscribe((result: EventMessage) => {
            var pay : AuthenticationResult = <AuthenticationResult>result.payload;
            this._authToken = pay.accessToken;
            this._authTokenExpires = pay.expiresOn;
            if (!this._isLoggedIn) {
                this.checkAccount();
            }
        });

        this.checkAccount();
        this.expiryTimerSource = timer(60000,60000);
        this.expiryTimerSourceSubscription = this.expiryTimerSource.subscribe(()=>this.authTokenRefresh());
}

    private readonly _destroying$ = new Subject<void>();

    private expiryTimerSource : Observable<number>=null;
    private expiryTimerSourceSubscription : Subscription;

    private _loggedIn = new  Subject<boolean>();
    public onLoggedIn(): Observable<boolean> { 
        return this._loggedIn.asObservable();
    }

    private _timedOut = new Subject<boolean>();
    public onTimedOut(): Observable<boolean> {
        return this._timedOut.asObservable();
    }

    private _authToken : string;
    public get authToken() : string {
        return this._authToken;
    }

    private _msalAccountInfoCached : AccountInfo=null;
    private get msalAccount() : AccountInfo {
        var accounts= this.msalAuthService.instance.getAllAccounts();
        if (accounts.length > 0)  {
             this._msalAccountInfoCached = accounts[0];
             return accounts[0];
        }
        return null;
    }

    private get msalAccountCached() : AccountInfo {
        return this._msalAccountInfoCached;
    }

    private _authTokenExpires: Date;
    private get authTokenExpires(): Date{
        return this._authTokenExpires;
    }

    private  _isLoggedInResolved:boolean=false;
    public isLoggedInResolved(): boolean {
        return this._isLoggedInResolved;
    }

    private _isLoggedIn:boolean=false;
    public get isLoggedIn(): boolean {
        return this._isLoggedIn;
    }

    public login() {
        if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
            if (this.msalGuardConfig.authRequest){
            this.msalAuthService.loginPopup({...this.msalGuardConfig.authRequest} as PopupRequest)
                .subscribe(() => this.checkAccount());
            } else {
                this.msalAuthService.loginPopup()
                .subscribe(() => this.checkAccount());
            }
        } else {
            if (this.msalGuardConfig.authRequest){
            this.msalAuthService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
            } else {
            this.msalAuthService.loginRedirect();
            }
        }
    }

    public logout() {
        var accounts= this.msalAuthService.instance.getAllAccounts();
        if (accounts.length> 0) {
            var a0 = accounts[0]
            this.msalAuthService.logoutRedirect({ account: a0});
            this._isLoggedIn=false;
        }
    }

    private _checkInProgress=false;
    private checkAccount() : void {
        if (this._checkInProgress || this.isLoggedIn || this.msalAccount == null) 
            return;
        this._checkInProgress=true;
        const accessTokenRequest = { scopes: [AuthConfig.resourceScope  ], account: this.msalAccount };
        this.msalAuthService.acquireTokenSilent(accessTokenRequest).pipe(first()).subscribe((response)=>{
            this._authToken = response.accessToken;
            this._authTokenExpires = response.expiresOn;
            this._isLoggedIn =  true;
            this._isLoggedInResolved=true;
            this._loggedIn.next();
            this._checkInProgress=false;
        });
    }

    public authTokenRefresh() : Observable<boolean> {

        if (!this.msalAccountCached) 
            return null;
        var obs = new Subject<boolean>();
        const accessTokenRequest = { scopes: [AuthConfig.resourceScope  ], account: this.msalAccountCached };
        this.msalAuthService.acquireTokenSilent(accessTokenRequest).pipe(first()).subscribe((response)=>{
            this._authToken = response.accessToken;
            this._authTokenExpires = response.expiresOn;
            obs.next(true);
        });
        return obs;
    }

}




import { Injector } from '@angular/core';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilService } from '../../svc/utilService';
import { ProjectActivityData } from './project-activity-data';
import { UserService } from '../../api/SVC';
import { GridAssistant } from 'src/app/model/gridAssistant';

import * as CRS from '../../api/CRS'

@Component({
    selector: 'project-activity-dialog',
    templateUrl: 'project-activity-dialog.html',
  })
  export class ProjectActivityDialog implements OnInit {
  
    constructor(
      private injector: Injector,
      private utilService: UtilService,
      private userService: UserService,
      public dialogRef: MatDialogRef<ProjectActivityDialog>,
      @Inject(MAT_DIALOG_DATA) public data: ProjectActivityData) {}

    public duration:string;

    public gridAssist: GridAssistant<CRS.RSGetUserActivity, CRS.RSActivityItem>;

    ngOnInit() {
        this.duration = this.utilService.localStorageHelper.activityListDuration;
        this.gridAssist = new GridAssistant<CRS.RSGetUserActivity, CRS.RSActivityItem>("#Project-Activity-Dialog", this.injector);
        this.gridAssist.setColumns(   [         
            { id: 'activityDate', name: 'Date', field: 'activityDate', sortable: true, width: 130,
                formatter:  (r,c,v,cd,dc)=> {
                    return this.utilService.getDateAsStringWithTime(v);
                }
            },
            { id: 'prjName', name: 'ProjectName', field: 'projectName', sortable: true, width: 200 },
            { id: 'text', name: 'Description', field: 'text', sortable: true, width: 400 },
          ]
        );
        this.gridAssist.setUpdate(()=>this.update());
    }

    public onNoClick(): void {
        this.dialogRef.close();
    }

    public durationChanged($event) {
      this.utilService.localStorageHelper.activityListDuration = this.duration;
      this.update();
    }

    private update() {
      this.userService.getUserActivity(this.duration).then((response) => {
        if (response.status.errorCode == 0) {
          this.gridAssist.mergeDataResponse(response, "items", "id", CRS.RSActivityItem );
        }
        else {
          this.gridAssist.items=[];
          this.gridAssist.errorMessage=response.status.message;
        }
    });

    }


}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Column, GridOption, Formatters, FieldType, Editors, SlickGrid } from 'angular-slickgrid';
import { Subscription } from 'rxjs';
import { Constants } from '../../api/Constants';
import { EventService } from '../../svc/eventService';
import { TrackedOperatingSystem } from '../../model/TrackedOperatingSystem';
import { CurrentProjectService } from '../../svc/currentProjectService';
import * as CRS from '../../api/CRS';

@Component({
  selector: Constants.ROUTE_PROJECTSETTINGSXCHECK,
  templateUrl: './project-settings-xcheck.html',
  styleUrls: ['../../shared/css/nav-bar.scss', '../../shared/css/tables.scss']
})
export class ProjectSettingsXCheck implements OnInit, OnDestroy {

  constructor(
    private currentProject: CurrentProjectService,
    private eventService: EventService) { }

  private subscriptions: Array<Subscription> =new Array<Subscription>();
  private grid: SlickGrid=null;
  public gridColumns: Column[];
  public gridOptions: GridOption;
  public trackableOperatingSystems : Array<TrackedOperatingSystem> = null;
    
  ngOnInit(): void {

    this.subscriptions.push(this.currentProject.dataContext.onLoaded().subscribe(()=> this.ensure));
    this.subscriptions.push(this.eventService.onProjectDetailsUpdateRequest().subscribe(()=> this.ensure()));
    this.ensure();
    this.initialiseGrid();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s)=>s.unsubscribe());
  }

  public get project() : CRS.RSGetProject {
    return this.currentProject.dataItem;
  }

  public canEdit(): boolean {
    return this.currentProject.canEdit();
  }

  public disableXCheckChanged() {
    var vv = (this.project.disableXCheck) ? "true" : null;
    this.currentProject.updateDisableXCheck(vv);
  }

  private ensure() {
    this.trackableOperatingSystems = this.currentProject.getTrackedOperatingSystems();
  }

  public gridReady(grid:SlickGrid) {
    this.grid = grid;
    this.grid.onBeforeEditCell.subscribe((r,c)=> {
      var cellId = this.grid.getColumns()[c.cell].id;
      var actionableCell:boolean=false;
      var row = this.trackableOperatingSystems[c.row];
      if (cellId == 'originalTestEnabled') 
      {
          row.originalTestEnabled = !row.originalTestEnabled;
          actionableCell=true;
      }
      if (cellId == 'msiTestEnabled') {
          row.msixTestEnabled = !row.msixTestEnabled;
          actionableCell=true;
      }
      if (actionableCell) {
        this.updateTrackable(row);
        this.grid.updateRow(c.row);
        this.grid.invalidate();
        return false;
      }
    });
  }

  private updateTrackable(tos: TrackedOperatingSystem) {
    tos.isChecked= tos.originalTestEnabled || tos.msixTestEnabled;
    this.currentProject.updateXCheckTrackedOperatingSystems(tos);
  }

  private initialiseGrid() {
    this.gridOptions = {
      editable: true,
      autoEdit: true,
      enableAutoResize: true,
      enableSorting: true,
      enableRowSelection: true,
      enableCheckboxSelector: false,
      enableCellNavigation:true,
      enableGridMenu:false,
      multiSelect: false,
      rowHeight:28,
      rowSelectionOptions: {
        selectActiveRow: true,
      },
      checkboxSelector: {
        hideSelectAllCheckbox: true,
      },
    };

    this.gridColumns = [
      { id: 'operatingSystemId', name: 'Operating System', field: 'operatingSystemId', sortable: true, width: 300 },
      { id: 'checkpoint', name: 'Checkpoint', field: 'checkpoint', sortable:true, width: 400 },
      { id: 'origin', name: 'Origin', field: 'origin', sortable:true, width:200 },
      { id: 'originalTestEnabled', name: 'Original Test Enabled', field: 'originalTestEnabled', sortable:true, width:200,
          type: FieldType.boolean, cssClass: 'sw-grid-align-centre sw-pointer',
          formatter: Formatters.checkmark, editor: { model: Editors.checkbox }
      },
      { id: 'msixTestEnabled', name: 'MSIX Test Enabled', field: 'msixTestEnabled', sortable:true, width:200,
          type:FieldType.boolean, cssClass: 'sw-grid-align-center sw-pointer',
          formatter: Formatters.checkmark, editor: { model: Editors.checkbox }
      },

    ];

  }
}

import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { CurrentApplicationService } from '../../svc/currentApplicationService';
import { Constants } from '../../api/Constants';
import * as CRS from '../../api/CRS';

@Component({
  selector: 'project-application-related-apps',
  templateUrl: './project-application-related-apps.html',
})
export class ProjectApplicationRelatedApps implements OnInit, OnDestroy {

  constructor(
      private currentProject: CurrentProjectService,
      private currentApplication: CurrentApplicationService, 
  ) { }

  private subscriptions : Array<Subscription> = new Array<Subscription>();

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x=>x.unsubscribe());
  }

  public get application() : CRS.RSGetApplication {
    return this.currentApplication.dataItem;
  }

  public isDataReady() : boolean {
    return this.currentProject.dataContext.isReady && this.currentApplication.dataContext.isReady;
  }

  public hasSome() : boolean {
    return this.isDataReady() && this.currentApplication.dataItem.extractedApplications.length>0;
  }

  public getAppLink(id:string): string {
    return `/${Constants.ROUTE_PROJECTDETAILS_MAIN}/${this.currentApplication.dataItem.projectId}/${Constants.ROUTE_PROJECTAPPLICATIONS}/${Constants.ROUTE_PROJECTAPPLICATIONLANDING_MAIN}/${id}`;
  }

}

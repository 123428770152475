<div fxLayout="column">
    <div fxFlex fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center" *ngFor="let kvp of items; let idx = index">
        <mat-form-field fxFlex appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput [(ngModel)]="kvp.id" type="text" class="full-width-bs" (change)="markDirty()" [disabled]="!canEdit()">
        </mat-form-field>
        <mat-form-field fxFlex appearance="outline">
            <mat-label>Label</mat-label>
            <input matInput [(ngModel)]="kvp.label" type="text" class="full-width-bs" (change)="markDirty()" [disabled]="!canEdit()">
        </mat-form-field>
        <mat-form-field fxFlex="60" appearance="outline">
            <mat-label>Value</mat-label>
            <input matInput [(ngModel)]="kvp.value" type="text" class="full-width-bs" (change)="markDirty()" [disabled]="!canEdit()">
        </mat-form-field>   
        <div fxFlex="10" class="sw-pad-bottom">
            <button mat-button (click)="remove(idx)" *ngIf="!isEmpty(idx)"><mat-icon image-size size="20pt">delete</mat-icon></button>
        </div>
    </div>
    <div fxFlex="none" fxLayout="row" fxLayoutGap="5px"> 
        <div fxFlex></div>
        <button mat-button fxFlex="none" (click)="add()" *ngIf="canEdit()" class="button-link-narrow">
            <mat-icon>add</mat-icon>
        </button>
        <button mat-button fxFlex="none" (click)="apply()" *ngIf="isDirty()" [disabled]="!isValid()" class="button-link-narrow">
            <mat-icon>done</mat-icon>
        </button>
        <button mat-button fxFlex="none" (click)="cancel()" *ngIf="isDirty()" class="button-link-narrow">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
</div>  

import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { PollingMethod } from './pollingMethod';
import { UtilService } from './utilService';

@Injectable({
    providedIn: 'root'
})
export class PollingService {
    
    private methods: { [key: string]: PollingMethod } = {};

    constructor(
        private router: Router,
        private utilService: UtilService
    ) {
    }

    public startPollingSequence(
        name: string,
        delay: number,
        stepTo: number,
        callback: (resolve: Function, reject: Function) => any
    ): PollingMethod {
        if (this.methods[name] && this.methods[name].running) {
            throw new Error(`A method with the name [${name}] already exists!`);
        }

        let method = new PollingMethod(delay, this.router , callback);
        this.methods[name] = method;
        method.start();

        return method;
    }

    public stopSequence(name: string ) {
        if (!this.methods[name]) 
            throw new Error('Cannot refresh a non-existing polling sequence [' + name + ']');

        if (this.methods[name].running)
            this.methods[name].stop();        
    }

    public exists(name:string) {
        return !this.utilService.isEmptyAny(this.methods[name])
    }

    public isRunning(name:string) {
        var ps = this.methods[name];
        if (ps)
            return ps.running;
        return false;
    }

    public changeRefreshDelay(name: string, delay: number, stepTo: number) {
        if (!this.methods[name])
            throw new Error('Cannot refresh a non-existing polling sequence [' + name + ']');

        if (this.methods[name].running) {
            this.methods[name].delay = delay;
            //this.methods[name].stepTo = stepTo;
            this.methods[name].refresh();
        }
    }

    public refreshPollingSequence( name: string) {
        if (!this.methods[name])
            throw new Error('Cannot refresh a non-existing/stopped polling sequence [' + name +']');

        if (this.methods[name].running) 
            this.methods[name].refresh();        
    }
}

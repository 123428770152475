import { Injectable } from '@angular/core';
import { first} from 'rxjs/operators';
import { AuthService } from './authService';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class DownloadService {

    constructor( 
        private authService: AuthService,
        private http:HttpClient
    ) { 
        let lthis =this;
    }

    getZip(url: string, filename:string =null) {

        var bearerAuth = `Bearer ${this.authService.authToken}`;
        var hdrs =  new HttpHeaders( { Authorization: bearerAuth });

        this.http.get(url,  { responseType: 'blob', headers:hdrs}).subscribe(data =>{

            const blob = new Blob([data], {
                type: 'application/zip'
            });

            var url = window.URL.createObjectURL(blob);
            var fileLink = document.createElement('a');
            fileLink.href = url;
            fileLink.download = "mydownload.zip";
            fileLink.click();     
        });
    }
    
    public downloadViaApiORIG(url:string) {

        var bearerAuth = `Bearer ${this.authService.authToken}`;
        var hdrs =  new HttpHeaders( { Authorization: bearerAuth });

        return this.http.get(url, {  responseType: 'blob', observe: 'response', headers:hdrs }).subscribe((data)=>{
            var cd=  data.headers.get("content-disposition");
            var splt = cd.split(';').filter(x=>x.trim().startsWith("filename="));
            if (splt.length>0) {
                var outputFilename = splt[0].split('=')[1];
                var file = new Blob([data.body], {type: data.headers.get("content-type")});
                var fileURL = URL.createObjectURL(file);
                var downloadLink = document.createElement("a");
                downloadLink.href = fileURL;
                downloadLink.download = outputFilename;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                URL.revokeObjectURL(fileURL);
            }
        });
    }
}

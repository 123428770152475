<p *ngIf="column && !column.isEditable" class="sw-label">{{column.value}}</p>

<div fxLayout="row" *ngIf="column && column.isEditable">
    <div fxFlex *ngIf="column.editType == 'string'">
        <input-edit datatype="STRING" [value]="value"></input-edit>
    </div>
    <div fxFlex *ngIf="column.editType == 'yesnona'" class="sw-ynna">
        <mat-select [(ngModel)]="value" style="width:100%" placeholder="Specify Result" (selectionChange)="valueChanged()">
            <mat-option value="y">Yes</mat-option>
            <mat-option value="n">No</mat-option>
            <mat-option value="na">N/A</mat-option>
        </mat-select>
    </div>
     <!-- <div fxFlex="none" class="sw-label">
        {{column.editType}}-{{value}}
    </div>  -->
</div>

<div *ngIf="column && column.editType == 'yesno'" style="text-align: center">
    <mat-icon *ngIf="value == 'True' || value == 'Yes'">done</mat-icon>
    <mat-icon *ngIf="value == 'False' || value == 'No'" class="sw-error">clear</mat-icon>
    <mat-icon *ngIf="value == 'NoInformation'" class="sw-error">question_mark</mat-icon>
</div>

<!-- If the subsequent column is to be merged into this one, then it can be a table or just a scalar value -->
<!-- listcolumns indicates table -->
<div *ngIf="nextColumnIsMergeAbove()" class="sw-merged">
    <div *ngIf="!getNextColumnListColumns()">
        <p class="sw-label">{{nextColumn.value}}</p>
    </div>
    <div *ngIf="getNextColumnListColumns()">
        <table *ngIf="getNextColumnListRows().length > 0">
            <thead *ngIf="!isColumnHeadingsHidden()">
                <tr>
                    <td *ngFor="let nc of getNextColumnListColumns()">{{nc}}</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let ncr of getNextColumnListRows()">
                    <td *ngFor="let nc of ncr.columns" [ngClass]="{'sw-table-no-border':isBorderHidden()}">{{nc.value}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>


<div fxLayout="row" style="max-width:1800px">

    <div fxFlex fxLayout="column">
        <div fxFlex="none">
            <h2>Project Info</h2>
        </div>
        <div fxFlex="none">
            <input-edit datatype="STRING-MIN3" label="Name" [value]="project.name" 
                        (on-change)="projectNameChanged($event)" [disable]="!canEdit()"></input-edit>
        </div>
        <div fxFlex="none">
            <input-edit datatype="MSTRING" label="Description" [value]="project.description" 
                        (on-change)="projectDescriptionChanged($event)" [disable]="!canEdit()"></input-edit>
        </div>
        <div fxFlex="none">
            <input-edit datatype="URL" label="Storage Access Key" [value]="project.storageAccessKey" 
                        (on-change)="projectStorageAccessKeyChanged($event)" [disable]="!canEdit()"></input-edit>
        </div>

        <div fxFlex="none" class="sw-big-vert-pad-top"></div>
        <div fxFlex="none"  class="sw-big-vert-pad-top" fxLayout="row" fxLayoutGap="10px">
            <div fxFlex>
                <mat-form-field appearance="outline" style="min-width:400px">
                    <mat-label>Desktop Base Image</mat-label>
                    <mat-select [(ngModel)]="project.desktopBaseImageId" (selectionChange)="desktopBaseImageChanged($event)" placeholder="Desktop Base Image Id" style="margin:0px">
                        <mat-option *ngFor='let item of project.trackableOperatingSystemConfigurations | callback:filterDesktopOs' [value]='item.id'>{{item.id}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>  
            <div fxFlex>
                <mat-slide-toggle [checked]="project.desktopBaseImageXCheckRunning" (change)="setBaseImageXCheckRunningStateChange()" aria-label="Accepting x-check tasks"></mat-slide-toggle>
            </div>
        </div>

        <div fxFlex fxLayout="row" fxLayoutGap="20px" class="sw-big-pad-top">
            <div fxFlex="none">
                <h2>Project Image</h2>
                <div>
                    <image-upload [image]='projectImageUrl' [disable]="!canEdit()" 
                            (on-change)="projectImageChanged($event)"></image-upload>
                </div>
            </div>
            <div fxFlex>
                <h2>Customer Reporting Image</h2>
                <div>
                    <image-upload [image]='projectCustomerReportingImageUrl' 
                        [disable]="!canEdit()" (on-change)="customerReportingImageChanged($event)"></image-upload>
                </div>
            </div>
        </div>
    </div>

    <div fxFlex="5"></div>

    <div fxFlex fxLayout="column" class="sw-big-vert-pad-top" *ngIf="projectIsReady && projectNotes.isDataReady">
        <div fxFlex="none">
            <h2>Notes</h2>
        </div>
        <div fxFlex>
            <notes-editor [items]="projectNotes.currentItems"></notes-editor>
        </div>
    </div>  

    <div fxFlex="5"></div>

</div>
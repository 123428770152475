<div fxFlex>
    <div fxLayout="column" fxFlexFill fxLayoutAlign="start center">
        <div fxFlex></div>
        <div fxFlex="none">
            <div>
                <img src="/img/misc/404.png" alt="error">
            </div>
        </div>
        <div fxFlex="none" class='sw-error-page-not-found'>
            <h3>PROJECT NOT FOUND</h3>
        </div>
        <div fxFlex></div>
    </div>
</div>

import { Component } from '@angular/core';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { Constants } from '../../api/Constants';

@Component({
  selector: "project-error",
  templateUrl: './project-error.html',
  styleUrls: ['../../shared/css/header.scss']
})
export class ProjectError {

  constructor(
    private currentProject: CurrentProjectService,
  ) { 
  }

  
}

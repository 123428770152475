<div fxLayout="column" fxFlexFill  fxLayoutAlign="start stretch" class="sw-pad">

    <spinner fxFlex *ngIf="!isDataReady() && !isInError()"></spinner>

    <div fxFlex *ngIf="isInError()" class="sw-segment">
        <div fxFlayout="column" fxLayoutAlign="center center">
            <div fxFlex fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center" class="sw-gen-error-panel">
                <div fxFlex="none"><img src="/img/misc/warning.png"></div>
                <div fxFlex="none">
                    <h2 class="sw-error">Error Code: {{errorCode}}</h2>
                    <h3>{{errorReason}}</h3>
                </div>
            </div>
        </div>
    </div>

    <project-application-detail-header fxFlex="none" *ngIf="isDataReadyAndValid() || isDataReadyAndPending()"></project-application-detail-header>

    <project-application-importing fxFlex *ngIf="isDataReadyAndImporting()"></project-application-importing>
    
    <div fxFlex="none" class="sw-pad-small"></div>

    <div fxFlex fxLayout="row" fxLayoutGap="10px" fxFlexFill fxLayoutAlign="start stretch" *ngIf="isDataReadyAndValid() || isDataReadyAndPending()">
        <div fxFlex="none" class="full-height-bs sw-segment" id="sw-actions-tree-container" *ngIf="isActive()">
            <application-action-tree (onSelect)="openAction($event)"></application-action-tree>
        </div>
        <div fxFlex>
            <div class="full-height-bs">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>

    <div fxFlex *ngIf="isDataReadyWithError()" class="sw-pad">
        <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
            <div fxFlex="none">
                <mat-icon class="sw-error" image-size size="56pt" style="min-width:80px">warning</mat-icon>
            </div>
            <div fxFlex>
                <div class="sw-title">THIS APPLICATION FAILED TO IMPORT SUCCESSFULLY</div>
                <div class="sw-subtext sw-upper">Check the media locally and re-import, as appropriate</div>
            </div>
        </div>
    </div>

</div>  
import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { CurrentApplicationService } from "../svc/currentApplicationService";
import { MenuService } from '../svc/menuService';
import { UtilService } from '../svc/utilService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';
import { DialogService } from '../svc/dialogService';

import * as Enum from '../api/Enum';

export class DeleteApplicationCommand {

    constructor(
        private injector: Injector,
    ) {
        this.router = this.injector.get(Router);
        this.menuService = this.injector.get(MenuService);
        this.dialogService = this.injector.get(DialogService);
        this.currentApplication = this.injector.get(CurrentApplicationService);
        this.utilService = this.injector.get(UtilService);
        
        this.menuService.register(Constants.MENU_DELETE, this.deleteCommand, "*"+Constants.ROUTE_PROJECTAPPLICATIONLANDING_MAIN )

        this.currentApplication.dataContext.onStateChanged().subscribe(x=> {
            this.isAppReady=x.loadState == Enum.LoadState.Ready;
        });

    }

    private isAppReady=false;
    private router: Router;
    private menuService: MenuService;
    private dialogService: DialogService;
    private currentApplication: CurrentApplicationService;
    private utilService: UtilService;

    private deleteCommand: AppCommand = AppCommand.create(
    ()=> { 
        return this.isAppReady;
    },
    ()=> {
        this.dialogService.showYesNoMessage("Delete Applicaton", ["Are you sure that you want to delete " + this.currentApplication.dataItem.name + "?"])
        .pipe(take(1))
        .subscribe((ans) => {
            if (ans) {
                this.currentApplication.delete()
                this.router.navigate([
                    Constants.ROUTE_PROJECTDETAILS_MAIN, 
                    this.utilService.compressGuidWithSvr(this.currentApplication.dataItem.projectId),
                    Constants.ROUTE_PROJECTAPPLICATIONS ]);        
            }
        });
    });
}


import { StdRule, StdRulePart } from '../../model/StdRule';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { CustomRuleSet } from 'src/app/model/customRuleSet';

import * as CRS from '../../api/CRS';

export class QueryRuleHelper {

    constructor(public currentProject:CurrentProjectService, ruleSetId:number) {
        this._ruleSetId = ruleSetId;
        currentProject.getCustomRuleSet(ruleSetId);
    }

    private _ruleSetId:number;
    
    public get ruleSetId() {
        return this._ruleSetId;
    }

    public get ruleSet() {
        return this.currentProject.getCustomRuleSet(this._ruleSetId);
    }

    public get rules(): Array<StdRule> {
        if (!this.ruleSet)
            return null;
        return this.ruleSet.rules;
    }

    public addRule(): void {
        if (this.ruleSet)
            this.ruleSet.addRule();
    }

    public deleteRule(r: StdRule): void {
        if (this.ruleSet)
            this.ruleSet.deleteRule(r);
    }

    public saveRule(r: StdRule) : void {
        if (this.ruleSet)
            this.ruleSet.saveRule(r);
    }

    public getColumnFiller(r: StdRule) : number {
        return 3 - r.expressionChain.length;
    }

    public expressionChanged(r: StdRulePart): void {
        if (this.ruleSet)
            this.ruleSet.registerChange(r);
    }

    public canDeleteRule() : boolean {
        return this.rules && (this.rules.length> 1 || (this.rules.length == 1 && this.rules[0].id != null));
    }

    public isLastRule(r: StdRule) : boolean {
        let i: number = this.rules.indexOf(r);
        return (i== this.rules.length - 1);
    }

    public ruleChanged(r:StdRule) : void {
        r.dirty=true;
        r.isValid=this.isValid(r);
    }

    public getSubElements(r: StdRulePart) : Array<CRS.RSCustomRuleElement> {
        if (!this.ruleSet)
            return null;
        return this.ruleSet.getSubElements(r);
    }

    private isNew(r: StdRule) : boolean {
        return r.id.length> 1;
    }

    public get isReady() : boolean {
        return this.ruleSet && this.ruleSet.isReady;   
    }

    public isValid(r:StdRule) : boolean {
        if (r.expressionChain.length < 2)
            return false;
        let lst = r.expressionChain.filter(c=>c.element==null );
        if (lst.length > 0 && !(r.expressionChain.length > 2 && lst.length==1 && r.expressionChain.indexOf(lst[0]) == r.expressionChain.length - 1)) {
            return false;
        }
        if (r.name.trim().length == 0)
            return false;
        lst= r.expressionChain.filter(c=>c.element!=null && c.hasContextValue && c.contextValue.trim().length == 0)
        if (lst.length>0)
            return false;

        return true;   
    }

    public executeRule(r: StdRule) {
        // var prjId : string = this.stateHelperService.getProjectId();
        // this.queryService.executeType2Query(prjId, r.serialise(), String(this.stateHelperService.getRuleGroupId())).then((data) => {
        //     this.queryResponse = data;

        //     this.$mdDialog.show( {
        //         controller:CustomQuery.CustomQueryResultsController,
        //         controllerAs:'vm',
        //         parent: angular.element(document.body),
        //         templateUrl: 'app/views/customQueryResults/CustomQueryResults.html',
        //         clickOutsideToClose:true,
        //         bindToController: true,
        //         locals: {
        //             jobId: data.jobId
        //         }
        //     }).then((answer)=> {
        //     });


        // });
    }


}
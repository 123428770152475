<h2 mat-dialog-title>VIEW IMAGE</h2>
  
<div mat-dialog-content class='sw-dialog-padding' style="width:900px;height:560px;overflow-y: hidden;">
    <IMG [src]="data.image" style="object-fit:contain" class="full-width-bs full-height-bs">
</div>

<div mat-dialog-actions fxLayout='row' fxLayoutGap="10px">
    <div fxFlex></div>
    <div fxFlex="none">
        <button mat-raised-button (click)='cancelDialog()'>
            <mat-icon>cancel</mat-icon>
            <span>Close</span>
        </button>
    </div>
</div>

import { Constants } from '../api/Constants';

import * as IRS from '../api/IRS';

export class Navigation {

    constructor(privileges: IRS.IRSUserPrivilege) {
        this.privileges = privileges;
    }

    private privileges: IRS.IRSUserPrivilege;

    private navItems = [
        { id:"APPS", text: "Applications", route: Constants.ROUTE_PROJECTAPPLICATIONS,    icon: "work" },
        { id:"DRIV", text: "Source",       route: Constants.ROUTE_PROJECTDRIVES,          icon: "dns",
            condition:()=>this.privileges?.canViewSource, 
        },
        { id:"RPTS", text: "Reports",      route: Constants.ROUTE_PROJECTREPORTS,         icon: "description",
            condition:()=>this.privileges?.canViewReports,
        },
        { id:"PTCH", text: "Patches",      route: Constants.ROUTE_PROJECTPATCHES,         icon: "archive", 
            condition:()=>this.privileges?.canPatch},
        { id:"ACTS", text: "Actions",      route: Constants.ROUTE_PROJECTACTIONS,         icon: "subscriptions", 
            condition:()=>this.privileges?.canConvert && this.privileges?.canViewActions},
        { id: "XCHK", text: "X-Check",  route:Constants.ROUTE_PROJECTXCHECK, icon: "workspaces",
            condition:()=>this.privileges?.xCheck},
        { id:"QUER", text: "Queries",      route: Constants.ROUTE_PROJECTQUERIES,         icon: "search", 
            condition:()=>this.privileges?.canQuery},
        { id:"SETT", text: "Settings",     route: Constants.ROUTE_PROJECTSETTINGS,        icon: "settings",
            condition:()=>this.privileges?.canViewSettings, 
        }
      ];
 
      public getAll(): Array<any> {
        return this.navItems;
      }

      public getOne(id:string): any {
        return this.navItems.find(x=>x.id == id);
      }

      public enabledCount() : number {
            return this.getAll().filter(x=>{
                return !x.condition || x.condition();
            }).length;
      }
}
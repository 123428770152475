import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { UtilService } from '../../svc/utilService';
import { CurrentConversionJobService } from '../../svc/currentConversionJobService';
import { StateHelperService } from '../../svc/stateHelperService';
import { Router } from '@angular/router';
import { Constants } from '../../api/Constants';

import * as CRS from '../../api/CRS';

@Component({
  selector: 'conversion-task-failed',
  templateUrl: './conversion-task-failed.html',
})
export class ConversionTaskFailed implements OnInit {

    constructor(
        private currentConversionJob: CurrentConversionJobService,
        private router: Router,
        private stateHelperService: StateHelperService,
        private utilService: UtilService
    ) { }

    ngOnInit(): void {
    }

    public get job(): CRS.RSGetActionDetails {
      return this.currentConversionJob.dataItem;
    }
    
    public goToApp($event) : boolean {
      this.router.navigate([
          Constants.ROUTE_PROJECTDETAILS_MAIN, 
          this.utilService.compressGuidWithSvr(this.stateHelperService.details.projectId),
          Constants.ROUTE_PROJECTAPPLICATIONS,
           Constants.ROUTE_PROJECTAPPLICATIONLANDING_MAIN, 
           this.utilService.compressGuid(this.job.task.applicationId),
           Constants.ROUTE_PROJECTAPPLICATIONACTIONSDETAIL_MAIN, 
           this.utilService.compressGuid(this.job.task.id)
      ]);     
      return false;   
  }

    public isApplicationBasedRoute() {
      return !this.utilService.isEmpty(this.stateHelperService.details.applicationId);
    }

}

<div fxLayout="column" fxLayoutGap="15px">

    <div fxFlex="none">
        <div class="sw-os-title sw-pad-small" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
            <div fxFlex="none">
                <span>{{item.operatingSystemId}}</span> &nbsp;
                <span *ngIf="item.checkpoint != 'Base'">({{item.checkpoint}})</span>
            </div>
            <div fxFlex></div>
            <div fxFlex="none">
                <div *ngFor="let rf of item.retainedFiles">
                    <button mat-button (click)="openRetainedLog($event, item.jobId, rf)">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                            <div fxFlex="none" class="sw-pad-top">
                                <mat-icon class="siz-150">library_books</mat-icon>
                            </div>
                            <div fxFlex [ngSwitch]="rf.name">
                                <span>{{rf.description}}</span>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
            <div fxFlex="none">
                <button mat-button (click)="clearJob()">Clear</button>
            </div>
        </div>
    </div>

    <div fxFlex="none" fxLayout="row">   

        <div fxFlex *ngIf="item.status==1" fxLayout="row" fxLayoutGap="5px">
            <div fxFlex="none">
                <mat-icon>hourglass_empty</mat-icon>
            </div>
            <div fxFlex>
                <h4>Capture is currently running for this image</h4>
            </div>
        </div>

        <div fxFlex *ngIf="item.status==2" fxLayout="row">
            <div fxFlex fxLayout="column" fxLayoutGap="20px">
                <div fxFlex="none" fxLayout="row" fxLayoutGap="5px">
                    <div fxFlex="none" class="sw-error">
                        <mat-icon>error_outline</mat-icon>
                    </div>
                    <div fxFlex>
                        <h4>Capture failed for this image</h4>
                    </div>  
                </div>
                <div fxFlex="none" fxLayout="row" fxLayoutGap="5px">
                    <div fxFlex="none">Failure Description</div>
                    <div fxFlex>
                        <div *ngIf="item.errorDescription" class="sw-error">{{item.errorDescription}}</div>
                        <div *ngIf="!item.errorDescription" class="sw-error">{{item.errorMessage}} (Code: {{item.errorCode}})</div>
                    </div>
                </div>
                <div fxFlex="none" fxLayout="row" *ngIf="item.failedActions && item.failedActions.length>0" fxLayoutGap="5px">
                    <div fxFlex="none">Failed Actions</div>
                </div>  
                <div fxFlex="none" fxLayout="row" *ngIf="item.failedActions && item.failedActions.length>0" fxLayoutGap="5px">
                    <div fxflex>
                        <div *ngFor="let fal of item.failedActions" fxLayout="row">
                            <div fxFlex="30" class="sw-error">{{fal.name}}</div>
                            <div fxFlex class="sw-error">{{fal.value}}</div>
                        </div>
                    </div>
                </div>
            </div>  
            <img fxFlex *ngIf="item.hasFailureScreenshot" [src]="getJobFailScreenshotImageUrl(item)" class="sw-task-shtcut-image">          
        </div>
        <div fxFlex="none" *ngIf="item.status==4">
            <xcheck-output-shortcuts *ngIf="isCurrentTab('SHT')" [item]="item"></xcheck-output-shortcuts>
            <xcheck-output-eventlog *ngIf="isCurrentTab('EVL')" [item]="item"></xcheck-output-eventlog>
            <xcheck-output-services *ngIf="isCurrentTab('SVC')" [item]="item"></xcheck-output-services>
            <xcheck-output-com *ngIf="isCurrentTab('COM')" [item]="item"></xcheck-output-com>
            <xcheck-output-dcom *ngIf="isCurrentTab('DCOM')" [item]="item"></xcheck-output-dcom>
            <xcheck-output-disk-usage *ngIf="isCurrentTab('DSK')" [item]="item"></xcheck-output-disk-usage>
            <xcheck-output-printers *ngIf="isCurrentTab('PRT')" [item]="item"></xcheck-output-printers>
            <xcheck-output-firewall *ngIf="isCurrentTab('FIR')" [item]="item"></xcheck-output-firewall>
            <xcheck-output-env *ngIf="isCurrentTab('ENV')" [item]="item"></xcheck-output-env>
            <xcheck-output-compatibility *ngIf="isCurrentTab('COMP')" [item]="item"></xcheck-output-compatibility>
            <xcheck-output-scheduled-tasks *ngIf="isCurrentTab('SCHD')" [item]="item"></xcheck-output-scheduled-tasks>
            <xcheck-output-defender-exclusions *ngIf="isCurrentTab('DFEX')" [item]="item"></xcheck-output-defender-exclusions>
        </div>

    </div>

</div>
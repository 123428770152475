<div class="sw-pad full-width-bs">

    <h1 *ngIf="user" class="sw-upper sw-bold">{{user.item.userEmail}}</h1>

    <table *ngIf="user" class="t0 full-width-bs">
        <tbody>
            <tr>
                <td style="width:200px">Email</td>
                <td>{{user.item.userEmail}}</td>
            </tr>
            <tr>
                <td style="width:200px">Servers</td>
                <td>
                    <div *ngFor="let s of user.servers">
                        {{s}}
                    </div>
                </td>
            </tr>
            <tr>
                <td>Features</td>
                <td>
                    <div fxLayout="row wrap" fxLayoutGap="20">
                        <div fxFlex="45" *ngFor="let f of user.featureTypes">
                            <mat-checkbox [(ngModel)]="f.hasFeature" (change)="changeState(f)">{{f.description}}</mat-checkbox>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>

</div>
<div class='sw-dialog-title'>
    <h2>Summary Information</h2>
</div>

<div mat-dialog-content class="view-summary-info-dialog-content">
    <form>
        <div fxLayout="row" fxLayoutGap="5px">
            <div fxLayout="column" fxFlex="40">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Title</mat-label>
                    <input matInput [(ngModel)]="title" type="text" [disabled]="true" name="summary_title"/>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Author</mat-label>
                    <input matInput [(ngModel)]="author" type="text" [disabled]="true" name="summary_author"/>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Subject</mat-label>
                    <input matInput [(ngModel)]="subject" type="text" [disabled]="true" name="summary_suject"/>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Comments</mat-label>
                    <input matInput [(ngModel)]="comments" type="text" [disabled]="true" name="summary_comments"/>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Creating Application</mat-label>
                    <input matInput [(ngModel)]="creatingApplication" type="text" [disabled]="true" name="summary_creatingapplication"/>
                </mat-form-field>
            </div>
            <div fxLayout="column" fxFlex="40" fxLayoutGap="5px">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Package Code</mat-label>
                    <input matInput [(ngModel)]="revisionNumber" type="text" [disabled]="true" name="summary_packagecode"/>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Keywords</mat-label>
                    <input matInput [(ngModel)]="keywords" type="text" [disabled]="true" name="summary_keywords"/>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Created</mat-label>
                    <input matInput [(ngModel)]="createTime" [disabled]="true" type="text" name="summary_createtime"/>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Saved</mat-label>
                    <input matInput [(ngModel)]="lastSaveTime" [disabled]="true" type="text" name="summary_saved"/>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Security</mat-label>
                    <input matInput [(ngModel)]="security" type="text" [disabled]="true" name="summary_security"/>
                </mat-form-field>
            </div>
            <div fxLayout="column" fxFlex="15" fxLayoutGap="5px">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label >Code Page</mat-label>
                    <input matInput [(ngModel)]="codePage" type="text" [disabled]="true" name="summary_codepage" />
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Schema</mat-label>
                    <input matInput [(ngModel)]="pageCount" type="text" [disabled]="true" name="summary_schema"/>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Platform</mat-label>
                    <input matInput [(ngModel)]="platform" type="text" [disabled]="true" name="summary_platform"/>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Languages</mat-label>
                    <input matInput [(ngModel)]="languages" type="text" [disabled]="true" name="summary_languages"/>
                </mat-form-field>
                <div fxFlex></div>
            </div>
        </div>

        <div fxLayout="row">
            <div fxFlex>
                <mat-checkbox [(ngModel)]="longFilenames" [disabled]="true" name="summary_longfilenames">Long Filenames</mat-checkbox>
            </div>
            <div fxFlex>
                <mat-checkbox [(ngModel)]="compressedFiles" [disabled]="true" name="summary_compressedFiles">Compressed Files</mat-checkbox>
            </div>
            <div fxFlex>
                <mat-checkbox [(ngModel)]="adminInstall" [disabled]="true" name="summary_adminInstall">Admin Install</mat-checkbox>
            </div>
            <div fxFlex>
                <mat-checkbox [(ngModel)]="elevatedPrivilegeNotRequired" [disabled]="true" name="summary_elevated">Elevated Privileges Not Req'd</mat-checkbox>
            </div>
        </div>

    </form>
</div>

<div mat-dialog-actions fxLayout='row'>
    <div fxFlex></div>
    <button mat-button (click)='cancelDialog()'>
        <mat-icon>cancel</mat-icon>
        <span>Close</span>
    </button>
</div>



<div fxLayout="row" fxFill class='full-height-bs'>

    <div [fxFlex]="stateHelperInstance.isNotCurrent() ? '0 0 200px' : ''" class="sw-segment">

        <div *ngIf="stateHelperInstance.isNotCurrent()" >
            <table class="sw-table-collapsable">
                <tr *ngFor="let pg of patchGroups?.items" [ngClass]='{"sw-table-activerow": classReference.selectedPatchgroup == pg?.id}' (click)="openPatchGroup(pg)">
                    <td>{{pg?.title}}</td>
                </tr>
            </table>
        </div>

        <div fxFlex fxLayoutGap="10px" fxLayout="row wrap" *ngIf="stateHelperInstance.isCurrent()" class="admin-flex-wrap admin-padding">

            <div fxFlex="30" *ngFor="let pg of patchGroups?.items">
                <div fxLayout="row" layout-align="space-between start" class="admin-patch-group-heading">
                    <div fxFlex>
                        <div class="div-hyperlink" (click)="openPatchGroup(pg)" *ngIf="!pg.isPlaceholder">
                            <div>{{pg?.title}}</div>
                        </div>
                        <div *ngIf="pg.isPlaceholder">
                            <div>{{pg?.title}}</div>
                        </div>
                    </div>
                    <div fxFlex *ngIf="!pg.isPlaceholder">
                        <a href="" (click)="openExportCsv($event, pg)" class="div-hyperlink">Export</a>    
                    </div>
                    <div fxFlex *ngIf="pg.isPlaceholder">
                        <a href="" (click)="generateBulletin($event, pg)" class="div-hyperlink">
                            Generate Bulletin
                        </a>
                    </div>
                    <div fxFlex *ngIf="!pg.isPlaceholder">
                        <a href="" (click)="setComplete($event, pg)" *ngIf="pg.canComplete" class="div-hyperlink">
                            Generate Checks
                        </a>
                    </div>
                    <div fxFlex="none" *ngIf="!pg.isPlaceholder">
                        <a href="" (click)="openExportJson($event, pg)" class="div-hyperlink">Json</a>
                    </div>
                </div>
                <div fxLayout="row" layout-wrap layout-padding class="admin-flex-wrap admin-padding">
                    <div *ngFor=" let ccg of pg?.checkCandidateGroups" fxFlex="20" class="admin-padding">
                        <div class="sw-ccg" [ngClass]="getClass(ccg)" title="{{ccg}}">
                            <div fxLayout="column">
                                <div fxFlex>{{getCCGStateName(ccg?.state)}}</div>
                                <div fxFlex>{{ccg?.remediationId}}</div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>  
        </div>  
    </div>

    <div fxLayout='column' fxFlex *ngIf='stateHelperInstance.isNotCurrent()'>
        <div class="full-height-bs">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
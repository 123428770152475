import { NavigationStart, NavigationEnd, ActivatedRoute, Router } from "@angular/router";
import { Observable, timer } from "rxjs";

export class PollingMethod {
    
    public running: boolean;
    private subscription:any;
    private routerSubscription:any;
    private currentDelay: number=0;

    private executingCallback: boolean;
    private queuedExecution: boolean;

    constructor(
        public delay: number,
        public router: Router,
        public callback: (resolve: Function, reject: Function) => any,
    ) {
        this.currentDelay = delay;
    }

    public start() {

        if (this.running) 
            return;
        if (!this.callback) 
             throw new Error('A callback is required in order to start a polling method')

        this.running = true;
        this.setTimer();
    }

    public refresh() {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.setTimer();
        }
    }

    public stop() {
        if (this.subscription) {
            this.subscription.unsubscribe();
            delete this.subscription;
            if (this.routerSubscription) {
                this.routerSubscription.unsubscribe();
                delete this.routerSubscription;
            }
        }
        this.running=false;
    }

    private setTimer() {
        this.subscription = timer(0, this.currentDelay).subscribe(()=> {
            this.execute();
        });
    }

    private execute() {
        this.executingCallback = true;
        var p = new Promise((resolve,reject)=> {
            this.callback(resolve,reject);
        }).finally(()=> {
            this.executingCallback=false;
        });
    }
}

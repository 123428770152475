<div fxLayout="column" fxLayoutGap="5px">
    <div fxFlex="none" *ngIf="clickerCommand.progressStatusText" fxLayout="row" fxLayoutGap="20px">
        <mat-progress-bar fxFlex mode="indeterminate"></mat-progress-bar>
        <span fxFlex="none" class="sw-bold">{{clickerCommand.progressStatusText}}</span>
        <mat-progress-bar fxFlex mode="indeterminate"></mat-progress-bar>
    </div>
    <div fxFlex fxLayout="row" fxLayoutGap="10px">
        <mat-form-field fxFlex appearance="outline" class="full-width-bs full-height-bs" *ngIf="clickerCommand.viewIndex <= 1">
            <textarea [(ngModel)]="clickerCommand.script" matInput *ngIf="clickerCommand.viewIndex==0"
                rows="10" type="text" 
                style="min-width: 400px;" 
                class="sw-input-edit full-height-bs">
            </textarea>
            <textarea [ngModel]="clickerCommand.response" matInput  *ngIf="clickerCommand.viewIndex==1"
                rows="10" type="text" 
                class="sw-input-edit full-height-bs">
            </textarea>
        </mat-form-field>
        <div fxflex class="full-width-bs full-height-bs" *ngIf="clickerCommand.viewIndex> 1" fxLayout="row" fxLayoutGap="20px">
            <div *ngFor="let i of clickerCommand.imageIndices" fxFlex="none">
                <IMG fxFlex="none" [src]="clickerCommand.getImageUrl(i)" 
                    (click)="clickerCommand.showExpandedImage(i)"
                    width="250px" height="250px">
            </div>
        </div>
        <div fxFlex="none" fxLayout="column" fxLayoutGap="10px">
            <div fxFlex="none">
                <button mat-raised-button color="primary" title="Execute Script" (click)="clickerCommand.cmd.execute()" [disabled]="!clickerCommand.cmd.can">
                    <mat-icon>play_arrow</mat-icon>
                </button>
            </div>
            <div fxFlex="none">
                <button mat-raised-button title="Save Script" (click)="clickerCommand.saveScript()">
                    <mat-icon>save</mat-icon>
                </button>
            </div>
            <div fxFlex="none">
                <button mat-raised-button title="View Script" (click)="clickerCommand.setViewIndex(0)" *ngIf="clickerCommand.hasResponse">
                    <mat-icon>description</mat-icon>
                </button>
            </div>
            <div fxFlex="none">
                <button mat-raised-button title="View output" (click)="clickerCommand.setViewIndex(1)" *ngIf="clickerCommand.hasResponse">
                    <mat-icon>developer_board</mat-icon>
                </button>
            </div>
            <div fxFlex="none" *ngIf="clickerCommand.imageIndices.length > 0">
                <button mat-raised-button title="Images" (click)="clickerCommand.setViewIndex(2)">
                    {{clickerCommand.imageIndices.length}}
                </button>
            </div>
        </div>
    </div>

</div>
<h2 mat-dialog-title>UPDATE WORKFLOW STATUS</h2>
<h4 class="full-width-bs">{{data.application.name}}</h4>
  
<div mat-dialog-content class='sw-dialog-padding full-width-bs' style="height:400px">

    <div fxLayout="column" fxLayoutGap="5px">
        <div fxFlex>
            <mat-form-field appearance="outline" class="full-width-bs">
                <mat-label>Workflow Stage</mat-label>
                <mat-select [(ngModel)]="data.stage" (selectionChange)="appDetailsChanged('WF')">
                    <mat-optgroup label="Stage">
                        <mat-option [value]="i" *ngFor="let wfs of data.workflowStages;let i = index">{{wfs}}</mat-option> 
                    </mat-optgroup>
                </mat-select>
            </mat-form-field> 
        </div>
    
        <div fxFlex>
            <mat-form-field appearance="outline" class="full-width-bs">
                <mat-label>Workflow Stage Status</mat-label>
                <mat-select [(ngModel)]="data.state" (selectionChange)="appDetailsChanged('WFS')">
                    <mat-optgroup label="Stage Status">
                        <mat-option [value]="i" *ngFor="let wfs of data.workflowStatuses;let i = index">{{wfs}}</mat-option> 
                    </mat-optgroup>
                </mat-select>
            </mat-form-field> 
        </div>

        <div fxFlex>
            <mat-form-field appearance="outline" class="full-width-bs">
                <mat-label>Assigned To</mat-label>
                <mat-select [(ngModel)]="data.assignee" (selectionChange)="appDetailsChanged('ASS')">
                    <mat-optgroup label="Assignee">
                        <mat-option [value]="wfs.userId" *ngFor="let wfs of data.assignees;let i = index">{{wfs.name}}</mat-option> 
                    </mat-optgroup>
                </mat-select>
            </mat-form-field> 
        </div>

        <div fxFlex>
            <div *ngIf="appDocCmd.isDataReady && appDocCmd.hasSome" fxLayout="column" fxLayoutGap="10px"> 
                <div fxFlex *ngFor="let iif of appDocCmd.documentationFiles.items">
                    <span><a href="{{appDocCmd.getDocUrl(iif)}}" target="_blank">{{iif.filename}}</a></span>
                </div>
            </div>
            <div *ngIf="appDocCmd.isDataReady && !appDocCmd.hasSome">No documentation was found for this application.</div>
        </div>
       
    </div>

</div>

<div mat-dialog-actions fxLayout='row' fxLayoutGap="10px">
    <div fxFlex></div>
    <div fxFlex="none">
        <button mat-raised-button color="primary" (click)='closeDialog()' [disabled]="!isValid()">
            <mat-icon>done</mat-icon>
            <span>Accept</span>
        </button>
    </div>
    <div fxFlex="none">
        <button mat-raised-button (click)='cancelDialog()'>
            <mat-icon>cancel</mat-icon>
            <span>Cancel</span>
        </button>
    </div>
</div>

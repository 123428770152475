import { Component, Input, Output, EventEmitter, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { CurrentConversionJobService } from '../../svc/currentConversionJobService';
import { StateHelperService } from '../../svc/stateHelperService';
import { TCItem } from '../../model/TCItem';

import * as CRS from '../../api/CRS';
import * as Enums from '../../api/Enum';

@Component({
  selector: 'conversion-task-outcome-summary',
  templateUrl: './conversion-task-outcome-summary.html',
  styleUrls: ['./conversion-task-outcome-summary.scss'],
})
export class ConversionTaskOutcomeSummary implements OnInit, OnDestroy {

    constructor(
        private stateHelperService: StateHelperService,
        private currentConversionJob : CurrentConversionJobService
    ) { }

    private subscriptions: Array<Subscription> =new Array<Subscription>();

    public items: Array<TCItem>=null;

    ngOnInit(): void {
        this.subscriptions.push(this.currentConversionJob.dataContext.onLoaded().subscribe(x=>this.update()));
        this.subscriptions.push(this.currentConversionJob.dataContext.onRefreshed().subscribe(x=>this.update()));
        this.update();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(x=>x.unsubscribe());
    }

    private update() : void
    {
        if (!this.currentConversionJob.hasTask())
            return;
            
        let ct: CRS.RSGetConversionTask = this.currentConversionJob.dataItem.task;

        var ct0 = (ct.completionStatContainsReparsePoints || ct.completionStatContainsQwords) ? 1 : 0;
        var ct1 = (ct.completionStatAllUserBasedInstall) ? 1 : 0;
        var ct2 = (ct.completionStatIceErrors) ? 1 : 0;
        var ct3 = (ct.completionStatInstallVerified) ? 0 : 2;
        var ct4 = -1;
        var ct5 = -1;

        this.items = [];
        this.items.push(new TCItem(ct0, "QWord,Junctions & Hard Links", "The install EXE contained elements that are not supported in an MSI. You should test the packaged application to see how important they are to its operation and add custom actions where necessary"));
        this.items.push(new TCItem(ct1, "User Install", "This package cannot be assigned to a machine and must be deployed to each user needing to use it. In many cases there is an alternative enterprise version of the software availabile for download from the vendor that can be assigned on a per-machine basis"));
        this.items.push(new TCItem(ct2, "ICE Errors", "We have endeavoured to convert this package to be of the highest quality. The errors remaining in this package are most likely caused by it having contents that do not fit easily into the usual pattern and it will require some additional testing before distribution"));
        this.items.push(new TCItem(ct3, "Install Verifier", "We have run this package in virtualised test environment and it has failed. Please report it to Application Readiness support"));
        this.items.push(new TCItem(ct4, "Interactive Test", "This MSI has been tested interactively via the test tab and been marked as failed"));
        this.items.push(new TCItem(ct5, "Platform Checks", "See the issues page for more details"));
    }

}

import * as CRS from '../api/CRS';
import { PlatformDefn } from '../model/PlatformDefn';

export class IssueListHelper {

    constructor(issues:  Array<CRS.RSIssue>, assessmentGroups : Array<CRS.RSAssessmentGroup>)
    {
        this.issues=issues;
        this.assessmentGroups = assessmentGroups;
    }

    public issues: Array<CRS.RSIssue>;
    public assessmentGroups: Array<CRS.RSAssessmentGroup>;
    public issuesForCurrentPlatform: Array<CRS.RSIssue>=[];
    public fixedIssues: Array<string>=[]; //sids

    public clearSelected() {
        this.issuesForCurrentPlatform.filter(x=>x.isSelected).forEach(x=>x.isSelected=false);
    }

    public ReCalcCustom(platform: number, showFixed: boolean) {

        var incomingIssues = this.issues;

        var checkAg = this.buildAssessmentGroupDictionary(this.assessmentGroups, platform);

        let existing: { [sid: string] : CRS.RSIssue; } = {};
        this.issuesForCurrentPlatform.forEach((i)=> {
            existing[i.id]=i;
        });
        let updated: { [sid: string] : CRS.RSIssue; } = {};
        incomingIssues.forEach((i)=> {
            updated[i.id] =i;
        });

        incomingIssues.forEach((i)=> {
            let asp : CRS.RSRAG = i.assessmentStatesDictionary[platform];
            if (asp !== undefined &&(showFixed|| asp.assessmentState != 0)) {
                i.custom = "0x" + asp.assessmentState.toString(16);
                i.custom2 = (i.canAutoFix?"a":"b") + asp.fixState.toString();
                i.custom3 = checkAg[i.checkId];
                let iss: CRS.RSIssue = existing[i.id];
                if (!iss) {
                    this.issuesForCurrentPlatform.push(i);
                }
            }
            else {
                let iss: CRS.RSIssue = existing[i.id];
                if (iss) {
                    let oldissidx = this.issuesForCurrentPlatform.indexOf(iss);
                    this.issuesForCurrentPlatform.splice(oldissidx, 1);
                }
            }

            let fidx = this.fixedIssues.indexOf(i.id);
            if (asp && asp.assessmentState == 0) { //fixed
                if (fidx==-1)
                    this.fixedIssues.push(i.id);
            }
            else if (fidx != -1) {
                this.fixedIssues.splice(fidx, 1);
            }

        });

        Object.keys(existing).forEach((i)=> {
            if (!updated[i])
            {
                let oldissidx = this.issuesForCurrentPlatform.indexOf(existing[i]);
                this.issuesForCurrentPlatform.splice(oldissidx, 1);
            }
        });
    }

    private buildAssessmentGroupDictionary(assessmentGroups : Array<CRS.RSAssessmentGroup>, platform: number) : { [key: string]: string } {
        let checkAg: { [key: string]: string } = {};
        if (assessmentGroups) {
            assessmentGroups.forEach((ag) => {
                if (ag.assessmentStatesDictionary[platform]) {
                    ag.checks.forEach((c)=> {
                        let tmp = checkAg[c.checkId];
                        if (tmp) {
                            checkAg[c.checkId] = tmp +", " + ag.name;
                        }
                        else {
                            checkAg[c.checkId] = ag.name;
                        }
                    });
                }
            });
        }
        return checkAg;
    }

    public static groupingOptions: Array<any> = [              
        {id: "R" ,name:"RAG"},
        {id: "AF", name:"Auto Fix"},
        {id: "AG", name:"Assessment Group"},
        {id: "CHK",name:"Check"},
        {id: "NONE", name: "None"}
    ];

    public static groupingOptionsMultiApp: Array<any> = [              
        {id: "R" ,name:"RAG"},
        {id: "AF", name:"Auto Fix"},
        {id: "APP", name:"Application"},
        {id: "NONE", name: "None"}
    ];

}

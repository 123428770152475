<div fxLayout="column" class="full-width-bs full-height-bs sw-pad" fxLayoutAlign="center center" >

    <spinner fxFlex *ngIf='!isDataReady()'></spinner>

    <div fxFlex *ngIf="isDataReady()"class="full-width">

        <table class="full-width-bs" *ngIf="application && application.installDependencies.length>0">
            <thead style="text-align: left; vertical-align: top;">
                <th>Prerequisite Type</th>
                <th>Description</th>
                <th></th>
                <th></th>
                <th></th>
            </thead>   
            <tbody>
                <tr *ngFor="let dep of application.installDependencies | orderBy:'index';let $index=index">
                    <td>
                        <span>{{dep.relationshipTypeName}}</span>
                        <span *ngIf="dep.relationshipSubTypeName">&nbsp;({{dep.relationshipSubTypeName}}</span>
                    </td>
                    <td>
                        <span *ngIf="dep.dependentApplicationDisplayName">{{dep.dependentApplicationDisplayName}}</span>
                        <span *ngIf="dep.locatorExpression">{{dep.locatorExpressionDisplayName}}</span>
                    </td>
                    <td style="width:40px">
                        <button (click)="moveUp(dep)" *ngIf="$index>0">
                            <mat-icon class="material-icons">arrow_drop_up</mat-icon>
                        </button>
                    </td>
                    <td style="width:40px">
                        <button (click)="moveDown(dep)" *ngIf="application.installDependencies.length>1 && $index < application.installDependencies.length-1">
                            <mat-icon>arrow_drop_down</mat-icon>
                        </button>
                    </td>
                    <td style="width:40px">
                        <button (click)="delete(dep)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>
                        <button mat-raised-button color="primary" (click)="add(null)">
                            <i class="material-icons">add</i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>

        <div *ngIf="application && application.installDependencies.length == 0">
            <div>There are no prerequisites associated with this application at the moment</div>
            <br>
            <div>Click&nbsp;<a href="" (click)="add($event)">here</a>&nbsp;to add.</div>
        </div>

    </div>
    
</div>

import { Component, OnInit } from '@angular/core';
import { AppUploadService } from '../../svc/appUploadService';

@Component({
  selector: 'file-upload-queue',
  templateUrl: './file-upload-queue.html',
  styleUrls: ['./file-upload-queue.scss']
})
export class FileUploadQueue {

    constructor(
        public appUploadService: AppUploadService, 
    ) {}
  
    public get message(): string {
        var ret = "";
        var cnt = this.inProgressOrSucceededCount;
        if (cnt>0) {
            var itms = (cnt==1) ? "item" : "items";
            ret = `Uploading ${cnt} ${itms}`;
        }
        var fails = this.failCount;
        if (fails>0) {
            var fm = (fails==1) ? "An error has occurred during upload":`${fails} errors have occurred during upload`;
            ret += ((ret=="")?"":". ") +fm;
        }
        return ret;
    }

    public get progress(): number {
        var total= 0;
        this.appUploadService.uploader.queue.forEach((x)=> {
            total += x.progress;
        });
        var len = this.appUploadService.uploader.queue.length;
        return (len<=0) ? 0: Math.ceil(total/len);
    }

    public get inProgressOrSucceededCount(): number {
        return this.appUploadService.uploader.queue.filter(x=>!x.isError && (x.isUploading || x.isUploaded || x.isSuccess)).length;
    }
    public get anyInProgress(): boolean {
        return this.appUploadService.uploader.queue.filter(x=>!x.isError && x.isUploading).length > 0;
    }

    public get anyFailed() : boolean {
        return this.appUploadService.uploader.queue.filter(x=>x.isError).length >0;
    }

    public get failCount() : number {
        return this.appUploadService.uploader.queue.filter(x=>x.isError).length;
    }
}
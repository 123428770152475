<div fxLayout="row" *ngIf="isDataReady()" >

    <div fxFlex fxLayout="row" fxLayoutGap="15px">
        <div fxFlex="none" *ngIf="application?.icon">
            <img class="sw-app-icon" [src]="application?.icon">
        </div>
        <div fxFlex="none" class="sw-main-element">
            <span>{{application?.name}}</span>
        </div>  
        <div fxFlex="none" *ngIf="application?.path" class="sw-sub-element">
            <span>{{application?.path}}</span>
        </div>
        <div fxFlex></div>
        <div fxFlex="none" fxLayout="row" fxLayoutGap="10px">
            <div *ngIf="application.hasDotNetElements" fxFlex="none" class="sw-software-framework">
                <img src="/img/application/DotNet.png">
            </div>
            <div *ngIf="application.hasJavaElements" fxFlex="none" class="sw-software-framework">
                <img src="/img/application/Java.png">
            </div>
            <div fxFlex></div>
        </div>
    </div>

</div>


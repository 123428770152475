import * as CRS from '../api/CRS';
import { DISOUserFeature } from './DISOUserFeature';

export class DISOUser {
    constructor(user: CRS.RSAdminUserItem, featureTypes: Array<CRS.RSFeatureType>) {
        this.item = user;
        this.featureTypes = featureTypes.map((f)=>{
            var hasFeature = this.features.indexOf(f.id) != -1;
            return new DISOUserFeature(f.id,f.description, hasFeature);
        });
    }

    public update(app: CRS.RSAdminUserItem,) {
        this.item =app;
    }

    public item: CRS.RSAdminUserItem;
    public featureTypes: Array<DISOUserFeature>;

    public get servers() : Array<string> {
        if (this.item && this.item.servers)
            return this.item.servers.split(';');
        return [];
    }

    public get features(): Array<string> {
        if (this.item && this.item.featureSet) 
            return this.item.featureSet.split(';');
        return [];
    }
}

import { Injector } from '@angular/core';
import { CurrentAssessmentGroupService } from '../svc/currentAssessmentGroupService';
import { MenuService } from '../svc/menuService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';
import { EventService } from '../svc/eventService';

export class ToggleShowFixedIssuesCommand {

    constructor(
        private injector: Injector
    ) {
        this.menuService = this.injector.get(MenuService);
        this.currentAssessmentGroup = this.injector.get(CurrentAssessmentGroupService);
        this.eventService =this.injector.get(EventService);

        this.menuService.register(Constants.MENU_TOGGLE_SHOW_FIXED_ISSUES, this.cmd, Constants.ROUTE_PROJECTREPORTCHECKDETAIL_MAIN )
    }

    private eventService: EventService;
    private currentAssessmentGroup: CurrentAssessmentGroupService;
    private menuService: MenuService;

    public cmd: AppCommand = AppCommand.create(
        ()=> { 
            return this.currentAssessmentGroup.dataContext.isReady; 
        },
        ()=> {
            var curr = localStorage.getItem(Constants.LOCALSET_FIXED_ISSUES_VISIBLE);
            var upd = (curr == "true") ? "false" : "true";
            var updb = (upd == "true");
            localStorage.setItem(Constants.LOCALSET_FIXED_ISSUES_VISIBLE, upd);
            this.eventService.__fixedIssuesVisibilityChangedTrigger(updb)
        }
    )
}
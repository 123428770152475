import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApplicationDocumentationCommand } from 'src/app/commands/application-documentation';
import { CommandCollection } from 'src/app/commands/command-collection';

@Component({
    selector: 'set-workflow-state-dialog',
    templateUrl: 'set-workflow-state-dialog.html'
})
export class SetWorkflowStateDialog implements OnInit {

    constructor(
        private dialogRef: MatDialogRef<boolean>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        
    }

    ngOnInit() {
        CommandCollection.ApplicationDocumentation.load(this.data.application.id);
    }

    isDataReady() : boolean {
        return true;
    }

    isValid(): boolean {
        return true;
    }

    closeDialog() {
        this.dialogRef.close(this.data);
    }

    cancelDialog() {
        this.dialogRef.close();
    }

    public appDetailsChanged(id:string) {
        if (id == "ASS") {
            var usm = this.data.assignees.filter(x=>x.userId == this.data.assignee);
            if (usm.length>0)
                this.data.assigneeName = usm[0].name
        }       
    }

    public get appDocCmd(): ApplicationDocumentationCommand {
        return CommandCollection.ApplicationDocumentation;
    }
}


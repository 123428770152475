import { Component, OnInit, Input } from '@angular/core';
import { QueryRuleHelper } from './queryRuleHelper';

import * as CRS from '../../api/CRS';

@Component({
  selector: 'project-query-ruleset-table',
  templateUrl: './project-query-ruleset-table.html',
  styleUrls: ['../../shared/css/nav-bar.scss']
})
export class ProjectQueryRulesetTable implements OnInit {

  constructor() { }

  @Input('qrh') public qrh: QueryRuleHelper

  ngOnInit(): void {
  }


}

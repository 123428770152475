import { Directive, Input, ElementRef, OnInit } from '@angular/core';

@Directive({
    selector: '[backgroundImage]'
  })
  export class BackgroundImageDirective implements OnInit{
    
    @Input() backgroundImage: string;
    @Input() backgroundSize: string;
    @Input() backgroundRepeat: string;
    
    constructor( private element: ElementRef) { }
   
    ngOnInit(){
        var siz = (this.backgroundSize) ? this.backgroundSize: 'cover';
        this.element.nativeElement.style.backgroundImage = 'url(' + this.backgroundImage + ')';
        this.element.nativeElement.style.backgroundSize= siz;
        if (this.backgroundRepeat == "false")
          this.element.nativeElement.style.backgroundRepeat="no-repeat";
    }
  }
import { Component, EventEmitter, OnInit, Input, Output, SimpleChanges } from '@angular/core';
import { UtilService } from '../../svc/utilService';
import { QAVALUEARGS } from '../../model/QAVALUEARGS';

import * as CRS from '../../api/CRS';

@Component({
  selector: 'qa-value',
  templateUrl: './qa-value.html',
  styleUrls: ['./qa-value.scss']
})
export class QaValue {

  constructor(
      private utilService: UtilService) { }

  public value:string;

  @Input('column') column : CRS.RSQaChecksheetColumn;
  @Input('nextColumn') nextColumn : CRS.RSQaChecksheetColumn;

  @Output('on-change') onChange= new EventEmitter<QAVALUEARGS>();

  ngOnChanges(changes: SimpleChanges) {
    var c: CRS.RSQaChecksheetColumn = changes.column.currentValue;
    if (c)
      this.value = c.value;
  }

  public valueChanged() {
    if (this.onChange)
      this.onChange.emit(new QAVALUEARGS(this.column, this.value));
  }

  public nextColumnIsMergeAbove() {
    if (this.nextColumn && this.nextColumn.flags) {
      return this.hasFlag(this.nextColumn.flags, "MergeAbove");
    }
    return false;
  }
  
  public getNextColumnListColumns() : Array<string> {
    if (!this.nextColumn || !this.nextColumn.listColumns)
      return null;
    return this.nextColumn.listColumns.split('|');
  }

  public getNextColumnListRows() : Array<any> {
    return this.nextColumn.valuesAsTable;
  }

  public isColumnHeadingsHidden(): boolean {
    return this.hasFlag(this.nextColumn.flags, "HideHeadings");
  }

  public isBorderHidden(): boolean {
    return this.hasFlag(this.nextColumn.flags, "NoBorder");
  }

  private hasFlag(flags:string, id:string) : boolean {
    return flags && flags.split('|').indexOf(id) != -1;
  }
}



<div *ngIf="!hasItems()">
    No events were created by this installation
</div>

<table style="padding-left:10px" *ngIf="hasItems()">
    <tr *ngFor="let eli of item.eventLogItems">
        <td style="min-width: 150px;" class="sw-column-top">{{eli.level}}</td>
        <td class="sw-column-top">{{eli.message}}</td>
    </tr>
</table>
     
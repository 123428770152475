import { Component } from '@angular/core';
import { CurrentConversionJobService } from '../../svc/currentConversionJobService';

import * as CRS from '../../api/CRS';

@Component({
  selector: 'conversion-task-output',
  templateUrl: './conversion-task-output.html'
})
export class ConversionTaskOutput {

    constructor(
        private currentConversionJob: CurrentConversionJobService,
    ) { }

    public get task() : CRS.RSGetConversionTask {
        if (!this.currentConversionJob.dataContext.isReady)
            return null;
        if (!this.currentConversionJob.hasTask())
            return null;
        return this.currentConversionJob.dataItem.task;
    }
  
    public openJobOutput($event, url:string) {
        var fileLink = document.createElement('a');
        fileLink.href = url;
        fileLink.click();     

        $event.stopPropagation();
        $event.preventDefault();
   }

}

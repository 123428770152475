<div fxLayout='column' fxFill class="sw-pad"
        [ngClass]='{"sw-viewcontainer-collapsed": !stateHelperInstance.isNotCurrent()}' style="border:solid 2px pink">

    <spinner fxFlex *ngIf='!assessmentGroup'></spinner>

    <div fxFlex="none" *ngIf="assessmentGroup && stateHelperInstance.isNotCurrent()" fxLayout="row">
        <div fxFlex>
            <input-edit datatype="STRING" label="ID" [value]="assessmentGroup?.id" (on-change)="updateGroupId($event)"></input-edit>
        </div>
        <div fxFlex>
            <input-edit datatype="STRING" label="Name" [value]="assessmentGroup?.name" (on-change)="updateGroupName($event)"></input-edit>
        </div>
    </div>

    <div fxFlex fxLayout="row" fxLayoutGap="10px">

        <div fxFlex="none" *ngIf="assessmentGroup" class="sw-segment" style="width:350px;">
            <div class="full-height-bs full-width-bs">
                <table class="sw-table-collapsable">
                    <tbody>
                        <tr *ngFor="let check of assessmentGroup?.checks" 
                            [ngClass]='{"sw-table-activerow": selectedGroupCheckId === check.id }'
                            (click)="openCheck(check.id)">
                            <td>{{check.id}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>  

        <div fxFlex *ngIf="stateHelperInstance.isNotCurrent()">
            <router-outlet></router-outlet>
        </div>

    </div>

</div>


import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { UtilService } from '../../svc/utilService';
import { StateHelperService } from '../../svc/stateHelperService';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { CheckInfoService } from '../../svc/checkInfoService';
import { CurrentAssessmentGroupService } from '../../svc/currentAssessmentGroupService';
import { PlatformService } from '../../svc/platformService';
import { EventService } from '../../svc/eventService';
import { Constants } from '../../api/Constants';
import { IssueListHelper } from '../../shared-helper/issue-list-helper';
import { CHKINFO } from '../../model/CHKINFO';

import * as CRS from '../../api/CRS';

@Component({
  selector: Constants.ROUTE_PROJECTREPORTCHECKDETAIL_MAIN,
  templateUrl: './project-reports-checks-detail.html'
})
export class ProjectReportsChecksDetail implements OnInit, OnDestroy {

    constructor(
      private injector: Injector,
      private currentAssessmentGroup: CurrentAssessmentGroupService, 
      private checkInfoService: CheckInfoService,
      private utilService:UtilService,
      private eventService:EventService,
      public currentProject: CurrentProjectService,
      public platformService: PlatformService,
      public stateHelperService: StateHelperService) { }

    public data: CRS.RSGetIssuesForCheck;
    private hoverIssueId: string = null;
    private subscriptions: Array<Subscription> =new Array<Subscription>();

    ngOnInit(): void {
        this.updateData();
        this.subscriptions.push(this.stateHelperService.onStateChange().subscribe((data)=> {
            this.updateData();
        }));
        // this.subscriptions.push(this.eventService.onFixedIssuesVisibilityChanged().subscribe((data)=>{
        //     this.reCalcCustom();
        // }));
        this.subscriptions.push(this.eventService.onCheckEnabledStateChanged().subscribe((data)=> {
            this.updateData();
        }));
    }

    ngOnDestroy() : void {
        this.subscriptions.forEach(x=>x.unsubscribe());
    }

    public isReady() : boolean {
        return false;
    }

    private async updateData()  {

        var checkId = this.stateHelperService.details.checkId;
        if (!checkId)
            return;

        this.currentAssessmentGroup.getIssuesForCheck(checkId).pipe(take(1)).subscribe((data)=> {
            this.data = data;
        });
    }

    private isApplicableToOs(issue:CRS.RSIssue) : boolean {
        let platformNum = this.platformService.getCurrent(this.stateHelperService.details.projectId);
        let platform = issue.assessmentStatesDictionary[platformNum];
        return platform != null && platform !== undefined;
    }

    private showMoreInfo(issue: CRS.RSIssue, state: boolean) {
        this.utilService.toggleItem(Constants.LOCALSET_APPLICATION_ISSUES_EXPANDED, issue.id, state);
    }

    private getIssueText(issue: CRS.RSIssue) : CHKINFO {
        let ci : CHKINFO = this.checkInfoService.get(issue.checkId, issue.ruleId);
        return ci; 
    }

    private isMoreInfoShown(issue: CRS.RSIssue) {
        return this.utilService.getToggleState(Constants.LOCALSET_APPLICATION_ISSUES_EXPANDED, issue.id);
    }

    private setHover(issue: CRS.RSIssue): void {
        this.hoverIssueId = (issue==null) ? null : issue.id;
    }

}

import { Component, Input, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { UtilService } from '../../svc/utilService';
import { Constants } from '../../api/Constants';
import { StateHelperService } from '../../svc/stateHelperService';
import { XCheckOutputStateInfo} from '../../model/XCheckOutputStateInfo';
import { CurrentConversionJobService } from '../../svc/currentConversionJobService';

import * as CRS from '../../api/CRS';

@Component({
  selector: 'xcheck-main',
  templateUrl: './xcheck-main.html',
  styleUrls: ['../xcheck.scss']
})
export class XCheckMain implements OnInit {

    constructor(
        private router:Router, 
        private route:ActivatedRoute, 
        private stateHelperService: StateHelperService,
        private currentConversionJob: CurrentConversionJobService,
        private utilService:UtilService) { }

    public currentState: XCheckOutputStateInfo;

    public get results() : Array<CRS.RSXCheckResult> {
        if (!this.currentConversionJob.dataContext.isReady || !this.currentConversionJob.isXCheckJob())
            return null;
        return this.currentConversionJob.dataItem.xCheckResults
    }

    ngOnInit(): void {
        this.currentState = new XCheckOutputStateInfo( this.currentConversionJob.getXCheckJobSubType());
    }


}

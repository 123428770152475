<spinner fxFlex *ngIf="!isDataReady()"></spinner>

<mat-accordion *ngIf="isDataReady()">
    <mat-expansion-panel *ngFor="let s of checksheet.sections">
      <mat-expansion-panel-header>
        <mat-panel-title>{{s.title}}</mat-panel-title>
      </mat-expansion-panel-header>
        <table>
          <thead *ngIf="!s.columnHeadingsHidden">
            <tr>
              <td *ngFor="let c of s.columnHeadings; let idx = index" [style.width]="getColumnWidth(s,idx)">
                <span *ngIf="c != '-'">{{c}}</span>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let r of s.rows">
              <td *ngFor="let c of getColumns(s,r)" [attr.colspan]="(isNextColumnMergeAbove(s,c)) ? 1 : 1" [style.display]="isColumnMergeAbove(c)?'none':null">
                <qa-value [column]="c" [nextColumn]="getNextColumn(s,c)" (on-change)="handleColumnValueChange($event)"></qa-value>
              </td>
            </tr>
          </tbody>
        </table>
    </mat-expansion-panel>
  </mat-accordion>
  

<div mat-dialog-title>
    <h4>CREATE VIRTUAL MACHINE</h4>
</div>

<div mat-dialog-content>

    <div class="sw-pad full-width-bs">
        <mat-form-field appearance="outline" class="full-width-bs">
            <mat-label>Base Image</mat-label>
            <mat-select [(ngModel)]="selectedBaseImage" aria-label="Base Image" aria-placeholder="Base Image" style="margin: 2px">
                <mat-option [value]="x" *ngFor="let x of getBaseImages()">{{x.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

</div>

<div mat-dialog-actions fxLayout='row' fxLayoutGap="10px">
    <div fxFlex></div>
    <button mat-button (click)='closeDialog()' [disabled]="!isValid()">
        <mat-icon>done</mat-icon>
        <span>Accept</span>
    </button> 
    <button mat-button (click)='cancelDialog()'>
        <mat-icon>cancel</mat-icon>
        <span>Cancel</span>
    </button>
</div>


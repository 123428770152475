<div fxLayout="column" class="full-width-bs full-height-bs">

    <project-queries-sidenav fxFlex="none" [project]="project"></project-queries-sidenav>

    <div fxFlex class="sw-big-pad ">
        <spinner *ngIf="!isLoaded()"></spinner>
        <router-outlet *ngIf="isLoaded()" class="full-height-bs"></router-outlet>
    </div>

</div>  


import { Component, OnInit, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router,ActivatedRoute } from '@angular/router';
import { Column, GridOption, GridStateChange, SlickGrid } from 'angular-slickgrid';
import { UtilService } from '../../svc/utilService';
import { ConversionService, ProjectService } from '../../api/SVC';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { CurrentConversionJobService } from '../../svc/currentConversionJobService';
import { PollingService } from '../../svc/pollingService';
import { DISOAction } from '../../model/DISOAction';
import { StateHelperService, StateHelperInstance } from '../../svc/stateHelperService';
import { Constants } from '../../api/Constants';

@Component({
  selector: Constants.ROUTE_PROJECTACTIONS,
  styleUrls: ['../../shared/css/buttons.scss'],
  templateUrl: './project-actions.html'
})
export class ProjectActions implements OnInit, OnDestroy {

  constructor(
    private router:Router,
    private route:ActivatedRoute, 
    private projectService: ProjectService, 
    private currentConversionJob: CurrentConversionJobService,
    private conversionService: ConversionService,
    private pollingService: PollingService,
    private datePipe: DatePipe,
    private currentProject : CurrentProjectService,
    private utilService: UtilService,
    public stateHelperService: StateHelperService
    ) { }

  ngOnInit(): void {

    this.stateHelperInstance =  this.stateHelperService.register(Constants.ROUTE_PROJECTACTIONS);
    this.pollingService.startPollingSequence(Constants.POLLING_ID_ACTIONVIEW, Constants.POLLING_ACTIONVIEW_INTVL, -1, (rv,rj)=> {
      this.update(rv, rj);
    });
    this.setGridColumns();
  }

  ngOnDestroy() {
    this.pollingService.stopSequence(Constants.POLLING_ID_ACTIONVIEW);
    if (this.actionGrid)
        this.actionGrid.destroy();
  }

  public stateHelperInstance: StateHelperInstance;
  public gridColumnDefs: Column[];
  public gridColumnDefsMinimal: Column[];
  public gridOptions: GridOption;
  public actions: Array<DISOAction> =[];
  public isDataLoaded : boolean=false;

  private actionGrid: SlickGrid=null;

  public isDataReady() : boolean {
    return this.currentProject.dataContext.isReady && this.isDataLoaded;
  }

  public isDataAny() : boolean {
    return this.isDataReady() && this.actions.length>0;
  }

  private update(resolve: Function, reject: Function): void {

    let prjId =this.stateHelperService.details.projectId;
    this.conversionService.getActionsForProject( prjId,false,true).then((response) => {
        DISOAction.Merge(response.data.items, this.actions);
        this.isDataLoaded=true;
        // if (this.actionGrid)
        //   this.actionGrid.resizeCanvas();
        resolve();
    }, () => {
        console.error('Actions couldn\'t be loaded');
        reject();
    });
  }

  public appAngularGridReady(angularGrid:any) {
     this.actionGrid = angularGrid.slickGrid;
  }

  public appGridStateChanged(gridStateChanges: GridStateChange) {
    if (gridStateChanges.change.type == "rowSelection") {
      var rl = this.actionGrid.getSelectedRows();
      if (rl.length>0)
        this.openAction(this.actionGrid.getDataItem(rl[0]));
    }
  }

  private openAction(action:DISOAction) {
    this.router.navigate([
      Constants.ROUTE_PROJECTACTIONDETAILS_MAIN, 
      this.utilService.compressGuid(action.item.jobId)
    ], {relativeTo: this.route});        
  }

  private setGridColumns() : void {
      this.gridColumnDefs = [
        { id: 'status', name: 'Status', field: 'item', sortable: true, width:32,
          formatter : (r,c,v,cd,dc)=> { return this.getStatusTemplate(dc)}
        },
        { id: 'date', name: 'Date', field: 'item', sortable: true, width: 65,
          formatter : (r,c,v,cd,dc)=> { return this.datePipe.transform(v.actionDate, "dd-MMM-yyyy"); }
        },
        { id:'hasUI', name: 'Has UI', field: 'item', sortable:true, width:40,
          formatter: (r,c,v,cd,dc)=> { return this.getTickTemplate(dc, "hasUI")}
        },
        { id:'action', name: 'Action', field: 'item', sortable:true, width:200,
        formatter: (r,c,v,cd,dc)=> { return v.label}
        },  
        { id:'origin', name: 'Origin', field: 'item', sortable:true, width:140,
          formatter : (r,c,v,cd,dc)=> { return v.origin }
        },
        { id:'name', name: 'Name', field: 'item', sortable:true, width:300,
          formatter : (r,c,v,cd,dc)=> { return v.filename }
        },
        { id:'path', name: 'Path', field:'item', sortable:true, width:400,
          formatter : (r,c,v,cd,dc)=> { return v.path }
        },
        { id: 'arguments', name: 'Arguments', field: 'item', sortable:true, width:300,
          formatter : (r,c,v,cd,dc)=> { return v.arguments }
        }
      ];

      this.gridColumnDefsMinimal = [
        { id: 'status', name: 'Status', field: 'item', sortable: true, width:40,
          formatter : (r,c,v,cd,dc)=> { return this.getStatusTemplate(dc)}
        },
        { id:'action', name: 'Action', field: 'item', sortable:true, width:80,
          formatter: (r,c,v,cd,dc)=> { return v.label}
        },
        { id:'name', name: 'Name', field: 'item', sortable:true, width:300,
          formatter : (r,c,v,cd,dc)=> { return v.filename }
        },
      ];

      this.gridOptions = {
        enableAutoResize: true,
        enableSorting: true,
        enableRowSelection: true,
        enableCheckboxSelector: true,
        enableCellNavigation:true,
        rowHeight:Constants.GRID_ROW_HEIGHT + 3,//need the extra 3 for progress bar
        checkboxSelector: {
          hideSelectAllCheckbox: false,
 //         selectableOverride: (row: number, dataContext: any, grid: any) => (dataContext.item.  status === 0)
        },
        multiSelect: false,
        rowSelectionOptions: {
          selectActiveRow: true,
        },
      };
  
  }

  private getTickTemplate(action:DISOAction, property:string) : string {
    var html = '';
    if (action.item[property]) {
        html += '<div class="sw-ok md-24">';
        html += '<i class="material-icons">check_circle</i>';
        html += '</div>';
    }
    return html;
  }

  private getStatusTemplate(action:DISOAction) : string {
    var ico = null;
    var isErr=false;
    var label="";
    switch(action.item.status)
    {
        case 0:
        case 1:
          ico="hourglass_empty";
          label="In progress";
          break;
        case 2:
          ico="error_outline";
          isErr=true;
          label="failed";
          break;
        case 3:
          ico="cancel";
          isErr=true;
          label="cancelled";
          break;
        case 4:
          ico="done";
          label="succeeded";
          break;
        default:
          html= action.item.status.toString();
    }
    var html="";
    var err = (isErr) ? " sw-error" : "";
    html += `<div class="sw-ok md-24${err}" title="${label}">`;
    html += '<i class="material-icons">' + ico + '</i>';
    html += '</div>';
    if (action.item.status == 1){
      html = this.getInProgressHtml(action, html);
    }
    return html;
  }

  private getInProgressHtml(action:DISOAction, statushtml:string){
    var progClass = `sw-progress-x-${action.item.percentComplete}`;
    return  "<div fxLayout='column'>" +
                `<div fxFlex>${statushtml}</div>` +
                `<div fxFlex='none' style='height:3px' class='${progClass}'></div>` +
            "</div>";
  }

}

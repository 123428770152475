import { Constants } from '../api/Constants';

export class ActionIdParts {

    static parse(jobId:string, isJobId: boolean, appId:string) : ActionIdParts {
        let parts = jobId.split(Constants.CONV_ACTION_DELIM);
        var ret=  new ActionIdParts();

        if (parts.length> 0){

            var appIdPart = parts.indexOf(appId);
            if (appIdPart != -1)
                parts.splice(appIdPart, 1);

            if (parts.length==1 && !isJobId) {
                ret.rootType = parts[0]
                ret.isRoot=true;
            }
            else {
                ret.isRoot=false;
                ret.rootType=null;
                ret.type = (parts.length>0) ? parts[1] : null;
                if (parts.length>1 && this.isXCheckChild(parts[1])) {
                    ret.type = parts[1];
                    ret.conversionType = 14;
                }
                else {
                    ret.conversionType=(parts.length>2) ? parseInt(parts[2]) : null; /* this is an action type for creating tasks */
                    ret.conversionSubType=(parts.length>3)?parts[3]:null;
                }
            }
        }

        return ret;
    }

    public isRoot: boolean;
    public rootType: string;
    public type:string;
    public conversionType:number;
    public conversionSubType:string;

    private static _xcheckChildren =[
        Constants.ACTION_XCHECK_SHT,
        Constants.ACTION_XCHECK_EVL,
        Constants.ACTION_XCHECK_DSK,
        Constants.ACTION_XCHECK_SVC,
        Constants.ACTION_XCHECK_COM,
        Constants.ACTION_XCHECK_DCOM,
        Constants.ACTION_XCHECK_PRT,
        Constants.ACTION_XCHECK_FIR,
        Constants.ACTION_XCHECK_ENV,
        Constants.ACTION_XCHECK_SCHED,
        Constants.ACTION_XCHECK_COMPAT,
        Constants.ACTION_XCHECK_DEFEX
    ];
    public static isXCheckChild(id:string) : boolean {
        if (!id)
            return false;
        return this._xcheckChildren.indexOf(id.toLocaleUpperCase())>=0;

    }

    public getJobCreateHeading() :string {
        switch(this.conversionType)
        {
            case 1: return "REPACKAGE APPLICATION";
            case 2: return "VIRTUALISE APPLICATION";
            case 20: return "CREATE MSIX PACKAGE";
            case 21: return "CREATE INTUNE PACKAGE";
            case 23: return "CREATE LIQUIDWARE PACKAGE";
            default:
                return null;
        }    
    }
}
import { Component, OnInit, Input } from '@angular/core';
import { UtilService } from '../../svc/utilService';
import { CurrentProjectService } from 'src/app/svc/currentProjectService';
import { INPUTEDITARGS } from '../../model/INPUTEDITARGS';
import { Constants } from '../../api/Constants';
import { ProjectNotes} from '../../model/projectNotes';

import * as CRS from '../../api/CRS';

@Component({
  selector: Constants.ROUTE_PROJECTSETTINGSGENERAL,
  templateUrl: './project-settings-general.html',
  styleUrls: ['../../shared/css/nav-bar.scss']
})
export class ProjectSettingsGeneral implements OnInit {

  constructor(
      private currentProject: CurrentProjectService,
      private utilService:UtilService) { }

  ngOnInit(): void {
  }

  public desktopBaseImageChanged(imageId:string) {
    this.currentProject.setDesktopPlatformBaseImage(this.project.desktopBaseImageId);
  }

  public setBaseImageXCheckRunningStateChange(): void {
    var desktopImageId = this.project.desktopBaseImageId;
    if (!desktopImageId) 
        return;
    var toscs = this.project.trackableOperatingSystemConfigurations.filter(x=>x.id == desktopImageId);
    if (!toscs || toscs.length==0)
        return;
    var tosc0=toscs[0];
    var toss = this.currentProject.getTrackedOperatingSystems().filter(x=>x.operatingSystemId == tosc0.operatingSystemId && tosc0.checkpoint == tosc0.checkpoint);
    if (!toss || toss.length == 0)
        return;
    var tos0 = toss[0];
    tos0.originalTestEnabled = this.project.desktopBaseImageXCheckRunning;
    if (tos0)
        this.currentProject.updateXCheckTrackedOperatingSystems(tos0);
  }

  public filterDesktopOs = (item: CRS.RSTrackableOperatingSystemConfiguration) : boolean => {
    return item.usesDesktopOperatingSystem;
  }

  public get project():  CRS.RSGetProject {
    return this.currentProject.dataItem;
  }

  public get projectNotes(): ProjectNotes {
    return this.currentProject.notes;
  }
  
  public get projectIsReady(): boolean {
    return this.currentProject.dataContext.isReady;
  }

  public canEdit(): boolean {
    return this.currentProject.canEdit();
  }

  public get projectVhds(): Array<CRS.RSVhd> {
    return this.currentProject.vhds;
  }

  public get projectVhdsReady(): boolean {
    return this.currentProject.vhdsReady;
  }

  public get projectImageUrl(): string {
    return this.currentProject.imageUrl;
  }
  public set projectImageUrl(value:string) {
    this.currentProject.imageUrl = value;
  }

  public get projectCustomerReportingImageUrl(): string {
    return this.currentProject.customerReportingImageUrl;
  }
  public set projectCustomerReportingImageUrl(value:string) {
    this.currentProject.customerReportingImageUrl = value;
  }

  public projectNameChanged(args : INPUTEDITARGS) {
      this.currentProject.updateName(args.value);
  }

  public projectStorageAccessKeyChanged(args: INPUTEDITARGS) {
      this.currentProject.updateStorageAccessKey(args.value);
  }

  public projectDescriptionChanged(args: INPUTEDITARGS) {
      this.currentProject.updateDescription(args.value);
  }

  public customerReportingImageChanged(value:string){
    this.currentProject.updateImage(Constants.IMAGE_TYPE_CUSTOMERREPORTING, value);
  }

  public projectImageChanged(value:string) {
    this.currentProject.updateImage(Constants.IMAGE_TYPE_DEFAULT, value);
  }



}

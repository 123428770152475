import { Component, Input, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'sw-button-text',
  templateUrl: './sw-button-text.html',
  styleUrls: ['./sw-button-text.scss']
})
export class SwButtonText  {

    @Input('icon') icon:string;
    @Input('icon-class') iconClass:string;
    @Input('text') text:string;
    @Input('collapsed') collapsed:boolean;
    @Input('iconSize') iconSize:string;
    @Input('customClass') customClass:string;
    @Input('disabled') disabled: boolean;

    public getClass() :string {
      if (this.customClass)
        return this.customClass;
      if (this.disabled)
        return "sw-button-text-wrapper-disabled";
      else 
        return "sw-button-text-wrapper";
  }

}




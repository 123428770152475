import { Injector } from '@angular/core';
import { CurrentApplicationService } from "../svc/currentApplicationService";
import { MenuService } from '../svc/menuService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';

export class UpdateAppCompatReportCommand {

    constructor(
        private injector: Injector
    ) {
        this.menuService = this.injector.get(MenuService);
        this.currentApplication = this.injector.get(CurrentApplicationService);

        this.menuService.register(Constants.MENU_UPDATE_APPCOMPAT_REPORT, this.updateAppCompatReportCommand, "*"+Constants.ROUTE_PROJECTAPPLICATIONLANDING_MAIN )
    }

    private menuService: MenuService;
    private currentApplication: CurrentApplicationService;

    private updateAppCompatReportCommand: AppCommand = AppCommand.createWithShow(
        ()=> {
            return true; 
        },
        () => {
            this.currentApplication.runAppCompatReport();
        },
        ()=> { 
            let ret: boolean = ( this.currentApplication.dataContext.isReady && this.currentApplication.dataItem.hasBeenAssessed);
            return ret;
        }
    );

}
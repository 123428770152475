import { Injectable, ɵbypassSanitizationTrustResourceUrl } from '@angular/core';
import { GridRegistration } from '../model/gridRegistration';
import { StateHelperService } from '../svc/stateHelperService';

@Injectable({
    providedIn: 'root'
})
export class GridColumnsHelperService {

    private registrations: { [id:string] : GridRegistration} = {};

    constructor(private stateHelperService: StateHelperService)
    { 
        let xthis : GridColumnsHelperService = this;
    }

    public register() : void {
        var rc = this.stateHelperService.details.statePathCurrent;
        if (rc) {
            let i = this.registrations[rc.id];
            if (!i) {
                i = this.registrations[rc.id] = new GridRegistration(rc.id);
            }
        }
    }

    public registerColumnVisibilityChange(columns: any) : void {
        this.record(columns);
    }

//     public getColumnVisibilityHidden(columnName: string) : boolean {
// this.$localStorage[]
//     }

    public list(columns: any) : Array<string> {
        var rc = this.stateHelperService.details.statePathCurrent;
        let r : GridRegistration = this.registrations[rc.id];
        if (r) {
            return columns.filter(x=>!x.hasOwnProperty("visible") || x.visible)
                    .filter(x=>x.id && !x.id.startsWith('_'))
                    .map(x=>x.id);
        }
        return null;
    }

    private record(columns: any) : void {
        var rc = this.stateHelperService.details.statePathCurrent;
        let r : GridRegistration = this.registrations[rc.id];
        if (r) {
            localStorage.setItem(r.stateName + "-grid-columns", JSON.stringify(columns));
        }
    }

    public restoreState(columns: any) : void {
        var rc = this.stateHelperService.details.statePathCurrent;
        let r : GridRegistration =this.registrations[rc.id];
        if (r) {
            let state : Array<string> = JSON.parse(localStorage.getItem(r.stateName + "-grid-columns"));
            if (state) {
                columns.forEach(c=> {
                    if (state.indexOf(c.name) != -1)
                        c.visible = true;
                    else if (c.enableHiding)
                        c.visible=false;
                });
            }
        }
    }
}
    

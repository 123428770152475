import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'save-msi-edit-dialog',
    templateUrl: 'save-msi-edit-dialog.html',
    styleUrls:[]
})
export class SaveMsiEditDialog implements OnInit {

    constructor(
        private dialogRef: MatDialogRef<boolean>,
    ) {
    }

    public response: any= {status:0};
 
    ngOnInit() {
    }

    isDataReady() : boolean {
        return true;
    }

    isValid(): boolean {
        return true;
    }

    closeDialog(result:number) {
        this.response.status = result;
        this.dialogRef.close(this.response);
    }

    cancelDialog() {

        this.dialogRef.close();
    }
}


import { Component, OnInit, Input } from '@angular/core';
import { UtilService } from '../../svc/utilService';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { RagValueResolverService } from '../../svc/ragValueResolverService'

import * as CRS from '../../api/CRS';

@Component({
  selector: 'rag-column',
  templateUrl: './rag-column.html',
  styleUrls: ['./rag-column.scss']
})
export class RagColumn implements OnInit {

  constructor(
      private utilService: UtilService, 
      private currentProjectService: CurrentProjectService,
      private ragValueResolverService: RagValueResolverService) { }

  @Input('rag-element') ragElement : CRS.RSRAGElement;
  @Input('neutralise') neutralise : boolean;
  @Input('explicit-value') explicitValue: string;
  @Input('alternative-explicit-value') alternativeExplicitValue: string;
  @Input('custom-class') customClass: string;
  @Input('admin-check-detail') adminCheckDetail: string;

  ngOnInit(): void {
  }

  public getValueCode() : string {
    let v = this.getValue();
    return (v) ? v.colorClass.substring(0,1).toLocaleUpperCase() : "";
  }

  public getCustomClass() : string {
    return this.customClass || "sw-rag";
  }

  public visuallyEnhanced() : boolean {
    return this.utilService.privileges?.visEnh;
  }

  public getValue() : CRS.RSRAG{
    let projectId: string;
    if(this.adminCheckDetail){
      projectId = this.adminCheckDetail;
    }
    else{
    projectId =this.currentProjectService.dataItem.id;
  }
    let ret: CRS.RSRAG=null;

    let expl:string =this.explicitValue;
    if (this.alternativeExplicitValue) {
        let grpObjKey = Object.keys(this.alternativeExplicitValue)[0];
        if (grpObjKey.indexOf("$$uiGrid") == 0) {
            expl = this.alternativeExplicitValue[grpObjKey].groupVal;
        }
    }

    try {
        ret= this.ragValueResolverService.resolve(projectId, this.ragElement, this.neutralise, expl);
    }
    catch(ex) {
        console.log(ex);
    }

    if (ret==null)
        ret=null;
    return ret;
  }

  public getValueColorClass() : string {
    var v = this.getValue();
    return (v) ? v.colorClass : null;
  }
}



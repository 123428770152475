
import {PlatformService} from '../platformService'

import * as CRS from '../../api/CRS';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
    selector: 'admin-add-patch-category-dialog',
    templateUrl: './admin-add-patch-category-dialog.html',
    styleUrls: [ '../../shared/css/core.scss', './dialogs.scss']
})
export class AdminAddPatchCategoryDialog implements OnInit {

    public title:string;

    constructor(
        private dialogRef: MatDialogRef<AdminAddPatchCategoryDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any)
        {

        }

    ngOnInit() {
        this.title = this.data.title;
    }

    private values : any ={
        category: "",
    }

    public category : string;

    public isValid() : boolean{
        if (!this.category ) {
            return true;
        }
        if (this.category.length < 3) {
            return false; 
        }
        if (this.category.match('[^A-Za-z0-9-]')) {
            return false;
        }
        return true;
    }

    public cancelDialog() {
        this.dialogRef.close("");
    }

    public finish() {
        this.values.category = this.category;
        this.dialogRef.close(this.values.category);
    }
}

<div fxLayout="row" class="full-height-bs">

    <div *ngIf="getValueType() == 'unadorned'" fxFlex>
        <input #ed1 autofocus  name="name" type="text"  [(ngModel)]="runningValue" [disabled]="disable" (focus)="setFocus('ed1',true)" (blur)="setFocus('ed1',false)" (input)="notifyChanged()" aria-label="enter value" class="sw-input-edit">
    </div>

    <div *ngIf="getValueType() == 'single' && getInputType() == 'text' && isSimpleString()" fxFlex>
        <mat-form-field appearance="outline" class="full-width-bs">
             <mat-label *ngIf="label">{{label}}</mat-label>
             <input #ed2 matInput name="name" type="text"  [(ngModel)]="runningValue" [disabled]="disable" (focus)="setFocus('ed2',true)" (blur)="setFocus('ed2',false)" (input)="notifyChanged()" aria-label="enter value" class="sw-input-edit"/>
         </mat-form-field>
     </div>

     <div *ngIf="getValueType() == 'single' && getInputType() == 'password' && isSimpleString()" flex>
        <mat-form-field appearance="outline" class="full-width-bs">
            <mat-label *ngIf="label">{{label}}</mat-label>
            <input #ed7 matInput name="name" type="password" [disabled]="disable" [(ngModel)]="runningValue" (focus)="setFocus('ed7',true)" (blur)="setFocus('ed7',false)" (change)="notifyChanged()" aria-label="enter value" class="sw-input-edit" autocomplete="off">
        </mat-form-field>
    </div>
 
     <div *ngIf="getValueType() == 'single' && isMultiString()" fxFlex >
        <mat-form-field appearance="outline" class="full-width-bs full-height-bs">
             <mat-label *ngIf="label">{{label}}</mat-label>
             <textarea #ed3 matInput name="name" rows="{{getNoRows()}}" type="text" style="min-width: 400px;" [disabled]="disable" [(ngModel)]="runningValue" (focus)="setFocus('ed3',true)" (blur)="setFocus('ed3',false)" (input)="notifyChanged()" aria-label="enter value" class="sw-input-edit full-height-bs"></textarea>
         </mat-form-field>
     </div>

     <div *ngIf="getValueType() == 'single' && datatype == 'JSON'" fxFlex>
        <mat-form-field appearance="outline" class="full-width-bs full-height-bs">
            <mat-label *ngIf="label">{{label}}</mat-label>
            <textarea #ed4 matInput name="name" rows="{{getNoRows()}}" type="text" style="min-width: 400px;" [disabled]="disable" [(ngModel)]="runningValue" (focus)="setFocus('ed4',true)" (blur)="setFocus('ed4',false)" (input)="notifyChanged()" aria-label="enter value" class="sw-input-edit full-height-bs"></textarea>
        </mat-form-field>
    </div>

     <div *ngIf="getValueType() == 'double'" fxFlex>
         <div layout="row" style="display: flex; grid-gap: 1rem; min-height: 30px; font-family: inherit; font-style: normal; font-size: 10pt;">
             <div class="sw-hor-pad">
                 <mat-form-field class="sw-thin">
                     <input #ed5 matInput id="i2a" type="text" [(ngModel)]="runningValue.v1" (focus)="setFocus('ed5', true)" (blur)="setFocus('ed5',false)" (input)="notifyChanged()" aria-label="enter value" class="sw-input-edit"/>
                 </mat-form-field>
             </div>
             <div class="sw-hor-pad">
                 <mat-form-field class="sw-thin">
                     <input #ed6 matInput id="i2b" type="text" [(ngModel)]="runningValue.v2" (focus)="setFocus('ed6-',true)" (blur)="setFocus('ed6-',false)" (input)="notifyChanged()" aria-label="enter value" class="sw-input-edit"/>
                 </mat-form-field>
             </div>
         </div>
     </div> 
 
     <div fxFlex="none" class="sw-pad-top-small"> 
         <button mat-button (click)="save()" [hidden]="!isChanged()" [disabled]="isInvalid()" class="button-link-narrow" style="color: rgb(80,112,80)">
             <mat-icon class="md-primary">done</mat-icon>
         </button>
         <button mat-button (click)="cancel()" [hidden]="!isChanged()" class="button-link-narrow" style="color: rgba(0,0,0,0.54);">
             <mat-icon class="material-icons">clear</mat-icon>
         </button>
     </div>
 
 </div>
<div fxLayout="column" class="sw-pad">

    <div flex="none" class="sw-big-pad"></div>

    <div fxFlex="none" layout="row">
        <div fxFlex="none">
            <mat-select [(ngModel)]="selectedCharts" aria-placeholder="Select Assessment Groups" placeholder="Select Assessment Groups"
                (selectionChange)="assessmentGroupSelectionChanged()"  multiple style="min-width:600px">
                <mat-optgroup label="Assessment Groups">
                    <mat-option [value]="ag.id" *ngFor="let ag of getActiveAssessmentGroups()">{{ag.name}}</mat-option>
                </mat-optgroup>
            </mat-select>
        </div>
        <div fxFlex></div>
    </div>

    <div flex="none" class="sw-big-pad"></div>

    <div fxFlex #chartRoot class="sw-chart-panel">
        <div class="sw-chart-cell">
            <canvas id="chart-0"></canvas>
        </div>
        <div class="sw-chart-cell">
            <canvas id="chart-1"></canvas>
        </div>
        <div class="sw-chart-cell">
            <canvas id="chart-2"></canvas>
        </div>
        <div class="sw-chart-cell">
            <canvas id="chart-3"></canvas>
        </div>
        <div class="sw-chart-cell">
            <canvas id="chart-4"></canvas>
        </div>
        <div class="sw-chart-cell">
            <canvas id="chart-5"></canvas>
        </div>
    </div>

</div>

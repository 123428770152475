<div class="full-height-bs promo-publish" fxFill fxLayout="column">

    <div fxFlex="none">

        <div class="sw-bold sw-upper">
            <h3>Publish Application</h3>
        </div>

        <div class="sw-pad">

            <div fxLayout="row">
            
                <div fxFlex="30">
                    <div class="sw-vert-pad" *ngIf="false"> <!-- on hold at the moment -->
                        <mat-checkbox [(ngModel)]="mergeTransforms">Merge Transforms</mat-checkbox>
                    </div>
                    <button mat-raised-button color="primary" [disabled]="!publishCommand.can" (click)="publishCommand.execute()">Launch</button>
                </div>

                <div fxFlex>
                    <div *ngIf="!publishCommand.can && cannotPublishReasons?.length > 0">
                        <div fxLayout="row" fxLayoutGap="10px">
                            <div fxFlex="none">
                                <mat-icon class="sw-warning md-24">info</mat-icon>
                            </div>
                            <div fxFlex>
                                This application cannot be published currently because:
                            </div>
                        </div>
                        <ul>
                            <li class="sw-warning" *ngFor="let d of cannotPublishReasons">
                                {{d}}
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div fxFlex fxLayout="row" class="sw-promo">
        <promo-info 
            top-paragraph="Collating all required documentation, binaries and publishing commands is tedious, technical work that needs to be accurate, 100% of the time." 
            bottom-paragraph="An often overlooked technical challenge now automated and validated."
            [bullets]="getPromoBullets()"
            img="/img/convert/publishing.png">
        </promo-info>
    </div>
</div>

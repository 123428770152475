
<div fxLayout="column" fxLayoutGap="15px" class="full-height-bs" fxLayoutAlign="center center" *ngIf="!authService.isLoggedInResolved()">
    <div fxFlex></div>
    <div fxFlex="none">
       <img class="header-logo-img" src="img/header/logoRDark.png">
    </div>
    <div fxFlex="none">
        <h2>Welcome To Application Readiness</h2>
    </div>
    <div fxFlex="none" *ngIf="!authService.isLoggedIn">You don't have an active connection to Readiness. Please sign in below</div>
    <button fxFlex="none" mat-button color="primary" *ngIf="!authService.isLoggedIn" (click)="authService.login()">Login</button>
    <div></div>
    <div fxFlex></div>
</div>


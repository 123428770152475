<div class='full-height-bs full-width-bs' 
        fxFlexFill 
        fxLayout="column" 
        fxLayoutGap="40px"
        fxLayoutAlign="start center">

    <div fxFlex="10"></div>

    <div fxFlex="none">
        <div>
            <img src="/img/misc/404.png" alt="error">
        </div>
    </div>

    <div fxFlex="none" class='sw-error-page-not-found'>
        PAGE NOT FOUND
    </div>

    <div fxFlex class='sw-errorButtons' fxLayout="row" fxLayoutGap="10px">
        <div fxFlex>
            <button mat-raised-button color="primary" (click)='goHome()'>Dashboard</button>
        </div>
    </div>

</div>
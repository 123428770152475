import { Component, OnInit, Input, SimpleChanges, OnDestroy } from '@angular/core';
import { Column, GridOption, SlickGrid, GridStateChange } from 'angular-slickgrid';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { PlatformService } from '../../svc/platformService';
import { UtilService } from '../../svc/utilService';
import { CHKINFO } from '../../model/CHKINFO';
import { CheckInfoService } from 'src/app/svc/checkInfoService';
import { RagValueResolverService } from '../../svc/ragValueResolverService';
import { StateHelperService } from '../../svc/stateHelperService';
import { MenuService } from '../../svc/menuService';
import { Constants } from 'src/app/api/Constants';

import * as CRS from '../../api/CRS';

@Component({
    selector:'app-issues-table',
    templateUrl: './app-issues-table.html',
    styleUrls:['./project-applications.scss']
})
export class AppIssuesTable implements OnInit, OnDestroy {

    @Input('issues') issues: Array<CRS.RSIssue>;
    @Input('options') options: Array<string>;
    @Input('show-selection-box') showSelectionBox: boolean;
    @Input('grid-id') gridId: string;

    public hideCheckLink: boolean=false;

    public isMouseOverChip : boolean = false;
    public isEditing: boolean=false;
    public name:string;

    constructor(
        private currentProject: CurrentProjectService,
        private platformService: PlatformService,
        private utilService: UtilService,
        private checkInfoService: CheckInfoService,
        private stateHelperService : StateHelperService,
        private menuService: MenuService
    ) {
    }

    ngOnInit() {
        this.prepareGrid();
    }

    ngOnDestroy(): void {
        if (this.grid)
            this.grid.destroy();
    }

    ngOnChanges(changes: SimpleChanges) {
        var iss = changes["issues"];
        if (iss && this.grid) {
            this.grid.resizeCanvas();
            this.grid.invalidateAllRows();
        }
    }

    private grid: SlickGrid;
    public appAngularGridReady(angularGrid:any) {
        this.grid = angularGrid.slickGrid;
    }

    public test() {
        this.grid.getContainerNode().style.width = "400px";
        this.grid.getContainerNode().style.height = "100px";
    }

    public appGridStateChanged(gridStateChanges: GridStateChange) {
        if (gridStateChanges.change.type != "rowSelection") 
            return;
        //  Syncronises the indexes of selected rows with the IsSelected flag on RSIssue items
        var selOld = this.issues.filter(x=>x.isSelected);
        var selDataItems: Array<CRS.RSIssue> = this.grid.getSelectedRows().map((idx)=>this.grid.getDataItem(idx));
        selDataItems.forEach((x)=>x.isSelected=true);
        selOld.forEach((x)=>{
            if (selDataItems.indexOf(x) == -1)
                x.isSelected=false;
        });
        this.menuService.refreshAll();
      }
    
    private isValidForPlatform(issue: CRS.RSIssue) : boolean{
        if (this.currentProject.dataContext.isReady) {
            let platform =this.platformService.getCurrent(this.currentProject.dataContext.id);
            return issue.assessmentStatesDictionary[platform] !== undefined;        
        }
        return false;
    }

    private getFixState(issue: CRS.RSIssue) : number {
        if (this.currentProject.dataContext.isReady) {
            let platform = this.platformService.getCurrent(this.currentProject.dataContext.id);
            let sd = issue.assessmentStatesDictionary[platform];
            return (sd===undefined) ? null : sd.fixState;
        }
        return -1;
    }

    private showMoreInfo(issue: CRS.RSIssue, state: boolean) {
        this.utilService.toggleItem("application-issues-expanded", issue.id, state);
    }

    public getIssueText(issue: CRS.RSIssue) : CHKINFO {
        if (issue.ruleId) {
            let ci : CHKINFO = this.checkInfoService.get(issue.checkId, issue.ruleId);
            return ci; 
        }
        return null;
    }

    private isMoreInfoShown(issue: CRS.RSIssue) {
        return this.utilService.getToggleState("application-issues-expanded", issue.id);
    }

    private setHover(issue: CRS.RSIssue): void {
        //this.hoverIssueId = (issue==null) ? null : issue.sid;
    }

    private goToReport(issue: CRS.RSIssue) {
        //this.$state.go('reportCheckIssues', { projectId: this.stateHelperService.getProjectId(), assessmentGroupId: issue.assGrp, checkId: issue.checkId });
    }

    private isSelectionBoxVisible() {
        return this.showSelectionBox && this.showSelectionBox;
    }

    private isSelected(issue: CRS.RSIssue) : boolean {
        //return this.selectedItems && this.selectedItems.indexOf(issue)  != -1;
        return false;
    }
    
    private toggleIssueSelect(issue: CRS.RSIssue) : void {
    //     let i : number = this.selectedItems.indexOf(issue);
    //     if (i==-1) {
    //         this.selectedItems.push(issue);
    //     }
    //     else {
    //         this.selectedItems.splice(i, 1);
    //         this.allSelected=false;
    //     }
    }

    private _isGridPrepared=false;
    private prepareGrid() : void
    {
        if (this._isGridPrepared)
            return;
        this._isGridPrepared=true;
        this.initialiseGrid();
    }

    private hasOption(id:string) : boolean {
        return this.options && this.options.indexOf(id) != -1;
    }
  
    public gridColumnDefs: Column[];
    public gridOptions: GridOption;
    private initialiseGrid() : void {
  
      this.gridColumnDefs = [];
      this.gridColumnDefs.push({ id: 'rag', name: 'RAG', field: 'rag', sortable: true, width: 50, maxWidth:50, formatter: (r,c,v,cd,dc)=> { return this.getRagTemplate(dc) } });
      if (this.hasOption("APP"))
          this.gridColumnDefs.push({ id: 'application', name: 'application', field: 'applicationName', sortable:true, width:200});
      if (this.hasOption("CHK"))
          this.gridColumnDefs.push({ id: 'check', name: 'Check', field: 'checkName', sortable: true, width: 260, formatter: (r,c,v,cd,dc)=> { return this.getCheckLinkTemplate(dc); } });
      if (this.hasOption("CHK"))
          this.gridColumnDefs.push({ id: 'assessmentGroup', name: 'Assessment Group', field: 'custom3', sortable: true, width: 150 });
      this.gridColumnDefs.push({ id: 'description', name: 'Description', field: 'text', sortable: true, width: 200 });
      this.gridColumnDefs.push({ id: 'autoFixable', name: 'Auto Fixable', field: 'canAutoFix', sortable: true, width: 60,
                                    formatter: (r,c,v,cd,dc)=> {
                                        return (dc.groupingKey) ? dc.value : this.getAutoFixTemplate(dc);
                                    },
                                    cssClass: "sw-grid-cell-middle"
      });
    //   this.gridColumnDefs.push({ id: 'category', name: 'Category', field:'category', sortable: true, width: 100, 
    //                                 formatter: (r,c,v,cd,dc)=> { return this.getCategoryTemplate(dc); }});
  
      this.gridOptions = {
          autoHeight:true,
        enableAutoResize: true,
        enableSorting: true,
        enableRowSelection: true,
        enableCheckboxSelector: true,
        enableCellNavigation:true,
        enableColumnPicker: true,
        rowHeight:Constants.GRID_ROW_HEIGHT,
        checkboxSelector: {
          hideSelectAllCheckbox: false,
        },
        multiSelect: true,
        rowSelectionOptions: {
          selectActiveRow: false,
        },
        gridMenu: {
          hideForceFitButton:true,
          hideSyncResizeButton: true,
        }
      };
    }
  
    public getRagTemplate(item:CRS.RSIssue) : string {
        var platNo = this.platformService.getCurrent(this.stateHelperService.details.projectId)
        if (platNo>0) {
            var assSt = item.assessmentStatesDictionary[platNo];
            if (assSt) {
                var val = RagValueResolverService.resolveExplicit(String(assSt.assessmentState));
                return "<div class='full-width full-height sw-rag-small sw-rag-" + val.colorClass + "'></div>";
            }
        }
        return null;
    }

    public getCheckLinkTemplate(item:CRS.RSIssue) : string {
        var projId = this.currentProject.dataContext.id;
        var agId = item.assGrp;
        var url =`#/prj/${projId}/rpt/ag/${agId}/ckd/${item.checkId}`;
        return "<div class='full-width full-height'><a href='" + url + "' class='sw-link'>" + item.checkName + "</a></div>";
    }

    public getAutoFixTemplate(item:CRS.RSIssue) : string {
        if (item.canAutoFix)
            return "<div class='full-width full-height'><i class='material-icons'>build</i></div>";
        return null;
    }

    public getCategoryTemplate(item:CRS.RSIssue) : string {
        return "<div class='full-width full-height'>" + this.getCategoryDisplay(item.category) + "</div>";
    }

    private getCategoryDisplay(category:any) : string {
        if (!category)
            return "";
        let catno= (String(category).length > 1) ? parseInt(String(category).substring(0, 1)) : category;
        switch(catno) 
        {
            case 1:
                return "1. Investigate Red Issues";
            case 2: 
                return "2. Auto-Fix Amber Issues";
            case 3: 
                return "3. Auto-Fix After Review";
            case 4: 
                return "4. Investigate Remaining Issues";
            case 5: 
                return "5. Information Only Observations";
            default: 
                return category + ". Unknown";
        }
    }
}


<div *ngIf="!hasItems()">
    No services were created by this installation
</div>

<table style="padding-left:10px" *ngIf="hasItems()">
    <tr *ngFor="let svc of item.serviceItems">
        <td style="min-width: 150px;" class="sw-column-top">{{svc.id}}</td>
        <td style="min-width: 150px;" class="sw-column-top">
            <div fxLayout="column">
                <div *ngFor="let std of svc.serviceTypeDescriptions">
                    {{std}}
                </div>
            </div>
        </td>
        <td class="sw-column-top"> 
            {{svc.startTypeDescription}}
        </td>
        <td>
            <div fxFlex fxLayout="column" fxLayoutAlign="center center"> 
                <div fxFlex *ngIf="!svc.screenshot">NO IMAGE</div>
                <img fxFlex *ngIf="svc.screenshot" [src]="getScreenshot(svc)" style="max-width:300px">
            </div>        
        </td>
    </tr>
</table>

     
<button mat-button [matMenuTriggerFor]="menu" aria-label="view" class="sw-button-link full-height-bs" [disabled]="isInError">
    <i image-size="24pt" aria-label="open menu" class='material-icons'>menu</i>
</button>

<mat-menu #menu>

    <button *ngFor="let m of menuItems | callback : activeSingularFilter" mat-menu-item (click)='menuService.invoke(m.id)' [disabled]='!menuService.canInvoke(m.id)' [hidden]="!activeSingularFilter(m)">
        <div fxLayout="row" fxLayoutAlign="start center" class="sw-menu-item">
            <div fxFlex="20">
                <mat-icon>{{m.icon}}</mat-icon>
            </div>
            <div fxFlex>{{(isToggleOn(m.storageId)) ? m.offText: m.text}}</div>
        </div>
    </button>

    <button *ngFor="let m of menuItems | callback : activeSubMenuFilter" [matMenuTriggerFor]="submenu" mat-menu-item>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
            <div fxFlex="20">
                <mat-icon>{{m.icon}}</mat-icon>
            </div>
            <div fxFlex>
                <span class="sw-pad-horiz">{{m.text}}</span>
            </div>
        </div>
        <mat-menu #submenu>
            <button *ngFor='let sm of menuService.getSubMenu(m.id)' mat-menu-item (click)="menuService.invokeSubMenuItem(m.id, sm)">
                <span>{{sm.display}}</span>
            </button>
        </mat-menu>
    </button>

</mat-menu>


<div fxLayout="column" class="full-width-bs">
    
    <div fxFlex="none">
        <h3 class="sw-upper">Running Processes {{itemCountStr}}</h3>
    </div>
    <div fxFlex>
        <div id="box">
            <table id="box-inner" >
                <thead>
                    <th style="width:60px" *ngIf="killAllowed"></th>
                    <th id="idh">#</th>
                    <th style="width:120px">Run Time</th>
                    <th>Command Line</th>
                </thead>
                <tbody>
                    <tr *ngFor="let i of items" [ngClass]="{'current-proc' : i.isTargetProcess}">
                        <td *ngIf="killAllowed" (mouseover)="setCurrent(i)">
                            <button mat-button (click)="kill(i)" *ngIf="i == currentItem">
                                <mat-icon>cancel</mat-icon>
                            </button> 
                        </td>
                        <td>{{i.processId}}</td>
                        <td>{{i.runningTime}}</td>
                        <td>{{i.commandLine}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</div>
<div fxLayout='column' fxFill [ngClass]='{"sw-viewcontainer-collapsed": this.router.url === "/dashboard/admin"}'>

    <div fxFlex="0 0 38px" class="sw-toolbar" fxLayout="row">
        <breadcrumb-bar fxFlex="none"></breadcrumb-bar>
        <div fxFlex></div>
        <project-menu fxFlex="none"></project-menu>
    </div>

    <div fxLayout="row" class="sw-color-pr-A700" fxFlex>
        <div fxFlex="none">
            <admin-side-nav></admin-side-nav>
        </div>
        <div fxFlex>
            <router-outlet></router-outlet> 
        </div>
    </div>

</div>
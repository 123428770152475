import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Constants } from "../../api/Constants";
import { MenuService } from "../../svc/menuService";

import * as CRS from "../../api/CRS";
import * as SVC from "../../api/SVC";


@Component({
    selector: Constants.ROUTE_ADMIN_ASSESSMENT_GROUPS_CHECK,
    templateUrl: "./admin-assessment-group-check.html",
    styleUrls: ["../../shared/css/admin.scss", "../../shared/css/view.scss"]
})
export class AdminAssessmentGroupCheck implements OnInit {

    // Assessment group check data
    public filePayload: CRS.RSAdminAssGrpGetFilePayload = null;

    constructor(
        private xadminService: SVC.AdminService,
        private route: ActivatedRoute, 
        private menuService: MenuService
    ) {}

    ngOnInit() {
        // Fetch data
        this.update()
    }

    private update() {
        this.route.params.subscribe(
            params => {
                // Get check ID from route 
                const checkId = params[Constants.ROUTE_ASSESSMENT_GROUP_CHECK_ID];

                // Reset data and show loading indicator
                this.filePayload = null;

                // Fetch check data
                this.xadminService.adminAssGrpGetFilePayload(checkId).then((response) => {
                    this.filePayload = response.data;
                    this.menuService.refreshAll();
                }, () => {
                    console.error('Server data couldn\'t be loaded');
                });
            }
        );
    }

}
import { Component, OnInit, Input } from '@angular/core';
import { take} from 'rxjs/operators';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { ProjectService } from '../../api/SVC';
import { Constants } from '../../api/Constants';
import { DialogService } from '../../svc/dialogService';

import * as CRS from '../../api/CRS';

@Component({
  selector: Constants.ROUTE_PROJECTSETTINGSSHARED,
  templateUrl: './project-settings-shared.html',
  styleUrls: ['../../shared/css/nav-bar.scss']
})
export class ProjectSettingsShared implements OnInit {

  constructor(
      private currentProject:CurrentProjectService, 
      private projectService: ProjectService,
      private dialogService: DialogService
  ) { }

  ngOnInit(): void {
  }

  public isCurrentProjectOwnerAndIsShared() : boolean {
      if (this.currentProject.dataContext.isReady && this.project.isOwner)
        return this.project.shares.length > 0;
      return false;
  }

  public get project() : CRS.RSGetProject {
    return this.currentProject.dataItem;
  }

  public get projectIsReady() : boolean {
    return this.currentProject.dataContext.isReady;
  }

  public getShares() : Array<CRS.RSProjectShare>
  {
    if (this.isCurrentProjectOwnerAndIsShared())
      return this.project.shares;
  }

  public deleteShare(share: CRS.RSProjectShare) {
    this.dialogService.showYesNoMessage("Stop Sharing", ["Are you sure that you want to stop sharing this project with " + share.userEmail + "?"])
      .pipe(take(1))
      .subscribe((ans)=>{
          if (ans) {
              this.projectService.deleteProjectShare(this.currentProject.dataContext.id, share.userId).then((r)=> {
                   //this.$rootScope.$broadcast(Constants.EVENT_PROJECTDETAILSUPDATEREQUEST, {fullRefresh:true});
              });
          }
      });    
  }
}

import { Injector } from '@angular/core';
import { MenuService } from '../svc/menuService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';
import { CurrentApplicationService } from '../svc/currentApplicationService';
import { PackageFindNav } from './package-find-nav';
import { EventService } from '../svc/eventService';
import { StateHelperService } from '../svc/stateHelperService';

export class PackageFindNextCommand extends PackageFindNav {

    constructor(
        private injector: Injector
    ) {
        super(injector)

        this.menuService = this.injector.get(MenuService);
        this.currentApplication = this.injector.get(CurrentApplicationService);
        this.eventService = this.injector.get(EventService);
        this.stateHelperService = this.injector.get(StateHelperService);

        this.menuService.register(Constants.MENU_PACKAGE_FIND_NEXT, this.cmd, null )

        this.eventService.onPackageFindExecutedRefresh().subscribe(()=>{
            this.cmd.refresh();
        })
    }
    
    private currentApplication: CurrentApplicationService;
    private menuService: MenuService;
    private eventService: EventService;
    private stateHelperService: StateHelperService;

    public cmd: AppCommand = AppCommand.create(
        ()=> {
            return this.currentApplication.browser.findResults && this.currentApplication.browser.findResults.length>  0;
        },
        ()=> {
            let i = this.currentApplication.browser.findResults.indexOf(this.currentApplication.browser.findItem);
            if (i!=-1 && i+1 < this.currentApplication.browser.findResults.length) {
                var routeTbl = this.stateHelperService.details.tableId;
                this.currentApplication.browser.goToFindItem(this.currentApplication.browser.findResults[i+1]);
                var tblId = this.currentApplication.browser.currentTable;
                this.cmd.refresh();
                if (tblId!=routeTbl)
                    this.openTableById(this.currentApplication, tblId);

            }
        }
    );

}

<div class='sw-dialog-title admin-add-patch-category-dialog-colours' >
        <span class='sp-dialog-title-icon'>
            <span><i class="material-icons">check_circle</i></span>
        </span>
        <span>Add Patch Category</span>
        <button mat-button class='sw-dialog-close' (click)='cancelDialog()'>
            <i class="material-icons">cancel</i>
        </button>
    </div>
    
    <div class="update-platform-name-dialog-content"  layout-padding>
    
        <div style="font-style: normal; font-size: 10pt;">
            <mat-form-field class="admin-patch-category-form">
                <mat-label>Enter Category Name</mat-label>
                <input matInput type="text" [(ngModel)]="category" maxlength="50" style="width:400px">
            </mat-form-field>
        </div>
        
    </div>
    
    <mat-dialog-actions fxLayout='row'>
        <div fxFlex></div>
        <button mat-button (click)='finish()' [disabled]="!isValid()">
                <mat-icon class="material-icons">done</mat-icon>
                <span>OK</span>
            </button>
            <button mat-button (click)='cancelDialog()'>
            <mat-icon class="material-icons">cancel</mat-icon>
            <span>Cancel</span>
        </button>
    </mat-dialog-actions>
    
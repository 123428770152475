import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { PlatformService } from '../../svc/platformService';
import { CurrentAssessmentGroupService } from '../../svc/currentAssessmentGroupService';

import * as CRS from '../../api/CRS';

@Component({
  selector: 'assessment-group-summary-table',
  styleUrls:['../../shared/css/tables.scss'],
  templateUrl: './assessment-group-summary-table.html'
})
export class AssessmentGroupSummaryTable implements OnInit {

  @Input("narrow") narrow:boolean;
  @Output("on-click") onClick= new EventEmitter<CRS.RSAssessmentGroup>();

   constructor(
     public router: Router,
     private currentProject: CurrentProjectService, 
     private platformService: PlatformService,
     private currentAssessmentGroup: CurrentAssessmentGroupService) { }

   ngOnInit(): void {
   }

   public get project(): CRS.RSGetProject {
     return this.currentProject.dataItem;
   }

   public isSelected(item: CRS.RSAssessmentGroup) : boolean {
      if (this.currentAssessmentGroup.dataContext.isReady) {
          return this.currentAssessmentGroup.dataContext.id  == item.id;
      }
      return false;
   }

   public isOdd(idx:number) : boolean {
     return (idx % 2) == 1;
   }

   public openAssessmentGroup(item: CRS.RSAssessmentGroup) {
      if (this.onClick)
        this.onClick.emit(item);
   }

  public isValidForPlatform(ag: CRS.RSAssessmentGroup) :boolean  {
      let p =this.platformService.getCurrent(this.project.id);
      return ag.assessmentStatesDictionary[p] !== undefined;
  }

  public getRagForAssessmentGroup(assessmentGroup: CRS.RSAssessmentGroup) {
    if (!this.currentProject.dataContext.isReady)
        return 0;
    var platNo =this.platformService.getCurrent(this.project.id);
    var as =assessmentGroup.assessmentStates[platNo-1];
    return (as)? as.assessmentState : 0;
  }
}

import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor,NG_VALUE_ACCESSOR } from '@angular/forms';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { JobTypeSpec } from 'src/app/model/JobTypeSpec';

import * as CRS from '../../api/CRS';
    
@Component({
    selector: "task-disk-selector",
    templateUrl: './task-disk-selector.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TaskDiskSelector),
            multi: true
        }
    ]
})

export class TaskDiskSelector implements ControlValueAccessor {

    constructor(
        private currentProject: CurrentProjectService
    ) {
    } 
    
    @Input() public label: string;
    @Input() public placeholder: string;
    @Input() public disable: boolean;
    @Output() selectionChange = new EventEmitter<void>();

    public get projectVhds(): Array<CRS.RSVhd> {
        return this.currentProject.vhds;
    }
    
    public get isReady(): boolean {
        return this.currentProject.vhdsReady;
    }
    
    public emitChange() : void {
        this.selectionChange.emit();
    }

    onChange: any = () => {}
    onTouch: any = () => {}

    val= null; 
    set value(val){  
        if( val !== undefined && this.val !== val){
            this.val = val
            this.onChange(val)
            this.onTouch(val)
            this.emitChange();
        }
    }

    public get value() : number {
        return this.val;
    }
            
    writeValue(value: any){ 
        if (value && this.val) // has this.value been initialised
            this.value = value
        this.val= value;
    }

    registerOnChange(fn: any){
        this.onChange = fn
    }

    registerOnTouched(fn: any){
        this.onTouch = fn
    }
}

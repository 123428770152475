import * as CRS from '../api/CRS';
import { Constants } from '../api/Constants';
import { UtilService } from '../svc/utilService';

export class ConversionTaskLaunchInfo {
    public constructor(utilService: UtilService, data: CRS.RSGetConversionTaskLaunchInfo) {
        this._data = data;
        this._utilService = utilService;
    }

    private _utilService: UtilService;
    private _data : CRS.RSGetConversionTaskLaunchInfo;

    public get unsatisfiedPreconditions() : Array<ConversionTaskPrecondition> {
        return this.mergedData.filter(x=>!x.isSatisfied).map(x=>new ConversionTaskPrecondition(x));
    }
    public get satisfiedPreconditions() : Array<ConversionTaskPrecondition> {
        return this.mergedData.filter(x=>x.isSatisfied).map(x=>new ConversionTaskPrecondition(x));
    }
    public get anyUnsatisfied(): boolean {
        return this.mergedData.some(x=>!x.isSatisfied);
    }

    private get mergedData() : Array<CRS.RSConversionTaskLaunchInfoPrecondition> {
        var a0 = this._data && this._data.preconditions ? this._data.preconditions: [];
        var a1 = this._pvadCondition ? [ this._pvadCondition] : [];
        return a0.concat( a1);
    }

    public reset(jobType: number, data:CRS.RSGetConversionTaskLaunchInfo) {
        if (this._data==null)
            this._data = new CRS.RSGetConversionTaskLaunchInfo();
        this._data.preconditions= (data) ? data.preconditions: null;
        this._jobType = jobType;
        this.pvadConditionResolve();
    }

    private _jobType: number=-1;
    private _usePvad:boolean=false;
    private _pvadDirectory:string=null;
    private _pvadCondition:CRS.RSConversionTaskLaunchInfoPrecondition=null;

    public updatePvad(value:boolean) {
        this._usePvad=value;
        this.pvadConditionResolve();
    }

    public updatePvadDirectory(value:string) {
        this._pvadDirectory=value;
        this.pvadConditionResolve();
    }

    private pvadConditionResolve()    {
        if (this._jobType == Constants.JOBTYPE_VIRTUALISE && this._usePvad && !(this._pvadDirectory && this._utilService.isValidPath(this._pvadDirectory) || this._utilService.isValidPropertyPrefixedPath(this._pvadDirectory))) {
            this._pvadCondition = new CRS.RSConversionTaskLaunchInfoPrecondition();
            this._pvadCondition.id= "PVADDIR";
            this._pvadCondition.description="PVAD directory is not a valid path";
            this._pvadCondition.isSatisfied=false;
        }
        else {
            this._pvadCondition=null;
        }
    }
}

export class ConversionTaskPrecondition {
    public constructor(data: CRS.RSConversionTaskLaunchInfoPrecondition) {
        this.id = data.id;
        this.description = data.description;
    }

    public id:string;
    public description:string;
}
<div fxFlex fxLayout="row" fxFill class="sw-promo">
    <div fxFlex="40%"></div>
    <div fxFlex>
        <div class="sw-promo-text">
            <p>{{topParagraph}}</p>
            <ul>
                <li *ngFor="let b of bullets">{{b}}</li>
            </ul>
            <p>{{bottomParagraph}}</p>
        </div>
    </div>
</div>

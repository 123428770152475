import * as CRS from '../api/CRS';

export class XCheckVirtualMachineChangeItem {

    public static create(item: CRS.RSXCheckVirtualMachine) : XCheckVirtualMachineChangeItem {
        var ret = new XCheckVirtualMachineChangeItem();
        ret.item = item;
        return ret;
    }

    public item: CRS.RSXCheckVirtualMachine;
}
import { Component, OnInit, Input } from '@angular/core';
import { CurrentProjectService } from '../../svc/currentProjectService';
import * as CRS from '../../api/CRS';

@Component({
  selector: ProjectSettingsReporting.SELECTOR,
  templateUrl: './project-settings-reporting.html',
  styleUrls: ['../../shared/css/nav-bar.scss']
})
export class ProjectSettingsReporting implements OnInit {

  constructor(
    private currentProject: CurrentProjectService) { }

  ngOnInit(): void {
  }

  public static SELECTOR: string = "rpt";

  public reportingFileFormats : Array<string> = [ "PDF", "DOCX"];

  public compareObjects(o1: any, o2: any): boolean {
    return o1 === (o2||"[NULL]"); // Look at SettingsService init of rpt config options
  }

  public get project():  CRS.RSGetProject {
    return this.currentProject.dataItem;
  }

  public get projectIsReady(): boolean {
    return this.currentProject.dataContext.isReady;
  }

  public canEdit(): boolean {
    return this.currentProject.canEdit();
  }

  public get reportingConfigurationTypes() : Array<any> {
    return this.currentProject.reportingConfigurationTypes;
  }

  public get reportingConfigurationTypesReady() : boolean {
    return this.currentProject.reportingConfigurationTypesReady;
  }

  public defaultAppIconImageChanged(v:string) {
    this.currentProject.updateDefaultAppIcon(v);
  }

  public reportingConfigurationChanged(){
    var v = this.project.reportingConfiguration;
    if (v=="[NULL]")
      v=null; 
    this.currentProject.updateReportingConfiguration(v);
  }

  public readmeIceOnOriginalPackageChanged() {
    var vv = (this.project.readmeIceOnOriginalPackage) ? "true" : null;
    this.currentProject.updateReadmeIceOnOriginalPackage(vv);
  }

  public readmeIceErrorsOnlyChanged() {
    var vv = (this.project.readmeIceErrorsOnly) ? "true" : null;
    this.currentProject.updateReadmeIceErrorsOnly(vv);
  }

  public reportingFileFormatChanged() {
    var v = this.project.reportingFileFormat;
    this.currentProject.updateReportingFileFormat(v);
  }

    public showEffortInAppCompatReportChanged() {
    var vv = (this.project.showEffortInAppCompatReport) ? "true" : null;
    this.currentProject.updateShowEffortInAppCompatReport(vv);
  }

}


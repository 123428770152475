import { Component, OnInit, Input } from '@angular/core';
import { UtilService } from '../../svc/utilService';
import { CurrentProjectService } from 'src/app/svc/currentProjectService';
import { INPUTEDITARGS } from '../../model/INPUTEDITARGS';
import { Constants } from '../../api/Constants';

import * as CRS from '../../api/CRS';

@Component({
  selector: Constants.ROUTE_PROJECTSETTINGSVHDS,
  templateUrl: './project-settings-vhds.html',
  styleUrls: ['../../shared/css/nav-bar.scss']
})
export class ProjectSettingsVHDs implements OnInit {

  constructor(
      private currentProject: CurrentProjectService,
      private utilService:UtilService) { }

  ngOnInit(): void {
  }

  public get project():  CRS.RSGetProject {
    return this.currentProject.dataItem;
  }

  public get projectIsReady(): boolean {
    return this.currentProject.dataContext.isReady;
  }

  public canEdit(): boolean {
    return this.currentProject.canEdit();
  }

  public get projectVhds(): Array<CRS.RSVhd> {
    return this.currentProject.vhds;
  }

  public get projectVhdsReady(): boolean {
    return this.currentProject.vhdsReady;
  }

  public vhdChange(jobType:number, subType:number) : void {
      var vhd=null;
      switch(jobType)
      {
          case 1: vhd = this.project.repackagingVhd; break;
          case 2: vhd = this.project.virtualisationVhd; break;
          case 20: vhd = this.project.msixVhd;break;
          case 25: vhd = this.project.liquidwareVhd;break;
          default:
              if (subType==null)
                  vhd = this.project.defaultTestingVhd;
              else
                  vhd = this.project.msixTestingVhd;
              break;
      }
       this.currentProject.updateConversionTypeVhd(jobType, subType|0, vhd);
  }

  public vhdUsernameMapChanged(args: INPUTEDITARGS) {
    this.currentProject.updateVhdUsernameMap(args.value);
  }
}

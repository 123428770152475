import { Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Constants } from 'src/app/api/Constants';
import { UtilService } from 'src/app/svc/utilService';
import { GridAssistant } from 'src/app/model/gridAssistant';

import * as CRS from '../../api/CRS';
import * as SVC from '../../api/SVC';

@Component({
    selector: Constants.ROUTE_ADMINCHECKLIBRARY,
    templateUrl: './admin-check-library.html',
    styleUrls: ['../../shared/css/view.scss', '../../shared/css/tables.scss']
  })
  
export class AdminCheckLibrary implements OnInit, OnDestroy {

    static selectedLibrary: string = '';
    public classReference = AdminCheckLibrary;

    constructor(
        private injector: Injector,
        private router: Router, 
        public route: ActivatedRoute,
        private xadminService: SVC.AdminService,
        public utilService: UtilService,
    ) {
        this.classReference.selectedLibrary = ''
    }

    public gridAssist: GridAssistant<CRS.RSAdminGetChecks, CRS.RSAdminCheck>;

    ngOnInit(): void {
        this.gridAssist = new GridAssistant<CRS.RSAdminGetChecks, CRS.RSAdminCheck>(Constants.ROUTE_ADMINCHECKLIBRARY, this.injector);
        this.gridAssist.setColumns(   [         
                { id: 'id',   name: 'Id',   field: 'id',   sortable: true, width: 200 },
                { id: 'name', name: 'Name', field: 'name', sortable: true, width: 400 }
            ]
        );
        this.gridAssist.setDetailColumns([ "Id" ]);
        this.gridAssist.allowRowSelection((r)=>this.openCheck(r));
        this.gridAssist.setUpdate(()=>this.update());
    }

    ngOnDestroy() : void {
        this.gridAssist.dispose();
    }
    
    private update(): void {
          this.xadminService.adminGetChecks().then((response) => {
            this.gridAssist.mergeDataResponse(response, "items", "id", CRS.RSAdminCheck );
        }, () => {
            console.error('Server data couldn\'t be loaded');
        });
    }

    public openCheck(check: CRS.RSAdminCheck) {
       
        if (check) {
            var options = {};
            const routerPath = this.router.url.split("/")
            if(routerPath.length > 0){
                routerPath.pop();
                const parentUrl = routerPath.join("/")
                if(parentUrl == "/" + Constants.ROUTE_ADMINDASHBOARD){
                    if (this.gridAssist.stateHelperService.details.checkId == check.id)
                    options = { reload: true };
                }
            }
            this.classReference.selectedLibrary = check.id;
            this.router.navigate([Constants.ROUTE_ADMINCHECKDETAIL_MAIN, check.id, options], {relativeTo: this.route});
        }
    }

    static updateSelectedLibrary(id: string) {
        this.selectedLibrary = id;
    }

}


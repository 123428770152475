import {PollingService} from '../../svc/pollingService';
import {NavStateService} from '../../svc/navStateService';
import {AppCommand} from '../../model/AppCommand';
import {BreadcrumbService} from '../../svc/breadcrumbService'
import {MenuService} from '../../svc/menuService';

import { ActivatedRoute, Router } from '@angular/router';

import * as STHS from '../../svc/stateHelperService';

import * as UTIL from '../../svc/utilService';
import * as CRS from '../../api/CRS';
import * as SVC from '../../api/SVC';
import { Constants } from 'src/app/api/Constants';
import { Component, OnInit, AfterContentChecked } from '@angular/core';
import { AdminPatchGroups } from '../admin-patch-groups/admin-patch-groups';

@Component({
    selector: "admin-patch-group-item",
    templateUrl: './admin-patch-group-item.html',
    styleUrls: ['../../shared/css/admin.scss', '../../shared/css/tables.scss', '../../shared/css/view.scss']
})

export class AdminPatchGroupItem implements OnInit, AfterContentChecked{

    static selectedKB: string = '';
    static selectedItem: string = '';
    public classReference = AdminPatchGroupItem;

    constructor(
        private router: Router, 
        private route: ActivatedRoute,  
        private xadminService: SVC.AdminService,
        private stateHelperService: STHS.StateHelperService,
        private breadcrumbService: BreadcrumbService,
        private menuService: MenuService

    ) {
        this.menuService.register(Constants.MENU_BACK, this.backCommand, `P-${Constants.ROUTE_ADMINPATCHGROUPS}`);
        this.classReference.selectedKB = '';
        const urlArray = this.router.url.split("/");
        if(urlArray.length > 5) this.classReference.selectedItem = urlArray[5];
        else this.classReference.selectedItem = '';
    }

    public stateHelperInstance: STHS.StateHelperInstance;
    public patchGroupItem: CRS.RSAdminGetPatchGroupItem=null;

    ngOnInit() {
        this.stateHelperInstance= this.stateHelperService.register(Constants.ROUTE_PATCHGROUP_MAIN);
        this.update();
    }
    public log(value) {console.log(value)}

    ngAfterContentChecked(){
        const urlArray = this.router.url.split("/");
        if(urlArray.length > 5 && this.classReference.selectedItem != urlArray[5]) {
            this.classReference.selectedItem = urlArray[5];
            this.xadminService.adminGetPatchGroupItem(this.classReference.selectedItem).then((response) => {
                this.patchGroupItem = response.data;
                AdminPatchGroups.updateSelectedPatchgroup(this.patchGroupItem.patchGroupId)
                this.breadcrumbService.setPatchGroup(this.patchGroupItem);
            }, () => {
                console.error('Patch group item data couldn\'t be loaded');
            });
        }
    }

    public openKB(cc: CRS.RSAdminCheckCandidateGrp) {
        this.classReference.selectedKB = cc.remediationId;
        this.router.navigate([Constants.ROUTE_PATCHGROUPITEMDETAIL_MAIN, cc.remediationId], {relativeTo: this.route});
    }

    private update(): void {

        this.xadminService.adminGetPatchGroupItem(this.classReference.selectedItem).then((response) => {
            this.patchGroupItem = response.data;
            AdminPatchGroups.updateSelectedPatchgroup(this.patchGroupItem.patchGroupId)
            this.breadcrumbService.setPatchGroup(this.patchGroupItem);
        }, () => {
            console.error('Patch group item data couldn\'t be loaded');
        });
    }

    static updateSelectedItem(id: string) {
        this.selectedItem = id;
    }

    static updateSelectedServer(id: string) {
        this.selectedKB = id;
    }

    private backCommand: AppCommand = AppCommand.create(
        ()=> {
            return true;
        },
        ()=> {
            this.classReference.selectedItem = '';
            this.router.navigate(["/" + Constants.ROUTE_ADMINDASHBOARD + "/" + Constants.ROUTE_ADMINPATCHGROUPS]);
        }
    )

}

import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Chart } from 'chart.js';
import { UtilService } from 'src/app/svc/utilService';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { XChartData } from '../../model/PatchExt';

import * as CRS from '../../api/CRS';

@Component({
  selector: 'assessment-group-charts',
  templateUrl: './assessment-group-charts.html',
  styleUrls: ['assessment-group-charts.scss']
})
export class AssessmentGroupCharts implements OnInit {

    constructor(
        private utilService: UtilService,
        public currentProject: CurrentProjectService) { }

    @ViewChild("chartRoot") chartRoot: ElementRef;

    ngOnInit(): void {
        this.selectedCharts = this.utilService.selectedAssessmentGroupCharts;
        this.initialSelectedCount=this.selectedCharts.length;
    }

    ngAfterViewInit():void {
//        this.observe();
        this.ensureAssGroupCharts();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.sizeChartCells();
    }

    public static SELECTOR : string = "rpt";

    private obs: MutationObserver=null;
    private startupPhase=true;
    private chartCanvasCount = 6;
    private initialSelectedCount=-1;

    public charts : { [pid: string] : Chart; } = {};
    public selectedCharts: Array<string> = null;

    public getActiveAssessmentGroups() {
        if (this.currentProject.dataContext.isReady)
            return this.currentProject.dataItem.assessmentGroups;//.filter(x=>!x.isDisabled)
        return null;
    }

    public assessmentGroupSelectionChanged() {
        this.utilService.selectedAssessmentGroupCharts = this.selectedCharts;
        this.ensureAssGroupCharts();
        //this.stopMutationObserver();
    }

    // private observe(): void {
    //     // Have to wait for the VM html elements to be rendered before we can hang a chart off them.
    //     this.obs = new MutationObserver((x) => {
    //         this.ensureAssGroupCharts();
    //         this.stopMutationObserver();
    //     });
    //     let c = { attributes: true, subtree: true, childList: true};
    //     var dr = this.chartRoot.nativeElement;
    //     this.obs.observe(dr, c);
    // }

    private getChartId(idx: number): string {
        return "chart-" + idx;
    }

    // Once the initial set of ags have been rendered, we don't want to watch for any more dom changes.
    // We can manage the updates to the charts through the model change after that.
    // private stopMutationObserver() : void {
    //     if(!this.startupPhase || !this.currentProject.dataContext.isReady)
    //          return;
    //     var done=0;
    //     for(var i=0;i<this.chartCanvasCount;i++){
    //         if (this.charts[this.getChartId(i)])
    //             done++;
    //     };
    //     if (done == this.initialSelectedCount) {
    //         this.obs.disconnect();
    //         this.startupPhase=false;
    //     }
    // }

    private ensureAssGroupCharts() : void {
        if (!this.selectedCharts) 
            return;
        var platNo = this.currentProject.platformNumber;
        var slot=0; // this is the actual slot where the chart will be placed
        for(var i=0; i<this.chartCanvasCount;i++) {
            var chid = this.getChartId(slot);
            var ctx = <HTMLCanvasElement>document.getElementById(chid);
            var agId = (i < this.selectedCharts.length) ? this.selectedCharts[i] : null;
            var ags = (agId)
                ? this.currentProject.dataItem.assessmentGroups.filter(a=>a.id == agId)
                : [];
            var ag0 = (ags.length>0 && ags[0].assessmentStatesDictionary[platNo]) ? ags[0] : null;
            var cht = this.charts[chid];
            if (cht)
                cht.destroy();
            ctx.innerHTML="&nbsp;";
            ctx.append(`<canvas id="chart-${i}"></canvas>`);
            if (ag0) {
                var chartData=this.getChartData(ag0,platNo);
                var titles=[ag0.name];
                this.charts[chid] = this.setupChart(ctx, titles, chartData);
                slot++;
            }
            else {
                if (cht) {
                    delete this.charts[chid]
                }
            }
        }
        this.sizeChartCells();
    }

    private sizeChartCells() {
        var chartDiv = <HTMLDivElement>this.chartRoot.nativeElement;
        var num = (this.selectedCharts.length<3) ? 3 : this.selectedCharts.length;
        var siz = (chartDiv.clientWidth * 0.8) / num;
        if (siz<200)
            siz=200;
        if (siz>300)
            siz=300;
        for(var i=0; i<this.chartCanvasCount; i++) {
            var ctx = <HTMLCanvasElement>document.getElementById(this.getChartId(i));
            if (i<this.selectedCharts.length) {
                ctx.style.height = ctx.style.width = `${siz}px`;
                ctx.parentElement.style.display="";
            }
            else {
                ctx.parentElement.style.display="none";
            }
        }
    }

    private setupChart(ctx:HTMLCanvasElement, titles: string[], chartData: XChartData) : Chart {
        var data = {
            labels: chartData.labels,
            datasets: [
            {
                data: chartData.data,
                backgroundColor: chartData.colours
            }]
            };

        var options =  {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            plugins: {
                labels: { render: 'label' },
            //     centretitle: {title: titles, font: '16px Ubuntu', fillStyle:'#000'}
            },
            title: {
                display: true,
                text: titles,
                fontSize:14,
                fontFamily:'Ubuntu'
            },
        };
        
        return new Chart(ctx, {
            type: 'doughnut',
            data:data,
            options: options
            });
    }

    private getChartData(assessmentGroup: CRS.RSAssessmentGroup, platNo: number) : XChartData {
        var cnts =[];
        var asd = assessmentGroup.assessmentStatesDictionary[platNo];
        cnts.push((asd) ? asd.greenAppCount : 0);
        cnts.push((asd) ? asd.amberAppCount : 0);
        cnts.push((asd) ? asd.blueAppCount : 0);
        cnts.push((asd) ? asd.redAppCount : 0);
        let cd = new XChartData();
        cd.colours = ['#69BC45', '#E3943C', '#4877AA', '#DD6E5D'];
        cd.labels =["Green","Amber","Blue", "Red"];
        cd.data = cnts;
        return cd;           
    }


}

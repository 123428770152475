import { Component, OnInit, Input } from '@angular/core';
import { UtilService } from '../../svc/utilService';

@Component({
  selector: 'promo-info',
  templateUrl: './promo-info.html',
  styleUrls: ['./promo-info.scss']
})
export class PromoInfo implements OnInit {

  constructor(
      private utilService: UtilService) { }

    @Input('top-paragraph') topParagraph: string;
    @Input('bottom-paragraph') bottomParagraph: string;
    @Input('bullets') bullets: Array<string>;
    @Input('img') img : string;

  ngOnInit(): void {
  }

}



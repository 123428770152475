<div>

    <div class="sw-bold sw-upper">
         <h3>Test Published Application</h3>
    </div>

    <button mat-raised-button color="primary" [disabled]="!publishedTestCommand.can" (click)="publishedTestCommand.execute()">Launch</button>
    
    <div class="sw-pad"></div>

</div>

import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { CommandCollection } from 'src/app/commands/command-collection';
import { SetApplicationSourcePathData } from 'src/app/model/SetApplicationSourcePathData';
import { CurrentProjectService } from 'src/app/svc/currentProjectService';

@Component({
    selector: 'set-application-source-path-dialog',
    templateUrl: 'setApplicationSourcePathDialog.html'
})
export class SetApplicationSourcePathDialog {

    constructor(
        private dialogRef: MatDialogRef<boolean>,
        private currentProject: CurrentProjectService
    ) {
        this.stateCtrl = new FormControl();
        this.stateCtrl.valueChanges.subscribe(val => {
            this.filterStates(val);
        });
    }

    filterStates(name: string) {
        if (name) {
            this.currentProject.searchPackageSourceFolders(name).then((d)=>{
                this.filteredStates = new Observable(observer => {
                    observer.next(d.data.items);
                });     
            });
        }
    }

    public stateCtrl: FormControl;
    public filteredStates: Observable<any[]>;
    public states: Array<any> = [  ];
    public errorMsg:string = null;

    public get details() : SetApplicationSourcePathData {
        return CommandCollection.SetApplicationSourcePath.data;
    }

    $onInit() {
        CommandCollection.SetApplicationSourcePath.resetData();
    }

    isDataReady() : boolean {
        return true;
    }

    isValid(): boolean {
        return CommandCollection.SetApplicationSourcePath.isDataValid();
    }

    closeDialog() {
        this.errorMsg=null;
        CommandCollection.SetApplicationSourcePath.apply(this.details.sourceMediaPath).then((r)=> {
            if(r.status && r.status.errorCode == 0) {
                this.dialogRef.close(this.details);
                CommandCollection.SetApplicationSourcePath.afterApply();
            }
            else if (r.status) {
                this.errorMsg = r.status.message;
            }
        });
    }

    cancelDialog() {
        this.dialogRef.close();
    }
}


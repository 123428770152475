import { Injectable, Injector } from '@angular/core';
import { Observable,pipe, throwError } from 'rxjs';
import { catchError,retry } from 'rxjs/operators';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from './svc/errorService';
import { Constants } from './api/Constants';

@Injectable({
    providedIn: 'root'
})
export class AppHttpInterceptHandler implements HttpInterceptor {

  constructor(private injector: Injector) { }

  intercept(req: HttpRequest<any>, next: HttpHandler):
  
    Observable<HttpEvent<any>> {
      var retryIterations = 1;//(req.method == "GET") ? 3: 1;
      return next.handle(req).pipe(retry(retryIterations),catchError((error)=>{
        return this.errorHandler(error);
      }));
    }

    errorHandler(error: HttpErrorResponse) {
      // const errorService = this.injector.get(ErrorService);
      // var pnfError = (error.error && error.error.startsWith("QC.Core.Exceptions.QProjectNotFoundException"));
      // if (!pnfError) {
      //   errorService.show(Constants.ERRTYPE_HTTP, error.message, error.statusText, error.status);
      // }
      return throwError(error);
    }
}

//default case: print, log, toast, etc...
//return throwError(error.message || error);

// return next.handle(request).do((event: HttpEvent<any>) => {
//   if (event instanceof HttpResponse) {
//     // do stuff with response if you want
//   }
// }, (err: any) => {
//   if (err instanceof HttpErrorResponse) {
//     if (err.status === 401) {
//       // redirect to the login route
//       // or show a modal
//     }
//   }
// });
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { CurrentConversionJobService } from '../../svc/currentConversionJobService';
import { StateHelperService } from '../../svc/stateHelperService';

import * as CRS from '../../api/CRS';
import { IdType } from 'src/app/api/Enum';

@Component({
  selector: 'conversion-task-log-view',
  templateUrl: './conversion-task-log-view.html',
})
export class ConversionTaskLogView implements OnInit, OnDestroy {

    constructor(
        private stateHelperService: StateHelperService,
        private currentConversionJob : CurrentConversionJobService
    ) { }

    @Input('light') public light:boolean;

    public showLog:boolean=false;
    private subscriptions: Array<Subscription> =new Array<Subscription>();

    ngOnInit(): void {
        this.subscriptions.push(this.stateHelperService.onIdChange().subscribe((a)=> {
            if (a.type == IdType.Job) {
                this.showLog=false;
            }
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s)=>s.unsubscribe());
    }

    public get log() : Array<CRS.RSTaskLogLine>  {
        return this.currentConversionJob.log;
    }

    public initiateLogLoad() {
        this.currentConversionJob.recallLog(this.showLog);
    }

}

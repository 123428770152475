import {DLITEMSEC} from './DLITEMSEC';
import * as CRS from '../api/CRS';
import { _supportsShadowDom } from '@angular/cdk/platform';
import { UtilService } from '../svc/utilService';

export class DLITEM {
    constructor(item: CRS.RSDriveLinkMapItem, utilService: UtilService) {
        this.item = item;
        this.id = item.applicationId;
        this.isChecked=false;
        this.importedTransforms =item.importedTransforms;
        this.secondaries = item.secondaries.map((x,i)=>new DLITEMSEC(x, i, this));
        this.selectedSecondaries=item.selectedSecondaries
        this.dateCreatedString = utilService.getDateAsStringWithTime(item.dateCreated);
    }
    public id:string;
    public item: CRS.RSDriveLinkMapItem;
    public importedTransforms: string;
    public isChecked: boolean;
    public dateCreatedString: string;
    public secondaries: Array<DLITEMSEC>;
    public get secondariesFlat(): string {
        return this.secondaries.map(x=>x.hash).join(';');
    }
    public selectedSecondaries : Array<string>=[];
    public get selectedSecondariesFlat(): string {
        return this.selectedSecondaries.join(";");
    }
    public set selectedSecondariesFlat(value:string) {
        //do nothing
    }
    public getIndex(hash:string):number{
        var ret=-1;
        this.secondaries.forEach((v,i)=>{
            if (v.hash == hash)
                ret= i;
        });
        return ret;
    }   

    public isSelected(item: DLITEMSEC): boolean {
        return item && this.selectedSecondaries.indexOf(item.hash)!=-1;
    }

    public getSecondaryState(): string {
        let ret="";
        if (this.item.importedTransforms)
            return this.importedTransforms;
            
        if (this.secondaries) {
            switch(this.secondaries.length)
            {
                case 0:
                    break;
                case 1:
                    let x0 = this.secondaries[0];
                    ret= x0.item + ((this.selectedSecondaries.length==1) ? " selected" : "");
                    break;
                default:
                    let cnt = this.selectedSecondaries.length;
                    ret = this.secondaries.length + " items. " + cnt + " selected";
                    break;
            }
        }
        return ret;
    }

    public setDefaultSecondaries(): void {
        if (this.secondaries && this.secondaries.length==1) {
            if (this.selectedSecondaries.length==0) { // not been checked by user previously
                this.selectedSecondaries = [this.secondaries[0].hash];
            }
        }
    }

    public addSecondary(item: DLITEMSEC) {
        this.selectedSecondaries.push(item.hash);
    }

    public removeSecondary(item: DLITEMSEC) {
        var idx = this.selectedSecondaries.indexOf(item.hash);
        if (idx>=0)
          this.selectedSecondaries.splice(idx , 1);
    }

    public moveUp(index: number) : void {
        var secItem = this.secondaries[index];
        var prevItem = this.secondaries[index - 1];
        this.secondaries.splice(index, 1);
        this.secondaries.splice(index - 1, 0, secItem);
        var sidx = this.selectedSecondaries.indexOf(secItem.hash);
        if (this.isSelected(prevItem)) {
            this.selectedSecondaries.splice(sidx, 1);
            this.selectedSecondaries.splice(sidx - 1, 0, secItem.hash);
        }
    }
}


import * as Enums from '../api/Enum';
import * as CRS from '../api/CRS';

export class AppState {

    public static create(state: Enums.LoadState) : AppState {
        var ret = new AppState();
        ret.loadState = state;
        return ret;
    }

    public static createFromApp(app: CRS.RSGetApplication)  : AppState {
        var ret =new AppState();
        ret.loadState = Enums.LoadState.Ready;
        ret.isMsi = app.isMsi;
        return ret;
    }

    public static createFromJob(job: CRS.RSGetActionDetails)  : AppState {
        var ret =new AppState();
        ret.loadState = Enums.LoadState.Ready;
        ret.jobType = (job.task) ? job.task.jobType : -1;
        ret.jobStatus = (job.task) ? job.task.status : -1;
        return ret;
    }

    public loadState : Enums.LoadState;
    public isMsi: boolean;

    public jobStatus: Enums.SpineStatus;
    public jobType:number;
   
}
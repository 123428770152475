<div fxLayout="row" fxFill class='full-height-bs'>

    <spinner fxFlex *ngIf="!isDataReady()"></spinner>
    
    <div fxFlex *ngIf="isDataReady()" class="sw-segment" style="overflow:auto">

        <mat-tab-group>

            <mat-tab label="Processes {{pstr}}">
                <table class="full-width-bs sw-pad">
                    <thead>
                        <tr>
                            <th style="width:40px"><mat-icon>queue</mat-icon></th>
                            <th style="width:70px">Instance</th>
                            <th style="width:60px">Server</th>
                            <th style="width:80px">ProcessId</th>
                            <th>User</th>
                            <th style="width:100px"><mat-icon>av_timer</mat-icon></th>
                            <th style="width:100px">Assembly</th>
                            <th style="width:150px">Msg Type</th>
                            <th style="width:100px">Job Type</th>
                            <th style="width:50px"><mat-icon>hourglass_empty</mat-icon></th>
                            <th style="width:80px"><mat-icon>bar_chart</mat-icon></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let r of tasks.items">
                            <td><mat-icon *ngIf="r.type=='Queue'">done</mat-icon></td>
                            <td>{{r.instanceId}}</td>
                            <td>{{r.serverId}}</td>
                            <td><span *ngIf="r.processId > 0">{{r.processId}}</span></td>
                            <td>{{r.user}}</td>
                            <td>{{r.timeExecuting}}</td>
                            <td>{{r.name}}</td>
                            <td>{{r.messageType}}</td>
                            <td>{{r.jobType}}</td>
                            <td>{{r.processorPercentage}}</td>
                            <td><mat-icon *ngIf="r.jobExecutingVerified">done</mat-icon></td>
                        </tr>
                    </tbody>
                </table>
            </mat-tab>

            <mat-tab label="Queue {{qstr}}">
                <table class="full-width-bs sw-pad">
                    <thead>
                        <tr>
                            <th style="width:40px">Router</th>
                            <th style="width:70px">Server</th>
                            <th style="width:100px"><mat-icon>av_timer</mat-icon></th>
                            <th style="width:60px">Payload</th>
                            <th style="width:80px">User</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let r of tasks.queues">
                            <td>{{r.routerKey}}</td>
                            <td>{{r.serverId}}</td>
                            <td>{{r.queueTime}}</td>
                            <td>{{r.payloadType}}</td>
                            <td>{{r.user}}</td>
                        </tr>
                    </tbody>
                </table>
            </mat-tab>

            <mat-tab label="Timer Activity {{tstr}}">
                <table class="full-width-bs sw-pad" >
                    <thead>
                        <tr>
                            <th style="width:70px">Server</th>
                            <th>Timer</th>
                            <th>Interval</th>
                            <th style="width:120px">Last Executed</th>
                            <th style="width:120px">Overdue</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let t of tasks.timerActivities">
                            <td>{{t.serverId}}</td>
                            <td>{{t.timerId}}</td>
                            <td>{{t.interval}}</td>
                            <td>{{t.lastExecutedExpression}}</td>
                            <td>{{t.overdueExpression}}</td>
                        </tr>
                    </tbody>
                </table>
            </mat-tab>

        </mat-tab-group>

    </div>

</div>

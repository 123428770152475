<div fxLayout="row" class="display-block">

   <div fxLayout='column' fxFlex class='sw-viewcontainer sw-viewcontainer-withtoolbar no-side-border' [ngClass]='{"sw-viewcontainer-collapsed": stateHelperInstance.isNotCurrent()}' style="overflow: inherit;">

    <div class='sw-viewcontainer-contents sw-contentview'>

        <div fxLayout="column">

            <div class="sw-input-container">
                <div>
                    <label>Id</label>
                </div>
                <div>
                    <span>{{checkId}}</span>
                </div>
            </div>

            <div [ngClass]="{'display-none' : !stateHelperInstance.shouldMinimise()}">
                <input-edit datatype="STRING" label="Name" [value]="check?.name"></input-edit>
                <input-edit datatype="MSTRING" label="Description" [value]="check?.description"></input-edit>
                <input-edit datatype="STRING" label="Package Types" [value]="check?.packageTypes"></input-edit>    
            </div>

            <div fxLayout="column" *ngIf="!shouldMinimise()">
                <h4 class="h4-adjustment">Inclusion From/To</h4>    
                <div *ngFor="let incexp of check?.inclusionExpressions">
                    <div fxLayout="row">
                        <input-edit [datatype]="'FROMTO'" [value]="incexp"></input-edit>
                    </div>
                </div>
            </div>

            <div fxLayout="column">
                <h4 class="h4-adjustment">Rules</h4>
                <table class="sw-table-collapsable">
                    <thead style="display: table-header-group !important;">
                        <tr>
                            <th>Id</th>
                            <th>RAG</th>
                            <th class="display-table-cell">Category</th>
                            <th class="display-table-cell">Message</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="check">
                        <tr *ngFor="let rule of check?.rules">
                            <td><button mat-button class="button-link-narrow button-lower md-primary" (click)="openRule(rule)">{{rule?.id}}</button></td>
                            <td><rag-column [explicit-value]="rule?.rag" [admin-check-detail]="checkId"></rag-column></td>
                            <td class="display-table-cell">{{rule?.category}}</td>
                            <td class="display-table-cell">{{rule?.message}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>


        </div>

    </div>

   </div>

    <div fxLayout='column' fxFlex *ngIf='stateHelperInstance.isNotCurrent()'>
        <router-outlet></router-outlet>
    </div>

</div>
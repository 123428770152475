import { Injector } from '@angular/core';
import { take} from 'rxjs/operators';
import { CurrentAssessmentGroupService } from "../svc/currentAssessmentGroupService";
import { MenuService } from '../svc/menuService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';
import { DialogService } from '../svc/dialogService';
import { StateHelperService } from '../svc/stateHelperService';

export class EnableCheckCommand {

    constructor(
        private injector: Injector
    ) {
        this.menuService = this.injector.get(MenuService);
        this.stateHelperService = this.injector.get(StateHelperService);
        this.dialogService = this.injector.get(DialogService);
        this.currentAssessmentGroup = this.injector.get(CurrentAssessmentGroupService);
        
        this.menuService.register(Constants.MENU_ENABLE_CHECK, this.command, Constants.ROUTE_PROJECTREPORTCHECKDETAIL_MAIN )
    }

    private stateHelperService: StateHelperService;
    private menuService: MenuService;
    private dialogService: DialogService;
    private currentAssessmentGroup: CurrentAssessmentGroupService;

    private command: AppCommand = AppCommand.createWithShow(
        ()=> {
            if (!this.currentAssessmentGroup.dataContext.isReady || !this.currentAssessmentGroup.isCurrentCheckEnabledResolved)
                return false;
            return this.currentAssessmentGroup.dataItem.isEnabled && !this.currentAssessmentGroup.currentCheckEnabled;
        },
        ()=> {
            var chkId = this.stateHelperService.details.checkId;
            var lst = this.currentAssessmentGroup.dataItem.checks.filter(x=>x.checkId == chkId);
            if (lst.length>0) {
                var chkName =lst[0].name;
                var txts =  [ `Are you sure that you want to enable the check '${chkName}'?`];
                this.dialogService.showYesNoMessage("Enable Check",txts)
                .pipe(take(1))
                 .subscribe((ans)=>{
                    if (ans){
                        this.currentAssessmentGroup.enableCheck(chkId);
                    }
                });
            }
        },
        ()=> {
            var ret= this.currentAssessmentGroup.dataContext.isReady 
                && this.currentAssessmentGroup.dataItem.isEnabled 
                && this.currentAssessmentGroup.isCurrentCheckEnabledResolved 
                && !this.currentAssessmentGroup.currentCheckEnabled;
            return ret;
        }
    );


}


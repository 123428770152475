import { Injector } from '@angular/core';
import { CurrentProjectService } from "../svc/currentProjectService";
import { MenuService } from '../svc/menuService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';
import { UtilService } from '../svc/utilService';
import { StateHelperService } from '../svc/stateHelperService';

export class ToggleVirtualMachinesPanelCommand {

    constructor(
        private injector: Injector
    ) {
        this.menuService = this.injector.get(MenuService);
        this.currentProject = this.injector.get(CurrentProjectService);
        this.stateHelperService = this.injector.get(StateHelperService);
        this.utilService = this.injector.get(UtilService);

        this.menuService.register(Constants.MENU_TOGGLE_SHOW_VMS_PANEL, this.cmd, Constants.ROUTE_PROJECTXCHECK);
        this.currentProject.onXCheckVirtualMachinesLoaded().subscribe((x)=>{
            this.hasVms = x;
        });
    }

    private hasVms:boolean=false;

    private stateHelperService: StateHelperService;
    private currentProject: CurrentProjectService;
    private utilService: UtilService;
    private menuService: MenuService;

    private cmd: AppCommand = AppCommand.createWithShow(
        ()=> {
            return true;
        },
        ()=> {
            this.utilService.showVmsPanel = !this.utilService.showVmsPanel;
        },
        ()=> {
            return true;
        }
    );

}

<!-- <button mat-button (click)="test()">redraw</button> -->
<angular-slickgrid  [gridId]="gridId" gridHeight="100%" gridWidth="100%"
    [columnDefinitions]="gridColumnDefs" 
    [gridOptions]="gridOptions"
    [dataset]="issues"
    (onAngularGridCreated)="appAngularGridReady($event)"
    (onGridStateChanged)="appGridStateChanged($event)" >
</angular-slickgrid>



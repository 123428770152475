import { Injector } from '@angular/core';
import { ViewSASKeyCommand } from '../commands/view-sas-key';
import { InitiateDriveScanCommand } from '../commands/initiate-drive-scan';
import { QueueImportCommand } from './queue-import';
import { ViewActivityCommand } from './view-activity';
import { DeleteApplicationCommand } from './delete-application';
import { UpdateAppIdCommand } from './update-appid';
import { DeleteProjectCommand } from './delete-project';
import { UpdateAppCompatReportCommand} from './update-app-compat-report';
import { DisableAssessmentGroupCommand } from './disable-assessment-group';
import { EnableAssessmentGroupCommand } from './enable-assessment-group';
import { ToggleShowFixedIssuesCommand } from './toggle-show-fixed-issues';
import { EnableCheckCommand } from './enable-check';
import { DisableCheckCommand } from './disable-check';
import { ReportOptionsCommand } from './report-options';
import { ResetApplicationActionCommand } from './reset-application-action';
import { ApplyFixesCommand } from './apply-fixes';
import { UnpublishCommand } from './unpublish-application';
import { PublishCommand} from './publish-application';
import { EditPackageCommand } from './edit-package';
import { PublishedTestCommand } from './published-test';
import { ToggleVirtualMachinesPanelCommand } from './toggle-virtual-machines-panel';
import { CreateXCheckVirtualMachineCommand } from './create-xcheck-virtual-machine';
import { CreateXCheckPatchTestCommand } from './create-xcheck-patch-test';
import { ViewPackageCommand } from './view-package';
import { ViewPackageMultiCommand } from './view-package-multi';
import { AddApplicationCommand } from './add-application';
import { SetApplicationSourcePathCommand } from './setApplicationSourcePath';
import { TaskReleaseCommand } from './task-release';
import { CreateConversionTaskCommand } from './create-conversion-task';
import { ApplicationDocumentationCommand } from './application-documentation';
import { ToggleViewEmptyTablesCommand} from './toggle-view-empty-tables';
import { ToggleViewInternalTablesCommand } from './toggle-view-internal-tables';
import { SavePackageCommand } from './save-package';
import { PackageFindCommand } from './package-find';
import { ViewPackageSummaryInformationCommand } from './view-package-summary-information';
import { PackageFindNextCommand } from './package-find-next';
import { PackageFindPrevCommand } from './package-find-prev';
import { AdminAssGrpsBackCommand } from './admin-assgrps-back';
import { AdminAssGrpsChkBackCommand } from './admin-assgrps-chk-back';
import { AdminClearCacheCommand } from './admin-clear-cache';
import { AdminRemotingResetCommand } from './admin-remoting-reset';
import { AdminRefreshMetricsCommand } from './admin-refresh-metrics';
import { IgnoreIssuesCommand } from './ignore-issues';
import { RemoveFailedAppsCommand } from './remove-failed-apps';
import { ImportMsiExtractsCommand } from './import-msi-extracts';
import { OpenProjectSelectorCommand } from './open-project-selector';
import { ClickerScriptEditCommand } from './clicker-script-edit';
import { RemotingWindowCommand } from './remoting-window';

export class CommandCollection {

    public static initialise(injector:Injector) {
        new InitiateDriveScanCommand(injector);
        new ViewSASKeyCommand(injector);
        new QueueImportCommand(injector);
        new ViewActivityCommand(injector);
        new DeleteApplicationCommand(injector);
        new DeleteProjectCommand(injector);
        new UpdateAppCompatReportCommand(injector);
        new UpdateAppIdCommand(injector);
        new DisableAssessmentGroupCommand(injector);
        new EnableAssessmentGroupCommand(injector);
        new ToggleShowFixedIssuesCommand(injector);
        new ApplyFixesCommand(injector);
        new EnableCheckCommand(injector);
        new DisableCheckCommand(injector);
        new ReportOptionsCommand(injector);
        new ResetApplicationActionCommand(injector);
        new UnpublishCommand(injector);
        new ToggleVirtualMachinesPanelCommand(injector);
        new CreateXCheckVirtualMachineCommand(injector);
        new CreateXCheckPatchTestCommand(injector);
        new ViewPackageCommand(injector);
        new ViewPackageMultiCommand(injector);
        new AddApplicationCommand(injector);
        new SetApplicationSourcePathCommand(injector);
        new AdminAssGrpsBackCommand(injector);
        new AdminAssGrpsChkBackCommand(injector);
        new AdminClearCacheCommand(injector);
        new AdminRemotingResetCommand(injector);
        new AdminRefreshMetricsCommand(injector);
        new IgnoreIssuesCommand(injector);
        new RemoveFailedAppsCommand(injector);
        new ImportMsiExtractsCommand(injector);
        new RemotingWindowCommand(injector);

        CommandCollection.CreateConversionTask = new CreateConversionTaskCommand(injector);
        CommandCollection.TaskRelease = new TaskReleaseCommand(injector);
        CommandCollection.ToggleEmptyTables = new ToggleViewEmptyTablesCommand(injector);
        CommandCollection.ToggleViewInternalTables = new ToggleViewInternalTablesCommand(injector);
        CommandCollection.SavePackage = new SavePackageCommand(injector);
        CommandCollection.PackageFind = new PackageFindCommand(injector);
        CommandCollection.PackageFindNext = new PackageFindNextCommand(injector);
        CommandCollection.PackageFindPrev=new PackageFindPrevCommand(injector);
        CommandCollection.ViewPackageSummaryInformation = new ViewPackageSummaryInformationCommand(injector);
        CommandCollection.Publish = new PublishCommand(injector);
        CommandCollection.PublishedTest =new PublishedTestCommand(injector);
        CommandCollection.EditPackage = new EditPackageCommand(injector);
        CommandCollection.OpenProjectSelector = new OpenProjectSelectorCommand(injector);
        CommandCollection.AddApplication = new AddApplicationCommand(injector);
        CommandCollection.SetApplicationSourcePath = new SetApplicationSourcePathCommand(injector);
        CommandCollection.ClickerScriptEdit = new ClickerScriptEditCommand(injector);
        CommandCollection.RemotingWindow =new RemotingWindowCommand(injector);
        CommandCollection.ApplicationDocumentation = new ApplicationDocumentationCommand(injector);
    }

    public static TaskRelease: TaskReleaseCommand;
    public static CreateConversionTask: CreateConversionTaskCommand;
    public static ToggleEmptyTables: ToggleViewEmptyTablesCommand;
    public static ToggleViewInternalTables: ToggleViewInternalTablesCommand;
    public static SavePackage: SavePackageCommand;
    public static PackageFind: PackageFindCommand;
    public static PackageFindNext: PackageFindNextCommand;
    public static PackageFindPrev:PackageFindPrevCommand;
    public static ViewPackageSummaryInformation: ViewPackageSummaryInformationCommand;
    public static Publish: PublishCommand;
    public static PublishedTest: PublishedTestCommand;
    public static OpenProjectSelector: OpenProjectSelectorCommand;
    public static EditPackage: EditPackageCommand;
    public static AddApplication: AddApplicationCommand;
    public static SetApplicationSourcePath: SetApplicationSourcePathCommand;
    public static ClickerScriptEdit: ClickerScriptEditCommand;
    public static RemotingWindow: RemotingWindowCommand;
    public static ApplicationDocumentation: ApplicationDocumentationCommand;

}
import { Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
    selector: 'complexity-column',
    templateUrl: './complexity-column.html',
    styleUrls:[ './complexity-column.scss']
}) 
export class ComplexityColumn {

    @Input('value') public value:number;

    constructor(
    ) {
    }
}
import * as CRS from '../api/CRS';
import * as IRS from '../api/IRS';

export class DISOAction {
    constructor(app: CRS.RSAction) {
        this.id = app.id;
        this.$$treeLevel = (app.isChild) ? 1 :0;
    }

    public update(app: CRS.RSAction) {
        this.item =app;
    }

    public id: string;
    public item: CRS.RSAction;
    public $$treeLevel:number =0;

    public static Merge(incoming: Array<CRS.RSAction>, mergeSet: Array<DISOAction>)
    {
        var merDict : { [id: string] : DISOAction } = {};
        mergeSet.forEach(x=> { merDict[x.id] = x; })
        var incDict:  { [id: string] : IRS.IRSAction } = {};
        incoming.forEach(x=> { incDict[x.id] = x; })

        incoming.forEach(x=> {
            let diso = merDict[x.id];
            if (diso) {
                diso.update(x);
            }
            else {
                let app = new DISOAction(x);
                app.update(x);
                mergeSet.push(app);
            }
        });

        let toDelete: Array<DISOAction> = [];
        mergeSet.forEach(x=> {
            if (!incDict[x.id])
                toDelete.push(x);
        });

        toDelete.forEach(x=> {
            mergeSet.splice(mergeSet.indexOf(x), 1);
            delete mergeSet[x.id];
        });
    }
}

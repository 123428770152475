import { Injector } from '@angular/core';
import { DriveScanService } from '../svc/driveScanService';
import { MenuService } from '../svc/menuService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';
import { DialogService,MessageType } from '../svc/dialogService';

export class ViewSASKeyCommand {

    constructor(
        private injector: Injector
    ) {
        this.dialogService = this.injector.get(DialogService);
        this.menuService = this.injector.get(MenuService);
        this.driveScanService=  this.injector.get(DriveScanService);
        this.menuService.register(Constants.MENU_VIEW_SAS_KEY, this.viewSASKey, Constants.ROUTE_PROJECTDRIVES )
    }

    private menuService: MenuService;
    private dialogService: DialogService;
    private driveScanService: DriveScanService;

    private viewSASKey: AppCommand = AppCommand.createWithShow(
        ()=> {
            return this.driveScanService.hasSASKey();
        },
        ()=> {
            this.dialogService.showMessage( "SAS Key", [ this.driveScanService.getSASKey()], MessageType.Info, "sas");
        },
        ()=> {
            return this.menuService.isRunnable(Constants.MENU_VIEW_SAS_KEY);
        }
    );

}
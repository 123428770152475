import {DLITEM} from './DLITEM';

export class DLITEMSEC {
    constructor(item: string, ordinal:number, owner: DLITEM) {
        this.item =item;
        this.hash=item;
        this.ordinal = ordinal;
    }
    public hash:string;
    public item:string;
    public ordinal: number;
}
import { Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { UtilService } from '../../svc/utilService';
import { INPUTEDITARGS } from '../../model/INPUTEDITARGS';

@Component({
    selector: 'input-edit',
    templateUrl: './input-edit.html',
    styleUrls: ['../../shared/css/core.scss', './input-edit.scss']
  
}) 
export class InputEdit implements AfterViewInit, OnInit{
    
    @Input('name') icon:string;
    @Input('context') context:string;
    @Input('label') label: string;
    @Input('value') value:any;
    @Input('disable') disable:boolean;
    @Input('datatype') datatype:string;
    @Input('focus') focus:boolean = true;
    @Input('format') format:string;
    @Input('rows') rows:number;
    
    @Output('on-change') onChange= new EventEmitter<INPUTEDITARGS>();
    @Output('on-cancel') onCancel=new EventEmitter<any>();
    @Output('on-blur') onBlur=new EventEmitter<any>();

    @ViewChild('ed1') ed1:ElementRef;
    @ViewChild('ed2') ed2:ElementRef;
    @ViewChild('ed3') ed3:ElementRef;
    @ViewChild('ed4') ed4:ElementRef;
    @ViewChild('ed5') ed5:ElementRef;
    @ViewChild('ed6') ed6:ElementRef;
    @ViewChild('ed7') ed7:ElementRef;

    public runningValue: any;
    public name:string;
    public minLength:number;
    private hasFocus: boolean = false;
    private isDirty: boolean=false;
    private initialFocusMade:boolean=false;

    constructor(
        private utilService: UtilService
    ) {
    }

    ngOnInit() {
        if (this.getDataTypeToken() == "FROMTO")
            this.runningValue = { v1: this.value.fromExpr, v2: this.value.toExpr };
    }

    ngAfterViewInit() {
        if (this.focus)
            setTimeout(()=>this.setInitialFocus(), 100);
    }

    private setInitialFocus(){
        var tgt:any=null;
        if (this.disable)
            return;
        switch(this.getValueType())
        {
            case "double":
                if (this.ed5)
                    tgt=this.ed5.nativeElement;
                break;
            case "unadorned":
                if (this.ed1)
                    tgt = this.ed1.nativeElement;
                break;
            default:
                if (this.ed3)
                    tgt=this.ed3.nativeElement;
                else if (this.ed2)
                    tgt = this.ed2.nativeElement;
                else if (this.ed4)
                    tgt = this.ed4.nativeElement;
                break;
        }
        if (tgt) {
            tgt.focus();
            tgt.select();
        }
        this.initialFocusMade=true;
    }

    private ngOnChanges(changes) {
        if (changes.value && changes.value.firstChange) {
            var val = changes.value.currentValue;
            if (this.format) {
                val = this.getFormatted(val);
            }
            this.runningValue = val;
        }
    }

    public canEdit(): boolean {
        return !this.disable;
    }

    public isEditing() : boolean {
        return this.hasFocus;
    }

    public isChanged(): boolean {
        return this.isDirty;
    }

    public getInputType(): string {
        let dt = this.getDataTypeToken();
        return dt=="PASSWORD" ? "password" : "text";
    }

    public getNoRows(): number {
        return (this.rows <= 0) ? 3: this.rows;
    }

    public getValueType() : string {
        if (this.getDataTypeToken() == "FROMTO") 
            return "double" 
        else if (this.isUnadornedIndicator()) // # prefix on first token results in simple input
            return "unadorned"
        return "single";
    }

    public isInvalid() : boolean {

        let v :any = this.runningValue;

        if (!v)
            return false;

        let dt = this.getDataTypeToken();
        let minc = this.getMinChars();

        if (dt != "FROMTO" && minc!=-1 && this.runningValue.length < minc)
            return true;

        switch(dt) {
            case "URL":
                var expression = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
                var regex = new RegExp(expression);
                try {
                    return v.match(regex) == null
                }
                catch(ex) {
                    return true;
                }
            case "SIMPLESTRING":
                var expression = /[^-a-zA-Z0-9@:%_\+.~#?&//=\s]/gi;
                var regex = new RegExp(expression);
                try {
                    return v.match(regex) != null
                }
                catch(ex) {
                    return true;
                }
            case "JSON":
                try {
                    if (v)
                        JSON.parse(v);
                    return false;
                }
                catch(ex) {
                    return true;
                }
            case "ANYSTRING":
                return false;
            case "VERSION":
                var expression=/^([0-9]+\.)+[0-9]+$/gi;
                var regex = new RegExp(expression);
                try {
                    return v.match(regex)==null;
                }
                catch(ex) {
                    return true;
                }
            case "DATE":
                var expression= /([A-Za-z]+)-([0-9]+)/gi;
                var regex = new RegExp(expression);
                try {
                var retx = regex.exec(v);
                if (retx==null)
                    return true;
                }
                catch(ex) {
                    return true;
                }
                return !(this.utilService.getMonthIndex(retx[1]) != -1 && (retx[2].length == 2 || retx[2].length == 4));
            case "APPID":
                var expression = /^[A-Za-z0-9_\-+.&%$!;:,?*=(){}\[\]]+$/;
                var regex = new RegExp(expression);
                try {
                    return v.match(regex)==null;
                }
                catch(ex) {
                    return true;
                }
            case "PUBLISHERNAME":
                var expression =/^(CN|L|O|OU|E|C|S|STREET|T|G|I|SN|DC|SERIALNUMBER|Description|PostalCode|POBox|Phone|X21Address|dnQualifier|(OID.(0 | [1-9][0-9])(.(0 | [1-9][0-9]))+))=(([^,+=" <>#;])+)*$/;
                var regex = new RegExp(expression);
                try {
                    var pn= v.match(regex)==null;
                    return pn;
                }
                catch(ex) {
                    return true;
                }
            case "FROMTO":
                return false;
            default: 
                return false;
        }

    }

    public getDataTypeToken(): string {
        if (!this.datatype)
            return null;
        let p = this.datatype.split('-');
        var dtt= p[0];
        if (dtt[0] == "#")
            dtt=dtt.substring(1);
        return dtt;
    }

    public isUnadornedIndicator() : boolean {
        if (!this.datatype || this.datatype.length < 1)
            return null;
        let p = this.datatype.split('-');
        return p[0][0] == "#"
    }

    private getFormatted(value): string {
        switch(this.datatype) {
            case "DATE":
                var dp = new DatePipe("en-US");
                return dp.transform(value, this.format);
                break;
        }
    }

    public isSimpleString(): boolean {
        return this.datatype != 'MSTRING'&& this.datatype != 'JSON';
    }
    
    public isMultiString(): boolean {
        return this.datatype == 'MSTRING';
    }

    private getMinChars() :number { 
        if (!this.datatype)
            return -1;
        let p =this.datatype.split('-');
        if (p.length>1) {
            for(var i=1;i<p.length;i++)
            {
                var regex = new RegExp(/MIN([0-9]+)/);
                var retx = regex.exec(p[i]);
                if (retx!=null)
                    return parseInt(retx[1]);
            }
        }
        return -1;
    }

    public setFocus(element:string, state: boolean) : void {
        if (this.initialFocusMade) {
            this.hasFocus=state;
            if (!state) {
                var z= document.activeElement;      
                this.onBlur.emit(null);
            }
        }
    }

    public notifyChanged() : void {
        this.isDirty=true;
    }

    public save() : void {
        if (this.getDataTypeToken() == "FROMTO") {
            this.value.fromExpr = this.runningValue.v1;
            this.value.toExpr = this.runningValue.v2;
        }
        else {
            this.value = this.runningValue;
        }
        if (this.onChange)
            this.onChange.emit(new INPUTEDITARGS(this.context, this.value));
        this.isDirty=false;
    }

    public cancel(): void {
        if (this.getDataTypeToken() == "FROMTO") {
            this.runningValue = { v1: this.value.fromExpr, v2: this.value.toExpr};
        }
        else {
            this.runningValue = this.value;
        }
        this.onCancel.emit(null);
        this.isDirty=false;
    }


}
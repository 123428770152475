<div class="sw-pad" fxLayout="column" fxFlexFill>

    <div fxFlex="none" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
        <div fxFlex="none">
            <mat-icon class="sw-error" image-size size="36pt" style="width:50px;height:50px">warning</mat-icon>
        </div>
        <div fxFlex>
            <h2>ACTION FAILED</h2>
        </div>
    </div>

    <div fxFlex="none">
        <div sw-pad></div>
    </div>

    <div fxFlex="none" class="sw-error">This action failed to complete successfully.</div>

    <div fxFlex="none" class="sw-pad"></div>

    <div fxFlex="none" class="sw-error">{{job.actionError}}</div>

    <div fxFlex="none" *ngIf="job.task">
        <h4 class="sw-errordark" *ngIf="job.task.installerExitCode">Installer failed returning error code {{job.task.installerExitCode}}</h4>
    </div>  
    <div fxFlex="none" *ngIf="job.task">
        <h4 class="sw-errordark" *ngIf="job.task.installerExitMessage">{{job.task.installerExitMessage}}</h4>
    </div>  

    <div fxFlex="none" *ngIf="job.task && job.task.type != 'MsiGather' && !isApplicationBasedRoute()" class="sw-pad-bottom">
        <div><a href="" (click)="goToApp($event)">View Application</a></div>
        <div class="sw-pad"></div>
    </div>

    <div fxFlex="none" *ngIf="job.task && job.task.outputItems.length > 0">
        <conversion-task-output></conversion-task-output>
    </div>

    <div fxFlex="none" *ngIf="job.task && job.task.retainedFiles.length > 0">
        <conversion-task-retained></conversion-task-retained>
    </div>

    <div fxFlex>
        <div class="sw-pad-top full-height-bs">
            <conversion-task-log-view [light]="true" class="log-view"></conversion-task-log-view>    
        </div>
    </div>

</div>



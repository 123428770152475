import { Injector } from '@angular/core';
import { CurrentProjectService } from "../svc/currentProjectService";
import { MenuService } from '../svc/menuService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';

export class RemoveFailedAppsCommand {

    constructor(
        private injector: Injector
    ) {
        this.menuService = this.injector.get(MenuService);
        this.currentProject = this.injector.get(CurrentProjectService);
        
        this.menuService.register(Constants.MENU_REMOVE_FAILED_APPS, this.command, Constants.ROUTE_PROJECTAPPLICATIONS )
    }

    private menuService: MenuService;
    private currentProject: CurrentProjectService;

    private command: AppCommand = AppCommand.createWithShow(
        ()=> {
            return this.currentProject.dataContext.isReady && this.currentProject.dataItem.applications.filter(a=>a.hasFailed).length>0;
        },
        () => {
            this.currentProject.clearImportFailures();
        },
        () => {
            return this.currentProject.dataContext.isReady;
        }
    );

}
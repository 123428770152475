export class TrackedOperatingSystem {

    public id: string ;
    public operatingSystemId: string;
    public checkpoint: string;
    public isChecked: boolean;
    public origin: string;
    public originalTestEnabled:boolean;
    public msixTestEnabled: boolean;

    constructor(operatingSystemId: string, checkpoint: string, origin: string ) {
        this.id= operatingSystemId + "_" + checkpoint + "_" + origin;
        this.operatingSystemId = operatingSystemId;
        this.checkpoint = checkpoint;
        this.origin = origin;
        this.isChecked=false;
    }
}
<div *ngIf="!hasItems()">
    No firewall items were created by this installation
</div>

<table style="padding-left:10px;width:100%" *ngIf="hasItems()">
    <thead>
        <tr>
            <th>Name</th>
            <th>Display</th>
            <th>Enabled</th>
            <th>Direction</th>
            <th>Action</th>
            <th>Program</th>
            <th>Protocol</th>
            <th>Local Port</th>
        </tr>
    </thead>
    <tr *ngFor="let cp of item.firewallItems">
        <td style="min-width: 150px;" class="sw-column-top">{{cp.name}}</td>
        <td style="min-width: 150px;" class="sw-column-top">{{cp.displayName}}</td>
        <td style="min-width: 80px;" class="sw-column-top">{{cp.enabled}}</td>
        <td style="min-width: 80px;" class="sw-column-top">{{cp.direction}}</td>
        <td style="min-width: 80px;" class="sw-column-top">{{cp.action}}</td>
        <td style="min-width: 150px;" class="sw-column-top">{{cp.program}}</td>
        <td style="min-width: 80px;" class="sw-column-top">{{cp.protocol}}</td>
        <td style="min-width: 80px;" class="sw-column-top">{{cp.localPort}}</td>
    </tr>
</table>


import { Injectable } from '@angular/core';
import { Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EventService {
    static $inject = ['$rootScope', '$state'];

    constructor()
    { 
    }

    private _onLocationChanged = new Subject<any>();
    public onLocationChange() {
        return this._onLocationChanged.asObservable();
    }

    private _onPlatformEdited = new Subject<any>();
    public onPlatformEdited() {
        return this._onPlatformEdited.asObservable();
    }

    private _onProjectIdChanged= new Subject<any>();
    public onProjectIdChanged() {
        return this._onProjectIdChanged.asObservable();
    }

    private _onProjectDetailsUpdateRequest = new Subject<any>();
    public onProjectDetailsUpdateRequest() {
        return this._onProjectDetailsUpdateRequest.asObservable();
    }

    private _onFixedIssuesVisibilityChanged = new Subject<boolean>();
    public onFixedIssuesVisibilityChanged() {
        return this._onFixedIssuesVisibilityChanged.asObservable();
    }

    private _onCheckEnabledStateChanged =new Subject<any>();
    public onCheckEnabledStateChanged() {
        return this._onCheckEnabledStateChanged.asObservable();
    }

    private _onPackageMoveToItemChanged =new Subject<any>();
    public onPackageMoveToItemChanged() {
        return this._onPackageMoveToItemChanged.asObservable();
    }

    private _onPackageFindExecutedRefresh = new Subject<any>();
    public onPackageFindExecutedRefresh() {
        return this._onPackageFindExecutedRefresh.asObservable();
    }

    public __locationChangedTrigger() {
        this._onLocationChanged.next();
    }
    public __platformEditedTrigger() {
        this._onPlatformEdited.next();
    }
    public __projectIdChangeTrigger() {
        this._onProjectIdChanged.next();
    }
    public __projectDetailsUpdateRequestTrigger() {
        this._onProjectDetailsUpdateRequest.next();
    }
    public __fixedIssuesVisibilityChangedTrigger(state:boolean) {
        this._onFixedIssuesVisibilityChanged.next(state);
    }
    public __checkEnabledStateChangedTrigger() {
        this._onCheckEnabledStateChanged.next();
    }
    public __packageMoveToItemChangedTrigger() {
        this._onPackageMoveToItemChanged.next();
    }
    public __packageFindExecutedRefreshTrigger() {
        this._onPackageFindExecutedRefresh.next();
    }

}


import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Column, GridOption, Formatter, Formatters, FieldType, Editors, OperatorType, SlickGrid, GridStateChange } from 'angular-slickgrid';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CurrentApplicationService } from '../../svc/currentApplicationService';
import { Constants } from '../../api/Constants';
import { StateHelperService } from '../../svc/stateHelperService';
import { PollingService } from '../../svc/pollingService';
import { UtilService } from '../../svc/utilService';
import { CreateInstallDependencyDialog } from '../../dialogs/create-install-dependency/create-install-dependency-dialog';

import * as CRS from '../../api/CRS';

@Component({
  //selector: Constants.ROUTE_PROJECTAPPLICATIONHISTORY,
  selector: 'project-application-prerequisites',
  templateUrl: './project-application-prerequisites.html',
})
export class ProjectApplicationPrerequisites implements OnInit, OnDestroy {

  constructor(
      private currentApplication: CurrentApplicationService,
      private stateHelperService: StateHelperService,
      private dialog: MatDialog, 
      private pollingService: PollingService,
      private utilService: UtilService
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    
  }

  public isDataReady() : boolean {
    return this.currentApplication.dataContext.isReady;
  }

  public get application(): CRS.RSGetApplication {
    return this.currentApplication.dataItem;
  }

  public add($event: Event) {
    if ($event)
      $event.stopPropagation();
    const dialogRef = this.dialog.open(CreateInstallDependencyDialog);
    dialogRef.afterClosed().subscribe( answer => {
      if (answer)
        this.currentApplication.registerInstallDependency(answer.relationshipType, answer.value)
    });
    return false;
  }

  public moveUp(dep: CRS.RSApplicationInstallDependency) {
    this.currentApplication.moveUpInstallDependency(dep);
  }

  public moveDown(dep: CRS.RSApplicationInstallDependency) {
    this.currentApplication.moveDownInstallDependency(dep);
  }

  public delete(dep:CRS.RSApplicationInstallDependency) {
    this.currentApplication.deleteInstallDependency(dep);
  } 

}

import { Injector } from '@angular/core';
import { CurrentApplicationService } from "../svc/currentApplicationService";
import { MenuService } from '../svc/menuService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';
import { DialogService } from '../svc/dialogService';
import { UtilService } from '../svc/utilService';
import { StateHelperService } from '../svc/stateHelperService';
import { CurrentConversionJobService } from '../svc/currentConversionJobService';

export class ImportMsiExtractsCommand {

    constructor(
        private injector: Injector
    ) {
        this.menuService = this.injector.get(MenuService);
        this.utilService = this.injector.get(UtilService);
        this.dialogService = this.injector.get(DialogService);
        this.currentApplication = this.injector.get(CurrentApplicationService);
        this.currentJob = this.injector.get(CurrentConversionJobService);
        this.stateHelperService = this.injector.get(StateHelperService);
        
        this.menuService.register(Constants.MENU_IMPORT_MSI_EXTRACTS, this.command, Constants.ROUTE_PROJECTAPPLICATIONACTIONSDETAIL_MAIN )
    }

    private stateHelperService: StateHelperService;
    private utilService: UtilService;
    private menuService: MenuService;
    private dialogService: DialogService;
    private currentApplication: CurrentApplicationService;
    private currentJob: CurrentConversionJobService;

    private command: AppCommand = AppCommand.createWithShow(
        ()=> {
            if (!this.currentJob.dataContext.isReady || !this.currentJob.hasTask()) 
                return false;
            var tsk = this.currentJob.dataItem.task;
            if (tsk.jobType != 1)
                return false;
            return (tsk.msiInstallersExtracted != null && tsk.msiInstallersExtracted.filter(x=>x.isChecked).length > 0);
        },
        ()=> {
            var tsk = this.currentJob.dataItem.task;
            let toImport: Array<string> = [];
            tsk.msiInstallersExtracted.filter(x=>x.isChecked).forEach(x=> {
                toImport.push( x.filename);
            });
            this.currentApplication.importMsiExtracts(tsk.id, toImport).then((ret)=>{
                var pl = (toImport.length==1) ? "" : "s";
                var pl2 = (toImport.length==1) ? "has": "have";
                this.dialogService.showInfoMessage("Import Extracted MSI", [ `${toImport.length} item${pl} ${pl2} been queued for import`]);
            });
        },
        ()=> {
            if (!this.currentJob.dataContext.isReady || !this.currentJob.hasTask()) 
                return false;
            if (this.currentJob.dataItem.task.jobType != 1)
                return false;
            return true;
        }
    );


}


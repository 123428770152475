
<div fxLayout="column" *ngIf="isDataReady()" class="sw-platform-editor-container full-height-bs">

    <div fxFlex="none" fxLayout="row">
        <div fxFlex></div>
        <a fxFlex="none" mat-icon-button href="" target="_blank" (click)="close($event)" id="close-button">
            <mat-icon>close</mat-icon>
        </a>
    </div>
    
    <div fxFlex class="sw-slider-container full-width-bs">
        <label for="osSlider">Operating System</label>
        <ngx-slider id="osSlider" [(value)]="osSlider.min" [(highValue)]="osSlider.max" [options]="osSlider.options" (userChangeEnd)="osSlider.updateEnd($event)"></ngx-slider>
    </div>

    <div fxFlex class="sw-slider-container full-width-bs" *ngIf="currentOSHasUpdates()" id="muSliderWrap">
        <div id="muSliderFillStart"></div>
        <div id="muSliderStart"></div>  
        <ngx-slider id="muSlider" [(value)]="updateDateSlider.value" [options]="updateDateSlider.options" style="max-width:200px" (userChangeEnd)="updateDateSlider.updateEnd($event)"></ngx-slider>
        <div id="muSliderEnd"></div>    
    </div>

    <div fxFlex class="sw-slider-container full-width-bs" *ngIf="officeSlider.items">
        <label style="width:120px" for="offSlider">Office Suite</label>
        <ngx-slider if="offSlider" class="custom-slider" [(value)]="officeSlider.value" [options]="officeSlider.options" (userChangeEnd)="officeSlider.updateEnd($event)"></ngx-slider>
    </div>

    <div fxFlex class="sw-slider-container full-width-bs" *ngIf="browserSlider.items">
        <label style="width:120px" for="browSlider">Browser</label>
        <ngx-slider id="browSlider" class="custom-slider" [(value)]="browserSlider.value" [options]="browserSlider.options"  (userChangeEnd)="browserSlider.updateEnd($event)"></ngx-slider>
    </div>

    <div fxFlex class="sw-slider-container full-width-bs" *ngIf="virtualisationSlider.items">
        <label for="virtSlider">Virtualisation Technology</label>
        <ngx-slider id="virtSlider" class="custom-slider" [(value)]="virtualisationSlider.value" [options]="virtualisationSlider.options" (userChangeEnd)="virtualisationSlider.updateEnd($event)"></ngx-slider>
    </div>

    <div fxFlex class="sw-slider-container full-width-bs">
        <label for="dotnetSlider">DotNet Version</label>
        <ngx-slider id="dotnetSlider" class="custom-slider" [(value)]="dotNetSlider.value" [options]="dotNetSlider.options"  (userChangeEnd)="dotNetSlider.updateEnd($event)"></ngx-slider>
    </div>

</div>
<div fxLayout='column' fxFill class='full-height-bs' >

    <div flex="none" class="sw-vert-pad">
        <div fxFlex fxLayout="row">
            <div fxFlex class="sw-pad" [ngClass]="isActivePatchGroup(pg) ? 'sw-proj-option-active' : 'sw-proj-option-inactive'" *ngFor="let pg of patchGroups?.items">
                <button mat-button (click)="selectPatchGroup(pg)"> 
                    {{pg?.title}}
                </button>
            </div>
        </div>
    </div>

    <div fxFlex class="sw-segment">

        <spinner *ngIf="patchGroupDetailsLoading"></spinner>

        <div *ngIf="patchGroupDetails && !patchGroupDetailsLoading" fxLayout="row" layout-wrap class="admin-flex-wrap sw-pad" style="font-style: normal; font-size: 10pt;">
            <div fxFlex="50" fxLayout="column" class="sw-pad-small" *ngFor="let pgi of patchGroupDetails">
                <div fxFlex fxLayout="row" class="sw-patch-cat-outer">
                    <div fxFlex="20" fxLayout="row" class="sw-patch-cat" [ngClass]="getPatchCatStateClass(pgi)">
                        <mat-select [(ngModel)]="pgi.category" (selectionChange)="categoryChange(pgi)" style="align-self: center; align-items: center;">
                            <mat-option [value]="c.id" *ngFor="let c of patchCategories">{{c.text}}</mat-option>
                        </mat-select>
                    </div>
                    <div fxFlex fxLayout="column">
                        <div fxFlex><a href="" (click)="openUrl(pgi)">{{pgi?.id}}</a></div>
                        <div fxFlex>{{pgi?.title}}</div>  
                    </div>
                </div>
            </div>
        </div>
        
    </div>  

</div>
import { Component, Input } from '@angular/core';
import { IGridAssistant } from 'src/app/model/IGridAssistant';

import * as CRS from '../../api/CRS';

@Component({
    selector: 'grid-assist-panel',
    templateUrl: './grid-assist-panel.html',
})

export class GridAssistPanel {

    @Input('grid') public gridAssist : IGridAssistant;

    constructor(
    ) {
    }
 
}
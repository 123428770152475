<div fxLayout="row" class="full-width-bs full-height-bs">

    <spinner fxFlex *ngIf="!isDataReady()"></spinner>

    <div fxFlex *ngIf="isDataReady()" class="sw-segment">
        <conversion-task-converted *ngIf="jobHasTask"></conversion-task-converted>
        <conversion-task-test *ngIf="jobIsUnstartedTestJob"></conversion-task-test>
        <conversion-task-edit *ngIf="jobIsEditJob"></conversion-task-edit>
        <conversion-task-convert *ngIf="jobIsUnstartedConversionJob"></conversion-task-convert>
        <conversion-task-publish *ngIf="jobIsUnstartedPublishJob"></conversion-task-publish>
        <conversion-task-failed *ngIf="jobIsFailed"></conversion-task-failed>
        <conversion-task-inprogress *ngIf="jobIsInProgress"></conversion-task-inprogress>
    
    </div>

</div>  
import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentApplicationService } from "../svc/currentApplicationService";
import { UtilService } from '../svc/utilService';
import { Constants } from '../api/Constants';
import { ConversionService } from '../api/SVC';

export class CreateConversionTaskCommand {

    constructor(
        private injector:Injector
   ) {
       this.router = this.injector.get(Router);
       this.conversionService = this.injector.get(ConversionService);
       this.currentApplication = this.injector.get(CurrentApplicationService);
       this.utilService = this.injector.get(UtilService);
    }

    private router:Router;
    private conversionService:ConversionService;
    private currentApplication: CurrentApplicationService;
    private utilService: UtilService;

    private projectId:string;
    private applicationId:string;

    public args:string=null;
    public companionFiles: Array<string> = [];
    public virtualiseFromRepack:boolean=false;
    public fixTransformPlatform:number=0;
    public useResponseTransform:boolean=false;
    public useExtractTransform:boolean=false;
    public msiExtractOnly:boolean=false;
    public useArpProductDetails:boolean=false;
    public usePVAD:boolean=false;
    public PVADDirectory:string=null;
    public manualModificationRequired:boolean=false;
    public useExistingSnapshot: boolean=false;

    public execute(conversionType:string) {

        this.projectId=this.currentApplication.dataItem.projectId; 
        this.applicationId=this.currentApplication.dataItem.id;
 
        this.conversionService.convertApplication(
            this.projectId, 
            this.applicationId,
            conversionType, 
            this.args,
            null, 
            null,
            null,
            this.companionFiles,
            this.virtualiseFromRepack,
            this.fixTransformPlatform, 
            this.useResponseTransform, 
            this.useExtractTransform,
            this.msiExtractOnly, 
            this.useArpProductDetails,
            this.usePVAD,
            this.PVADDirectory,
            this.manualModificationRequired,
            this.useExistingSnapshot
            ).then((response)=> {
                this.redirect(response.data.jobId);
            });
    }

    
    private redirect(jobId:string) { 

        this.currentApplication.dataContext.refreshCurrent().then((data)=> {
            this.router.navigate([
                Constants.ROUTE_PROJECTDETAILS_MAIN, 
                this.utilService.compressGuidWithSvr(this.projectId),
                Constants.ROUTE_PROJECTAPPLICATIONS,
                Constants.ROUTE_PROJECTAPPLICATIONLANDING_MAIN, 
                this.utilService.compressGuid(this.applicationId),
                Constants.ROUTE_PROJECTAPPLICATIONACTIONSDETAIL_MAIN, 
                this.utilService.compressGuid(jobId) ]);   
        });
    }
}


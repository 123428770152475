<h2 mat-dialog-title>CREATE GRID PROFILE</h2>
  
<div mat-dialog-content class='sw-dialog-padding' style="width:450px">

    <div fxLayout="column" fxLayoutGap="5px">
        <div fxFlex>
            <p>Profile Name:</p>
            <input type="text" [(ngModel)]="values.name" class="full-width-bs">
        </div>
    </div>

</div>

<div mat-dialog-actions fxLayout='row' fxLayoutGap="10px">
    <div fxFlex></div>
    <div fxFlex="none">
        <button mat-raised-button color="primary" (click)='closeDialog()' [disabled]="!isValid()">
            <mat-icon>done</mat-icon>
            <span>Accept</span>
        </button>
    </div>
    <div fxFlex="none">
        <button mat-raised-button (click)='cancelDialog()'>
            <mat-icon>cancel</mat-icon>
            <span>Cancel</span>
        </button>
    </div>
</div>

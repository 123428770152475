<div fxLayout="row" fxLayoutGap="10px">

    <div fxFlex *ngIf="!hasItems()" class="sw-pad">
        No shortcuts were created by this installation
    </div>
            
    <div fxFlex *ngFor="let srtr of item.shortcutItems" fxLayout="column" class="sw-task-shtcut-image">
        <div fxFlex fxLayout="column" fxLayoutAlign="center center"> 
            <div *ngIf="!srtr.screenshot">NO IMAGE</div>
            <img *ngIf="srtr.screenshot" [src]="getScreenshot(srtr)" style="max-width:300px">
        </div>
        <div fxFlex="20" class="sw-task-shtcut-image-foot" fxLayout="column" fxLayoutAlign="center center">
            <div fxFlex>
                <h3>{{srtr.shortcutFilename}}</h3>
            </div>
        </div>
    </div>

</div>
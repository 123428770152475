<div fxLayout='row' class="full-width-bs full-height-bs" *ngIf="isDataReady()">

    <div *ngIf="task && task.responseStatus != 0" fxFlex fxLayout="row" fxLayoutAlign="start start">
        <div fxFlex>
            <img src="img/misc/error.png" width="60px" height="60px">
        </div>
        <div fxFlex>
            <h2>Conversion job not found</h2>
        </div>
    </div>

    <div fxLayout='column' fxLayoutGap="25px" fxFlex fxLayoutAlign="start stretch">

        <div fxFlex fxLayout="column" fxLayoutAlign="start stretch" *ngIf="task && task.isInProgress && !isCancellingTask()">
            <div fxFlex fxLayout="row" class="event-container">
                <div fxFlex>
                    <!-- <timeline-right time-running="vm.timeRunning" conversion-task="vm.conversionTask"  log-items="vm.jobLog" class="full-height"/> -->
                </div>
            </div>
        </div>

        <div fxFlex="none" *ngIf="task && task.nothingGeneratedWarning" class="conversion-complete-container">
            <div fxLayout="row" fxLayoutGap="5px">
                <div fxFlex="none">
                    <img src="img/misc/warning.png" style="width:48px"/>
                </div>    
                <div fxFlex>This conversion generated a package, but didn't capture any files or registry keys.</div>
            </div>
        </div>

        <div fxFlex="none" *ngIf="task && task.status == 4 && !task.nothingGeneratedWarning">
            <div fxLayout="row" class="full-width-bs full-height-bs">
                <div fxFlex fxLayout="column">
                    <div fxFlex>
                        <conversion-task-completion-info></conversion-task-completion-info> 
                    </div>  
                </div>
            </div>
        </div>

        <div fxFlex *ngIf="task && task.status == 3" class="conversion-complete-container" fxLayout="column" fxLayoutGap="10px">
            <div fxFlex="none" class="sw-pad-top">
                <h2 class="sw-error">Conversion Cancelled</h2>
            </div>  
            <div fxFlex="none" *ngIf="task && task.type != 'MsiGather' && !isApplicationBasedRoute()" class="sw-pad-bottom">
                <div><a href="" (click)="goToApp($event)">View Application</a></div>
                <div class="sw-pad"></div>
            </div>
            <div fxFlex>
                 <conversion-task-log-view [light]="true" class="log-view"></conversion-task-log-view>    
            </div>
        </div>

        <div fxFlex *ngIf="task && task.status == 5" class="conversion-complete-container" fxLayout="column">
            <div fxFlex="none">
                <h2 class="sw-error">Conversion timed out</h2>
                <div *ngIf="task.installerExitCode">
                    <h4 class="sw-error">Error code was {{task.installerExitCode}}</h4>
                </div>  
                <div *ngIf="task.installerExitMessage">
                    <h4 class="sw-error">{{task.installerExitMessage}}</h4>
                </div>  
            </div>  
            <div fxFlex>
                <conversion-task-log-view [light]="true" class="log-view"></conversion-task-log-view>
            </div>
        </div>

        <div fxFlex fxLayout="row" fxLayoutAlign="center center" class="md-padding" *ngIf="isCancellingTask()">
            <span>Task is being cancelled. This may take some time to complete.</span>
        </div>

        <div fxFlex="none" *ngIf="task.outputItems.length > 0 && !currentConversionJob.isFailed()">
            <conversion-task-output></conversion-task-output>
        </div>

        <div fxFlex="none" *ngIf="task && task.retainedFiles && !currentConversionJob.isFailed()">
            <conversion-task-retained></conversion-task-retained>
        </div>

    </div>

</div>
